import noop from 'lodash/noop'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { useForm } from '../../../../../hooks'
import { usePredefinedInvoiceValues } from '../hooks/usePredefinedInvoiceValues'
import { InvoiceFormSchema } from '../types/invoiceFormSchema'
import { PredefinedInvoiceValues } from '../types/predefinedInvoiceValues'
import { getDefaultValues } from '../utils/getDefaultValues'
import { getInvoiceFormSchema } from '../utils/getInvoiceFormSchema'
import { useInvoiceEditorVatRates } from './InvoiceEditorVatRatesContext'

interface ContextState {
  isLoading: boolean
  predefinedInvoiceValues: PredefinedInvoiceValues
  validate: () => Promise<boolean>
}

const InvoiceEditorFormContext = createContext<ContextState | undefined>(undefined)

interface InvoiceEditorFormContextProviderProps {
  children?: ReactNode
}

export const InvoiceEditorFormContextProvider = ({ children }: InvoiceEditorFormContextProviderProps): ReactElement => {
  const { t } = useTranslation()
  const { setDirty } = useDirtyRoute()

  const {
    Form,
    formState: { isDirty },
    handleSubmit,
    trigger,
    reset: resetForm,
  } = useForm<InvoiceFormSchema>({
    defaultValues: getDefaultValues(),
    validationSchema: getInvoiceFormSchema(t),
  })
  const { isLoading: isLoadingVatRates } = useInvoiceEditorVatRates()
  const { predefinedInvoiceValues, isLoading: isLoadingPredefinedInvoiceValues } = usePredefinedInvoiceValues()

  const isLoading = isLoadingVatRates || isLoadingPredefinedInvoiceValues

  // we're using a fake submit for validation due to how useForm's mode param works
  const handleValidation = useCallback(async () => {
    await handleSubmit(noop)()
    return trigger()
  }, [handleSubmit, trigger])

  useEffect(() => {
    if (predefinedInvoiceValues && !isLoading) {
      const updatedFormValues = getDefaultValues(predefinedInvoiceValues)

      resetForm(updatedFormValues)
    }
  }, [isLoading, predefinedInvoiceValues, resetForm])

  useEffect(() => {
    setDirty(isDirty)
    // Run effect only on `isDirty` value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty])

  return (
    <InvoiceEditorFormContext.Provider value={{ isLoading, predefinedInvoiceValues, validate: handleValidation }}>
      <Form>{children}</Form>
    </InvoiceEditorFormContext.Provider>
  )
}

export const useInvoiceEditorForm = () => {
  const context = useContext(InvoiceEditorFormContext)

  if (!context) {
    throw new Error('InvoiceEditorFormContext is missing in the module!')
  }

  return context
}
