import { getCurrencyNumber } from './getCurrencyNumber'
import { getFormattedNumber } from './getFormattedNumber'
import { getNumberSeparators } from './getNumberSeparators'
import { getPercentNumber } from './getPercentNumber'
import { parseToNumber } from './parseToNumber'
import { sanitizeNumberString } from './sanitizeNumberString'

export const NumberFormat = {
  getCurrencyNumber,
  getFormattedNumber,
  getNumberSeparators,
  getPercentNumber,
  parseToNumber,
  sanitizeNumberString,
}
