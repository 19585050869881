import { useModalContentToggler } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { ModalId } from '../../../../../../enums/modalId'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { useSegment } from '../../../../../../hooks'
import { useCreateBillReconciliationSuggestion } from '../../../../hooks/useAddBillReconciliationSuggestion'
import { useReconcileBill } from '../../../../hooks/useReconcileBill'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMatch } from '../../contexts/matchContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'

export const MatchModalFooter = (): ReactElement => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { initialBill, selectedDifferenceType } = useBillReconciliation()
  const { hasMatchDifference, matchSuggestion } = useMatch()
  const { setActiveState } = useModalContentToggler()
  const { isProcessing } = useCreateBillReconciliationSuggestion()
  const { close: closeMatchModal } = useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Pay and reconcile modal closed')
    },
  })

  const initialBillId = initialBill?.id

  const { reconcileBill, isProcessing: isProcessingReconcile } = useReconcileBill({
    matchId: matchSuggestion?.match_id,
    billId: initialBillId,
    selectedDifferenceType,
    onSuccess: async () => {
      closeMatchModal()
      track('xxx - expenses - Pay and reconcile modal - register payment clicked')
      if (initialBillId) {
        await queryClient.cancelQueries({ queryKey: [QueryKeys.BillRecociliationMatches, initialBillId] })
        queryClient.setQueryData([QueryKeys.BillRecociliationMatches, initialBillId], undefined)
      }
    },
  })

  const isEnterPaymentButtonDisabled = useMemo(
    () => isProcessing || (hasMatchDifference && !selectedDifferenceType),
    [hasMatchDifference, isProcessing, selectedDifferenceType],
  )

  const handleEnterPayment = useCallback(async () => {
    reconcileBill()
  }, [reconcileBill])

  const handleRegisterMultipleBillsButton = useCallback(() => {
    track('xxx - expenses - Pay and reconcile modal - register mutliple bills clicked')

    setActiveState(BillReconciliationModalStateId.MultipleBills)
  }, [setActiveState, track])

  return (
    <ButtonsGroup>
      <Button onClick={handleRegisterMultipleBillsButton} variant="text" icon="papersPile">
        {t('bill.payment_reconcile.button.register_multiple_bills')}
      </Button>
      <Button onClick={handleEnterPayment} loading={isProcessingReconcile} disabled={isEnterPaymentButtonDisabled}>
        {t('bill.payment_reconcile.button.enter_payment_and_reconcile')}
      </Button>
    </ButtonsGroup>
  )
}
