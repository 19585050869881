import { trackError } from '../../../../../utils/trackError'
import { InvoiceBalanceModifierReference } from '../enums/InvoiceBalanceModifierReference'

const billBalanceModifierReferenceValues = Object.values(InvoiceBalanceModifierReference) as string[]

interface BalanceModifierReferenceResult {
  modifierType: InvoiceBalanceModifierReference | undefined
  modifierId: string | undefined
}

export const getInvoiceBalanceModifierReference = (
  modifierReference?: string | null,
): BalanceModifierReferenceResult | undefined => {
  const [modifierType, modifierId] = (modifierReference || '').split(':')

  if (!billBalanceModifierReferenceValues.includes(modifierType)) {
    trackError('Passed modifierReference type is not in the InvoiceBalanceModifierReference enum')
    return undefined
  }

  return { modifierType: modifierType as InvoiceBalanceModifierReference, modifierId }
}
