import { useEffect, useRef } from 'react'

const MAX_POLLING_ATTEMPTS = 5
const POLLING_INTERVAL = 5000

interface UsePollingProps {
  onPoll: () => void
  interval?: number
  isEnabled?: boolean
  maxAttempts?: number
}

export const usePolling = ({
  onPoll,
  interval = POLLING_INTERVAL,
  isEnabled = true,
  maxAttempts = MAX_POLLING_ATTEMPTS,
}: UsePollingProps): void => {
  const pollingAttempts = useRef(0)
  useEffect(() => {
    if (isEnabled) {
      const fetchIntervalId = setInterval(() => {
        if (pollingAttempts.current < maxAttempts) {
          onPoll()
          pollingAttempts.current += 1
        } else {
          clearInterval(fetchIntervalId)
        }
      }, interval)

      return () => clearInterval(fetchIntervalId)
    }
  }, [interval, isEnabled, maxAttempts, onPoll])
}
