import { Button, ButtonsGroup, Color, IconButton, Space } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import * as Styled from './styles'

export const FindAccountant = (): ReactElement => {
  const { t } = useTranslation()
  const { isWidgetHidden, toggleWidgetVisibility, isEditMode } = useDashboardSettings()

  const { url: settingsOrganizationUsersUrl } = useEmberRouteUrl(EmberRoute.SettingsOrganizationUsers)
  const findAccountantRoute = reactRoute.findAccountant.getMainRoute()
  const { url: findAccountantUrl } = useOrganizationViewUrl(findAccountantRoute)

  const isHidden = isWidgetHidden(WidgetId.FindAccountant)

  const handleHideWiget = useCallback(() => {
    toggleWidgetVisibility({ id: WidgetId.FindAccountant, shouldHide: true })
  }, [toggleWidgetVisibility])

  return (
    <Widget isHidden={isHidden} backgroundColor={Color.Green10}>
      <Styled.FindAccountantWrapper>
        <Styled.Header>
          <Space size="s" />
          <Styled.Title variant="displayS">{t('dashboard.widget.find_accountant.title')}</Styled.Title>
          <Space />
          <Styled.Content>{t('dashboard.widget.find_accountant.content')}</Styled.Content>
        </Styled.Header>
        <ButtonsGroup>
          <Button type="button" variant="secondary" to={findAccountantUrl}>
            {t('dashboard.widget.find_accountant.find_bookkeeper.cta')}
          </Button>
          <Button type="button" variant="text" to={settingsOrganizationUsersUrl}>
            {t('dashboard.widget.find_accountant.invite_bookkeeper.cta')}
          </Button>
        </ButtonsGroup>
        <Styled.Illustration />
      </Styled.FindAccountantWrapper>
      {!isEditMode && (
        <Styled.CloseIconWrapper>
          <IconButton icon="xSign" size="m" onClick={handleHideWiget} color={Color.DeepSeaGreen} />
        </Styled.CloseIconWrapper>
      )}
    </Widget>
  )
}
