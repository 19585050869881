import { trackError } from '../trackError'
import { reactRoute } from './reactRoute'

// this function maps the deleted ember route to the new react route
// it is useful by link-module web-component and most probably shouldn't be used anywhere else
export const getReactRouteFromDeletedEmberRoute = (resourceName: string, resourceId?: string, arg2?: string) => {
  if (resourceName === 'external_invoice') {
    return reactRoute.invoices.getViewInvoiceRoute(resourceId, arg2)
  }

  if (resourceName === 'bill') {
    return reactRoute.bills.getViewBillRoute(resourceId)
  }

  if (resourceName === 'bill_from_contact') {
    return reactRoute.bills.getNewBillFromContactRoute(resourceId)
  }

  trackError(`No reactRoute equivalent found for route "${resourceName}"`)

  return ''
}
