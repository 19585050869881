import { DraggableItemList, InputNumberLocalized } from '@components'
import { Divider, FormItemsGroup, Input, Radio, RadioGroup, Space, Switch, Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFieldArray, useFormContext } from '../../../../../../../../../hooks/useForm'
import { AddDeductionComponentButton } from '../../../shared'
import { useCreateOrEditVatRateFromTemplateModal } from '../../context/createOrEditVatRateFromTemplateModalContext'
import { CreateOrEditVatRateFromTemplateFormSchema, getDefaultDeductionComponent } from '../../utils/formData'
import { AdditionalFieldSelectWrapper } from './elements/AdditionalFieldSelectWrapper'
import { DeductionComponentFromTemplate } from './elements/DeductionComponentFromTemplate'

export const CreateOrEditVatRateFromTemplateForm = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, control } = useFormContext<CreateOrEditVatRateFromTemplateFormSchema>()
  const {
    modalBodyElement,
    isDisabled: disabled,
    isEditMode,
    isVatRateFormVisible: isVatRateFormActive,
    enabledFields,
  } = useCreateOrEditVatRateFromTemplateModal()

  const {
    fields: deductionComponents,
    append: addDeductionComponent,
    remove: removeDeductionComponent,
    move: moveDeductionComponent,
  } = useFieldArray({
    control,
    name: 'deductionComponents',
  })

  const handleDeductionComponentOrderChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveDeductionComponent(oldIndex, newIndex)
    },
    [moveDeductionComponent],
  )
  const handleAddDeductionComponent = useCallback(() => {
    addDeductionComponent(getDefaultDeductionComponent())
  }, [addDeductionComponent])

  const handleDeleteClick = useCallback(
    (index: number) => () => {
      removeDeductionComponent(index)
    },
    [removeDeductionComponent],
  )

  if (!isVatRateFormActive) {
    return <></>
  }

  const deductionComponentCount = deductionComponents.length

  const isDeductionComponentsDisabled = disabled && !enabledFields.isDeductionComponentsNumberEditable

  return (
    <>
      {!isEditMode && <Space size="l" />}
      <FormItemsGroup itemsInRow={1}>
        <FormItem
          label={t('name')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.name_extra')}
          name="name"
          render={({ field }) => <Input {...field} disabled={disabled} />}
        />
        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.description')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.description_extra')}
          name="description"
          render={({ field }) => <Input {...field} disabled={disabled} />}
        />
      </FormItemsGroup>
      <FormItemsGroup itemsInRow={2}>
        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.abbreviation')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.abbreviation_extra')}
          name="abbreviation"
          render={({ field }) => <Input {...field} disabled={disabled} />}
        />
        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.rate')}
          name="rate"
          render={({ field }) => (
            <InputNumberLocalized
              {...field}
              suffix={<Text colorVariant="secondary">%</Text>}
              withFormatting={false}
              disabled={disabled && !enabledFields.rate}
            />
          )}
        />
        <FormItem
          label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.vat_return')}
          extra={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.vat_return_extra')}
          name="netAmountMetaFieldId"
          render={({ field: { value, onChange, ...props } }) => (
            <AdditionalFieldSelectWrapper
              {...props}
              allowClear
              dropdownSize="fitTrigger"
              onSelect={(id, value) => onChange(value?.id)}
              selectedId={value}
              placeholder={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.no_vat_return_extra')}
              disabled={disabled && !enabledFields.taxSummaryLine}
            />
          )}
        />
      </FormItemsGroup>
      <FormItemsGroup itemsInRow={3}>
        <FormItem
          name="appliesTo"
          label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.appliesTo')}
          render={({ field: { value, onChange, ...props } }) => (
            <RadioGroup {...props} value={value} onChange={onChange} disabled={disabled}>
              <Radio value="sales">{t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.sales')}</Radio>
              <Radio value="purchase">
                {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.purchase')}
              </Radio>
            </RadioGroup>
          )}
        />
        <FormItem
          name="isActive"
          label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.state')}
          render={({ field: { value, onChange } }) => (
            <Switch checked={value} onChange={onChange} disabled={disabled && !enabledFields.isActive}>
              {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.active')}
            </Switch>
          )}
        />
      </FormItemsGroup>
      <Space size="m" />
      <Divider />
      <Space size="l" />
      <Text colorVariant="secondary">
        {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.deduction_components')}
      </Text>

      <DraggableItemList
        id="vat-rate-modal-deduction-components"
        isDraggable={deductionComponentCount > 1}
        onOrderChange={handleDeductionComponentOrderChange}
        scrollableContainer={modalBodyElement}
        disabled={isDeductionComponentsDisabled}
      >
        {deductionComponents.map((deductionComponent, index) => (
          <DeductionComponentFromTemplate
            id={deductionComponent.id}
            isEditMode={isEditMode}
            index={index}
            key={deductionComponent.id}
            count={deductionComponentCount}
            onDelete={handleDeleteClick(index)}
            disabled={isDeductionComponentsDisabled}
            enabledFields={enabledFields?.deductionComponents?.[index]}
          />
        ))}
      </DraggableItemList>
      <Space size="s" />
      <AddDeductionComponentButton
        label={t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.add_deduction_component')}
        onAdd={handleAddDeductionComponent}
        count={deductionComponentCount}
        disabled={isDeductionComponentsDisabled}
      />
    </>
  )
}
