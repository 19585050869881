import {
  FocusedInput,
  OnDatesChangeProps,
  START_DATE,
  useDatepicker,
  UseDatepickerProps,
} from '@datepicker-react/hooks'
import { Locale as LocaleDateFns } from 'date-fns'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import { maxDateDefault, minDateDefault } from './constants/dateDefaults'
import { DatePickerContextProvider } from './contexts/datePickerContext'
import { Month } from './elements/Month'
import * as Styled from './styles'
import { SelectedDate } from './types/selectedDate'

interface State extends SelectedDate {
  focusedInput: FocusedInput
}

export interface DatePickerProps
  extends Pick<UseDatepickerProps, 'isDateBlocked' | 'isDateBlocked' | 'unavailableDates' | 'firstDayOfWeek'> {
  defaultValue?: Date
  maxDate?: Date
  minDate?: Date
  onChange?: (date: Date) => void
  value?: Date
  locale: LocaleDateFns
}

export const DatePicker = ({
  defaultValue,
  firstDayOfWeek,
  isDateBlocked,
  maxDate = maxDateDefault,
  minDate = minDateDefault,
  onChange,
  unavailableDates,
  value,
  locale,
}: DatePickerProps): ReactElement => {
  const [state, setState] = useState<State>({
    selectedDate: defaultValue || value || null,
    focusedInput: START_DATE,
  })

  const handleDateChange = useCallback(
    (data: OnDatesChangeProps) => {
      const selectedDate = data.startDate

      if (!data.focusedInput) {
        setState({ selectedDate, focusedInput: START_DATE })
      } else {
        setState({ selectedDate, focusedInput: data.focusedInput })
      }

      if (selectedDate) {
        onChange?.(selectedDate)
      }
    },
    [onChange],
  )

  const { activeMonths, goToDate, ...datePickerProps } = useDatepicker({
    endDate: state.selectedDate,
    exactMinBookingDays: true,
    firstDayOfWeek,
    focusedInput: state.focusedInput,
    isDateBlocked,
    maxBookingDate: maxDate,
    minBookingDate: minDate,
    numberOfMonths: 1, // should NOT be changed
    onDatesChange: handleDateChange,
    startDate: state.selectedDate,
    unavailableDates,
  })

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedDate: value || null,
    }))

    goToDate(value || new Date())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  // we assume that we use only single month approach here (the library allows to select date rages but we want to keep it simple)
  const activeMonth = activeMonths[0]

  return (
    <DatePickerContextProvider
      value={{
        goToDate,
        maxBookingDate: maxDate,
        minBookingDate: minDate,
        selectedDate: state.selectedDate,
        ...datePickerProps,
      }}
    >
      <Styled.DatePickerWrapper>
        <Month
          key={`${activeMonth.year}-${activeMonth.month}`}
          month={activeMonth.month}
          year={activeMonth.year}
          locale={locale}
        />
      </Styled.DatePickerWrapper>
    </DatePickerContextProvider>
  )
}
