import { GridTemplateValue } from '@design-system'

import mean from 'lodash/mean'

import { InvoiceLineColumn } from '../../../../../../../../../types/invoiceLineColumn'

type InvoiceLineColumnWidths = {
  [key in InvoiceLineColumn]: GridTemplateValue
}

export const INVOICE_LINE_COLUMNS_WIDTHS: Partial<InvoiceLineColumnWidths> = {
  description: 10,
  productType: '80px',
  quantity: 4,
  unit: 3,
  unitPrice: 5,
  vatPct: 4,
  total: 5,
  taxTotal: 5,
}

export const AVERAGE_COLUMN_WIDTH = Math.round(mean(Object.values(INVOICE_LINE_COLUMNS_WIDTHS)))
