import React from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { trackError } from '../../../utils/trackError'
import { TOAST_SAFETY_OFFSET } from '../../constants/toastSafetyOffset'
import { Spacing } from '../../enums/spacing'
import { pxToNumber } from '../../utils/pxToNumber'
import { Notification, NotificationProps } from '../Notification'

const NOTIFICATION_AUTO_CLOSE_MS_DEFAULT = 4000
const NOTIFICATION_AUTO_CLOSE_MS_MINIMUM = 2000
const NOTIFICATION_AUTO_CLOSE_MS_MAXIMUM = 7000

const AVERAGE_CHARACTER_READING_SPEED = 75
const ERROR_MESSAGE_EXTRA_TIME = 1000

export const toastNotifyOptionsDefault: ToastOptions = {
  closeButton: false,
  hideProgressBar: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  position: 'bottom-right',
  style: {
    marginTop: `-${TOAST_SAFETY_OFFSET - pxToNumber(Spacing.L)}px`,
  },
}

export interface NotifyProps extends Omit<NotificationProps, 'onClose'> {
  onClose?: () => void
}

export const notify = (notificationProps: NotifyProps, toastOptions?: ToastOptions) => {
  // additional security as "notify"" can be invoked from the ember side without checking types
  if (!notificationProps.id || !notificationProps.message) {
    trackError('You have to pass "id" and "message" for the notification to make it work')
    return
  }

  if (toast.isActive(notificationProps.id)) {
    toast.dismiss(notificationProps.id)
  }

  const handleClose = (toastId: string) => {
    notificationProps.onClose?.()
    toast.dismiss(toastId)
  }

  const getAutoCloseTime = () => {
    if (typeof notificationProps.message !== 'string') {
      return NOTIFICATION_AUTO_CLOSE_MS_DEFAULT
    }

    const messageLength = notificationProps.message.length

    const dynamicTimeBasedOnMessage = Math.min(
      Math.max(messageLength * AVERAGE_CHARACTER_READING_SPEED, NOTIFICATION_AUTO_CLOSE_MS_MINIMUM),
      NOTIFICATION_AUTO_CLOSE_MS_MAXIMUM,
    )

    if (notificationProps.variant === 'error') {
      return Math.min(dynamicTimeBasedOnMessage + ERROR_MESSAGE_EXTRA_TIME, NOTIFICATION_AUTO_CLOSE_MS_MAXIMUM)
    }

    return dynamicTimeBasedOnMessage
  }

  toast(<Notification {...notificationProps} onClose={handleClose} />, {
    ...toastNotifyOptionsDefault,
    autoClose: getAutoCloseTime(),
    ...toastOptions,
    toastId: notificationProps.id,
  })
}
