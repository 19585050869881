import { Color, FontWeight, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const SummaryContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
`

type SummaryLineProps = {
  withBorder?: boolean
}

export const SummaryLine = styled.li<SummaryLineProps>`
  display: flex;
  padding-top: ${Spacing.XXS};
  padding-bottom: ${Spacing.XXS};

  ${({ withBorder }) =>
    withBorder &&
    css`
      border-top: 1px solid ${Color.Gray40};
      border-bottom: 2px solid ${Color.Gray40};
    `}
`

export const LineValue = styled.p`
  min-width: 100px;
  margin-left: 30px;
  flex-shrink: 0;
  text-align: right;
`

export const LineHeader = styled.p`
  min-width: 100px;
  text-align: right;
  font-weight: ${FontWeight.Medium};
`
