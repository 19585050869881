import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useSegment } from '../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { InvoicePaymentTermSelect } from './elements/InvoicePaymentTermSelect'
import { InvoicePaymentTermValue } from './types/invoicePaymentTermValue'

export const InvoicePaymentTerm = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  const handleDropdownOpen = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Form - Payment terms dropdown opened')
  }, [track])

  return (
    <FormItem
      name="paymentTerm"
      label={t('external_invoices.editor.form.field.payment_term.label')}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <InvoicePaymentTermSelect
            {...props}
            defaultSelectedId={String(value)}
            dropdownSize="fitTrigger"
            onDropdownOpen={handleDropdownOpen}
            onSelect={(_id?: string, value?: InvoicePaymentTermValue) => {
              onChange?.(value)
              track('XXX - Invoicing Core - Invoice Editor - Form - Payment terms selected', { option: value })
            }}
            selectedId={String(value)}
          />
        )
      }}
    />
  )
}
