import { DateFormatter } from '@components'
import {
  amountToDisplayValue,
  Attachment,
  Badge,
  Color,
  Flex,
  getStringFromReactNode,
  Spacing,
  Text,
} from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { getLocalDateFromDateString } from '../../../../../../../../utils/getLocalDateFromDateString'
import { AttachmentType } from '../../../../../../enums/attachmentType'

const DEFAULT_TITLE_VALUE = '-'

interface AttachmentTileContentProps {
  attachment: Attachment
}

export const AttachmentTileContent = ({ attachment }: AttachmentTileContentProps): ReactElement => {
  const { amount, documentDate, file, supplier, type } = attachment
  const { t } = useTranslation()

  const title = supplier || file.fileName
  const attachmentTileAmount = useMemo(() => (amount ? amountToDisplayValue(amount) : '-'), [amount])
  const attachmentTileTitle = useMemo(() => getStringFromReactNode(title) || DEFAULT_TITLE_VALUE, [title])

  const attachmentTileDate = useMemo(() => getLocalDateFromDateString(documentDate || ''), [documentDate])

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between" gap={Spacing.S}>
        <Text as="h3" color={Color.Gray200} variant="large" weight="medium" truncate>
          {attachmentTileTitle}
        </Text>
        {type === AttachmentType.EDocument && (
          <Badge variant={'primary'}>{t('receipts.receipts_list.type.e_invoice')}</Badge>
        )}
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text colorVariant="secondary">{attachmentTileDate ? <DateFormatter value={attachmentTileDate} /> : '-'}</Text>
        <Text color={Color.Gray200} variant="large" weight="medium">
          {attachmentTileAmount}
        </Text>
      </Flex>
    </>
  )
}
