import { DateInputLocalized } from '@components'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useSegment } from '../../../../../../../../../../hooks'

export const InvoiceIssueDate = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { FormItem } = useFormContext()

  const handleDatePickerOpen = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Form - Issue date picker opened')
  }, [track])

  return (
    <FormItem
      name="issueDate"
      label={t('external_invoices.editor.form.field.issue_date.label')}
      render={({ field: { value, onChange, ...props } }) => (
        <DateInputLocalized
          {...props}
          onChange={(value) => {
            onChange(value)
            track('XXX - Invoicing Core - Invoice Editor - Form - Issue date selected', {
              date: value,
            })
          }}
          onOpen={handleDatePickerOpen}
          value={value}
        />
      )}
    />
  )
}
