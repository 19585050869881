import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { BorderRadius } from '../../../../enums/borderRadius'
import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { FontSize } from '../../../../enums/fontSize'
import { FontWeight } from '../../../../enums/fontWeight'
import { Spacing } from '../../../../enums/spacing'
import { Themable } from '../../../../types/themable'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'
import { Size } from './types/size'

const sizeToDimension: Record<Size, number> = {
  m: 32,
  l: 40,
}

interface TabButtonWrapperProps extends Themable {
  size: Size
  selected?: boolean
  disabled?: boolean
  fitToWidth?: boolean
}

export const TabButtonWrapper = styled.button<TabButtonWrapperProps>`
  display: inline-flex;
  height: ${({ size }) => sizeToDimension[size]}px;
  padding-left: ${Spacing.L};
  padding-right: ${Spacing.L};
  border: none;
  border-radius: ${BorderRadius.S};
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: ${({ theme }) => theme.ds.colors.base.textSecondary};
  font-family: ${DefaultValue.FontFamily};
  font-size: ${FontSize.TextNormal};
  font-weight: ${FontWeight.Bold};
  white-space: nowrap;
  cursor: pointer;
  ${getDefaultTransition('background-color', 'color')}

  /* Disabled */
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Color.Gray50};
      background-color: transparent;
      cursor: not-allowed;

      ${PrefixWrapper},
      ${SuffixWrapper} {
        opacity: 0.6;
      }
    `}

  /* Selected */
  ${({ disabled, selected, theme }) =>
    !disabled &&
    selected &&
    css`
      color: ${theme.ds.colors.base.textPrimary};
      background-color: ${Color.White};
      cursor: default;
    `}

    /* Hover + Active */
    ${({ selected, disabled, theme }) =>
    !selected &&
    !disabled &&
    css`
      &:hover {
        color: ${theme.ds.colors.base.textPrimary};
        background-color: ${Color.Gray40};
      }
      &:active {
        color: ${theme.ds.colors.base.textPrimary};
        background-color: ${Color.Gray30};
      }
    `}

    ${({ fitToWidth }) =>
    fitToWidth &&
    css`
      flex: 1;
    `}
`

const PrefixSuffixWrapper = styled.div`
  display: flex;
`

export const PrefixWrapper = styled(PrefixSuffixWrapper)`
  &:not(:empty) {
    margin-right: ${Spacing.S};
  }
`

export const SuffixWrapper = styled(PrefixSuffixWrapper)`
  &:not(:empty) {
    margin-left: ${Spacing.S};
  }
`
