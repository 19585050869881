import { ActionGroup, ActionGroupItem, Text } from '@components-deprecated'
import { Button, Flex as FlexDS, ModuleLayout, SearchInput, Space, Spacing, useItemsPerPage } from '@design-system'

import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { useShallowCompareEffect } from 'react-use'
import { Box, Flex } from 'rebass'

import { useInboxOrganizationId } from '../../../contexts/inboxOrganizationIdContext'
import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { useQueryParamsStorage } from '../../../hooks/useQueryParamsStorage'
import { UmbrellaOrganizationSelector } from '../../app/organizations/elements/UmbrellaOrganizationSelector'
import { Organization } from '../../app/organizations/types'
import { EmptyVouchersList, VoucherList } from '../elements'
import { BillUpload } from '../elements/billUpload'
import { DatePeriodChooser } from '../elements/DatePeriodChooser'
import { DatePeriodChooserDropdownButton } from '../elements/DatePeriodChooserDropdownButton'
import { useVouchers } from '../hooks/useVouchers'
import { InboxRoutes, Voucher, VoucherInboxState } from '../types'
import { getFirstVoucherFound } from '../utils'

const REFETCH_AFTER_UPLOAD_TIMEOUT = 3000

const SideActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`

const BillUploadWrapper = styled(Box)`
  margin-bottom: 20px;
`

const VoucherActionsHeader = styled(Flex)`
  margin-bottom: 20px;
  align-items: center;
`

const DatePeriodChooserWrapper = styled(DatePeriodChooserDropdownButton)`
  margin-left: 10px;
`

const HandleNextVoucherButton = styled(Button)`
  margin-left: ${Spacing.M};
`

const EmptyStateWrapper = styled(Flex)`
  width: 100%;
  margin-bottom: 40px;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
`

type InboxState = VoucherInboxState | 'all'

type DefaultState = {
  filters: Filters
  sort: string
  offset: number
}

const getDefaultState = (customerView: boolean, queryParams?: QueryParams): DefaultState => {
  const defaultState: DefaultState = {
    filters: {
      inboxState: customerView ? 'all' : VoucherInboxState.RECEIVED,
      fromDate: null,
      toDate: null,
      page: 1,
      search: '',
    },
    sort: customerView ? '-createdTimestamp' : 'createdTimestamp',
    offset: 0,
  }

  if (queryParams) {
    const { inboxState, fromDate, toDate, page, search, sort } = queryParams
    defaultState.filters = {
      ...defaultState.filters,
      ...{
        inboxState: inboxState || defaultState.filters.inboxState,
        fromDate: fromDate ? new Date(fromDate) : defaultState.filters.fromDate,
        toDate: toDate ? new Date(toDate) : defaultState.filters.toDate,
        search: search || defaultState.filters.search,
        page: page || defaultState.filters.page,
      },
    }
    defaultState.sort = sort || defaultState.sort
  }

  return defaultState
}

const getQueryByState = (state: DefaultState): QueryParams => {
  const {
    filters: { inboxState, fromDate, toDate, page, search },
    sort,
  } = state

  return {
    ...(sort ? { sort } : {}),
    ...(inboxState ? { inboxState } : {}),
    ...(fromDate ? { fromDate: fromDate.toISOString() } : {}),
    ...(toDate ? { toDate: toDate.toISOString() } : {}),
    ...(search ? { search } : {}),
    ...(page ? { page } : {}),
  }
}

type PartnerInboxProps = {
  billEmailAddress?: string
  customerView?: boolean
  organizationId?: string
  umbrellaId?: string
}

type QueryParams = {
  fromDate?: string
  inboxState?: InboxState
  page?: number
  search?: string
  sort?: string
  toDate?: string
}

type Filters = {
  fromDate: Date | null
  inboxState: InboxState
  page?: number
  search?: string
  toDate: Date | null
}

export const PartnerInbox = ({ customerView, umbrellaId }: PartnerInboxProps) => {
  const [organizationId, setOrganizationId] = useInboxOrganizationId()
  const defaultQuery = useMemo(() => getQueryByState(getDefaultState(!!customerView)), [customerView])
  const { queryParams, setQueryParam, setQueryParams, compileUrlWithQuery } = useQueryParamsStorage<QueryParams>(
    QueryParamKeys.VoucherList,
    defaultQuery,
    true,
  )
  const defaultState = useMemo(() => getDefaultState(!!customerView, queryParams), [customerView, queryParams])
  const [{ inboxState, fromDate, toDate, search }, setFilters] = useState<Filters>(defaultState.filters)
  const [sort, setSort] = useState<string>(defaultState.sort)
  const [nextVoucherToHandle, setNextVoucherToHandle] = useState<Voucher | undefined>()
  const [offset, setOffset] = useState<number>(defaultState.offset)
  const [itemsPerPage] = useItemsPerPage()
  const { t } = useTranslation()
  // Get the base path for inbox (can be in umbrella ":umbrella_id/inbox/voucher-inbox" or organization ":org_id/vouchers")
  const { path } = useRouteMatch()

  const currentPage = queryParams.page ? Number(queryParams.page) : 1

  const getCurrentQuery = useCallback(
    () => ({
      inboxState: inboxState !== 'all' ? inboxState : undefined,
      search,
      fromDate: fromDate?.toISOString(),
      toDate: toDate?.toISOString(),
      sort,
      offset,
      limit: itemsPerPage,
      ...(inboxState === VoucherInboxState.RECEIVED && !customerView
        ? {
            dueDateTo: new Date(),
            includeNullDueDate: true,
          }
        : {}),
    }),
    [inboxState, search, fromDate, toDate, sort, offset, itemsPerPage, customerView],
  )

  const isSearchInputFocused = !!search

  const vouchersProps = useMemo(
    () => ({
      organizationId,
      ...getCurrentQuery(),
    }),
    [getCurrentQuery, organizationId],
  )

  const {
    deleteVouchers,
    isLoading: areVouchersLoading,
    pagination,
    refetchVouchers,
    vouchers,
  } = useVouchers(vouchersProps)
  const areVouchersVisible = vouchers.length || inboxState !== VoucherInboxState.RECEIVED || areVouchersLoading

  useShallowCompareEffect(() => {
    if (!queryParams) {
      return
    }

    const stateUpdated = getDefaultState(!!customerView, queryParams)

    if (isEmpty(queryParams)) {
      const queryParamsUpdated = getQueryByState(stateUpdated)
      setQueryParams(queryParamsUpdated)
      return
    }

    if (queryParams.sort && queryParams.sort !== sort) {
      setSort(queryParams.sort)
    }

    setFilters(stateUpdated.filters)
  }, [queryParams, sort])

  const resetPagination = useCallback(() => {
    setOffset(0)
  }, [])

  const updateFilters = useCallback(
    <T extends keyof Filters>(key: T, value: Filters[T]) => {
      if (key !== 'page') {
        resetPagination()
      }

      setFilters((prevFilters) => {
        return {
          ...prevFilters,
          [key]: value,
        }
      })
    },
    [resetPagination],
  )

  const fetchNextVoucherToHandle = useCallback(
    async (organizationId: string) => {
      if (!vouchers.length) {
        if (nextVoucherToHandle !== undefined) {
          setNextVoucherToHandle(undefined)
        }

        return
      }

      if (inboxState !== 'all' && inboxState !== VoucherInboxState.RECEIVED) {
        if (nextVoucherToHandle !== undefined) {
          setNextVoucherToHandle(undefined)
        }

        return
      }

      const voucherToHandle = vouchers.find((voucher) => voucher.inboxState === VoucherInboxState.RECEIVED)
      const voucher =
        voucherToHandle ||
        (await getFirstVoucherFound(organizationId, { ...getCurrentQuery(), inboxState: VoucherInboxState.RECEIVED }))

      if (voucher && voucher.id !== nextVoucherToHandle?.id) {
        setNextVoucherToHandle(voucher)
      } else if (!voucher && nextVoucherToHandle !== undefined) {
        setNextVoucherToHandle(undefined)
      }
    },
    [nextVoucherToHandle, getCurrentQuery, inboxState, vouchers],
  )

  useEffect(() => {
    if (customerView || !organizationId) {
      return
    }

    fetchNextVoucherToHandle(organizationId)
  }, [customerView, fetchNextVoucherToHandle, vouchers, organizationId])

  const compileVoucherToHandleUrl = useCallback(
    (voucherId: string) => {
      const urlPath = `${path}/${voucherId}/${InboxRoutes.CreateBill}`
      const { sort } = queryParams

      return compileUrlWithQuery(urlPath, { sort })
    },
    [compileUrlWithQuery, queryParams, path],
  )

  const handleNextVoucher = useCallback(async () => {
    if (nextVoucherToHandle) {
      const urlPath = `${path}/${nextVoucherToHandle.id}/${InboxRoutes.CreateBill}`
      const { sort } = queryParams

      setQueryParams({ sort }, urlPath)
    }
  }, [nextVoucherToHandle, queryParams, setQueryParams, path])

  const handleUploadFinish = useCallback(
    (isAnyFileUploaded: boolean) => {
      if (!isAnyFileUploaded) {
        return
      }

      window.setTimeout(() => {
        refetchVouchers()
      }, REFETCH_AFTER_UPLOAD_TIMEOUT)
    },
    [refetchVouchers],
  )

  const handleOrganizationSelect = useCallback(
    (organization: Organization) => {
      setOrganizationId(organization.id)
    },
    [setOrganizationId],
  )

  const handleChangeState = useCallback(
    (state: string) => {
      updateFilters('inboxState', state as InboxState)
      setQueryParams({ inboxState: state as InboxState, page: 1 }, '', false)
    },
    [setQueryParams, updateFilters],
  )

  const handleChangeSort = useCallback(
    (sort: string) => {
      setSort(sort)
      setQueryParam('sort', sort)
    },
    [setQueryParam],
  )

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value || undefined
      updateFilters('search', value)
      setQueryParams({ page: 1, search: value }, '', false)
    },
    [setQueryParams, updateFilters],
  )

  const handleSetFromDate = useCallback(
    (date: Date | null) => {
      updateFilters('fromDate', date)
      setQueryParams({ fromDate: date ? date?.toISOString() : '', page: 1 }, '', false)
    },
    [setQueryParams, updateFilters],
  )

  const handleSetToDate = useCallback(
    (date: Date | null) => {
      updateFilters('toDate', date)
      setQueryParams({ page: 1, toDate: date ? date?.toISOString() : '' }, '', false)
    },
    [setQueryParams, updateFilters],
  )

  const handleChangePage = useCallback(
    (page: number) => {
      setOffset(page - 1)
      setQueryParam('page', page)
    },
    [setQueryParam],
  )

  return (
    <ModuleLayout
      title={t('voucher.inbox.header.title')}
      sideActions={
        !customerView && (
          <SideActionsWrapper>
            {umbrellaId && (
              <Box ml="auto">
                <UmbrellaOrganizationSelector
                  umbrellaId={umbrellaId}
                  preselectedId={organizationId}
                  onItemSelect={handleOrganizationSelect}
                  filters={{ isInboxEnabled: true }}
                  mb={0}
                  inboxEnabledOnly
                />
              </Box>
            )}
            <HandleNextVoucherButton disabled={!nextVoucherToHandle} onClick={handleNextVoucher}>
              {t('voucher.inbox.action.handle_next_voucher')}
            </HandleNextVoucherButton>
          </SideActionsWrapper>
        )
      }
    >
      {organizationId ? (
        <>
          {customerView && (
            <BillUploadWrapper>
              <BillUpload
                organizationId={organizationId}
                onUploadFinish={handleUploadFinish}
                scrollQuerySelector="[data-id='scrollable-voucher-list']"
              />
            </BillUploadWrapper>
          )}
          <VoucherActionsHeader>
            <FlexDS>
              <ActionGroup onSelectAction={handleChangeState} key={inboxState}>
                {[
                  'all',
                  VoucherInboxState.RECEIVED,
                  VoucherInboxState.RECORDED,
                  VoucherInboxState.NEED_INFO,
                  VoucherInboxState.ARCHIVED,
                  VoucherInboxState.DISCARDED,
                ].map((state) => (
                  <ActionGroupItem key={state} value={state} selected={inboxState === state}>
                    {t(`voucher.inbox.state.${state}`)}
                  </ActionGroupItem>
                ))}
              </ActionGroup>
            </FlexDS>

            <Space size="auto" horizontal />
            <SearchInput onChangeDebounced={handleChangeSearch} value={search} focused={isSearchInputFocused} />
            <DatePeriodChooserWrapper>
              <DatePeriodChooser
                fromDate={fromDate}
                toDate={toDate}
                onChangeFromDate={handleSetFromDate}
                onChangeToDate={handleSetToDate}
              />
            </DatePeriodChooserWrapper>
          </VoucherActionsHeader>

          {!areVouchersVisible && !customerView ? (
            <EmptyVouchersList />
          ) : (
            <VoucherList
              compileVoucherToHandleUrl={compileVoucherToHandleUrl}
              customerView={customerView}
              defaultPage={currentPage}
              defaultSort={sort}
              isLoading={areVouchersLoading}
              onDelete={deleteVouchers}
              onFetch={handleChangePage}
              onSort={handleChangeSort}
              pagination={pagination}
              vouchers={vouchers}
            />
          )}
        </>
      ) : (
        !customerView && (
          <EmptyStateWrapper>
            <Text variant="h2">{t('voucher.inbox.list.organization_is_not_selected')}</Text>
          </EmptyStateWrapper>
        )
      )}
    </ModuleLayout>
  )
}
