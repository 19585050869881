import { Account } from '@modules-deprecated/app/accounts/types'
import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { queryClient } from '../../../../../config/queryClient'
import { CustomEvent } from '../../../../../enums/customEvent'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { CustomEventType, useCustomEventListener } from '../../../../../hooks/useCustomEventListener'

interface EmberUserOrganizationsPayload {
  organization: Organization
  accounts: Account[]
}

export const useSetInitialQueryData = () => {
  useCustomEventListener<EmberUserOrganizationsPayload>(
    CustomEvent.EmberUserOrganizationsLoaded,
    (data: CustomEventType<EmberUserOrganizationsPayload>) => {
      const { organization, accounts } = data.detail || {}

      if (!organization?.id) {
        return
      }

      const queryKeysAndInitialData = [
        {
          queryKey: [QueryKeys.Accounts, organization.id],
          initialData: { accounts },
        },
        {
          queryKey: [QueryKeys.UserOrganization, organization.id],
          initialData: { organization },
        },
      ]

      for (const { queryKey, initialData } of queryKeysAndInitialData) {
        const isDataCached = !!queryClient.getQueryData(queryKey)
        if (!isDataCached) {
          queryClient.setQueryData(queryKey, initialData)
        }
      }
    },
  )
}
