import { createContext, ReactElement, ReactNode, useContext } from 'react'

import { TaxRate } from '@modules-deprecated/app/taxrates/types'

import { useTaxRates } from '../hooks/useTaxRates'

interface ContextState {
  vatRates: TaxRate[]
  isLoading: boolean
}

const InvoiceEditorVatRatesContext = createContext<ContextState | undefined>(undefined)

interface InvoiceEditorVatRatesContextProviderProps {
  children?: ReactNode
}

export const InvoiceEditorVatRatesContextProvider = ({
  children,
}: InvoiceEditorVatRatesContextProviderProps): ReactElement => {
  const { data: vatRates, isLoading } = useTaxRates()

  return (
    <InvoiceEditorVatRatesContext.Provider value={{ vatRates, isLoading }}>
      {children}
    </InvoiceEditorVatRatesContext.Provider>
  )
}

export const useInvoiceEditorVatRates = () => {
  const context = useContext(InvoiceEditorVatRatesContext)

  if (!context) {
    throw new Error('InvoiceEditorVatRatesContext is missing in the module!')
  }

  return context
}
