import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { SettingsRoute } from '@views/settings/enums/settingsRoute'
import { UmbrellaRoute } from '@views/umbrella/enums/UmbrellaRoute'

import { CustomEvent } from '../enums/customEvent'
import { trackError } from '../utils/trackError'
import { useRouterTypeDetection } from './routing/useRouterDetection'
import { CustomEventType, useCustomEventListener } from './useCustomEventListener'

export interface CustomEventDetail {
  route: UmbrellaRoute | SettingsRoute
}

export const useCustomEventsRoutes = () => {
  const history = useHistory()

  const { isHashRouter } = useRouterTypeDetection()
  if (!isHashRouter) {
    trackError("useCustomEventsRoutes should be used in views using HashRouter. You don't need it in View Container.")
  }

  const handleRouteLinkClick = useCallback(
    (event: CustomEventType<CustomEventDetail>) => {
      const route = event.detail?.route

      if (route) {
        history.push(route)
      }
    },
    [history],
  )

  useCustomEventListener(CustomEvent.RoutingLinkClicked, handleRouteLinkClick)
}
