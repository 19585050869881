import { SaveStatus } from '@components'
import { Textarea } from '@design-system'

import { ReactElement, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAttachmentPreviewModal } from '../../../../context/attachmentPreviewModalContext'
import { AttachmentInfo } from '../AttachmentInfo'
import * as Styled from './styles'

const STATUS_PANEL_SIZE = 24

export const DescriptionArea = (): ReactElement => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLDivElement>(null)
  const { description, handleDescriptionChange, saveStatus } = useAttachmentPreviewModal()
  const [maxHeight, setMaxHeight] = useState(64)

  const shouldShowSaveStatus = saveStatus !== 'idle'

  const getMaxHeight = useCallback(() => {
    if (containerRef.current) {
      const containerHeight = containerRef.current?.getBoundingClientRect().height - STATUS_PANEL_SIZE
      setMaxHeight(containerHeight)
    }
  }, [])

  useLayoutEffect(getMaxHeight, [getMaxHeight])

  useEffect(() => {
    window.addEventListener('resize', getMaxHeight)
    return () => {
      window.removeEventListener('resize', getMaxHeight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AttachmentInfo title={t('attachment_preview_modal.details.notes_label')} fitToHeight>
      <Styled.DescriptionAreaWrapper ref={containerRef}>
        <Textarea
          expandOnType
          maxHeight={maxHeight}
          onChange={handleDescriptionChange}
          placeholder={t('attachment_preview_modal.details.notes.placeholder')}
          resizable={false}
          value={description}
        />
        {shouldShowSaveStatus && <SaveStatus status={saveStatus} />}
      </Styled.DescriptionAreaWrapper>
    </AttachmentInfo>
  )
}
