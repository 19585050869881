import { ModalProps, withModalConditionalRender } from '@design-system'

import { SendAsEmailFormProvider } from './contexts/SendAsEmailFormContext'
import { SendAsEmailModalComponent } from './elements/SendAsEmailModalComponent'

type SendAsEmailProps = ModalProps

const SendAsEmail = (modalProps: SendAsEmailProps) => {
  return (
    <SendAsEmailFormProvider>
      <SendAsEmailModalComponent {...modalProps} />
    </SendAsEmailFormProvider>
  )
}

export const SendAsEmailModal = withModalConditionalRender(SendAsEmail)
