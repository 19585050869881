import { Color, SectionMessage, Text } from '@design-system'

import { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { formatAmountCurrency } from '../../../../../../utils/stringFormatter'

interface PrepaymentDifferenceLineProps {
  difference: number
  currency: string
}

export const PrepaymentDifferenceLine = ({ difference, currency }: PrepaymentDifferenceLineProps): ReactElement => {
  const { t } = useTranslation()
  const formattedDifference = formatAmountCurrency(difference, currency)
  return (
    <SectionMessage icon="checkCircle" title={t('bill.payment_reconcile.prepayment.difference.title')} variant="info">
      <Text color={Color.Purple}>
        <Trans
          i18nKey="bill.payment_reconcile.prepayment.difference.content"
          tOptions={{ difference: formattedDifference }}
          components={{ 1: <strong /> }}
        />
      </Text>
    </SectionMessage>
  )
}
