import { ShortcutKey } from '@components'
import { Color, Divider, Flex, Space, Spacing, Text } from '@design-system'

import { useTranslation } from 'react-i18next'

import { ALT_SHORTCUT, CMD_SHORTCUT } from '../../../../constants/shortcuts'
import { isMac } from '../../../../utils/isMac'

export const metakeyLabel = isMac ? '⌘' : 'Ctrl'

export const AttachmentShortcuts = () => {
  const { t } = useTranslation()

  return (
    <>
      <Space size="xl" />

      <Divider dividerColor={Color.Gray40} />
      <Space size="xxl" />

      <Flex gap={Spacing.XL} alignItems="center">
        <Text variant="h4" uppercase color={Color.Gray90}>
          {t('bill.attachment.shortcuts')}
        </Text>

        <Flex alignItems="center" gap={Spacing.S}>
          <Text variant="micro" color={Color.Gray90}>
            {t('bill.attachment.approve_bill')}
          </Text>

          <ShortcutKey label={CMD_SHORTCUT} />
          <Text variant="micro" color={Color.Gray90}>
            +
          </Text>
          <ShortcutKey label="enter" />
        </Flex>

        <Flex alignItems="center" gap={Spacing.S}>
          <Text variant="micro" color={Color.Gray90}>
            {t('bill.attachment.register_payment')}
          </Text>

          <ShortcutKey label={ALT_SHORTCUT} />
          <Text variant="micro" color={Color.Gray90}>
            +
          </Text>
          <ShortcutKey label="p" />
        </Flex>

        <Flex alignItems="center" gap={Spacing.S}>
          <Text variant="micro" color={Color.Gray90}>
            {t('attachments.zoom_in_out')}
          </Text>

          <ShortcutKey label={t('attachments.scroll')} />
        </Flex>

        <Flex alignItems="center" gap={Spacing.S}>
          <Text variant="micro" color={Color.Gray90}>
            {t('attachments.navigate_between_pages')}
          </Text>

          <Flex alignItems="center" gap={Spacing.XXS}>
            <ShortcutKey label="<" />
            <ShortcutKey label=">" />
          </Flex>
        </Flex>
      </Flex>

      <Space size="xl" />
    </>
  )
}
