import { AttachmentPreviewModalBody } from '../elements/AttachmentPreviewModal/AttachmentPreviewModalBody'
import { ModalBody as SelectAttachmentModalBody } from '../elements/ChooseAttachmentModal/elements/ModalBody'
import { AttachmentModalSteps } from '../enums/attachmentModalSteps'

export const attachmentModalSteps = [
  {
    id: AttachmentModalSteps.Select,
    headerTKey: 'ds.modal_files_chooser.default.title',
    modalBody: <SelectAttachmentModalBody />,
  },
  {
    backwardsNavigationStateId: AttachmentModalSteps.Select,
    id: AttachmentModalSteps.Preview,
    headerTKey: 'attachment_preview_modal.title',
    modalBody: <AttachmentPreviewModalBody />,
    withBackwardsNavigation: true,
  },
]
