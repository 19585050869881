import { Flex, Text } from '@design-system'

import cc from 'classcat'
import { useTheme } from 'emotion-theming'
import React from 'react'

import { Theme } from '../../../types/theme'
import { reactClass } from '../../../utils'
import { IconCaretDown } from '../../Icons'
import styles from '../defaultStyles'
import { SortDirection, TableColumn, TableRow } from '../types'
import * as Styled from './styles'

export type TableHeadProps<T> = {
  className?: string
  columns: TableColumn<T>[]
  columnsLayout: string
  onHover?: (arg: boolean) => void
  onSort?: (property: string) => void
  rowSpacing?: number
}

export const TableHead = <T extends TableRow>({
  className,
  columns,
  columnsLayout,
  onHover = () => null,
  onSort = () => null,
  rowSpacing,
}: TableHeadProps<T>) => {
  const theme = useTheme<Theme>()

  return (
    <thead
      className={cc([className, reactClass('infinite-table-head')])}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <Styled.TableRow columns={columnsLayout} rowSpacing={rowSpacing}>
        {columns.map(({ field, isHeaderVisible = true, text = '', type, sortDirection, sortProperty }, index) => (
          <Flex as="th" justifyContent={styles[type].justifyContent} key={field.toString()}>
            <Styled.TableHeaderTextWrapper sortProperty={sortProperty}>
              <Text key={index} onClick={() => sortProperty && onSort(sortProperty)} weight="bold">
                {isHeaderVisible && text}
              </Text>
            </Styled.TableHeaderTextWrapper>
            {sortDirection && sortDirection !== SortDirection.None && (
              <IconCaretDown
                width={12}
                height={12}
                rotate={sortDirection === SortDirection.Desc}
                color={theme.ds.colors.base.textPrimary}
              />
            )}
          </Flex>
        ))}
      </Styled.TableRow>
    </thead>
  )
}
