import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { Scope } from '../../../../../enums/scope'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { fetchSubscriptionPlans } from '../services/query-api'

export const useFetchSubscriptionPlans = () => {
  const { organization } = useUserOrganization()

  const hasOrganizationReadScope = isAuthorized(Scope.OrganizationRead)

  const { data, ...rest } = useQuery(
    [QueryKeys.SubscriptionPlans, organization?.id],
    () => fetchSubscriptionPlans(organization?.id as string),
    {
      enabled: !!organization?.id && hasOrganizationReadScope,
    },
  )

  return { subscriptionPlans: data || [], ...rest }
}
