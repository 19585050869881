import sortBy from 'lodash/sortBy'
import { call, put, takeLatest } from 'redux-saga/effects'

import { getCurrentLocale, getRequest } from '../../../utils'
import { accountsReceived, AccountsRequested } from './action-creators'
import { ACCOUNTS_REQUESTED } from './actions'
import { Account } from './types'

function* fetchAccounts({ payload }: AccountsRequested) {
  const { organizationId } = payload
  try {
    const locale = getCurrentLocale()

    const res: { accounts: Account[] } = yield call(
      getRequest,
      `/v2/accounts?organizationId=${organizationId}&include=account.group:embed`,
      { 'accept-language': locale },
    )

    const accounts = sortBy(res.accounts, ['group.priority', 'group.accountNo', 'accountNo'])

    yield put(accountsReceived(accounts))
  } catch (e) {
    yield put(accountsReceived([]))
  }
}

export default function* () {
  yield takeLatest(ACCOUNTS_REQUESTED, fetchAccounts)
}
