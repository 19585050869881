import { NavItem } from '@design-system'

import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'

import { Scope } from '../../../../../enums/scope'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { FastItem } from '../types/fastItem'
import { FastItems } from '../types/fastItems'

const fastItemsScopes: Record<FastItem, Scope | Scope[] | null> = {
  newInvoice: Scope.InvoiceWrite,
  newBill: Scope.BillWrite,
  newQuote: Scope.OfferWrite,
}

interface GetFastMenuFilteredItemsOptions {
  fastItems: FastItems
  isNewBillItemDisabled?: boolean
  isSourceZervant?: boolean
  isUmbrellaContext?: boolean
}

export const getFastMenuFilteredItems = ({
  fastItems,
  isNewBillItemDisabled,
  isSourceZervant,
  isUmbrellaContext,
}: GetFastMenuFilteredItemsOptions): NavItem<string>[] => {
  if (isUmbrellaContext) {
    return []
  }

  let filteredItems = fastItems

  if (isNewBillItemDisabled) {
    filteredItems = omit(filteredItems, ['newBill'])
  }

  filteredItems = pickBy(filteredItems, (_item, key) => {
    const itemScopes = fastItemsScopes[key as FastItem]
    // This is a temporary solution needed for Invoice Editor Beta,
    // at the later stage it should be replaced with proper Scopes adjustments.
    const newInvoiceItemBetaEnabled = isSourceZervant && key === 'newInvoice'

    if ((itemScopes && isAuthorized(itemScopes)) || newInvoiceItemBetaEnabled) {
      return true
    }
  })

  return Object.values(filteredItems)
}
