import { DateFormatter, Translate } from '@components'
import { CellValue, Table, TableCellContent, TableColumn } from '@design-system'

import React, { ReactElement, useMemo } from 'react'

import { LocaleCode } from '../../../../../../enums/localeCode'
import { useLocale } from '../../../../../../hooks/useLocale'
import { UmbrellaOrganizationsInvitationsTableAccessor } from '../../enums/umbrellaOrganizationsInvitationsTableAccessor'
import { useInvitedOrganizations } from '../../hooks/useInvitedOrganizations'
import { InvitationTableRow } from '../../types/invitationTableRow'
import { OrganizationActions } from '../../types/organizationActions'
import { OrganizationName } from '../../types/organizationName'
import { Actions } from './elements/Actions'
import { Organization } from './elements/Organization'

export const UmbrellaOrganizationsInvitationsTable = (): ReactElement => {
  const locale = useLocale()
  const { data } = useInvitedOrganizations()

  const dateFormat = locale.code === LocaleCode.EN_US ? 'MMMM d, yyyy' : 'd. MMMM yyyy'

  const columns: TableColumn<InvitationTableRow>[] = useMemo(
    () => [
      {
        accessor: UmbrellaOrganizationsInvitationsTableAccessor.Invitations,
        Cell: ({ value: props }: CellValue<OrganizationName>) => (
          <TableCellContent>
            <Organization logoId={props.logoId || undefined} name={props.name} />
          </TableCellContent>
        ),
        Header: <Translate value="umbrella_organizations.invitations_table.invitations_column" />,
      },
      {
        accessor: UmbrellaOrganizationsInvitationsTableAccessor.InvitationDate,
        Cell: ({ value: invitationDate }: CellValue<string>) => (
          <TableCellContent>
            <DateFormatter dateFormat={dateFormat} value={invitationDate} />
          </TableCellContent>
        ),
        Header: <Translate value="umbrella_organizations.invitations_table.invitation_date_column" />,
      },
      {
        accessor: UmbrellaOrganizationsInvitationsTableAccessor.Email,
        Cell: ({ value: email }: CellValue<string>) => <TableCellContent>{email}</TableCellContent>,
        Header: <Translate value="umbrella_organizations.invitations_table.email_column" />,
      },
      {
        accessor: UmbrellaOrganizationsInvitationsTableAccessor.Actions,
        alignment: 'right',
        Cell: ({ value: props }: CellValue<OrganizationActions>) => <Actions {...props} />,
        Header: '',
      },
    ],
    [dateFormat],
  )

  const invitedOrganizationsData: InvitationTableRow[] = useMemo(
    () =>
      data.map(({ createdTime, id, organization, token }) => ({
        [UmbrellaOrganizationsInvitationsTableAccessor.Actions]: {
          invitationId: id,
          invitationToken: token,
        },
        [UmbrellaOrganizationsInvitationsTableAccessor.Email]: organization.email,
        [UmbrellaOrganizationsInvitationsTableAccessor.InvitationDate]: createdTime,
        [UmbrellaOrganizationsInvitationsTableAccessor.Invitations]: {
          logoId: organization.logoFileId,
          name: organization.name,
        },
        id,
      })),
    [data],
  )

  return <Table columns={columns} data={invitedOrganizationsData} />
}
