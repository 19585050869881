import { Flex, ModuleLayout, Spacing } from '@design-system'

import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { CookieKeys } from '../../../../enums/cookieKeys'
import { useSegment } from '../../../../hooks'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { InvoiceUrlParams } from '../../types/invoiceUrlParams'
import { InvoiceContextProvider } from './contexts/invoiceContext'
import { InvoicePreview } from './elements/InvoicePreview'
import { InvoiceTitle } from './elements/InvoiceTitle'
import { InvoiceViewActions } from './elements/InvoiceViewActions'
import { InvoiceViewSidebar } from './elements/InvoiceViewSidebar'
import * as Styled from './styles'

export const InvoiceView = () => {
  const { track } = useSegment()
  const { documentType, invoiceId } = useParams<InvoiceUrlParams>()
  const { url: invoicesUrl } = useOrganizationViewUrl(reactRoute.invoices.getMainRoute())

  const storedInvoicesListQuery = Cookies.get(CookieKeys.InvoicingInvoicesListQuery) || ''

  const handleBackButtonClick = useCallback(() => {
    track('XXX - Invoicing Core - Invoice View - Back arrow button clicked')
  }, [track])

  return (
    <InvoiceContextProvider documentType={documentType} invoiceId={invoiceId}>
      <ModuleLayout
        backButtonTo={invoicesUrl && `${invoicesUrl}${storedInvoicesListQuery}`}
        onBackButtonClick={handleBackButtonClick}
        sideActions={<InvoiceViewActions />}
        title={<InvoiceTitle />}
        withBackButton
      >
        <Styled.InvoiceViewWrapper>
          <Flex gap={Spacing.XL}>
            <Flex.Item>
              <InvoicePreview />
            </Flex.Item>
            <Flex.Item>
              <InvoiceViewSidebar />
            </Flex.Item>
          </Flex>
        </Styled.InvoiceViewWrapper>
      </ModuleLayout>
    </InvoiceContextProvider>
  )
}
