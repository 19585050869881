import { getYear } from 'date-fns'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useOnClickOutside } from '../../hooks'
import { useOrganizationFiscalYears } from '../../hooks/useOrganizationFiscalYears'
import { IconCalendar } from '../Icons'
import './FiscalYearSelector.styles.scss'

export type FiscalYearSelectorProps = {
  className?: string
  defaultYear?: number
  firstFiscalYearStart: string
  fiscalYearEndMonth: number
  onYearSelect: (year: number) => void
}

export const FiscalYearSelector = ({
  className,
  defaultYear,
  firstFiscalYearStart,
  fiscalYearEndMonth,
  onYearSelect,
  ...rest
}: FiscalYearSelectorProps) => {
  const { t } = useTranslation()
  const currentYear = getYear(new Date())
  const { years } = useOrganizationFiscalYears()

  const [selectedYear, setSelectedYear] = useState(defaultYear || currentYear)
  const [isToggledOn, setIsToggledOn] = useState(false)
  const selectorRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (defaultYear === undefined || defaultYear === selectedYear) {
      return
    }
    setSelectedYear(defaultYear)
  }, [defaultYear, selectedYear])

  const handleFiscalYearClick = (year: number) => {
    setSelectedYear(year)
    onYearSelect(year)
    setIsToggledOn(false)
  }

  useOnClickOutside(selectorRef, () => isToggledOn && setIsToggledOn(false))

  return (
    <div
      ref={selectorRef}
      className={`${className || ''} fiscal-year-selector-dropdown ${isToggledOn ? 'active' : ''}`}
    >
      <IconCalendar width="16" height="16" onClick={() => setIsToggledOn(!isToggledOn)} />
      <span className="fiscal-year-selector-title" role="none" onClick={() => setIsToggledOn(!isToggledOn)}>
        {t('fiscal_year', { year: selectedYear })}
      </span>
      <ul>
        {years.map((year) => (
          <li
            key={year.toString()}
            role="none"
            onClick={(_event) => handleFiscalYearClick(year)}
            onKeyUp={(_event) => handleFiscalYearClick(year)}
          >
            {t('fiscal_year', { year })}
          </li>
        ))}
      </ul>
    </div>
  )
}
