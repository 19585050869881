import { Attachment, Space } from '@design-system'

import { ReactElement } from 'react'

import { ChooseFileDropdown } from '@features/attachments/elements/ChooseFileDropdown'

import { useFormContext, useWatch } from '../../../../hooks/useForm'
import { attachmentsFilesToAttachmentsIds } from '../BillEditableForm/utils/attachmentsFilesToAttachmentsIds'
import { BillFormSchema } from '../BillEditableForm/utils/formData'

interface BillEditableAttachmentsActionsProps {
  onAttachmentSelect?: (attachment: Attachment) => void
  onFilesSelect?: (files: File[]) => void
}

export const BillEditableAttachmentsActions = ({
  onAttachmentSelect,
  onFilesSelect,
}: BillEditableAttachmentsActionsProps): ReactElement => {
  const { control } = useFormContext<BillFormSchema>()
  const attachmentsFilesChosen = useWatch({ control, name: 'attachmentsFilesChosen', defaultValue: [] })
  const attachmentsIdsChosen = attachmentsFilesToAttachmentsIds(attachmentsFilesChosen || [])

  return (
    <>
      <Space size="m" />
      <ChooseFileDropdown
        onFileFromUploadsSelect={onAttachmentSelect}
        onFileFromDeviceSelect={onFilesSelect}
        attachmentsIdsChosen={attachmentsIdsChosen}
      />
    </>
  )
}
