import debounce from 'lodash/debounce'
import { useMemo } from 'react'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { TRACKING_DELAY } from '../../../../../../../../constants/trackingDelay'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { useUpdateDependentLineValues } from '../../hooks/useUpdateDependentLineValues'
import { CurrencyInput } from '../CurrencyInput'

type InvoiceLineTotalIncludingVatProps = CommonInvoiceLineFieldProps

export const InvoiceLineTotalIncludingVat = ({ lineIndex }: InvoiceLineTotalIncludingVatProps) => {
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { handleTaxIncludingVatChange } = useUpdateDependentLineValues(lineIndex)

  const handleDebouncedInputNumberChange = useMemo(
    () =>
      debounce((total: number) => {
        track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Total incl. VAT changed', {
          total,
        })
      }, TRACKING_DELAY),
    [track],
  )

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.totalIncludingVat`}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <CurrencyInput
            {...props}
            value={value}
            onChange={(totalIncludingVat) => {
              handleTaxIncludingVatChange(totalIncludingVat)
              onChange(totalIncludingVat)
              handleDebouncedInputNumberChange(totalIncludingVat)
            }}
          />
        )
      }}
    />
  )
}
