import { css } from '@emotion/core'

export const getButtonWrapperStyles = () => css`
  position: absolute;
  display: flex;
  align-items: center;
  padding: 10px;
  height: 100%;
  width: 75px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
`
