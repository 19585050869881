import { InvoiceFormLine } from '../types/invoiceForm/invoiceFormLine'
import { InvoicePayloadSummary, InvoicePayloadTaxSummary } from '../types/invoicePayload/invoicePayloadSummary'
import { getTaxAmount } from './getTaxAmount'
import { NumberValue } from './NumberValue'

type InvoiceSummaryTotals = Omit<InvoicePayloadSummary, 'currency'>

const initialSummaryTotals: InvoiceSummaryTotals = {
  discountTotal: 0,
  grossTotal: 0,
  netTotal: 0,
  taxSummary: [],
  taxTotal: 0,
}

interface GetUpdatedTaxSummaryOptions {
  currentSummary: InvoicePayloadTaxSummary[]
  lineTaxTotal: number
  vatRatePercent: number
}

const getUpdatedTaxSummary = ({ currentSummary, lineTaxTotal, vatRatePercent }: GetUpdatedTaxSummaryOptions) => {
  const existingVatRateIndex = currentSummary.findIndex((tax) => tax.percentage === vatRatePercent)

  if (existingVatRateIndex !== -1) {
    return currentSummary.map((tax, index) =>
      index === existingVatRateIndex ? { ...tax, amount: tax.amount + lineTaxTotal } : tax,
    )
  }

  return [
    ...currentSummary,
    {
      amount: lineTaxTotal,
      percentage: vatRatePercent,
    },
  ]
}

export const getInvoiceSummaryTotals = (invoiceLines: InvoiceFormLine[]) => {
  return invoiceLines.reduce((summary, line) => {
    const lineTaxTotal = getTaxAmount(line.totalExcludingVat, line.vatRatePercent)

    const grossTotal = new NumberValue(summary.grossTotal).add(line.totalIncludingVat).getRounded()
    const netTotal = new NumberValue(summary.netTotal).add(line.totalExcludingVat).getRounded()
    const taxTotal = new NumberValue(summary.taxTotal).add(lineTaxTotal).getRounded()
    const vatRatePercent = new NumberValue(line.vatRatePercent).getValue()

    return {
      ...initialSummaryTotals,
      grossTotal,
      netTotal,
      taxTotal,
      taxSummary: getUpdatedTaxSummary({ currentSummary: summary.taxSummary, lineTaxTotal, vatRatePercent }),
    }
  }, initialSummaryTotals)
}
