import { IconName, TextColorVariant } from '@design-system'

import { SaveStatusType } from '../types/saveStatusType'

interface SaveStatusVariant {
  translationKey: string
  colorVariant: TextColorVariant
  iconName?: IconName
}

export const saveStatusVariants: Record<SaveStatusType, SaveStatusVariant> = {
  saving: {
    translationKey: 'save_status_component.status_saving',
    colorVariant: 'secondary',
    iconName: 'circleDashed',
  },
  saved: {
    translationKey: 'save_status_component.status_saved',
    colorVariant: 'success',
    iconName: 'checkCircle',
  },
  error: {
    translationKey: 'save_status_component.status_error',
    colorVariant: 'danger',
  },
}
