import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'

interface HtmlPreviewProps {
  fitToHeight?: boolean
  isLoading?: boolean
}

export const HtmlWrapper = styled.div<HtmlPreviewProps>`
  width: ${({ fitToHeight }) => (fitToHeight ? 'auto' : '100%')};
  height: ${({ fitToHeight }) => (fitToHeight ? '100%' : 'auto')};
  background-color: ${Color.White};

  ${({ isLoading }) => {
    if (isLoading) {
      return `
        visibility: hidden;
      `
    }
  }};
`
