import { Select, SelectProps } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'

import { useLocale } from '../../../hooks/useLocale'
import { getMonthItems } from './utils/getMonthItems'

interface MonthSelectProps extends Omit<SelectProps<number>, 'items' | 'onSelect'> {
  onSelect?: (value?: number) => void
}

export const MonthSelect = ({ selectedId, onSelect, ...selectProps }: MonthSelectProps): ReactElement => {
  const { code: locale } = useLocale()

  const items = useMemo(() => getMonthItems(locale), [locale])

  const handleSelect = useCallback(
    (_id?: string, value?: number) => {
      onSelect?.(value)
    },
    [onSelect],
  )

  return (
    <Select {...selectProps} dropdownSize="fitTrigger" selectedId={selectedId} items={items} onSelect={handleSelect} />
  )
}
