import { FullScreenOverlay } from '@components'
import { Color, IconButton, Text } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

import { useSegment } from '../../../../../../hooks'
import { SettingsRoute } from '../../../../enums/settingsRoute'
import { SubscriptionPlans } from '../SubscriptionPlans'
import * as Styled from './styles'

interface SubscriptionPlansOverlayProps {
  onPlanSelect: (plan: SubscriptionPlan, period?: SubscriptionPeriod) => void
}

export const SubscriptionPlansOverlay = ({ onPlanSelect }: SubscriptionPlansOverlayProps): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const history = useHistory()

  const handleCloseButtonClick = useCallback(() => {
    history.push(SettingsRoute.OrganizationSubscription)
  }, [history])

  useEffect(() => {
    track('xxx - iam - pricing viewed')
  }, [track])

  return (
    <FullScreenOverlay bgColor={Color.Gray20}>
      <Styled.OverlayContainer>
        <Styled.OverlaySubscriptionPlansAndHeaderWrapper>
          <Styled.OverlayHeader>
            <Styled.OverlayHeaderLeftSection />
            <Text variant="h1">{t('organization_subscription.plans.choose_your_subscription')}</Text>
            <IconButton icon="xSign" onClick={handleCloseButtonClick} />
          </Styled.OverlayHeader>
          <SubscriptionPlans onPlanSelect={onPlanSelect} />
        </Styled.OverlaySubscriptionPlansAndHeaderWrapper>
      </Styled.OverlayContainer>
    </FullScreenOverlay>
  )
}
