import { ProtectedComponent } from '@components'
import { Button, ButtonDropdown, ButtonsGroup, NavItem } from '@design-system'

import Cookies from 'js-cookie'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { CookieKeys } from '../../../../../../enums/cookieKeys'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { Scope } from '../../../../../../enums/scope'
import { useSegment } from '../../../../../../hooks'
import { useEmberRouteUrl } from '../../../../../../hooks/routing/useEmberRouteUrl'

export const InvoicesSideActions = () => {
  const { t } = useTranslation()
  const { url: newInvoiceUrl } = useEmberRouteUrl(EmberRoute.InvoicesNew)
  const { url: newCreditNoteUrl } = useEmberRouteUrl(EmberRoute.InvoicesNewCreditNote)
  const history = useHistory()
  const { track } = useSegment()

  const handleCreateCreditNote = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'invoice_list',
      action: 'more: create credit note',
    })

    Cookies.set(CookieKeys.InvoicesListQuery, history.location.search)
    if (newCreditNoteUrl) {
      history.push(newCreditNoteUrl)
    }
  }, [track, newCreditNoteUrl, history])

  const handleAction = (value: string) => {
    if (value === 'creditnote') {
      handleCreateCreditNote()
    }
  }

  const dropdownItems = useMemo(
    (): NavItem[] => [
      {
        id: 'creditnote',
        children: t('invoices.side_actions.create_credit_note'),
        value: 'create_credit_note',
      },
    ],
    [t],
  )

  const handleCreateInvoiceClick = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'invoice_list',
      action: 'create invoice',
    })

    Cookies.set(CookieKeys.InvoicesListQuery, history.location.search)
    if (newInvoiceUrl) {
      history.push(newInvoiceUrl)
    }
  }, [track, newInvoiceUrl, history])

  return (
    <ProtectedComponent scopes={Scope.InvoiceWrite}>
      <ButtonsGroup>
        <Button icon="plusCircle" onClick={handleCreateInvoiceClick}>
          {t('invoices.side_actions.create_invoice')}
        </Button>
        <ButtonDropdown items={dropdownItems} onSelect={handleAction} placement="top-start" variant="secondary">
          {t('invoices.side_actions.more')}
        </ButtonDropdown>
      </ButtonsGroup>
    </ProtectedComponent>
  )
}
