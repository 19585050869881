import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { fetchContactsAndContactPersons } from '../query-api'
import { getContactsQueryKey } from '../utils/getContactsQueryKey'
import { ContactsQueryProps } from './useFetchContacts'

export const useIsContactsListEmpty = (): boolean => {
  const { organization } = useUserOrganization()

  const queryProps: ContactsQueryProps = useMemo(
    () => ({
      page: 1,
      pageSize: 1 as ItemsPerPage,
      organizationId: organization?.id as string,
    }),
    [organization?.id],
  )

  const queryKey = useMemo(() => getContactsQueryKey(queryProps), [queryProps])

  const { data: contactsData, isLoading } = useQuery(queryKey, () => fetchContactsAndContactPersons(queryProps), {
    enabled: !!organization,
  })

  return !isLoading && contactsData?.contacts.length === 0
}
