import { Contact } from '@views/contacts/types/contact'

import { trackError } from '../../../../utils/trackError'
import { fetchContactById } from '../query-api'

export const getContact = async (contactId: string): Promise<Contact | undefined> => {
  try {
    const data = await fetchContactById(contactId)
    return data.contact
  } catch (error) {
    trackError(error)
    return undefined
  }
}
