import qs from 'qs'

import { SortDirection } from '../../../../enums/sortDirection'
import { Metable } from '../../../../types/metable'
import { deleteRequest, getCurrentLocale, getRequest, postRequest, putRequest } from '../../../../utils'
import { AdditionalFieldsSortProperty } from './enums/additionalFieldsSortProperty'
import { VatAccountsSortProperty } from './enums/vatAccountsSortProperty'
import { TaxAccount } from './types/taxAccount'
import { TaxAccountWithDetails } from './types/taxAccountWithDetails'
import { TaxMetaField } from './types/taxMetaField'

export interface FetchTaxAccountResponseData extends Metable {
  salesTaxAccounts: TaxAccountWithDetails[]
}

export interface FetchAdditionalFieldsResponseData extends Metable {
  salesTaxMetaFields: TaxMetaField[]
}

interface TaxAccountsQueryParams {
  organizationId: string
  sortDirection?: SortDirection
  sortProperty?: VatAccountsSortProperty
  include?: string
}

interface AdditionalFieldsQueryParams {
  organizationId: string
  sortDirection?: SortDirection
  sortProperty?: AdditionalFieldsSortProperty
  include?: string
}

export const fetchTaxAccounts = ({
  organizationId,
  sortDirection,
  sortProperty,
  include = 'salesTaxAccount.account:embed',
}: TaxAccountsQueryParams): Promise<FetchTaxAccountResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    organizationId,
    sortDirection,
    sortProperty,
    include,
  })

  return getRequest(`/v2/salesTaxAccounts?${queryParams}`, { 'accept-language': locale })
}

export const fetchAdditionalFields = ({
  organizationId,
  sortDirection,
  sortProperty,
}: AdditionalFieldsQueryParams): Promise<FetchAdditionalFieldsResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    organizationId,
    sortDirection,
    sortProperty,
  })

  return getRequest(`/v2/salesTaxMetaFields?${queryParams}`, { 'accept-language': locale })
}

export const deleteTaxAccount = (accountId: string): Promise<void> => {
  return deleteRequest(`/v2/salesTaxAccounts/${accountId}`)
}

export const deleteAdditionalField = (additionalFieldId: string): Promise<void> => {
  return deleteRequest(`/v2/salesTaxMetaFields/${additionalFieldId}`)
}

export const postVatAccount = (payload: TaxAccount): Promise<void> => {
  return postRequest(`/v2/salesTaxAccounts`, { salesTaxAccount: payload })
}

export const updateVatAccount = (id: string, payload: Partial<TaxAccount>): Promise<void> => {
  return putRequest(`/v2/salesTaxAccounts/${id}`, {
    salesTaxAccount: payload,
  })
}

export const postAdditionalField = (payload: Partial<Omit<TaxMetaField, 'isPredefined'>>): Promise<void> => {
  return postRequest(`/v2/salesTaxMetaFields`, { salesTaxMetaField: payload })
}

export const updateAdditionalField = (id: string, payload: Partial<TaxMetaField>): Promise<void> => {
  return putRequest(`/v2/salesTaxMetaFields/${id}`, {
    salesTaxMetaField: payload,
  })
}
