import { trackError } from '../../../../../utils/trackError'
import { BillBalanceModifierReference } from '../enums/BillBalanceModifierReference'

const billBalanceModifierReferenceValues = Object.values(BillBalanceModifierReference) as string[]

interface BalanceModifierReferenceResult {
  modifierType: BillBalanceModifierReference | undefined
  modifierId: string | undefined
}

export const getBalanceModifierReference = (
  modifierReference?: string | null,
): BalanceModifierReferenceResult | undefined => {
  const [modifierType, modifierId] = (modifierReference || '').split(':')

  if (!billBalanceModifierReferenceValues.includes(modifierType)) {
    trackError('Passed modifierReference type is not in the BillBalanceModifierReference enum')
    return undefined
  }

  return { modifierType: modifierType as BillBalanceModifierReference, modifierId }
}
