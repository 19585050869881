import { useModalContentToggler } from '@components'
import { Link, Space, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../../../hooks'
import { BillReconciliationModalStateId } from '../../../../enums/billReconciliationModalStateId'

export const LinkToManualPayment = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { setActiveState } = useModalContentToggler()

  const handleLinkClick = useCallback(() => {
    setActiveState(BillReconciliationModalStateId.ManualPayment)
    track('xxx - expenses - Bank line modal - manual payment clicked', {
      bankLinesCount: 0,
    })
  }, [setActiveState, track])

  return (
    <>
      <Space />
      <Text colorVariant="secondary">
        {t('bill.payment_reconcile.not_the_right_match')}{' '}
        <Text as="span">
          <Link onClick={handleLinkClick}>{t('bill.payment_reconcile.enter_without_reconciling')}</Link>
        </Text>
      </Text>
    </>
  )
}
