import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useControls, useTransformContext, useTransformEffect } from 'react-zoom-pan-pinch'

import { Color } from '../../../../../../enums/color'
import { Spacing } from '../../../../../../enums/spacing'
import { Button } from '../../../../../Button'
import { Flex } from '../../../../../Flex'
import { IconButton } from '../../../../../IconButton'
import { Text } from '../../../../../Text'
import { ZoomDirection } from '../../../../enums/zoomDirection'
import { handleZoom } from '../../../../utils/handleZoom'
import * as Styled from './styles'

export const ZoomControls = () => {
  const { t } = useTranslation()
  const [isMoved, setIsMoved] = useState(false)
  const { setTransform, resetTransform } = useControls()
  const { transformState } = useTransformContext()

  useTransformEffect(({ state }) => {
    const { scale, positionX, positionY } = state
    const isImagePanned = positionX !== 0 || positionY !== 0
    const isImageZoomed = scale !== 1
    setIsMoved(isImagePanned || isImageZoomed)
  })

  const handleResetTransformClick = useCallback(() => {
    isMoved && resetTransform()
  }, [isMoved, resetTransform])

  const handleZoomClick = useCallback(
    (direction: ZoomDirection) => {
      handleZoom({ direction, transformState, setTransform })
    },
    [setTransform, transformState],
  )

  return (
    <Flex alignItems="center" gap={!isMoved ? Spacing.S : undefined}>
      <IconButton
        size="m"
        icon="magnifyingGlassPlus"
        onClick={() => handleZoomClick(ZoomDirection.zoomIn)}
        title={t('attachments.zoom_in')}
        color={Color.Gray90}
      />
      {isMoved ? (
        <Styled.ButtonWrapper>
          <Button variant="text" color={Color.Gray90} size="m" onClick={handleResetTransformClick}>
            {t('attachments.reset_zoom')}
          </Button>
        </Styled.ButtonWrapper>
      ) : (
        <Text color={Color.Gray90}>{t('attachments.zoom')}</Text>
      )}
      <IconButton
        size="m"
        icon="magnifyingGlassMinus"
        onClick={() => handleZoomClick(ZoomDirection.zoomOut)}
        title={t('attachments.zoom_out')}
        color={Color.Gray90}
      />
    </Flex>
  )
}
