import { getDisplayDate } from '@design-system'

import { TFunction } from 'i18next'

import { PeriodMode } from '../../../../../../enums/periodMode'
import { PeriodValue } from '../../../../../../types/periodValue'
import { getCurrentLocale } from '../../../../../../utils'
import { getPeriodModeToLabel } from '../../../utils/getPeriodModes'
import { indexValueToDisplayValue } from '../../../utils/indexValueToDisplayValue'

const getValue = (periodValue?: PeriodValue) => {
  if (periodValue?.value === undefined && !periodValue?.customDate) {
    return ''
  }

  if (periodValue.customDate) {
    const locale = getCurrentLocale()
    const { startDate, endDate } = periodValue.customDate
    return `${startDate ? getDisplayDate(startDate, locale) : '∞'} - ${endDate ? getDisplayDate(endDate, locale) : '∞'}`
  }

  if (periodValue.value !== undefined) {
    switch (periodValue.mode) {
      case PeriodMode.Month:
      case PeriodMode.Quarter:
        return `${indexValueToDisplayValue(periodValue.value)}/${periodValue.year}`

      case PeriodMode.FiscalYear:
        return periodValue.value.toString()
    }
  }

  return ''
}

const getLabel = (t: TFunction, periodValue?: PeriodValue) => {
  if (!periodValue?.mode || periodValue?.customDate) {
    return ''
  }

  const periodModeToLabel = getPeriodModeToLabel(t)[periodValue.mode]
  return periodModeToLabel || ''
}

export const getPeriodButtonLabel = (t: TFunction, periodValue?: PeriodValue) => {
  if (!periodValue) {
    return ''
  }

  const label = getLabel(t, periodValue) || ''
  const value = getValue(periodValue) || ''
  const separator = !!label && !!value ? ': ' : ''

  return `${label}${separator}${value}`
}
