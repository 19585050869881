import isNil from 'lodash/isNil'

type NumberValueInput = number | string | undefined | null

// This is pretty generic and not tied to the invoice, so can be moved up in
// the tree and used in more places with time, should there be need for it

export class NumberValue {
  private static MATH_OPERATION_PRECISION = 10

  private value: number

  constructor(inputValue: NumberValueInput) {
    this.setValue(inputValue)
  }

  private static parseInput(inputValue: NumberValueInput): number {
    if (isNil(inputValue)) {
      return 0
    }

    const parsedInput = typeof inputValue === 'string' ? parseFloat(inputValue) : inputValue

    return isNaN(parsedInput) ? 0 : parsedInput
  }

  setValue(inputValue: NumberValueInput) {
    this.value = NumberValue.parseInput(inputValue)
  }

  getValue(): number {
    return this.value
  }

  getStringValue(): string {
    return this.getValue().toString()
  }

  getCopy(): NumberValue {
    return new NumberValue(this.value)
  }

  getRounded(precision = 2): number {
    const factor = Math.pow(10, precision)
    return Math.round(this.value * factor) / factor
  }

  add(number: NumberValueInput): NumberValue {
    const result = this.getValue() + NumberValue.parseInput(number)
    this.setValue(result.toFixed(NumberValue.MATH_OPERATION_PRECISION))
    return this
  }

  subtract(number: NumberValueInput): NumberValue {
    const result = this.getValue() - NumberValue.parseInput(number)
    this.setValue(result.toFixed(NumberValue.MATH_OPERATION_PRECISION))
    return this
  }

  multiply(number: NumberValueInput): NumberValue {
    const result = this.getValue() * NumberValue.parseInput(number)
    this.setValue(result.toFixed(NumberValue.MATH_OPERATION_PRECISION))
    return this
  }

  divide(number: NumberValueInput): NumberValue {
    const result = this.getValue() / NumberValue.parseInput(number)
    this.setValue(result.toFixed(NumberValue.MATH_OPERATION_PRECISION))
    return this
  }

  round() {
    this.value = this.getRounded()
    return this
  }
}
