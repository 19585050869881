import { Modal, ModalProps } from '@design-system'

import { ReactElement } from 'react'
import 'react-i18next'

import { useSendAsEmailForm } from '../../contexts/SendAsEmailFormContext'
import { SendAsEmailModalBody } from './elements/SendAsEmailModalBody'
import { SendAsEmailModalFooter } from './elements/SendAsEmailModalFooter'

type SendAsEmailModalComponentProps = ModalProps

export const SendAsEmailModalComponent = (modalProps: SendAsEmailModalComponentProps): ReactElement => {
  const { isSubmitting } = useSendAsEmailForm()

  // TODO: add translations
  return (
    <Modal {...modalProps} size="s" closable={!isSubmitting}>
      <Modal.Header title="Send by email" />
      <SendAsEmailModalBody />
      <SendAsEmailModalFooter id={modalProps.id} />
    </Modal>
  )
}
