import { Color, getDisplayDateFormat } from '@design-system'

import React, { memo, ReactElement, ReactNode } from 'react'

import { getCurrentLocale } from '../../../utils'
import { formatDate } from '../../../utils/date-fns'
import * as Styled from './styles'

interface DateFormatterProps {
  children?: ReactNode
  color?: Color
  dateFormat?: string
  withHour?: boolean
  value: string | Date
}

export const DateFormatter = memo(
  ({ children, color, dateFormat, value, withHour }: DateFormatterProps): ReactElement => {
    const fullDate = formatDate(value, 'yyyy-MM-dd HH:mm:ss')
    const locale = getCurrentLocale()
    const displayDateFormat = dateFormat || getDisplayDateFormat(locale, withHour)
    const displayDate = formatDate(value, displayDateFormat)

    return (
      <Styled.DateFormatterWrapper dateTime={fullDate} color={color}>
        {children || displayDate}
      </Styled.DateFormatterWrapper>
    )
  },
)
