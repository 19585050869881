import { Flex } from '@design-system'

import { format } from 'date-fns'
import { ReactDatePickerProps } from 'react-datepicker'

import { useLocale } from '../../hooks/useLocale'
import { CalendarIconCaret } from './CalendarIconCaret'
import * as Styled from './styles'

export type CalendarHeaderComponent = ReactDatePickerProps['renderCustomHeader']

const CalendarHeader: CalendarHeaderComponent = ({
  decreaseMonth,
  decreaseYear,
  increaseMonth,
  increaseYear,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  prevMonthButtonDisabled,
  date,
}) => {
  const locale = useLocale()
  const month = format(date, 'MMMM', { locale })
  const year = format(date, 'yyyy', { locale })
  return (
    <Flex>
      <Styled.MonthHeader>
        <CalendarIconCaret direction="left" onClick={decreaseMonth} disabled={!prevMonthButtonDisabled} />
        {month}
        <CalendarIconCaret direction="right" onClick={increaseMonth} disabled={nextMonthButtonDisabled} />
      </Styled.MonthHeader>

      <Styled.CalendarHeaderContainer>
        <CalendarIconCaret direction="left" onClick={decreaseYear} disabled={prevYearButtonDisabled} />
        {year}
        <CalendarIconCaret direction="right" onClick={increaseYear} disabled={nextYearButtonDisabled} />
      </Styled.CalendarHeaderContainer>
    </Flex>
  )
}

export default CalendarHeader
