import { Modal } from '@design-system'

import { EmailMessage } from './elements/EmailMessage'
import { EmailSubject } from './elements/EmailSubject'

export const SendAsEmailModalBody = () => {
  return (
    <Modal.Body>
      <EmailSubject />
      <EmailMessage />
    </Modal.Body>
  )
}
