import { css } from '@emotion/core'

import { DividerDirection } from '../types/dividerDirection'

interface GetDirectionFlexStylesProps {
  direction: DividerDirection
  hasChildren: boolean
}

export const getDividerDirectionFlexStyles = ({ direction, hasChildren }: GetDirectionFlexStylesProps) => css`
  content: '';

  ${direction === 'vertical'
    ? `
      flex-direction: ${hasChildren ? 'row' : 'column'};
      align-self: stretch;
    `
    : `flex-direction: row;
      align-items: center;`}
`
