import { APIError, deleteRequest, getRequest, postRequest } from '../utils'

export type PaymentMethodType = 'bank' | 'cash' | 'check' | 'fi' | 'mobilepay' | 'other' | 'stripe'

export interface PaymentMethod {
  bankAccountId: string
  createdTime: string
  id: string
  isDefault: boolean
  isDisabled: boolean
  isPending: boolean
  name: string
  organizationId: string
  supportsCardPayments: boolean
  type: PaymentMethodType
  updatedTime: string
}

export interface CurrencyDefaultPaymentMethod {
  currencyCode: string
  paymentMethodId: string
}

export interface FetchPaymentMethodsResponseData {
  data: PaymentMethod[]
  meta: { currencyDefaults: CurrencyDefaultPaymentMethod[] }
}

export const fetchPaymentMethods = (organizationId: string): Promise<FetchPaymentMethodsResponseData> => {
  return getRequest(`/organizations/${organizationId}/paymentMethods`)
}

export interface CreatePaymentMethodOptions {
  payload: Partial<PaymentMethod>
  organizationId?: string
}

export interface CreatePaymentMethodResponse {
  data: Partial<PaymentMethod>
}

export const createPaymentMethod = ({
  payload,
  organizationId,
}: CreatePaymentMethodOptions): Promise<CreatePaymentMethodResponse> => {
  return postRequest(`/organizations/${organizationId}/paymentMethods`, { data: { ...payload, organizationId } })
}

export interface DeletePaymentMethodOptions {
  organizationId: string
  paymentMethodId: string
}

export const deletePaymentMethod = async ({
  organizationId,
  paymentMethodId,
}: DeletePaymentMethodOptions): Promise<void> => {
  try {
    return await deleteRequest(`/organizations/${organizationId}/paymentMethods/${paymentMethodId}`)
  } catch (error) {
    if (error instanceof APIError) {
      if (error?.statusCode === 422) {
        // prevent logging this error to DataDog
        error.silent = true
      }
    }

    // we still need to throw it in order to display error notification to the user
    throw error
  }
}
