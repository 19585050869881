/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'CountrySelect' from /components instead
 */
import { CountryFlag } from '@components'
import {
  SelectListItem as SelectListItemComponent,
  SmartSelect,
  SmartSelectItem,
  SmartSelectProps,
} from '@components-deprecated'
import { Color, Spacing, Text } from '@design-system'

import styled from '@emotion/styled'
import React from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getCurrentLocale } from '../../../../utils'
import { getItemsForSelect } from '../../../../utils/forms'
import { fetchCountries } from '../query-api'
import { Country } from '../types'

declare global {
  interface Window {
    ENV: any
  }
}

const SelectListItem = styled(SelectListItemComponent)`
  display: flex;
`

const FlagImageWrapper = styled.div`
  margin-right: ${Spacing.S};
`

const renderer = ({ icon, id, name = '', onItemSelect = () => null, selectedId, ...rest }: SmartSelectItem) => {
  const handleRowSelect = () => onItemSelect(id)

  return (
    <SelectListItem key={id} id={id} selectedId={selectedId} onClick={handleRowSelect} {...rest}>
      <FlagImageWrapper>
        <CountryFlag countryCode={icon} />
      </FlagImageWrapper>
      <Text weight="medium" color={selectedId === id ? Color.Blue : undefined}>
        {name}
      </Text>
    </SelectListItem>
  )
}

export type CountrySelectorProps = SmartSelectProps<Country> & {
  formControl?: Control<any>
  preselectedId?: string
  onItemSelect?: (country: Country, name?: string) => void
}

export const CountrySelector = ({
  formControl,
  name = 'country-selector',
  onItemSelect = () => null,
  preselectedId,
  listWidth,
  ...rest
}: CountrySelectorProps) => {
  const { t } = useTranslation()
  const locale = getCurrentLocale()
  const { data } = useQuery([QueryKeys.Countries, locale], () => fetchCountries(locale))

  const countries = data?.countries || []
  const items = getItemsForSelect<Country>(countries)

  return (
    <SmartSelect<Country>
      formControl={formControl}
      items={items}
      name={name}
      listWidth={listWidth}
      notFoundText={t('app.countries.country_selector.not_found')}
      onItemSelect={onItemSelect}
      placeholder={t('app.countries.country_selector.select_country')}
      preselectedId={preselectedId}
      render={renderer}
      autoComplete="disable-autocomplete"
      selectFirst
      {...rest}
    />
  )
}
