import { useMutation } from 'react-query'
import { APIError } from 'src/utils'

import { createManualPayment } from '../query-api'

interface UseCreateManualPaymentProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useCreateManualPayment = (props?: UseCreateManualPaymentProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: create,
    isLoading: isProcessing,
    ...rest
  } = useMutation(createManualPayment, {
    onSuccess,
    onError,
  })

  return { create, isProcessing, ...rest }
}
