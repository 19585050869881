import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '@modules-deprecated/app/user'

import { useForm } from '../../../../../hooks'
import { getDefaultValues, getValidationSchema } from '../utils/profilePicFormData'

export const useUserProfilePictureForm = () => {
  const { t } = useTranslation()
  const { user, isLoading: isUserDataLoading } = useCurrentUser()

  const { reset, ...form } = useForm({
    defaultValues: useMemo(() => getDefaultValues(user), [user]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (!isUserDataLoading) {
      reset(getDefaultValues(user))
    }
  }, [isUserDataLoading, reset, user])

  return { ...form, isLoading: isUserDataLoading }
}
