import { GlobalPeriodSelect } from '@components'
import { Button, Checkbox, Flex, Modal, useModal } from '@design-system'

import React, { ChangeEvent, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../enums/modalId'
import { PeriodMode } from '../../enums/periodMode'
import { useSegment } from '../../hooks'
import { ModalError } from './elements/ModalError'
import { ModalSkeleton } from './elements/ModalSkeleton'
import { useDaybookConsequences } from './hooks/useDaybookConsequences'

interface DaybookConsequencesModalProps {
  onModalClose?: () => void
  daybookId: string
}

const disabledPeriodModes: PeriodMode[] = [PeriodMode.All, PeriodMode.Month, PeriodMode.Quarter, PeriodMode.CustomDate]

export const DaybookConsequencesModal = ({ onModalClose, daybookId }: DaybookConsequencesModalProps) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { reportHtml, isLoading, showAllAccounts, setShowAllAccounts, isError, isFetching } =
    useDaybookConsequences(daybookId)
  const isLoadingOrFetching = isLoading || isFetching

  const handleModalClose = useCallback(() => {
    onModalClose?.()
  }, [onModalClose])

  const { close } = useModal(ModalId.DaybookConsequencesModal, { onClose: handleModalClose })

  const handleCloseButtonClick = useCallback(() => close(), [close])

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setShowAllAccounts(event.target.checked)
    },
    [setShowAllAccounts],
  )

  useEffect(() => {
    track('xxx - mikkel - daybook consequence')
  }, [track])

  return (
    <Modal closable id={ModalId.DaybookConsequencesModal} forceRender>
      <Modal.Header title={t('daybook.consequencesModal.title')} />
      <Modal.Body>
        {!isError && isLoadingOrFetching && <ModalSkeleton />}
        {!isError && !isLoadingOrFetching && reportHtml && (
          <>
            <Flex flexDirection="row" justifyContent="space-between">
              <Flex.Item>
                <GlobalPeriodSelect disabledPeriodModes={disabledPeriodModes} />
              </Flex.Item>
              <Flex.Item alignSelf="flex-end">
                <Checkbox
                  checked={showAllAccounts}
                  onChange={handleCheckboxChange}
                  children={t('daybook.consequencesModal.toggle_label')}
                />
              </Flex.Item>
            </Flex>
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            <div dangerouslySetInnerHTML={{ __html: reportHtml }} />
          </>
        )}
        {!isLoadingOrFetching && isError && <ModalError />}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCloseButtonClick}>{t('daybook.consequencesModal.close')}</Button>
      </Modal.Footer>
    </Modal>
  )
}
