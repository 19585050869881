import { Icon, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import * as Styled from './styles'
import { SaveStatusType } from './types/saveStatusType'
import { saveStatusVariants } from './utils/saveStatusVariants'

interface SaveStatusProps {
  status: SaveStatusType
}

export const SaveStatus = ({ status }: SaveStatusProps): ReactElement => {
  const { t } = useTranslation()
  const { translationKey, colorVariant, iconName } = saveStatusVariants[status]

  const isLoading = status === 'saving'

  return (
    <Text colorVariant={colorVariant} alignment="right" variant="micro">
      {iconName && (
        <Styled.IconWrapper isLoading={isLoading}>
          <Icon icon={iconName} color="currentColor" />
        </Styled.IconWrapper>
      )}
      {t(translationKey)}
    </Text>
  )
}
