import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { IconCross } from '../Icons'

type FileListContainerProps = {
  sameHeightFileList?: boolean
}

export const FileListContainer = styled.div<FileListContainerProps>`
  display: flex;
  width: 100%;
  height: ${({ sameHeightFileList }) => (sameHeightFileList ? '160px' : '275px')};
  border-radius: 10px;
  border: 1px solid ${Color.Gray40};
  position: relative;
  box-shadow: 0 1px 3px ${transparentize(0.85, Color.Gray90)};
  overflow: auto;
`

export const FileListScrollableArea = styled.ul`
  display: flex;
  padding: ${Spacing.M};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  flex-direction: column;
  background-color: ${Color.White};
  overflow: auto;
`

export const FileItem = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${Spacing.XS};

  & + & {
    border-top: 1px solid ${Color.Gray40};
    margin-top: 6px;
    padding-top: 6px;
  }
`

export const ButtonWrapper = styled.div`
  margin-left: ${Spacing.S};
  flex-shrink: 0;
`

export const CloseButton = styled(IconCross)`
  width: 18px;
  height: 18px;
  color: ${Color.Gray90};
  cursor: pointer;
  margin-left: auto;
`
