import { Link, SectionMessage, SkeletonBox, Text } from '@design-system'

import { ReactElement } from 'react'
import { Trans } from 'react-i18next'

import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'
import { useAttachmentsCounter } from '@features/attachments/hooks/useAttachmentsCounter'

import { useOrganizationViewUrl } from '../../../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../../../utils/routing/reactRoute'
import * as Styled from './styles'

export const ReceiptsToFill = (): ReactElement | null => {
  const { url: uploadsUrl } = useOrganizationViewUrl(reactRoute.uploads.getMainRoute())
  const { countMap: receiptsCountMap, isLoading: isReceiptsLoading } = useAttachmentsCounter()
  const { [AttachmentFilterType.All]: allReceiptsCount } = receiptsCountMap

  if ((!allReceiptsCount && !isReceiptsLoading) || !uploadsUrl) {
    return null
  }

  return (
    <Styled.ReceiptsToFillWrapper>
      {isReceiptsLoading ? (
        <SkeletonBox fullWidth height={56} />
      ) : (
        <SectionMessage variant="info">
          <Trans i18nKey="bills.receipts_to_fill" count={allReceiptsCount}>
            You have {{ allReceiptsCount }} receipts ready to be filled!
            <Text as="span" inherit weight="bold">
              <Link to={uploadsUrl}>Fill now</Link>
            </Text>
          </Trans>
        </SectionMessage>
      )}
    </Styled.ReceiptsToFillWrapper>
  )
}
