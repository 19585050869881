import styled from '@emotion/styled'

import { Checkbox as CheckboxComponent } from '../../Form'
import { rowStyles } from '../styles'

interface TableRowStylesProps {
  columns: string
  rowSpacing?: number
}

export const TableRowWrapper = styled.tr<TableRowStylesProps>`
  ${rowStyles};
  grid-column-gap: ${({ rowSpacing }) => rowSpacing || 30}px;
  grid-template-columns: ${({ columns }) => columns};
`

export const CheckboxWrapper = styled.td`
  display: flex;
  height: 100%;
  position: absolute;
  left: 6px;
  align-items: center;
  cursor: default;
`
export const Checkbox = styled(CheckboxComponent)`
  margin: 0;

  label {
    margin: 0;
  }
`
