import { DEFAULT_FORMATTER_LOCALE } from '../../constants/defaultFormatterLocale'
import { getNumberSeparators } from './getNumberSeparators'
import { sanitizeNumberString } from './sanitizeNumberString'
import { getNumberFormatLocale } from './utils/getNumberFormatLocale'

export const parseToNumber = (input = '', inputLocale?: string): number => {
  const locale = getNumberFormatLocale(inputLocale || DEFAULT_FORMATTER_LOCALE)
  const { decimalSeparator } = getNumberSeparators(locale)
  const sanitizedNumber = sanitizeNumberString(input, decimalSeparator)

  return parseFloat(sanitizedNumber.replace(decimalSeparator, '.') || '0')
}
