import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { FormItemWrapper } from '../FormItem/styles'
import { GridTemplateValue } from './types/gridTemplateValue'
import { getGridTemplateColumnsStyle } from './utils/getGridTemplateColumnsStyle'

interface FormItemsGroupProps {
  columnSpacing: Spacing
  itemsInRow: number
  itemsTemplate?: GridTemplateValue[]
  rowSpacing: Spacing
  spacing: Spacing
}

export const FormItemsGroup = styled.div<FormItemsGroupProps>`
  display: grid;
  width: 100%;
  column-gap: ${({ columnSpacing }) => columnSpacing};
  row-gap: ${({ rowSpacing }) => rowSpacing};
  ${({ itemsInRow, itemsTemplate }) => getGridTemplateColumnsStyle(itemsInRow, itemsTemplate)};

  &:not(:last-of-type) {
    margin-bottom: ${({ spacing }) => spacing};
  }

  ${() => FormItemWrapper} {
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }
`
