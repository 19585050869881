import { rotateAnimation, Spacing } from '@design-system'

import styled from '@emotion/styled'

interface IconWrapperProps {
  isLoading: boolean
}

export const IconWrapper = styled.span<IconWrapperProps>`
  margin-right: ${Spacing.XXS};

  > svg {
    ${({ isLoading }) => isLoading && rotateAnimation()};
  }
`
