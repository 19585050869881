import { Attachment, Checkbox, Space, withModalConditionalRender } from '@design-system'

import React, { ChangeEvent, ReactElement, ReactNode, useCallback } from 'react'

import { AttachmentPreviewModal as AttachmentPreviewModalComponent } from '../../../../elements/AttachmentPreviewModal'
import { AttachmentType } from '../../../../enums/attachmentType'
import { getAttachmentPreviewModalId } from '../../utils/getAttachmentPreviewModalId'
import { AttachmentTileContent } from './elements/AttachmentTileContent/AttachmentTileContent'
import { AttachmentTileImage } from './elements/AttachmentTileImage/AttachmentTileImage'
import * as Styled from './styles'

const AttachmentPreviewModal = withModalConditionalRender(AttachmentPreviewModalComponent)

interface AttachmentTileProps {
  attachment: Attachment
  footer?: ReactNode
  isSelectable?: boolean
  isSelected?: boolean
  onImageClick?: () => void
  onSelect?: (id: string, isSelected: boolean) => void
  onFileSelectFromPreview?: () => void
}

export const AttachmentTile = ({
  attachment,
  footer,
  isSelectable,
  isSelected,
  onImageClick,
  onSelect,
  onFileSelectFromPreview,
}: AttachmentTileProps): ReactElement => {
  const { file, id, type } = attachment
  const attachmentPreviewModalId = getAttachmentPreviewModalId(id)
  const shouldShowCheckbox = isSelectable ?? type !== AttachmentType.EDocument

  const handleCheckboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onSelect?.(id, event.target.checked)
    },
    [id, onSelect],
  )

  const handleFileSelectFromPreview = useCallback(() => {
    onFileSelectFromPreview?.()
  }, [onFileSelectFromPreview])

  return (
    <>
      <Styled.AttachmentTileWrapper checked={isSelected}>
        {shouldShowCheckbox && (
          <Styled.AttachmentTileCheckboxWrapper>
            <Checkbox checked={isSelected} onChange={handleCheckboxChange} />
          </Styled.AttachmentTileCheckboxWrapper>
        )}
        <AttachmentTileImage
          attachmentImageFile={file}
          attachmentPreviewModalId={attachmentPreviewModalId}
          onClick={onImageClick}
        />
        <Styled.AttachmentTileContentWrapper>
          <AttachmentTileContent attachment={attachment} />
          {footer && (
            <>
              <Space size="s" />
              {footer}
            </>
          )}
        </Styled.AttachmentTileContentWrapper>
      </Styled.AttachmentTileWrapper>

      <AttachmentPreviewModal
        id={attachmentPreviewModalId}
        attachment={attachment}
        onFileSelect={handleFileSelectFromPreview}
      />
    </>
  )
}
