import { InvoiceForm } from '../../types/invoiceForm'
import { InvoicePayloadInvoiceNumber } from '../../types/invoicePayload/invoicePayLoadNumber'

export const getInvoiceNumberPayload = (formValues: InvoiceForm): InvoicePayloadInvoiceNumber => {
  const invoiceNumberPayload: InvoicePayloadInvoiceNumber = {
    number: formValues.invoiceNumber,
  }

  if (formValues?.invoiceNumberPrefix) {
    invoiceNumberPayload.prefix = formValues.invoiceNumberPrefix
  }

  return invoiceNumberPayload
}
