import { InvoiceLineColumn } from '../../../../../../../../../types/invoiceLineColumn'

export const INVOICE_LINE_COLUMNS_ORDER: InvoiceLineColumn[] = [
  'description',
  'productType',
  'quantity',
  'unit',
  'unitPrice',
  'vatPct',
  'total',
  'taxTotal',
]
