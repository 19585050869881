import { AttachmentsNavigation, ProtectedComponent } from '@components'
import { AspectRatioVariant, FilesPreview, SkeletonBox } from '@design-system'

import React, { memo, ReactElement, useCallback, useMemo, useRef, useState } from 'react'

import { Scope } from '../../../../enums/scope'
import { getImageClassName } from '../../../../utils/getClassName'
import { useBillAttachmentPageContext } from '../../contexts/billAttachmentPageContext'
import { getAttachmentsFilesFromBill } from '../../utils/getAttachmentsFilesFromBill'
import { BillAttachmentPreview } from '../BillAttachmentPreview'
import { BillAttachmentPreviewBlocked } from '../BillAttachmentPreviewBlocked'
import { BillReadableAttachmentsDropzone } from '../BillReadableAttachmentsDropzone'
import { useBillView } from '../BillReadableForm'
import * as Styled from './styles'

export const BillReadableAttachments = memo((): ReactElement => {
  const [isUploading, setIsUploading] = useState(false)
  const { billData, isFetching: isFetchingBill, isFetchingInvalidation: isFetchingBillInvalidation } = useBillView()
  const [currentPageIndex, setCurrentPageIndex] = useBillAttachmentPageContext()
  const billImageWrapperRef = useRef<HTMLDivElement>(null)

  const { bill } = billData || {}
  const attachmentsFiles = useMemo(() => (bill ? getAttachmentsFilesFromBill(bill) : []), [bill]) || []

  const isUploadingDropzone = isUploading || isFetchingBillInvalidation

  const handlePageChange = useCallback(
    (index: number) => {
      setCurrentPageIndex(index)
    },
    [setCurrentPageIndex],
  )

  const handleUploadStart = useCallback(() => {
    setIsUploading(true)
  }, [])

  const handleUploadFinished = useCallback(() => {
    setIsUploading(false)
  }, [])

  return (
    <Styled.BillReadableAttachmentsWrapper>
      <Styled.BillImageWrapper ref={billImageWrapperRef}>
        {isFetchingBill ? (
          <SkeletonBox aspectRatio={AspectRatioVariant.billAttachment} fullWidth />
        ) : (
          <>
            {attachmentsFiles.length > 0 && !isUploadingDropzone ? (
              <ProtectedComponent scopes={Scope.BillAttachmentRead} unauthorizedNode={<BillAttachmentPreviewBlocked />}>
                <BillAttachmentPreview>
                  <FilesPreview
                    aspectRatio={AspectRatioVariant.billAttachment}
                    className={getImageClassName()}
                    files={[{ src: attachmentsFiles[currentPageIndex].downloadUrl }]}
                    fitToWidth
                    navigationDirection="horizontal"
                    withDownloadButton
                    withNavigation
                    isFloatingActionsView
                    zoomable
                  />
                </BillAttachmentPreview>
              </ProtectedComponent>
            ) : (
              <Styled.AttachmentDropzoneWrapper aspectRatio={AspectRatioVariant.billAttachment}>
                <BillReadableAttachmentsDropzone
                  isUploading={isUploadingDropzone}
                  onUploadFinished={handleUploadFinished}
                  onUploadStart={handleUploadStart}
                />
              </Styled.AttachmentDropzoneWrapper>
            )}
          </>
        )}
      </Styled.BillImageWrapper>
      {attachmentsFiles?.length > 1 && (
        <Styled.BillReadableAttachmentsActions>
          <AttachmentsNavigation
            onClick={handlePageChange}
            currentIndex={currentPageIndex}
            total={attachmentsFiles.length}
          />
        </Styled.BillReadableAttachmentsActions>
      )}
    </Styled.BillReadableAttachmentsWrapper>
  )
})
