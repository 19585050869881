import { PlanConfirmationModal as PlanConfirmationModalComponent, ProcessingScreen } from '@components'
import { useModal, withModalConditionalRender } from '@design-system'

import qs from 'qs'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation } from 'react-use'

import { SubscriptionPeriod } from '@modules-deprecated/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { useCouponEligibility } from '@views/settings/hooks/useCouponEligibility'
import { useCouponParams } from '@views/settings/hooks/useCouponParams'
import { SubscriptionPlans } from '@views/settings/routes/OrganizationSubscriptionSettings/elements/SubscriptionPlans'
import { fetchSubscription } from '@views/settings/routes/OrganizationSubscriptionSettings/services/query-api'
import { EditSubscriptionParams } from '@views/settings/routes/OrganizationSubscriptionSettings/types'

import { ModalId } from '../../enums/modalId'
import { QueryKeys } from '../../enums/queryKeys'
import { isValidSubscriptionPlan } from '../../utils/isValidSubscriptionPlan'
import * as Styled from './styles'

const PlanConfirmationModal = withModalConditionalRender(PlanConfirmationModalComponent)

interface SubscriptionTrialExpiredProps {
  onEditSubscription: (detail: EditSubscriptionParams) => void
  organizationId: string
}

export const SubscriptionTrialExpired = ({ onEditSubscription, organizationId }: SubscriptionTrialExpiredProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [showProcessingScreen, setShowProcessingScreen] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionPlan>(SubscriptionPlan.Premium)
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>(SubscriptionPeriod.Yearly)
  const [confirmPrice, setConfirmPrice] = useState<number>(0)
  const [recurringPrice, setRecurringPrice] = useState<number>(0)

  const { data: subscription, isLoading: loadingSubscription } = useQuery(
    [QueryKeys.OrganizationSubscription, organizationId],
    () => fetchSubscription(organizationId),
    {
      enabled: !!organizationId,
    },
  )
  const { search } = useLocation()

  const params = qs.parse(search || '', {
    ignoreQueryPrefix: true,
  })

  const { handleCouponEligibilityCheck, setDiscountPercent, totalPrice, totalRecurringPrice } = useCouponEligibility({
    organizationId,
    selectedPeriod,
    selectedPlan,
    confirmPrice,
    recurringPrice,
    signupCouponPlan: isValidSubscriptionPlan(params.plan) ? params.plan : undefined,
  })

  const { open: openPlanConfirmationModal, close: closeConfirmationModal } = useModal(ModalId.PlanConfirmationModal, {
    onClose: () => setDiscountPercent(0),
  })

  const handlePlanConfirm = (coupon?: string) => {
    setShowProcessingScreen(true)
    onEditSubscription({
      detail: {
        plan: selectedPlan,
        period: selectedPeriod || undefined,
        price: confirmPrice,
        coupon,
      },
    })
    queryClient.invalidateQueries(QueryKeys.OrganizationSubscription)
    queryClient.invalidateQueries(QueryKeys.OrganizationSubscriptionInfo)
    closeConfirmationModal()
    setShowProcessingScreen(false)
  }

  const handlePlanSelect = useCallback(
    (plan: SubscriptionPlan, period?: SubscriptionPeriod) => {
      if (period) {
        setSelectedPeriod(period)
      }
      setSelectedPlan(plan)
      openPlanConfirmationModal()
    },
    [openPlanConfirmationModal],
  )

  useCouponParams({ params, organizationId, handlePlanSelect })

  const handlePriceChange = useCallback((confirmPrice: number, recurringPrice: number) => {
    setConfirmPrice(confirmPrice)
    setRecurringPrice(recurringPrice)
  }, [])

  if (loadingSubscription || !subscription) {
    return null
  }

  return (
    <>
      <Styled.SubHeader variant="large">{t('organization_subscription.trial.subheader')}</Styled.SubHeader>
      <Styled.PlansWrapper>
        <SubscriptionPlans onPlanSelect={handlePlanSelect} />
      </Styled.PlansWrapper>
      {showProcessingScreen && <ProcessingScreen />}
      <PlanConfirmationModal
        id={ModalId.PlanConfirmationModal}
        onConfirm={handlePlanConfirm}
        onCouponEligibilityCheck={(coupon) => handleCouponEligibilityCheck(coupon)}
        onPriceChange={handlePriceChange}
        planText={t(`organization_subscription.${selectedPlan}`)}
        price={totalPrice}
        recurringPrice={totalRecurringPrice}
        subscriptionPeriod={selectedPeriod}
        subscriptionPlan={selectedPlan}
      />
    </>
  )
}
