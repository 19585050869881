import { AttachmentFile } from '@design-system'

import { VoucherFile } from '@modules-deprecated/inbox/types'

import { BohrScanResult } from '../../../../../types/bohrScanResult'

export const getBohrScanResultFromAttachmentFile = (
  attachmentFile: AttachmentFile | VoucherFile | undefined,
): BohrScanResult => {
  return JSON.parse(attachmentFile?.dataJson || '{}')
}
