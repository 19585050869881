import { useModal } from '@design-system'

import { useEffect } from 'react'

import { ModalId } from '../../../../../enums/modalId'

export const useCloseReconciliationModals = (): void => {
  const { close: closeBillReconciliationModal } = useModal(ModalId.BillReconciliationModal)

  // Cleanup useEffect to close all Bill Reconciliation modals when the component is (un)mounted
  useEffect(() => {
    return () => {
      closeBillReconciliationModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
