import styled from '@emotion/styled'

interface LoaderCellProps {
  columnCount: number
}

export const LoaderCell = styled.td<LoaderCellProps>`
  grid-column: span ${({ columnCount }) => columnCount};
  text-align: center;
`

export const TableFooter = styled.tfoot`
  margin: -10px;
  height: 10px;
`
