import cc from 'classcat'
import React, { ChangeEvent, MouseEvent, useRef } from 'react'

import { TableCell } from '../TableCell'
import { TableCellWrapper } from '../TableCellWrapper'
import { TableColumn, TableRow } from '../types'
import * as Styled from './styles'

type TableRowComponentProps<T extends TableRow> = {
  className?: string
  columns: TableColumn<T>[]
  columnsLayout: string
  index: number
  isRowSelectionDisabled?: boolean
  onRowSelect?: (row: T, selected: boolean) => void
  onSelect?: (row: T, index: number) => void
  row: T
  rowSpacing?: number
  selectable?: boolean
  totalLabelColumnName?: keyof T
  hoverable?: boolean
}

export const TableRowComponent = <T extends TableRow>({
  className,
  columns,
  columnsLayout,
  hoverable,
  index,
  isRowSelectionDisabled,
  onRowSelect = () => null,
  onSelect,
  row,
  rowSpacing,
  selectable,
  totalLabelColumnName,
  ...rest
}: TableRowComponentProps<T>) => {
  const checkboxWrapperRef = useRef<HTMLTableCellElement>(null)

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (selectable && checkboxWrapperRef.current?.contains(event.target as Node)) {
      return
    }

    onSelect && onSelect(row, index)
  }

  return (
    <Styled.TableRowWrapper
      key={row.id}
      onClick={handleClick}
      className={cc([className, { hoverable }])}
      data-row-id={row.id}
      columns={columnsLayout}
      rowSpacing={rowSpacing}
    >
      {selectable && (
        <Styled.CheckboxWrapper ref={checkboxWrapperRef}>
          <Styled.Checkbox
            onChange={(event: ChangeEvent<HTMLInputElement>) => onRowSelect(row, event.target.checked)}
            disabled={isRowSelectionDisabled}
          />
        </Styled.CheckboxWrapper>
      )}
      {columns.map((column: TableColumn<T>, index: number) => (
        <TableCellWrapper row={row} key={`${row.id}-${index}`}>
          <TableCell<T>
            row={row}
            field={column.field}
            render={column.render}
            type={column.type}
            customStyle={column.customStyle}
            truncate={column.truncate}
            isTotalLabel={totalLabelColumnName === column.field}
          />
        </TableCellWrapper>
      ))}
    </Styled.TableRowWrapper>
  )
}
