import { ExportButtonDropdown, ExportType } from '@components'

import React, { ReactElement, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useSegment } from '../../../../hooks'
import { useDownloadExport } from '../../../../hooks/useDownloadExport'
import { trackError } from '../../../../utils/trackError'
import { ReportsApiUrl } from '../../enums/reportsApiUrl'
import { useBalanceFilters } from '../../routes/Balance/hooks/useBalanceFilters'
import { useProfitAndLossFilters } from '../../routes/ProfitAndLoss/hooks/useProfitAndLossFilters'
import { useTrialBalanceFilters } from '../../routes/TrialBalance/hooks/useTrialBalanceFilters'
import {
  BalanceSheetReportOptions,
  IncomeStatementsReportOptions,
  TrialBalanceReportOptions,
} from '../../types/reportOptions'
import { getExportStatementDownloadUrl } from '../../utils/getExportStatementDownloadUrl'
import { getReportPeriodForEvents } from '../../utils/getReportPeriodForEvents'

export type ExportReportFilterOptions =
  | IncomeStatementsReportOptions
  | BalanceSheetReportOptions
  | TrialBalanceReportOptions

export type FilterOptions = IncomeStatementsReportOptions | BalanceSheetReportOptions | TrialBalanceReportOptions

interface ExportReportProps {
  options?: ExportReportFilterOptions
  reportUrl?: ReportsApiUrl
}

const exportReportName: Record<ReportsApiUrl, string> = {
  [ReportsApiUrl.Balance]: 'Balance',
  [ReportsApiUrl.ProfitAndLoss]: 'Profit and Loss',
  [ReportsApiUrl.TrialBalance]: 'Trial balance',
}

export const ExportReport = ({ options, reportUrl }: ExportReportProps): ReactElement => {
  const { download } = useDownloadExport()
  const { organization } = useUserOrganization()
  const location = useLocation()
  const { track } = useSegment()
  const exportTypesToSkip: ExportType[] = []

  if (reportUrl !== ReportsApiUrl.TrialBalance) {
    exportTypesToSkip.push(ExportType.RegnskabBasis)
  }

  const exportStatement = useCallback(
    (type: ExportType) => {
      if (!organization?.id || !reportUrl) {
        trackError(`Coulnd't get the report url for the path: ${location.pathname}`)
        return
      }
      const downloadUrl = getExportStatementDownloadUrl(reportUrl, organization.id, type, options)
      download(downloadUrl)
      /* eslint-disable @typescript-eslint/naming-convention */
      track('XXX - Mikkel - Exports', {
        export_type: exportReportName[reportUrl],
        file_format: type,
        period: options ? getReportPeriodForEvents(options) : 'all',
      })
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    [organization?.id, reportUrl, options, download, track, location.pathname],
  )

  const handleSelect = useCallback(
    (_: string, value: ExportType) => {
      exportStatement(value)
    },
    [exportStatement],
  )

  return <ExportButtonDropdown exportTypesToSkip={exportTypesToSkip} onSelect={handleSelect} />
}

const ExportReportProfitAndLoss = () => {
  const [options] = useProfitAndLossFilters()
  return <ExportReport reportUrl={ReportsApiUrl.ProfitAndLoss} options={options} />
}

const ExportReportTrialBalance = () => {
  const [options] = useTrialBalanceFilters()
  return <ExportReport reportUrl={ReportsApiUrl.TrialBalance} options={options} />
}

const ExportReportBalance = () => {
  const [options] = useBalanceFilters()
  return <ExportReport reportUrl={ReportsApiUrl.Balance} options={options} />
}

ExportReport.ProfitAndLoss = ExportReportProfitAndLoss
ExportReport.TrialBalance = ExportReportTrialBalance
ExportReport.Balance = ExportReportBalance
