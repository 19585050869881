import { NotesPanel, ReceiptRejectionButton, UpgradeSubscriptionModal } from '@components'
import { Button, ButtonsGroup, ModalConfirmation, notify, Tooltip, useModal, useModals } from '@design-system'

import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { AttachmentType } from '@features/attachments/enums/attachmentType'

import { OPEN_NOTES_PANEL_SHORTCUT, SUBMIT_SHORTCUT } from '../../../../constants/shortcuts'
import { useDirtyRoute } from '../../../../contexts/dirtyRouteContext'
import { ModalId } from '../../../../enums/modalId'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useFormContext, useSegment, useWatch } from '../../../../hooks'
import { useComponentId, useOrganizationBrand, useSubscriptionLimits } from '../../../../hooks/'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useBillAttachmentPageContext } from '../../contexts/billAttachmentPageContext'
import { BillState } from '../../enums/billState'
import { BillSubmitAction } from '../../enums/billSubmitAction'
import { BulkMode } from '../../enums/bulkMode'
import { useBillTimeline } from '../../hooks/useBillTimeline'
import { useBulkBillsNavigation } from '../../hooks/useBulkBillsNavigation'
import { useInvalidateBills } from '../../hooks/useInvalidateBills'
import { Bill } from '../../types/bill'
import { BillBulkEditNavigation } from '../BillBulkEditNavigation'
import { BillDeleteAction } from '../BillDeleteAction'
import { BillDuplicationsModal } from '../BillDuplicationsModal'
import { useBillForm } from '../BillEditableForm/contexts/billFormContext'
import { useBillDuplicatesCheck } from '../BillEditableForm/hooks/useBillDuplicatesCheck'
import { useDeleteBill } from '../BillEditableForm/hooks/useDeleteBill'
import { BillFormSchema } from '../BillEditableForm/utils/formData'
import { BillNotesPanel } from '../BillNotesPanel'

export const BillEditableActions = (): ReactElement => {
  const [duplicatedBills, setDuplicatedBills] = useState<Bill[]>()
  const [isNotesPanelOpen, setIsNotesPanelOpen] = useState(false)
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { invalidateBills } = useInvalidateBills()
  const { checkForDuplicateBills, isChecking: isCheckingDuplicates } = useBillDuplicatesCheck()
  const { approve, billId, processingAction, save, bulkEditMode, isFetching } = useBillForm()
  const { hasExceededExpensesLimit, invalidateLimits, expensesLimit } = useSubscriptionLimits()
  const { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal } = useModal(
    ModalId.BillDeleteConfirmationModal,
  )
  const { open: openDuplicateBillModal } = useModal(ModalId.DuplicatedBillActionModal, {
    onClose: () => setDuplicatedBills(undefined),
  })
  const upgradeModalId = useComponentId(ModalId.UpgradeSubscriptionModal)
  const { open: openUpgradeSubscriptionModal } = useModal(upgradeModalId)
  const { setDirty } = useDirtyRoute()
  const { hasNextBill, goToNextBill } = useBulkBillsNavigation()
  const [{ openedIds: openedModalsIds }] = useModals()
  const { track } = useSegment()
  const { control } = useFormContext<BillFormSchema>()
  const [currentPageIndex] = useBillAttachmentPageContext()
  const billState = useWatch({ control, name: 'state' })
  const attachmentsFiles = useWatch({ control, name: 'attachmentsFiles', defaultValue: [] })
  const { count: notesCount } = useBillTimeline(billId)
  const { isBrandBilly } = useOrganizationBrand()
  const { url: billsUrl } = useOrganizationViewUrl(reactRoute.bills.getMainRoute())
  const { url: uploadsUrl } = useOrganizationViewUrl(reactRoute.uploads.getMainRoute())

  // Computed values

  const isApprovalProcessing =
    processingAction === BillSubmitAction.Approve || processingAction === BillSubmitAction.ApproveBulk
  const isSaveProcessing = processingAction === BillSubmitAction.Save
  const isDraftBillOrBillBulk = billState === BillState.Draft || bulkEditMode === BulkMode.Bill // isBulkBillEditMode to faster detect drafts
  const isCurrentFileEDocument = attachmentsFiles?.[currentPageIndex]?.attachmentType === AttachmentType.EDocument

  // Delete mutation

  const { delete: deleteBill, isLoading: isDeleting } = useDeleteBill({
    onSuccess: async () => {
      notify({
        id: 'bill-deleted',
        message: t('bill.notification.delete_success'),
        variant: 'success',
      })

      await invalidateBills()
      closeDeleteConfirmationModal()

      if (bulkEditMode === BulkMode.Bill && hasNextBill) {
        goToNextBill()
      } else {
        // Route back to the bills list
        history.replace(`${billsUrl}${location.search}`)
      }
    },
    onError: (error) => {
      notify({
        id: 'bill-deleted',
        message: error?.message || t('bill.notification.delete_error'),
        variant: 'error',
      })
    },
  })

  // Helpers

  const approveBillLabel = useMemo(() => {
    if (bulkEditMode) {
      return t('bill.action.approve_and_next')
    }

    if (billState === BillState.Approved) {
      return t('bill.action.update_approved')
    }

    return t('bill.action.approve')
  }, [billState, bulkEditMode, t])

  // Handlers

  const handleSaveClick = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'draft_bill_view',
      action: 'save draft',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bills_beta: true,
    })

    if (isFetching) {
      return
    }

    save()
  }, [isFetching, save, track])

  const handleApprove = useCallback(async () => {
    if (hasExceededExpensesLimit) {
      handleSaveClick()

      if (isBrandBilly) {
        openUpgradeSubscriptionModal()
      } else {
        notify({
          id: 'expenses-limit-exceeded',
          message: t('premium_feature.feature.free_expenses_limit.body', { limit: expensesLimit }),
          variant: 'error',
        })
      }
      return
    }

    if (billState === BillState.Approved) {
      approve()
      invalidateLimits()
      return
    }

    const duplicates = await checkForDuplicateBills()
    if (duplicates.length > 0) {
      setDuplicatedBills(duplicates)
      openDuplicateBillModal()
    } else {
      approve()
      invalidateLimits()
    }
  }, [
    approve,
    billState,
    checkForDuplicateBills,
    expensesLimit,
    handleSaveClick,
    hasExceededExpensesLimit,
    invalidateLimits,
    isBrandBilly,
    openDuplicateBillModal,
    openUpgradeSubscriptionModal,
    t,
  ])

  const handleApproveShortcut = useCallback(() => {
    handleApprove()
  }, [handleApprove])

  const handleApproveClick = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'draft_bill_view',
      action: 'approve',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bills_beta: true,
    })

    if (isFetching) {
      return
    }

    handleApprove()
  }, [handleApprove, isFetching, track])

  const handleDeleteClick = useCallback(() => {
    track('XXX - Jesper - Action Taken', {
      context: 'draft_bill_view',
      action: 'delete draft',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      bills_beta: true,
    })
    openDeleteConfirmationModal()
  }, [openDeleteConfirmationModal, track])

  const handleDeleteBill = useCallback(() => {
    if (!billId) {
      history.replace(`${billsUrl}${location.search}`)
      return
    }

    deleteBill(billId)
  }, [billId, billsUrl, deleteBill, history, location.search])

  const handleDeleteModalOkClick = useCallback(() => {
    handleDeleteBill()
  }, [handleDeleteBill])

  const handleOnDuplicateKeep = useCallback(() => {
    setDirty(false)
    approve()
  }, [approve, setDirty])

  const handleOnDuplicateDiscard = useCallback(() => {
    setDirty(false)
    handleDeleteBill()
  }, [handleDeleteBill, setDirty])

  const handleAddNotesButtonClick = useCallback(() => {
    setIsNotesPanelOpen(true)
  }, [])

  const handleNotesPanelClose = useCallback(() => {
    setIsNotesPanelOpen(false)
  }, [])

  const handleOpenNotesPanelShortcut = useCallback(() => {
    setIsNotesPanelOpen(true)
  }, [])

  const handleRejectEInvoice = useCallback(() => {
    if (uploadsUrl) {
      history.push(uploadsUrl)
    }
  }, [uploadsUrl, history])

  // Shortcuts

  useHotkeys(
    SUBMIT_SHORTCUT,
    handleApproveShortcut,
    {
      enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'],
      enabled: !openedModalsIds.length && !isNotesPanelOpen,
    },
    [openedModalsIds, isNotesPanelOpen],
  )

  useHotkeys(
    OPEN_NOTES_PANEL_SHORTCUT,
    handleOpenNotesPanelShortcut,
    { enabled: !!billId && !isFetching, enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'] },
    [billId, isFetching, handleOpenNotesPanelShortcut],
  )

  return (
    <>
      <ButtonsGroup>
        <Button
          disabled={isFetching || (!!processingAction && !isApprovalProcessing)}
          icon="checkCircle"
          loading={isApprovalProcessing || isCheckingDuplicates}
          onClick={handleApproveClick}
        >
          {approveBillLabel}
        </Button>
        {isCurrentFileEDocument && (
          <ReceiptRejectionButton
            attachmentId={attachmentsFiles[currentPageIndex]?.attachmentId}
            onSuccess={handleRejectEInvoice}
          />
        )}
        {/*
          We have to disable saving as draft in the attachment's bulk edit, as we don't have billId at this point.
          Th url and whole mechanism of this bulk is based on the attachments ids so we would have to handle it additionally
          to also keep the billId in the url after creation. It's doable, but for now we're skipping this case.
        */}
        {bulkEditMode !== BulkMode.Attachment && billState !== BillState.Approved && (
          <Button
            disabled={isFetching || (!!processingAction && !isSaveProcessing)}
            icon="arrowUpInsideCloud"
            loading={isSaveProcessing}
            onClick={handleSaveClick}
            variant="secondary"
          >
            {billId ? t('bill.action.update') : t('bill.action.save_as_draft')}
          </Button>
        )}
        {billId && !isFetching && (
          <Tooltip label={t('note_panel.title')} placement="bottom">
            <NotesPanel.Trigger onClick={handleAddNotesButtonClick} count={notesCount} />
          </Tooltip>
        )}
        {isDraftBillOrBillBulk && <BillDeleteAction onClick={handleDeleteClick} />}
        {!!bulkEditMode && <BillBulkEditNavigation />}
      </ButtonsGroup>

      {/* Delete Confirmation Modal  */}
      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={ModalId.BillDeleteConfirmationModal}
        message={t('bill.action.delete.message')}
        okLabel={t('delete')}
        okLoading={isDeleting}
        onOk={handleDeleteModalOkClick}
        title={t('bill.action.delete.title')}
      />

      {/* Bill Duplication Modal  */}
      <BillDuplicationsModal
        duplicatedBills={duplicatedBills}
        onOk={handleOnDuplicateKeep}
        onCancel={handleOnDuplicateDiscard}
      />

      {/* Notes Panel */}
      {billId && <BillNotesPanel billId={billId} isOpen={isNotesPanelOpen} onClose={handleNotesPanelClose} />}
      {/* Upgrade Subscription Modal */}
      <UpgradeSubscriptionModal id={upgradeModalId} trackingContext={TrackingContext.FreeExpensesLimitExceeded} />
    </>
  )
}
