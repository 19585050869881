import { isMac } from '../utils/isMac'

export const OPEN_REGISTER_PAYMENT_MODAL_SHORTCUT = isMac ? 'option+p' : 'alt+p'

export const GLOBAL_SEARCH_SHORTCUT = isMac ? 'command+s' : 'ctrl+s'

export const OPEN_HELP_MODAL_SHORTCUT = isMac ? 'option+h' : 'alt+h'

export const SUBMIT_SHORTCUT = isMac ? 'command+enter' : 'ctrl+enter'

export const NAVIGATE_LEFT_SHORTCUT = isMac ? 'command+left' : 'ctrl+left'

export const NAVIGATE_RIGHT_SHORTCUT = isMac ? 'command+right' : 'ctrl+right'

export const DELETE_SHORTCUT = isMac ? 'command+backspace' : 'ctrl+backspace'

export const OPEN_NOTES_PANEL_SHORTCUT = isMac ? 'option+n' : 'alt+n'

export const CMD_SHORTCUT = isMac ? 'command' : 'ctrl'

export const ALT_SHORTCUT = isMac ? 'option' : 'alt'
