import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const FileThumbnailContainer = styled.figure`
  display: flex;
  width: 47px;
  height: 47px;
  margin-right: ${Spacing.S};
  padding: ${Spacing.XXS};
  border: 1px solid ${Color.Gray40};
  background-color: ${Color.White};
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
`
