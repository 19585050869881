import { ZervantLocale } from '../../../enums/zervantLocale'
import { InvoiceLanguage } from '../types/invoiceLanguage'

export const invoiceLanguageToLocale: Record<InvoiceLanguage, ZervantLocale> = {
  de: ZervantLocale.DE_DE,
  en: ZervantLocale.EN_GB,
  fi: ZervantLocale.FI_FI,
  fr: ZervantLocale.FR_FR,
  nl: ZervantLocale.NL_NL,
  sv: ZervantLocale.SV_SE,
}
