import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'

export const useInvalidateBills = () => {
  const queryClient = useQueryClient()

  const invalidateBills = useCallback(async () => {
    await queryClient.invalidateQueries(QueryKeys.Bills)
    await queryClient.invalidateQueries(QueryKeys.BillsSummary)
    await queryClient.invalidateQueries(QueryKeys.Creditors)
  }, [queryClient])

  const invalidateBill = useCallback(
    async (billId?: string) => {
      await queryClient.invalidateQueries([QueryKeys.Bill, billId])
      await queryClient.invalidateQueries([QueryKeys.BillRecociliationMatches, billId])
    },
    [queryClient],
  )

  return {
    invalidateBills,
    invalidateBill,
  }
}
