import { ButtonProtected } from '@components'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as NoBookkeepersIllustration } from '../../../../../../../../assets/images/organization-users-settings/no-bookkeepers.svg'
import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'
import { useEmberTransitionTo } from '../../../../../../../../hooks/routing/useEmberTransitionTo'
import { useOrganizationViewUrl } from '../../../../../../../../hooks/routing/useOrganizationViewUrl'
import { getImageClassName } from '../../../../../../../../utils/getClassName'
import { reactRoute } from '../../../../../../../../utils/routing/reactRoute'
import * as Styled from './styles'

export const BookkeepersEmptyView = (): ReactElement => {
  const { t } = useTranslation()
  const { transitionTo } = useEmberTransitionTo()

  const findAccountantRoute = reactRoute.findAccountant.getMainRoute()
  const { url: findAccountantUrl } = useOrganizationViewUrl(findAccountantRoute)

  const handleFindBookkeeperClick = useCallback(() => {
    if (findAccountantUrl) {
      transitionTo(findAccountantUrl)
    }
  }, [transitionTo, findAccountantUrl])

  return (
    <Styled.BookkeepersEmptyView>
      <NoBookkeepersIllustration className={getImageClassName()} />
      <Styled.Content colorVariant="secondary">
        {t('settings.organization.users.bookkeepers_section.empty_content')}
      </Styled.Content>
      <ButtonProtected
        scopes={Scope.UserWriteAccountant}
        trackingContext={TrackingContext.OrganizationSettingsUser}
        onClick={handleFindBookkeeperClick}
      >
        {t('settings.organization.users.bookkeepers_section.find_bookkeeper')}
      </ButtonProtected>
    </Styled.BookkeepersEmptyView>
  )
}
