import { useCallback } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { trackError } from '../../../../utils/trackError'
import { COMPANY_NAME } from '../constants'
import { onboardUserToFroda } from '../query-api'

export const useFrodaOnboarding = (
  openFrodaProcessingModal: () => void,
  closeFrodaProcessingModal: () => void,
  setHomeAccessUrl: (url: string) => void,
) => {
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()

  const onboardUser = useCallback(async () => {
    const names = user?.name?.split(' ') ?? []

    if (names.length < 2 || !user?.phone || !organization?.registrationNo) {
      return null
    }

    const lastName = names.pop() ?? ''
    const firstName = names.join(' ')

    try {
      openFrodaProcessingModal()

      const frodaHomeUrl = await onboardUserToFroda({
        externalId: organization.id,
        locale: user.locale.split('_')[0],
        firstName,
        lastName,
        email: user.email,
        countryCode: organization.countryId,
        mobileNumber: user.phone,
        companyName: COMPANY_NAME,
        registrationNumber: organization.registrationNo,
        vatNumber: organization.countryId.concat(organization.registrationNo),
        customerSince: organization.createdTime as string,
      })

      setHomeAccessUrl(frodaHomeUrl)
    } catch (error) {
      trackError(error)
    } finally {
      closeFrodaProcessingModal()
    }
  }, [
    openFrodaProcessingModal,
    closeFrodaProcessingModal,
    organization?.countryId,
    organization?.createdTime,
    organization?.id,
    organization?.registrationNo,
    user?.email,
    user?.locale,
    user?.name,
    user?.phone,
    setHomeAccessUrl,
  ])

  return {
    onboardUser,
  }
}
