import { notify } from '@design-system'

import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser, useUpdateUser } from '@modules-deprecated/app/user'
import { useChangeUserEmail } from '@modules-deprecated/app/user/hooks/useChangeEmail'

import { queryClient } from '../../../../../config/queryClient'
import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useForm } from '../../../../../hooks'
import { useOrganizationBrand } from '../../../../../hooks/useOrganizationBrand'
import { getDefaultValues, getValidationSchema, UserProfileForm } from '../utils/userFormData'

export const useUserProfileForm = () => {
  const { t } = useTranslation()
  const { setDirty } = useDirtyRoute()
  const { user, isLoading: isUserDataLoading } = useCurrentUser()
  const { isBrandBilly } = useOrganizationBrand()
  const isEmailChangeAllowed = isBrandBilly

  const { changeUserEmail } = useChangeUserEmail({
    onSuccess: () => {
      notify({
        id: NotificationKeys.ChangeEmailAddress,
        message: t('settings.user.check_confirmation_email', {
          email: user?.email,
        }),
        variant: 'success',
      })
    },
    onError: () => {
      notify({
        id: NotificationKeys.ChangeEmailAddress,
        message: t('settings.user.check_confirmation_email_error'),
        variant: 'error',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.CurrentUser)
    },
  })
  const { update, isLoading: isSubmitting } = useUpdateUser({
    onSuccess: () => {
      notify({
        id: NotificationKeys.SettingsUserProfile,
        message: t('settings.user.profile_update_success'),
        variant: 'success',
      })
      setDirty(false)
    },
    onError: () => {
      notify({
        id: NotificationKeys.SettingsUserProfile,
        message: t('settings.user.profile_update_error'),
        variant: 'error',
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.CurrentUser)
    },
  })

  const handleFormSubmit = useCallback(
    (values: UserProfileForm) => {
      if (!user) {
        return
      }

      if (user.email !== values.email && isEmailChangeAllowed) {
        changeUserEmail({
          providerId: 'billy_dk',
          email: values.email,
        })
      }

      update({
        id: user.id,
        canCreateDaybooks: values.canCreateDaybooks,
        name: values.name,
        phone: values.phone,
      })
    },
    [changeUserEmail, update, user, isEmailChangeAllowed],
  )

  const {
    formState: { isDirty },
    reset,
    ...form
  } = useForm({
    defaultValues: useMemo(() => getDefaultValues(user), [user]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
    onSubmit: handleFormSubmit,
  })

  useEffect(() => {
    if (!isUserDataLoading) {
      reset(getDefaultValues(user))
    }
  }, [isUserDataLoading, reset, user])

  useEffect(
    () => {
      setDirty(isDirty)
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty],
  )

  return { ...form, isEmailChangeAllowed, isLoading: isUserDataLoading, isSubmitting }
}
