import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { Asable } from '../../types/asable'
import { AlignContent } from './types/alignContent'
import { AlignItems } from './types/alignItems'
import { FlexDirection } from './types/flexDirection'
import { FlexWrap } from './types/flexWrap'
import { JustifyContent } from './types/justifyContent'

export interface FlexWrapperProps extends Asable {
  alignContent?: AlignContent
  alignItems?: AlignItems
  flexDirection?: FlexDirection
  flexGrow?: number
  flexWrap?: FlexWrap
  gap?: Spacing
  justifyContent?: JustifyContent
  width?: string | number
}

export const FlexWrapper = styled.div<FlexWrapperProps>`
  display: flex;
  ${({ alignContent }) => alignContent && `align-content: ${alignContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ flexGrow }) => flexGrow && `flex-grow: ${flexGrow}`};
  ${({ gap }) => gap && `gap: ${gap}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ width }) => width && `width: ${width}`};
`
