import { Button } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment, useZervantNavigation } from '../../../../../../../../hooks'

export const ExternalViewInvoicesButton = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { redirectToZervantInvoicesView } = useZervantNavigation()

  const handleButtonClick = useCallback(() => {
    track('XXX - Invoicing Core - Access invoices list in Zervant clicked')
    redirectToZervantInvoicesView()
  }, [redirectToZervantInvoicesView, track])

  return (
    <Button onClick={handleButtonClick} variant="secondary">
      {t('external_invoices.zervant_button.cta')}
    </Button>
  )
}
