import { NavItem, NavListItemWithInlineDescription, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AttachmentsSortFilterDropdownValue } from '@features/attachments/types/attachmentsSortFilterDropdownValue'
import { getDisplayTitle } from '@features/attachments/utils/getDisplayTitle'
import { getSortFilterId } from '@features/attachments/utils/getSortFilterId'
import { getSortingItems } from '@features/attachments/utils/getSortingItems'

import { defaultAttachmentsQuery, useAttachmentsFilters } from '../../hooks/useAttachmentsFilters'
import * as Styled from './styles'

export const ReceiptsSorting = (): ReactElement => {
  const { t } = useTranslation()
  const [{ sortBy, sortDirection }, setFilters] = useAttachmentsFilters()

  const items: NavItem<AttachmentsSortFilterDropdownValue>[] = useMemo(() => getSortingItems(t), [t])

  const handleSelect = useCallback(
    (_id: string, value: AttachmentsSortFilterDropdownValue) => {
      const { direction, property } = value

      setFilters({ page: defaultAttachmentsQuery.page, sortBy: property, sortDirection: direction })
    },
    [setFilters],
  )

  return (
    <Styled.ReceiptsSortingWrapper>
      <Text colorVariant="secondary" nowrap>
        {t('receipts.sorting.title')}
      </Text>
      <Styled.ButtonDropdownWrapper
        dropdownSize="fitTrigger"
        dropdownItemRender={(props) => <NavListItemWithInlineDescription {...props} />}
        getDisplayValue={getDisplayTitle}
        items={items}
        onSelect={handleSelect}
        selectedId={getSortFilterId(sortBy, sortDirection)}
        variant="secondary"
      />
    </Styled.ReceiptsSortingWrapper>
  )
}
