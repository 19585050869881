import {
  Attachment,
  ButtonDropdown,
  NavItem,
  useDropzoneInput,
  useModal,
  withModalConditionalRender,
} from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ChooseAttachmentModal as ChooseAttachmentModalComponent } from '@features/attachments/elements/ChooseAttachmentModal'

import { ModalId } from '../../../../enums/modalId'
import { openFileDialog } from './utils/openFileDialog'

export enum FileOriginOption {
  Device = 'device',
  Uploads = 'uploads',
}

interface ChooseFileDropdownProps {
  attachmentsIdsChosen?: string[]
  onFileFromUploadsSelect?: (attachment: Attachment) => void
  onFileFromDeviceSelect?: (files: File[]) => void
}

const ChooseAttachmentModal = withModalConditionalRender(ChooseAttachmentModalComponent)

export const ChooseFileDropdown = ({
  attachmentsIdsChosen,
  onFileFromUploadsSelect,
  onFileFromDeviceSelect,
}: ChooseFileDropdownProps): ReactElement => {
  const { t } = useTranslation()
  const { open: openChooseFromUploadsModal } = useModal(ModalId.AttachmentsModal)

  const items: NavItem<FileOriginOption>[] = useMemo(
    () => [
      {
        id: FileOriginOption.Device,
        children: t('choose_file_dropdown.device'),
        value: FileOriginOption.Device,
      },
      {
        id: FileOriginOption.Uploads,
        children: t('choose_file_dropdown.uploads'),
        value: FileOriginOption.Uploads,
      },
    ],
    [t],
  )

  const handleFileFromDeviceSelect = useCallback(
    (files: File[]) => {
      onFileFromDeviceSelect?.(files)
    },
    [onFileFromDeviceSelect],
  )

  const { inputRef, inputProps } = useDropzoneInput({ onFilesSelect: handleFileFromDeviceSelect, multiple: true })

  const handleFileFromUploadsSelect = useCallback(
    (attachment: Attachment) => {
      onFileFromUploadsSelect?.(attachment)
    },
    [onFileFromUploadsSelect],
  )

  const handleItemSelect = useCallback(
    (id: string, value: FileOriginOption) => {
      switch (value) {
        case FileOriginOption.Device:
          openFileDialog(inputRef)
          break
        case FileOriginOption.Uploads:
          openChooseFromUploadsModal()
          break
      }
    },
    [inputRef, openChooseFromUploadsModal],
  )

  return (
    <>
      <ButtonDropdown
        items={items}
        onSelect={handleItemSelect}
        variant="secondary"
        placement="bottom-start"
        dropdownWidth={215}
      >
        {t('choose_file_dropdown')}
      </ButtonDropdown>
      <input ref={inputRef} {...inputProps} />

      <ChooseAttachmentModal
        id={ModalId.AttachmentsModal}
        idsToSkip={attachmentsIdsChosen}
        onSelect={handleFileFromUploadsSelect}
        size="xl"
      />
    </>
  )
}
