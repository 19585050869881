import styled from '@emotion/styled'

import { DefaultValue } from '../../enums/defaultValue'
import { Themable } from '../../types/themable'
import { skeletonAnimation } from '../../utils/skeletonAnimation'

const DEFAULT_WIDTH = 150
const DEFAULT_HEIGHT = 50

interface SkeletonBoxProps extends Themable {
  aspectRatio?: string
  fullHeight?: boolean
  fullWidth?: boolean
  height?: number
  width?: number
}

export const SkeletonBox = styled.div<SkeletonBoxProps>`
  ${({ aspectRatio, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) =>
    aspectRatio ? `aspect-ratio: ${aspectRatio};` : `width: ${width}px; height: ${height}px;`};
  ${({ fullWidth }) => fullWidth && 'width: 100%'};
  ${({ fullHeight }) => fullHeight && 'height: 100%'};
  ${skeletonAnimation()};
  border-radius: ${DefaultValue.BorderRadius};
`
