import { DateFormatter } from '@components'
import { Flex, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useAttachmentPreviewModal } from '../../../../context/attachmentPreviewModalContext'
import { AttachmentInfo } from '../AttachmentInfo'

export const FileDetails = (): ReactElement => {
  const { fileName, createdDate } = useAttachmentPreviewModal()
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column">
      <Text weight="bold">{t('attachment_preview_modal.details.file_subtitle')}</Text>
      <Space size="xxs" />
      <AttachmentInfo title={t('attachment_preview_modal.details.filename_label')}>
        <Text>{fileName}</Text>
      </AttachmentInfo>
      <AttachmentInfo title={t('attachment_preview_modal.details.upload_date_label')}>
        <Text>{createdDate ? <DateFormatter value={createdDate} /> : '-'}</Text>
      </AttachmentInfo>
    </Flex>
  )
}
