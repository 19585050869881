import { ButtonDropdown, NavItem, NavListItemWithInlineDescription } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AttachmentsSortFilterDropdownValue } from '../../../../../../types/attachmentsSortFilterDropdownValue'
import { getDisplayTitle } from '../../../../../../utils/getDisplayTitle'
import { getSortFilterId } from '../../../../../../utils/getSortFilterId'
import { getSortingItems } from '../../../../../../utils/getSortingItems'
import {
  defaultChooseAttachmentModalFilters,
  useChooseAttachmentModal,
} from '../../../../contexts/chooseAttachmentModalContext'

export const SortAction = (): ReactElement => {
  const { t } = useTranslation()
  const {
    filters: { sortDirection, sortProperty },
    setFilters,
  } = useChooseAttachmentModal()

  const items: NavItem<AttachmentsSortFilterDropdownValue>[] = useMemo(() => getSortingItems(t), [t])

  const handleSelect = useCallback(
    (_id: string, value: AttachmentsSortFilterDropdownValue) => {
      const { direction, property } = value

      setFilters({ page: defaultChooseAttachmentModalFilters.page, sortProperty: property, sortDirection: direction })
    },
    [setFilters],
  )

  return (
    <ButtonDropdown
      dropdownSize="xl"
      dropdownItemRender={(props) => <NavListItemWithInlineDescription {...props} />}
      getDisplayValue={getDisplayTitle}
      items={items}
      onSelect={handleSelect}
      selectedId={getSortFilterId(sortProperty, sortDirection)}
      variant="secondary"
    />
  )
}
