import { ReactZoomPanPinchState } from 'react-zoom-pan-pinch'

const Bounds = {
  minX: -500,
  maxX: 500,
  minY: -600,
  maxY: 600,
}

export const checkBounds = (transformState: ReactZoomPanPinchState) => {
  const { positionX, positionY, scale } = transformState

  const clampedX = Math.max(Bounds.minX, Math.min(Bounds.maxX, positionX))
  const clampedY = Math.max(Bounds.minY, Math.min(Bounds.maxY, positionY))

  const isOutsideBounds = (positionX !== clampedX || positionY !== clampedY) && scale <= 2

  return { isOutsideBounds, x: clampedX, y: clampedY, scale }
}
