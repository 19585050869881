import { DateInput, DateInputProps as DateInputPropsDS } from '@design-system'

import { Locale } from 'date-fns'

import { useLocale } from '../../hooks/useLocale'
import { isLanguage, Language } from '../../types/language'
import { languageToDateLocale } from '../../utils/languageToDateLocale'

type DateInputLocalizedProps = Omit<DateInputPropsDS, 'locale'>

export const DateInputLocalized = (props: DateInputLocalizedProps) => {
  const { code } = useLocale()

  const language: Language = isLanguage(code) ? code : 'en'
  const locale: Locale = languageToDateLocale[language]

  return <DateInput {...props} locale={locale} />
}
