import { notify } from '@design-system'

import uniq from 'lodash/uniq'
import queryString from 'qs'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { NotificationKeys } from '../../enums/notificationKeys'
import { deleteRequest, getCurrentLocale, getRequest, postRequest, putRequest } from '../../utils'
import { trackError } from '../../utils/trackError'
import { ReconcilablePostingType } from '../bankReconciliation/types'
import {
  accountsReceived,
  fileReceived,
  taxRatesReceived,
  voucherActionCustomRequest,
  voucherActionRecieved,
  voucherActionRequestFailed,
  vouchersNavigationReceived,
} from './action-creators'
import {
  ACCOUNTS_REQUEST,
  FILE_REQUEST,
  INBOX_ACTION_REQUEST,
  TAX_RATES_REQUEST,
  VOUCHER_ACTION_REQUEST,
  VOUCHER_DELETE,
  VOUCHER_DISCARD,
  VOUCHER_REQUEST_MORE_INFO,
  VOUCHERS_NAVIGATION_REQUEST,
} from './actions'
import { voucherServiceUrl } from './services/api'
import {
  AccountsRequestAction,
  FileRequestAction,
  TaxRatesRequestAction,
  Voucher,
  VoucherAction,
  VoucherActionRequestAction,
  VoucherDeleteAction,
  VoucherDiscardAction,
  VoucherInboxState,
  VoucherListQueryParams,
  VoucherNavigationRequest,
  VoucherRequestMoreInfoAction,
} from './types'

export const NAVIGATION_VOUCHERS_PREFETCH_SIDE_AMOUNT = 10

function* fetchTaxRates(action: TaxRatesRequestAction) {
  const response: { taxRates: any } = yield call(
    getRequest,
    `/v2/taxRates?organizationId=${action.payload}&include=taxRate.deductionComponents:embed`,
  )
  yield put(taxRatesReceived(response.taxRates))
}

function* fetchAccounts(action: AccountsRequestAction) {
  const locale = getCurrentLocale()

  const response: { accounts: any } = yield call(
    getRequest,
    `/v2/accounts?organizationId=${action.payload}&sortProperty=accountNo&include=account.group:embed`,
    { 'accept-language': locale },
  )
  yield put(accountsReceived(response.accounts))
}

function* inboxActionSaga({ payload }: any) {
  try {
    // todo call correct endpoint
    yield call(postRequest, '/', payload)
  } catch (error) {
    trackError(error)
  }
}

function* reconcileEntry(entryId: string, entryType: ReconcilablePostingType, bankLineMatchIds: string[]) {
  try {
    for (const bankLineMatchId of bankLineMatchIds) {
      yield call(postRequest, '/v2/bankLineSubjectAssociations', {
        bankLineSubjectAssociation: {
          matchId: bankLineMatchId,
          subjectReference: `${entryType}:${entryId}`,
        },
      })

      yield call(putRequest, `/v2/bankLineMatches/${bankLineMatchId}`, {
        bankLineMatch: {
          isApproved: true,
        },
      })
    }
  } catch (error: any) {
    notify({
      id: NotificationKeys.VoucherInboxEntryReconcile,
      message: `Error: ${error.body?.errorMessage}`,
      variant: 'error',
    })
  }
}

function* voucherActionRequest(request: VoucherActionRequestAction) {
  const { action, organizationId, voucherId, bankLinesIds, data = {} } = request.payload

  if (action === VoucherAction.update) {
    yield updateVoucher(request)
    return
  }

  try {
    const response: { id: any } = yield call(
      postRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}/actions/${action}`,
      data,
    )

    if ([VoucherAction.createBill, VoucherAction.createDaybookTransaction].includes(action) && bankLinesIds?.length) {
      const entryType =
        action === VoucherAction.createBill ? ReconcilablePostingType.Bill : ReconcilablePostingType.DaybookTransaction

      yield reconcileEntry(response.id, entryType, bankLinesIds)
    }

    if (!response.id) {
      throw new Error(`Could not proccess "${action}" action of voucher with id "${voucherId}"`)
    }

    yield put(voucherActionRecieved({ voucherId, action }))
  } catch (error: any) {
    yield put(voucherActionRequestFailed({ action, error, voucherId }))
  }
}

function* vouchersToNavigateRequest({ payload }: VoucherNavigationRequest) {
  const { organizationId, voucher, sort } = payload
  const inverseSort = sort && sort.charAt(0) === '-' ? sort.substring(1) : `-${sort}`

  try {
    const parametersPrev: VoucherListQueryParams = {
      limit: NAVIGATION_VOUCHERS_PREFETCH_SIDE_AMOUNT + 1,
      sort: inverseSort || '-createdTimestamp',
      inboxState: VoucherInboxState.RECEIVED,
      toDate: voucher.createdTimestamp,
      dueDateTo: new Date(),
      includeNullDueDate: true,
    }

    const parametersNext: VoucherListQueryParams = {
      limit: NAVIGATION_VOUCHERS_PREFETCH_SIDE_AMOUNT + 1,
      sort: sort || 'createdTimestamp',
      inboxState: VoucherInboxState.RECEIVED,
      fromDate: voucher.createdTimestamp,
      dueDateTo: new Date(),
      includeNullDueDate: true,
    }

    const vouchersPrev: Voucher[] = yield call(
      getRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers?${queryString.stringify(parametersPrev)}`,
    )
    const vouchersNext: Voucher[] = yield call(
      getRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers?${queryString.stringify(parametersNext)}`,
    )

    const vouchersIds: string[] = []

    for (const voucher of vouchersPrev.reverse()) {
      vouchersIds.push(voucher.id)
    }
    for (const voucher of vouchersNext) {
      vouchersIds.push(voucher.id)
    }

    yield put(vouchersNavigationReceived({ vouchersIds: uniq(vouchersIds), organizationId }))
  } catch (error) {
    trackError(error)
  }
}

function* updateVoucher({ payload }: VoucherActionRequestAction) {
  const { organizationId, voucherId, data = {} } = payload
  const action = VoucherAction.update

  try {
    const response: { id: any } = yield call(
      putRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${voucherId}`,
      data,
    )

    if (!response.id) {
      throw new Error(`Could not proccess "${action}" action of voucher with id "${voucherId}"`)
    }

    yield put(voucherActionRecieved({ voucherId, action }))
  } catch (error: any) {
    yield put(voucherActionRequestFailed({ action, error, voucherId }))
  }
}

function* fetchFile({ payload }: FileRequestAction) {
  const { id } = payload
  const response: { file: any } = yield call(getRequest, `/v2/files/${id}`)
  yield put(fileReceived(response.file))
}

function* voucherDelete({ payload }: VoucherDeleteAction) {
  const { id, organizationId } = payload
  const action = VoucherAction.delete

  yield put(voucherActionCustomRequest({ action, organizationId, voucherId: id }))

  try {
    const response: { status: any } = yield call(
      deleteRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${id}`,
    )

    if (response.status === 200 || response.status === 204) {
      yield put(voucherActionRecieved({ voucherId: id, action }))
    } else {
      throw new Error(`Could not proccess "${action}" action of voucher with id "${id}"`)
    }
  } catch (error: any) {
    yield put(voucherActionRequestFailed({ action, error, voucherId: id }))
  }
}

function* voucherRequestMoreInfo({ payload }: VoucherRequestMoreInfoAction) {
  const { id, organizationId, reason, comment } = payload
  const action = VoucherAction.requireInfo

  yield put(voucherActionCustomRequest({ action, organizationId, voucherId: id }))

  try {
    const response: { id: any } = yield call(
      postRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${id}/actions/requireInfo`,
      {
        reason,
        comment,
      },
    )

    if (!response.id) {
      throw new Error(`Could not proccess "${action}" action of voucher with id "${id}"`)
    }

    yield put(voucherActionRecieved({ voucherId: id, action }))
  } catch (error: any) {
    yield put(voucherActionRequestFailed({ action, error, voucherId: id }))
  }
}

function* voucherDiscard({ payload }: VoucherDiscardAction) {
  const { id, organizationId, reason, comment } = payload
  const action = VoucherAction.discard

  yield put(voucherActionCustomRequest({ action, organizationId, voucherId: id }))

  try {
    const response: { id: any } = yield call(
      postRequest,
      `${voucherServiceUrl}/organizations/${organizationId}/vouchers/${id}/actions/discard`,
      {
        reason,
        comment,
      },
    )

    if (!response.id) {
      throw new Error(`Could not proccess "${action}" action of voucher with id "${id}"`)
    }

    yield put(voucherActionRecieved({ voucherId: id, action }))
  } catch (error: any) {
    yield put(voucherActionRequestFailed({ action, error, voucherId: id }))
  }
}

export default function* (): any {
  yield all([
    yield takeLatest(TAX_RATES_REQUEST, fetchTaxRates),
    yield takeLatest(ACCOUNTS_REQUEST, fetchAccounts),
    yield takeLatest(INBOX_ACTION_REQUEST, inboxActionSaga),
    yield takeLatest(VOUCHER_ACTION_REQUEST, voucherActionRequest),
    yield takeLatest(FILE_REQUEST, fetchFile),
    yield takeLatest(VOUCHER_DELETE, voucherDelete),
    yield takeLatest(VOUCHER_REQUEST_MORE_INFO, voucherRequestMoreInfo),
    yield takeLatest(VOUCHER_DISCARD, voucherDiscard),
    yield takeLatest(VOUCHERS_NAVIGATION_REQUEST, vouchersToNavigateRequest),
  ])
}
