import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { useUpdateDependentLineValues } from '../../hooks/useUpdateDependentLineValues'
import { CurrencyInput } from '../CurrencyInput'

type InvoiceLineTotalExcludingVatProps = CommonInvoiceLineFieldProps

export const InvoiceLineTotalExcludingVat = ({ lineIndex }: InvoiceLineTotalExcludingVatProps) => {
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { handleTaxExcludingVatChange } = useUpdateDependentLineValues(lineIndex)

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.totalExcludingVat`}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <CurrencyInput
            {...props}
            onChange={(totalExcludingVat) => {
              handleTaxExcludingVatChange(totalExcludingVat)
              onChange(totalExcludingVat)
            }}
            readOnly
            value={value}
          />
        )
      }}
    />
  )
}
