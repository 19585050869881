import { css } from '@emotion/core'

import { GridTemplateValue } from '../types/gridTemplateValue'

export const getGridTemplateColumnsStyle = (itemsInRow: number, itemsTemplate?: GridTemplateValue[]) => {
  if (itemsTemplate) {
    return css`
      grid-template-columns: ${itemsTemplate
        .map((value) => (typeof value === 'number' ? `${value}fr` : value))
        .join(' ')};
    `
  }

  return css`
    grid-template-columns: repeat(${itemsInRow}, 1fr);
  `
}
