import { useCallback } from 'react'

import { useCreateAccount } from '@modules-deprecated/app/accounts/hooks/useCreateAccount'
import { Account, AccountNature } from '@modules-deprecated/app/accounts/types'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { trackError } from '../../../../../utils/trackError'
import { DEFAULT_STRIPE_ACCOUNT_NAME, DEFAULT_STRIPE_ACCOUNT_NUMBER } from '../constants/defaultStripeAccountProps'

interface UseCreateStripeAccountProps {
  receivableAccountGroupId?: string
  onError: () => void
}

export const useCreateStripeAccount = ({ receivableAccountGroupId, onError }: UseCreateStripeAccountProps) => {
  const { organization } = useUserOrganization()
  const {
    create: createAccount,
    isProcessing,
    ...rest
  } = useCreateAccount({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.Accounts)
    },
    onError: () => {
      onError()
    },
  })

  const createStripeAccount = useCallback(() => {
    if (!receivableAccountGroupId) {
      trackError('An account with systemRole "accountsReceivable" is required to create a Stripe account')
      return
    }

    const createStripeAccountPayload: Partial<Account> = {
      accountNo: DEFAULT_STRIPE_ACCOUNT_NUMBER,
      currencyId: organization?.baseCurrencyId,
      description: 'For Stripe payments.',
      groupId: receivableAccountGroupId,
      isBankAccount: true,
      isPaymentEnabled: true,
      name: DEFAULT_STRIPE_ACCOUNT_NAME,
      natureId: AccountNature.Asset,
    }

    createAccount({ payload: createStripeAccountPayload, organizationId: organization?.id })
  }, [createAccount, organization?.id, organization?.baseCurrencyId, receivableAccountGroupId])

  return { createStripeAccount, isProcessing, ...rest }
}
