import { TFunction } from 'i18next'
import isArray from 'lodash/isArray'
import { ErrorCode, FileError, FileRejection } from 'react-dropzone'

import { formatBytes } from '../../../utils/formatBytes'
import { LimitOptions } from '../types/limitOptions'

const getCustomMessage = (fileError: FileError, limitOptions: LimitOptions, t: TFunction): string => {
  const { accept = '', maxFiles = 0, maxSize = 0, minSize = 0 } = limitOptions
  const acceptArray = isArray(accept) ? accept : [accept]
  let fileTypes

  switch (fileError.code) {
    case ErrorCode.FileTooLarge:
      return `${t('ds.dropzone.error.file_too_large')} ${formatBytes(maxSize)}`
    case ErrorCode.FileTooSmall:
      return `${t('ds.dropzone.error.file_too_small')} ${formatBytes(minSize)}`
    case ErrorCode.FileInvalidType:
      fileTypes = acceptArray.map((type) => type?.split('/')?.[1] || type).join(', ')
      return `${t('ds.dropzone.error.file_invaild_type', { count: acceptArray.length })} "${fileTypes}"`
    case ErrorCode.TooManyFiles:
      return `${t('ds.dropzone.error.too_many_files', { count: maxFiles })}`
    default:
      return fileError.message
  }
}

export const translateRejectedFilesErrors = (
  fileRejections: FileRejection[],
  limitOptions: LimitOptions,
  t: TFunction,
): void => {
  for (const fileRejection of fileRejections) {
    for (const error of fileRejection.errors) {
      error.message = getCustomMessage(error, limitOptions, t)
    }
  }
}
