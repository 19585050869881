import { useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { FieldErrors } from 'react-hook-form'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { SUBMIT_SHORTCUT } from '../../../../../constants/shortcuts'
import { ModalId } from '../../../../../enums/modalId'
import { FormComponent, useForm, useSegment } from '../../../../../hooks'
import { getAmountsFromBillLines } from '../../../utils/getAmountsFromBillLines'
import { BillForm } from '../../BillEditableForm/utils/formData'
import { defaultValues, getValidationSchema, RegisterPaymentForm } from '../utils/formData'

interface ContextState {
  closeModal: () => void
  currentBillFormValues?: BillForm
  Form: FormComponent
  inclVatAmountTotal: number
  handlePaymentRegister: () => void
  handlePaymentRemoval: () => void
  hasPaymentRegistered?: boolean
}

const BillRegisterPaymentModalContext = createContext<ContextState | undefined>(undefined)

interface BillRegisterPaymentModalContextProps {
  billFormErrors?: FieldErrors<BillForm>
  children?: ReactNode
  currentBillFormValues?: BillForm
  hasPaymentRegistered?: boolean
  modalId: string
  onRemove?: () => void
  onSubmit?: (form: RegisterPaymentForm) => void
}

export const BillRegisterPaymentModalContextProvider = ({
  billFormErrors,
  children,
  currentBillFormValues,
  hasPaymentRegistered,
  modalId,
  onRemove,
  onSubmit,
}: BillRegisterPaymentModalContextProps): ReactElement => {
  const { billDate, billLines, paymentDate, paymentAccount } = currentBillFormValues || {}

  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { accounts = [] } = useAccounts()
  const { isOpen: isDirtyRouteModalOpened } = useModal(ModalId.DirtyRouteModal)

  const { close: closeModal, isOpen: isModalOpen } = useModal(modalId, {
    onClose: () => {
      resetForm()
      clearErrors()
    },
  })

  const { handleSubmit, Form, setValue, clearErrors, getValues, setError } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const { inclVatAmountTotal } = getAmountsFromBillLines(billLines)

  const submitForm = useCallback(
    (form: RegisterPaymentForm) => {
      onSubmit?.(form)
      track('xxx - expenses - Expense draft - register payment')
      closeModal()
    },
    [closeModal, onSubmit, track],
  )

  const resetForm = useCallback(() => {
    setValue('paymentAccount', undefined)
    setValue('billDate', undefined)
    setValue('paymentDate', undefined)
  }, [setValue])

  const handleModalClose = useCallback(() => {
    closeModal()
  }, [closeModal])

  const handlePaymentRemoval = useCallback(() => {
    onRemove?.()
    closeModal()
  }, [onRemove, closeModal])

  const handlePaymentRegister = useCallback(() => {
    handleSubmit(submitForm)()
  }, [handleSubmit, submitForm])

  // Set 'paymentDate' if already saved
  useEffect(() => {
    if (isModalOpen && paymentDate) {
      setValue('paymentDate', paymentDate)

      if (billFormErrors?.paymentDate) {
        setError('paymentDate', billFormErrors?.paymentDate)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, paymentDate, billFormErrors])

  // Set 'paymentAccount' if already saved
  useEffect(() => {
    if (isModalOpen && accounts.length && paymentAccount?.id) {
      const account = accounts.find((account) => account.id === paymentAccount.id)
      setValue('paymentAccount', account)

      if (billFormErrors?.paymentAccount) {
        setError('paymentAccount.id', billFormErrors?.paymentAccount)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, accounts, paymentAccount, billFormErrors])

  // Set 'paymentDate' if doesn't exist, using the 'billDate' initially
  useEffect(() => {
    if (isModalOpen && !getValues('paymentDate') && billDate && !paymentDate) {
      setValue('paymentDate', billDate)
    }

    if (isModalOpen && !getValues('billDate') && billDate) {
      setValue('billDate', billDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, billDate])

  // Set 'paymentAccount' if doesn't exist, using the default organization bill account
  useEffect(() => {
    if (
      isModalOpen &&
      !getValues('paymentAccount') &&
      organization?.defaultBillBankAccountId &&
      accounts.length &&
      !paymentAccount?.id
    ) {
      const account = accounts.find((account) => account.id === organization.defaultBillBankAccountId)
      setValue('paymentAccount', account)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, accounts, organization?.defaultBillBankAccountId])

  useHotkeys(
    SUBMIT_SHORTCUT,
    handlePaymentRegister,
    { enableOnTags: ['INPUT', 'TEXTAREA', 'SELECT'], enabled: isModalOpen && !isDirtyRouteModalOpened },
    [isModalOpen, isDirtyRouteModalOpened],
  )

  return (
    <BillRegisterPaymentModalContext.Provider
      value={{
        closeModal: handleModalClose,
        currentBillFormValues,
        Form,
        hasPaymentRegistered,
        inclVatAmountTotal,
        handlePaymentRegister,
        handlePaymentRemoval,
      }}
    >
      {children}
    </BillRegisterPaymentModalContext.Provider>
  )
}

export const useBillRegisterPaymentModal = () => {
  const context = useContext(BillRegisterPaymentModalContext)

  if (!context) {
    throw new Error('BillRegisterPaymentModalContext is missing in the module!')
  }

  return context
}
