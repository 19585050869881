import { ModalContentToggler } from '@components'
import { Attachment, ModalProps, useModal } from '@design-system'

import { ReactElement, useState } from 'react'

import { attachmentModalSteps } from '@features/attachments/constants/attachmentModalSteps'

import { ModalId } from '../../../../../src/enums/modalId'
import { getAttachmentPreviewModalId } from '../AttachmentList/utils/getAttachmentPreviewModalId'
import { AttachmentPreviewModalContextProvider } from '../AttachmentPreviewModal/context/attachmentPreviewModalContext'
import { ChooseAttachmentModalContextProvider } from './contexts/chooseAttachmentModalContext'

export interface ChooseFromUploadsModalProps extends ModalProps {
  idsToSkip?: string[]
  onSelect: (file: Attachment) => void
}

export const ChooseAttachmentModal = ({ idsToSkip, onSelect, ...rest }: ChooseFromUploadsModalProps): ReactElement => {
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | undefined>(undefined)

  const { close: closeAttachmentModal } = useModal(ModalId.AttachmentsModal)

  const attachmentPreviewModalId = getAttachmentPreviewModalId(selectedAttachment?.id || '')

  const handleAttachmentSelectFromModal = () => {
    if (selectedAttachment) {
      onSelect(selectedAttachment)
      closeAttachmentModal()
    }
  }

  return (
    <ChooseAttachmentModalContextProvider
      onFileImageClick={setSelectedAttachment}
      onSelect={onSelect}
      idsToSkip={idsToSkip}
    >
      <AttachmentPreviewModalContextProvider
        attachment={selectedAttachment}
        modalId={attachmentPreviewModalId}
        onFileSelect={handleAttachmentSelectFromModal}
      >
        <ModalContentToggler initialState="select" states={attachmentModalSteps} withTransition={false} {...rest} />
      </AttachmentPreviewModalContextProvider>
    </ChooseAttachmentModalContextProvider>
  )
}
