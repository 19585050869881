import { getRoleToRoleName, Role, RoleSelect } from '@components'
import { Badge, notify, TableCellContent } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { QueryKeys } from '../../../../../../../../../../enums/queryKeys'
import { useUpdateUserRole } from '../../../../../../hooks/useUpdateUserRole'
import { UpdateUserRoleResponseData } from '../../../../../../query-api'
import { User } from '../../../../../../types'

interface RoleCellProps {
  isInvitationPending?: boolean
  isOwner?: boolean
  role?: Role
  userEmail: string
  userId: string
}

export const RoleCell = ({
  isInvitationPending,
  isOwner,
  role,
  userEmail,
  userId,
}: RoleCellProps): ReactElement | null => {
  const [selectedRole, setSelectedRole] = useState(role)
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const roleToRoleName = useMemo(() => getRoleToRoleName(t), [t])
  const queryClient = useQueryClient()

  const { update: updateUserRole } = useUpdateUserRole({
    onSuccess: async (response: UpdateUserRoleResponseData) => {
      const { data } = response
      await queryClient.cancelQueries([QueryKeys.OrganizationUsers, organization?.id])
      const previousData: User[] | undefined = queryClient.getQueryData([QueryKeys.OrganizationUsers, organization?.id])
      const updatedData = previousData?.map((user) => {
        if (user.id === data.userId) {
          return { ...user, userRole: data.userRole }
        }

        return user
      })

      queryClient.setQueryData([QueryKeys.OrganizationUsers, organization?.id], updatedData)
    },
    onError: () => {
      notify({
        id: 'role-updated-error',
        message: (
          <Trans value="roles.update.error">
            Could not update role for <strong>{userEmail}</strong>
          </Trans>
        ),
        variant: 'error',
      })

      setSelectedRole(role)
      queryClient.invalidateQueries(QueryKeys.OrganizationUsers)
    },
  })

  useEffect(
    () => {
      if (selectedRole !== role) {
        setSelectedRole(role)
      }
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [role],
  )

  const handleRoleSelect = useCallback(
    (_?: string, value?: Role) => {
      if (!value || !organization?.id) {
        return
      }

      updateUserRole({
        organizationId: organization.id,
        userRole: value,
        userId,
      })
      setSelectedRole(value)
    },
    [organization?.id, updateUserRole, userId],
  )

  if (!selectedRole) {
    return null
  }

  if (isOwner) {
    return <Badge variant="neutral">{t('settings.organization.users.owner_status')}</Badge>
  }

  return isInvitationPending ? (
    <TableCellContent>{roleToRoleName[selectedRole]}</TableCellContent>
  ) : (
    <RoleSelect selectedId={selectedRole} onSelect={handleRoleSelect} />
  )
}
