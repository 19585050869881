import { TwoColumnsLayout } from '@components'

import { ReactElement } from 'react'

import { BillOverviewReconciliationSuggestion } from '../BillOverviewReconciliationSuggestion'
import { BillPaymentPane } from '../BillPaymentPane'
import { BillReadableAttachments } from '../BillReadableAttachments'
import { BillReadableForm } from '../BillReadableForm'

export const BillReadableLayout = (): ReactElement => {
  return (
    <>
      <BillOverviewReconciliationSuggestion />
      <BillPaymentPane />
      <TwoColumnsLayout asymmetrical fullHeight>
        <BillReadableAttachments />
        <BillReadableForm />
      </TwoColumnsLayout>
    </>
  )
}
