import Cookies from 'js-cookie'
import { useCallback } from 'react'

import { CookieKeys } from '../enums/cookieKeys'

export const useZervantNavigation = () => {
  const externalToken = Cookies.get(CookieKeys.BillyApiExternalToken)
  const zervantRootUrl = `${window.ENV.ZERVANT_URL}/login/login?zervant-return-access=${externalToken}`

  const redirectToZervantDashboard = useCallback(() => {
    window.location.href = zervantRootUrl
  }, [zervantRootUrl])

  const redirectToZervantInvoicesView = useCallback(() => {
    window.location.href = `${zervantRootUrl}&view=invoices`
  }, [zervantRootUrl])

  const redirectToZervantInvoiceView = useCallback(
    (invoiceId: string) => {
      window.location.href = `${zervantRootUrl}&view=invoice&invoiceId=${invoiceId}`
    },
    [zervantRootUrl],
  )

  return {
    redirectToZervantDashboard,
    redirectToZervantInvoicesView,
    redirectToZervantInvoiceView,
  }
}
