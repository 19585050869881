import { RefObject } from 'react'
import { DropEvent, DropzoneInputProps, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone'

import { dropzoneBlockedDefaultProps, DropzoneBlockedDefaultProps } from '../constants/dropzoneBlockedDefaultProps'

export interface UseFileUploadProps
  extends Omit<
    DropzoneOptions,
    'onDragEnter' | 'onDrop' | 'onDragLeave' | 'onDragOver' | 'noDrag' | 'onDropAccepted' | 'onDropRejected'
  > {
  isBlocked?: boolean
  onFilesSelect: (acceptedFiles: File[], rejectedFiles: FileRejection[], event: DropEvent) => void
  onFilesSelectAccepted?: DropzoneOptions['onDropAccepted']
  onFilesSelectRejected?: (rejectedFiles: FileRejection[], event: DropEvent) => void
}

export const useDropzoneInput = ({
  isBlocked,
  onFilesSelect,
  onFilesSelectAccepted,
  onFilesSelectRejected,
  accept,
  multiple,
  minSize,
  maxSize,
  maxFiles,
  preventDropOnDocument,
  noClick,
  noKeyboard,
  noDragEventsBubbling,
  getFilesFromEvent,
  onFileDialogCancel,
  validator,
}: UseFileUploadProps) => {
  const dropzoneBlockedProps: DropzoneBlockedDefaultProps | undefined = isBlocked
    ? dropzoneBlockedDefaultProps
    : undefined

  let dropzoneOptions: DropzoneOptions = {
    multiple,
    minSize,
    maxSize,
    maxFiles,
    preventDropOnDocument,
    noClick,
    noKeyboard,
    noDragEventsBubbling,
    onFileDialogCancel,
    validator,
    accept,
    noDrag: true,
    onDropAccepted: onFilesSelectAccepted,
    onDropRejected: onFilesSelectRejected,
    onDrop: onFilesSelect,
    ...dropzoneBlockedProps,
  }

  if (getFilesFromEvent) {
    dropzoneOptions = { getFilesFromEvent, ...dropzoneOptions }
  }

  const { getInputProps } = useDropzone(dropzoneOptions)

  const { ref: inputRef, ...inputProps } = getInputProps() as DropzoneInputProps & {
    ref: RefObject<HTMLInputElement> | undefined
  }

  return { inputRef, inputProps }
}
