import { withModalConditionalRender } from '@design-system'

import { ReactElement } from 'react'

import { ModalId } from '../../../../../enums/modalId'
import { FrodaLendingEligibilityModal as FrodaLendingEligibility } from '../FrodaLendingEligibilityModal'
import { FrodaProcessingModal as FrodaProcessing } from '../FrodaProcessingModal'

const FrodaLendingEligibilityModal = withModalConditionalRender(FrodaLendingEligibility)
const FrodaProcessingModal = withModalConditionalRender(FrodaProcessing)

export const FrodaFinanceLendingModalsGroup = (): ReactElement => {
  return (
    <>
      <FrodaLendingEligibilityModal id={ModalId.FrodaLendingEligibilityModal} />
      <FrodaProcessingModal id={ModalId.FrodaProcessingModal} />
    </>
  )
}
