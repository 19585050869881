import { Company } from '@modules-deprecated/app/companies/types'

import { BohrSupplier } from '../../../../../types/bohrSupplier'

interface GetCombinedVendorPropProps {
  propName: string
  lookupVendor: Company | undefined
  vendorKey: keyof Company | undefined
  supplier: BohrSupplier | null
  supplierKey: keyof BohrSupplier
}

/**
 * Retrieves a property value from either the `lookupVendor` or `supplier` object
 * and returns it in an object with a specified key name.
 *
 * - The function **prioritizes** `lookupVendor` if the specified `vendorKey` exists.
 * - If `lookupVendor` does not have a valid value, it **falls back** to `supplier`.
 * - If neither object contains the property, it **returns an empty object**.
 *
 * @param {Object} props - An object containing the necessary lookup values.
 * @param {string} props.propName - The key name to use in the returned object.
 * @param {Company | undefined} props.lookupVendor - The vendor lookup object, which may contain the desired property.
 * @param {keyof Company | undefined} props.vendorKey - The key to retrieve from `lookupVendor`. Can be `undefined` if no vendor equivalent exists.
 * @param {BohrSupplier | null} props.supplier - The supplier object, which may contain the desired property.
 * @param {keyof BohrSupplier} props.supplierKey - The key to retrieve from `supplier`.
 * @returns {Partial<Record<string, unknown>>} - An object with `propName` as the key and the retrieved value, or an empty object if no value exists.
 *
 * @example
 * const vendor = { id: "123", name: "Acme Corp" };
 * const supplier = { registration_no: "456", name: "Beta Ltd" };
 *
 * getCombinedVendorProp({
 *   propName: "registrationNo",
 *   lookupVendor: vendor,
 *   vendorKey: "id",
 *   supplier: supplier,
 *   supplierKey: "registration_no"
 * });
 * // Returns: { registrationNo: "123" }
 *
 * getCombinedVendorProp({
 *   propName: "companyName",
 *   lookupVendor: undefined,
 *   vendorKey: undefined,
 *   supplier: supplier,
 *   supplierKey: "name"
 * });
 * // Returns: { companyName: "Beta Ltd" }
 *
 * getCombinedVendorProp({
 *   propName: "vatNumber",
 *   lookupVendor: vendor,
 *   vendorKey: "vatNo",
 *   supplier: supplier,
 *   supplierKey: "vat_no"
 * });
 * // Returns: {} (if both vendor.vatNo and supplier.vat_no are null/undefined)
 */
export const getCombinedVendorProp = ({
  propName,
  lookupVendor,
  vendorKey,
  supplier,
  supplierKey,
}: GetCombinedVendorPropProps) => {
  if (!lookupVendor || !vendorKey) {
    return supplier?.[supplierKey] ? { [propName]: supplier[supplierKey] } : {}
  }
  const value = lookupVendor?.[vendorKey] ?? supplier?.[supplierKey]
  return value ? { [propName]: value } : {}
}
