import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { DefaultValue } from '../../enums/defaultValue'

export const ToggleGroupWrapper = styled.div`
  display: inline-flex;
  border-radius: ${DefaultValue.BorderRadius};
  background-color: ${Color.DayGrey};
`
