import { DropzoneProtected } from '@components'
import { AttachmentFile, notify } from '@design-system'

import { ReactElement, useCallback, useRef } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { useUploadFiles } from '@features/attachments/hooks/useUploadFiles'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../config/queryClient'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useSegment } from '../../../../hooks'
import { APIError } from '../../../../utils'
import { getErrorMessage } from '../../../../utils/getErrorMessage'
import { useInvalidateBills } from '../../hooks/useInvalidateBills'
import { UpdateBillPayload } from '../../query-api'
import { BillAttachment } from '../../types/billAttachment'
import { useUpdateBill } from '../BillEditableForm/hooks/useUpdateBill'
import { useBillView } from '../BillReadableForm'

interface BillReadableAttachmentsDropzoneProps {
  isUploading?: boolean
  onUploadFinished?: () => void
  onUploadStart?: () => void
}

export const BillReadableAttachmentsDropzone = ({
  isUploading,
  onUploadFinished,
  onUploadStart,
}: BillReadableAttachmentsDropzoneProps): ReactElement => {
  const uploadedAttachmentFilesRef = useRef<AttachmentFile[]>([])
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { billData } = useBillView()
  const { invalidateBills } = useInvalidateBills()

  const billId = billData?.bill?.id
  const organizationId = organization?.id

  const { update: updateBill } = useUpdateBill({
    onSuccess: () => {
      onUploadFinished?.()
      queryClient.invalidateQueries([QueryKeys.Bill, billId])
      invalidateBills()
      notify({
        id: NotificationKeys.BillAttachmentFilesUpdate,
        message: t('bill.notification.attachments_update_success'),
        variant: 'success',
      })
    },
    onError: (error: APIError | undefined) => {
      onUploadFinished?.()
      const message = getErrorMessage(error, 'bill') || t('bill.notification.attachments_update_error')
      notify({
        id: NotificationKeys.BillAttachmentFilesUpdate,
        message,
        variant: 'error',
      })
    },
  })

  const { uploadFiles } = useUploadFiles({
    onUpload(file: AttachmentFile) {
      uploadedAttachmentFilesRef.current = [...uploadedAttachmentFilesRef.current, file]
    },
    onSuccess() {
      track('File Uploaded (Client)', {
        context: TrackingContext.BillUpload,
      })

      // Update bill
      if (billId && organizationId) {
        const formattedAttachmentFiles: Partial<BillAttachment>[] = uploadedAttachmentFilesRef.current.map(
          (attachmentFile) => {
            return { fileId: attachmentFile?.id, organizationId }
          },
        )

        const updateBillAttachmentFilesPayload: UpdateBillPayload = {
          billId,
          payload: { organizationId, attachments: formattedAttachmentFiles },
        }

        updateBill(updateBillAttachmentFilesPayload)
        uploadedAttachmentFilesRef.current = []
      }
    },
    onError() {
      onUploadFinished?.()
      notify({
        id: NotificationKeys.UploadFiles,
        message: t('file.rejected'),
        variant: 'error',
      })
      uploadedAttachmentFilesRef.current = []
    },
    options: {
      'x-should-scan': true,
    },
  })

  const handleFilesDropped = useCallback(
    (filesAccepted: File[], filesRejected?: FileRejection[]) => {
      uploadFiles(filesAccepted, filesRejected)
      onUploadStart?.()
    },
    [onUploadStart, uploadFiles],
  )

  return (
    <DropzoneProtected
      contentText={t('bill.view.bill_attachments_dropzone.placeholder')}
      scopes={Scope.BillAttachmentWrite}
      onDrop={handleFilesDropped}
      isUploading={isUploading}
      trackingContext={TrackingContext.BillUpload}
    />
  )
}
