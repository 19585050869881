import React, { memo, ReactElement, useCallback, useMemo, useState } from 'react'
import { DocumentProps, Page, PageProps, pdfjs } from 'react-pdf'

import { trackError } from '../../../../../utils/trackError'
import { Image } from '../../../Image'
import { usePdfPreload } from '../../contexts/pdfPreloadContext'
import { DocumentLocalProps, FileComponentProps, FileType } from '../../types'
import { getFileType } from '../../utils/getFileType'
import { FileErrorThumbnail, FileLockedThumbnail } from '../FileThumbnails'
import { LIMIT_CANVAS_WIDTH_CLASS_NAME } from './constants/limitCanvasWidthClassName'
import * as Styled from './styles'

// Required by the react-pdf library:
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const documentOptions: DocumentProps['options'] = {
  cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
}

type ErrorType = 'default' | 'password-protected'

export const PdfReact = memo((props: FileComponentProps): ReactElement | null => {
  const { activePage, aspectRatio, fitType, fitToHeight, fitToWidth, height, onLoad, onPdfLoad, src, width } = props
  const [error, setError] = useState<ErrorType>()
  const { renderPdfBySrc, setIsLoading } = usePdfPreload()

  const isError = !!error
  const pageHeight = useMemo(() => (fitToHeight && height ? height : undefined), [fitToHeight, height])

  const srcFileType = getFileType(src)

  const handleLoad = useCallback(
    (pdf: pdfjs.PDFDocumentProxy) => {
      const { numPages } = pdf
      setIsLoading(false)
      onPdfLoad(numPages)
      onLoad?.()
    },
    [onLoad, onPdfLoad, setIsLoading],
  )

  const handlePassword = useCallback(() => {
    trackError(`Pdf ${src} is password protected!`)
    setError('password-protected')
  }, [src])

  const handleLoadError = useCallback(
    (error: Error) => {
      error.message = `Error while loading pdf file: ${src}. ${error.message}`
      trackError(error)
      setError('default')
    },
    [src],
  )

  const handleSourceError = useCallback(
    (error: Error) => {
      error.message = `Error while getting pdf file: ${src}. ${error.message}`
      trackError(error)
      setError('default')
    },
    [src],
  )

  const documentProps: DocumentLocalProps = useMemo(
    () => ({
      options: documentOptions,
      externalLinkTarget: '_blank',
    }),
    [],
  )

  const pageProps: PageProps = useMemo(
    () => ({
      pageIndex: activePage,
      height: pageHeight,
      width: pageHeight ? 0 : width,
      scale: 2,
    }),
    [activePage, width, pageHeight],
  )

  const previewDocumentProps: DocumentLocalProps = useMemo(
    () => ({
      ...documentProps,
      onPassword: handlePassword,
      onLoadError: handleLoadError,
      onLoadProgress: () => setIsLoading(true),
      onLoadSuccess: handleLoad,
      onSourceError: handleSourceError,
      externalLinkTarget: '_blank',
      className: LIMIT_CANVAS_WIDTH_CLASS_NAME,
    }),
    [documentProps, handleLoad, handleLoadError, handlePassword, handleSourceError, setIsLoading],
  )

  const PdfComponent = renderPdfBySrc({
    documentProps: previewDocumentProps,
    children: <Page key={pageProps.scale?.toString()} {...pageProps} />,
    src,
  })

  const RenderedComponent = useMemo(
    () => (srcFileType === FileType.Pdf ? PdfComponent : <Image src={src} objectFit={fitType} />),
    [PdfComponent, src, srcFileType, fitType],
  )

  if (!RenderedComponent) {
    return null
  }

  return (
    <Styled.PdfReactWrapper isError={isError} aspectRatio={aspectRatio} height={!fitToWidth ? height : undefined}>
      {isError ? (
        <Styled.ErrorWrapper>
          {error === 'password-protected' ? <FileLockedThumbnail /> : <FileErrorThumbnail />}
        </Styled.ErrorWrapper>
      ) : (
        <Styled.ScaleWrapper>{RenderedComponent}</Styled.ScaleWrapper>
      )}
    </Styled.PdfReactWrapper>
  )
})
