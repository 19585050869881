import { Flex } from '@design-system'

import { ReactElement, useEffect, useState } from 'react'

import { InvoiceHtmlPreview } from '../../../../../../elements/InvoiceHtmlPreview'
import { useInvoicePreview } from '../../../../hooks/useInvoicePreview'
import * as Styled from './styles'

export const InvoiceEditorPreview = (): ReactElement => {
  const [htmlFiles, setHtmlFiles] = useState<string[] | undefined>(undefined)
  const { getInvoicePreview } = useInvoicePreview({
    onSuccess: ({ data }) => {
      setHtmlFiles(data)
    },
  })

  useEffect(() => {
    getInvoicePreview()
  }, [getInvoicePreview])

  return (
    <Styled.InvoiceEditorPreviewWrapper>
      <Flex justifyContent="center">
        <InvoiceHtmlPreview htmlFiles={htmlFiles} />
      </Flex>
    </Styled.InvoiceEditorPreviewWrapper>
  )
}
