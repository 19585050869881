import { InputNumberLocalized, InputNumberLocalizedProps } from '@components'

import { useFormContext } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceDocumentType } from '../../../../../../../../../../../../../../enums/invoiceDocumentType'
import { useGetZervantDocumentSettings } from '../../../../../../../../../../../../../../hooks/useGetZervantDocumentSettings'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'

type CurrencyInputProps = InputNumberLocalizedProps

export const CurrencyInput = ({ readOnly = false, ...inputProps }: CurrencyInputProps) => {
  const { getValues } = useFormContext<InvoiceFormSchema>()
  const { getInvoiceLocale } = useGetZervantDocumentSettings(InvoiceDocumentType.Invoice)
  const locale = getInvoiceLocale()

  const currency = getValues('currency')

  return (
    <InputNumberLocalized
      {...inputProps}
      bordered={!readOnly}
      locale={locale}
      min={0}
      readOnly={readOnly}
      suffix={currency}
    />
  )
}
