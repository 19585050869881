import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { previewInvoiceDraft, PreviewInvoiceDraftResponse } from '../../../query-api'
import { useInvoiceDraftPayload } from './useInvoiceDraftPayload'

interface UseInvoicePreviewProps {
  onSuccess?: (data: AxiosResponse<PreviewInvoiceDraftResponse>) => void
}

export const useInvoicePreview = ({ onSuccess }: UseInvoicePreviewProps) => {
  const { getInvoiceDraftPayload } = useInvoiceDraftPayload()

  const { mutate: getInvoicePreviewData, isLoading: isProcessing } = useMutation(previewInvoiceDraft, { onSuccess })

  const getInvoicePreview = useCallback(() => {
    const invoiceDraftPayload = getInvoiceDraftPayload()

    return getInvoicePreviewData(invoiceDraftPayload)
  }, [getInvoiceDraftPayload, getInvoicePreviewData])

  return { getInvoicePreview, isProcessing }
}
