import { Flex, IconButton, Space, Tooltip } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceLineFields } from './elements/InvoiceLineFields'

interface InvoiceLineProps {
  index: number
  isRemovable?: boolean
  onRemove: () => void
}

export const InvoiceLine = ({ index, isRemovable = true, onRemove }: InvoiceLineProps) => {
  const { t } = useTranslation()

  const handleRemoveButtonClick = useCallback(() => {
    onRemove()
  }, [onRemove])

  return (
    <Flex alignContent="center">
      <InvoiceLineFields lineIndex={index} />
      <Space horizontal />
      <Tooltip label={t('remove')} placement="top">
        <IconButton disabled={!isRemovable} icon="trash" onClick={handleRemoveButtonClick} />
      </Tooltip>
    </Flex>
  )
}
