import { Language } from '../../types/language'
import { formatDate, getRequest, postRequest } from '../../utils'
import { Payroll } from './types/payroll'

export interface CreateSalaryAccountPayloadData {
  userLanguage: Language
  organizationAddress: string
  organizationCity: string
  organizationCvr: string
  organizationId: string
  organizationName: string
  organizationPostalCode: string
  userEmail: string
  userName: string
  userPhone: string
}

export interface CreateSalaryAccountResponseData {
  login: string
}

export const createSalaryAccount = (data: CreateSalaryAccountPayloadData): Promise<CreateSalaryAccountResponseData> => {
  const {
    organizationAddress,
    organizationCity,
    organizationCvr,
    organizationId,
    organizationName,
    organizationPostalCode,
    userEmail,
    userLanguage,
    userName,
    userPhone,
  } = data

  return postRequest(`/integrations/salary/${organizationId}/setup`, {
    /* eslint-disable @typescript-eslint/naming-convention */
    registration_number: organizationCvr,
    email: userEmail,
    phone: userPhone,
    language: userLanguage,
    owner_name: encodeURIComponent(userName),
    company_name: organizationName,
    address: organizationAddress,
    postal_code: organizationPostalCode,
    city: organizationCity,
    /* eslint-enable @typescript-eslint/naming-convention */
  })
}

export interface GetSalaryConnectionStatusResponseData {
  /* eslint-disable @typescript-eslint/naming-convention */
  can_connect: boolean
  can_install: boolean
  is_connected: boolean
  /* eslint-enable @typescript-eslint/naming-convention */
}

export const getSalaryConnectionStatus = (
  organizationId: string,
  registrationNo: string,
  userEmail: string,
): Promise<GetSalaryConnectionStatusResponseData> =>
  getRequest(
    `/integrations/salary/${organizationId}/companies/status?registration_number=${registrationNo}&email=${encodeURIComponent(
      userEmail,
    )}`,
  )

export interface GetSalaryLoginResponseData {
  login: string
}

export const getSalaryLoginUrl = (organizationId: string, userEmail: string): Promise<GetSalaryLoginResponseData> =>
  getRequest(`/integrations/salary/${organizationId}/companies/getLogin?email=${encodeURIComponent(userEmail)}`)

export interface GetPayrollListResponseData {
  data: Payroll[]
}

export const getSalaryPayrollList = (
  organizationId: string,
  registrationNo: string,
  from: Date,
  to: Date,
): Promise<GetPayrollListResponseData> => {
  const fromFormatted = formatDate(from)
  const toFormatted = formatDate(to)

  return getRequest(
    `/integrations/salary/${organizationId}/payrolls/get?registration_number=${registrationNo}&from=${fromFormatted}&to=${toFormatted}`,
  )
}

export interface ConnectExistingSalaryAccountPayload {
  organizationId: string
  redirectUrl: string
  registrationNo: string
  userEmail: string
}

export interface ConnectExistingSalaryAccountResponseData {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  redirect_url: string
}

export const connectExistingSalaryAccount = ({
  organizationId,
  registrationNo,
  userEmail,
  redirectUrl,
}: ConnectExistingSalaryAccountPayload): Promise<ConnectExistingSalaryAccountResponseData> =>
  postRequest(`/integrations/salary/${organizationId}/connect`, {
    /* eslint-disable @typescript-eslint/naming-convention */
    registration_number: registrationNo,
    email: userEmail,
    redirect_url: redirectUrl,
    /* eslint-enable @typescript-eslint/naming-convention */
  })

export interface StatisticsData {
  averageTransfersLastYear: number
  employeeCount: number
  totalTransfersThisYear: number
}

export interface GetSalaryStatisticsResponseData {
  data: StatisticsData
}

export const getSalaryStatistics = (
  organizationId: string,
  registrationNo: string,
): Promise<GetSalaryStatisticsResponseData> =>
  getRequest(`/integrations/salary/${organizationId}/companies/statistics?registration_number=${registrationNo}`)
