import React, { forwardRef, ReactElement, ReactNode, Ref, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonProps } from '../Button'
import { DropdownRef } from '../Dropdown'
import { DropdownNavList, DropdownNavListProps } from '../DropdownNavList'
import { filterItemsBasic, NavItem } from '../NavList'
import { Text } from '../Text'
import { getDisplayValue } from './utils/getDisplayValue'

export interface ButtonDropdownProps<T = string>
  extends Omit<ButtonProps, 'children' | 'centered' | 'onSelect'>,
    Pick<DropdownNavListProps<T>, 'items' | 'onClose' | 'onOpen' | 'placement' | 'withNavigation' | 'withSearch'> {
  // ButtonDropdown props
  children?: ReactNode
  defaultSelectedId?: string
  /** custom filtering function for some specific use cases */
  itemsFilterFn?: (items: NavItem<T>[], searchValue: string) => NavItem<T>[]
  onSelect?: DropdownNavListProps<T>['onItemClick']
  /** define custom value which will be visible in Select after selecting: */
  getDisplayValue?: (item: NavItem<T>) => ReactNode
  selectedId?: string
  triggerElement?: ReactElement
  // DropdownNavList props
  dropdownClassName?: DropdownNavListProps<T>['className']
  dropdownFetching?: DropdownNavListProps<T>['isFetching']
  dropdownFooter?: DropdownNavListProps<T>['footer']
  dropdownHeader?: DropdownNavListProps<T>['header']
  dropdownId?: DropdownNavListProps<T>['id']
  dropdownItemRender?: DropdownNavListProps<T>['itemRender']
  dropdownKeepHeightOnSearching?: DropdownNavListProps<T>['keepHeightOnSearching']
  dropdownMaxHeight?: DropdownNavListProps<T>['maxHeight']
  dropdownNotFoundContent?: DropdownNavListProps<T>['notFoundContent']
  dropdownScrollEndOffset?: DropdownNavListProps<T>['scrollEndOffset']
  dropdownSize?: DropdownNavListProps<T>['size']
  dropdownWidth?: DropdownNavListProps<T>['width']
  onDropdownScrollEnd?: () => void
}

export const ButtonDropdownForwarded = <T, K>(
  {
    // ButtonDropdown props
    children,
    defaultSelectedId,
    itemsFilterFn,
    getDisplayValue: getDisplayValueCustom,
    onSelect,
    selectedId: selectedIdControlled,
    triggerElement,
    // DropdownNavList props
    dropdownClassName,
    dropdownFetching,
    dropdownFooter,
    dropdownHeader,
    dropdownId,
    dropdownItemRender,
    dropdownKeepHeightOnSearching,
    dropdownMaxHeight = 'default',
    dropdownNotFoundContent,
    dropdownScrollEndOffset,
    dropdownSize = 'l',
    dropdownWidth,
    items,
    onClose,
    onDropdownScrollEnd,
    onOpen,
    placement = 'bottom-end',
    withNavigation = true,
    withSearch = false,
    // Button props
    className,
    fullWidth,
    ...rest
  }: ButtonDropdownProps<T>,
  forwardedRef: Ref<DropdownRef<K>>,
): ReactElement => {
  const [selectedId, setSelectedId] = useState<string | undefined>(defaultSelectedId || selectedIdControlled)
  const [itemsList, setItemsList] = useState<NavItem<T>[]>(items)
  const [itemsListProcessed, setItemsListProcessed] = useState<NavItem<T>[]>(items)
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()

  const selectedItem = useMemo(() => items.find((item) => item.id === selectedId), [items, selectedId])
  const buttonDisplayValue = useMemo(
    () => getDisplayValue(selectedItem, children, getDisplayValueCustom) || t('ds.button_dropdown.placeholder'),
    [selectedItem, children, t, getDisplayValueCustom],
  )

  useEffect(() => {
    setItemsList(items)
    setItemsListProcessed(items)
    // it should only fire on 'items' change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    if (selectedIdControlled !== undefined) {
      setSelectedId(selectedIdControlled)
    }
  }, [selectedIdControlled])

  const handleDropdownOpen = useCallback(() => {
    setIsOpen(true)
    onOpen && onOpen()
  }, [onOpen])

  const handleDropdownClose = useCallback(() => {
    setIsOpen(false)
    onClose && onClose()
    setItemsListProcessed(itemsList)
  }, [itemsList, onClose])

  const handleItemClick = useCallback(
    (id: string, value: T) => {
      onSelect?.(id, value)
      setItemsListProcessed(itemsList)
    },
    [itemsList, onSelect],
  )

  const filterItems = useCallback(
    (searchValue: string) => {
      const filterFn = itemsFilterFn || filterItemsBasic
      const itemsFiltered = filterFn(itemsList, searchValue)
      setItemsListProcessed(itemsFiltered)
    },
    [itemsFilterFn, itemsList],
  )

  return (
    <DropdownNavList
      className={dropdownClassName}
      footer={dropdownFooter}
      header={dropdownHeader}
      id={dropdownId}
      isFetching={dropdownFetching}
      itemRender={dropdownItemRender}
      items={itemsListProcessed}
      keepHeightOnSearching={dropdownKeepHeightOnSearching}
      maxHeight={dropdownMaxHeight}
      notFoundContent={dropdownNotFoundContent}
      onClose={handleDropdownClose}
      onItemClick={handleItemClick}
      onOpen={handleDropdownOpen}
      onScrollEnd={onDropdownScrollEnd}
      onSearch={filterItems}
      placement={placement}
      ref={forwardedRef}
      scrollEndOffset={dropdownScrollEndOffset}
      selectedId={selectedId}
      shouldSearchForBestMatched={withSearch}
      size={dropdownSize}
      trigger={
        triggerElement || (
          <Button
            className={className}
            iconRight={isOpen ? 'chevronUp' : 'chevronDown'}
            fullWidth={fullWidth}
            centered={false}
            {...rest}
          >
            <Text as="span" truncate inherit>
              {buttonDisplayValue}
            </Text>
          </Button>
        )
      }
      width={dropdownWidth}
      withNavigation={withNavigation}
      withSearch={withSearch}
    />
  )
}

export const ButtonDropdown = forwardRef(ButtonDropdownForwarded) as <T>(
  props: ButtonDropdownProps<T> & { ref?: Ref<HTMLDivElement | null> },
) => ReactElement
