import { Attachment, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'

import { useEmberCloseModal } from '../../../../contexts/emberCloseModalContext'
import { ModalId } from '../../../../enums/modalId'
import { ChooseAttachmentModal, ChooseFromUploadsModalProps } from '../ChooseAttachmentModal'

type ChooseAttachmentEmberModalProps = ChooseFromUploadsModalProps

export const ChooseAttachmentEmberModal = ({ idsToSkip, onSelect }: ChooseAttachmentEmberModalProps): ReactElement => {
  const { closeEmberModal } = useEmberCloseModal()

  useModal(ModalId.AttachmentsModal, {
    onClose: () => closeEmberModal(),
  })

  const handleSelect = useCallback(
    (attachment: Attachment) => {
      onSelect(attachment)
    },
    [onSelect],
  )

  return <ChooseAttachmentModal id={ModalId.AttachmentsModal} idsToSkip={idsToSkip} onSelect={handleSelect} size="xl" />
}
