import { useCallback } from 'react'

import { getGlobalBilly } from '../../utils/globalBilly'
import { trackError } from '../../utils/trackError'

export const useEmberTransitionTo = () => {
  const transitionTo = useCallback((url: string, ...args: any) => {
    try {
      const billyApp = getGlobalBilly()
      const emberRouter = billyApp?.__container__?.lookup('router:main')
      emberRouter.transitionTo(url, ...args)
    } catch (error) {
      trackError(error)
    }
  }, [])
  return {
    transitionTo,
  }
}
