import styled from '@emotion/styled'

import { BorderRadius } from '../../../../enums/borderRadius'
import { Color } from '../../../../enums/color'
import { Spacing } from '../../../../enums/spacing'
import { getShadow } from '../../../../utils/getShadow'

interface FloatingPanelWrapperProps {
  isInView: boolean
}

export const FloatingPanelWrapper = styled.div<FloatingPanelWrapperProps>`
  position: ${({ isInView }) => (isInView ? 'absolute' : 'fixed')};
  bottom: 64px;
  background-color: ${Color.White};
  padding: ${Spacing.S} ${Spacing.L};
  ${getShadow('raisedStrong')}
  border-radius: ${BorderRadius.L};
  border: 1px solid ${Color.Gray40};
`
