import { Divider, SkeletonText, Space, Text } from '@design-system'

import { ReactElement, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useBillView } from '@views/bills/elements/BillReadableForm'

import { useSegment } from '../../../../../../hooks'
import { useContactBalance } from '../../contexts/contactBalanceContext'
import { PaymentDateInput } from '../PaymentDateInput'
import { PrepaymentDifferenceLine } from '../PrepaymentDifferenceLine'
import { usePrepaymentModalDisplayValues } from './hooks/usePrepaymentModalDisplayValues'
import * as Styled from './styles'

export const PrepaymentModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { contactName, displayedContactBalanceAmount } = usePrepaymentModalDisplayValues()
  const { billData } = useBillView()
  const { contactBalance } = useContactBalance()

  const billBalanceAfterPrepayment = useMemo(() => {
    const billBalance = billData?.bill?.balance
    const contactBalanceAmount = contactBalance?.amount
    if (!billBalance || !contactBalanceAmount) {
      return null
    }
    const balanceDifference = billBalance - contactBalanceAmount
    return billBalance > contactBalanceAmount ? balanceDifference : 0
  }, [billData?.bill?.balance, contactBalance?.amount])

  const currency = useMemo(() => billData?.bill?.currencyId || '', [billData?.bill?.currencyId])

  useEffect(() => {
    track('xxx - expenses - Prepayment modal opened')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Space />
      <Styled.PrePaymentModalBodyContainer>
        <Styled.PrePaymentModalBodyColumn flex={7}>
          {!!displayedContactBalanceAmount && !!contactName ? (
            <Text>
              <Trans
                i18nKey="bill.payment_reconcile.prepayment.you_have_credit"
                values={{
                  amount: displayedContactBalanceAmount,
                  name: contactName,
                }}
                components={{ strong: <strong /> }}
              />
            </Text>
          ) : (
            <SkeletonText />
          )}
          <Space />
          <Text>{t('bill.payment_reconcile.prepayment.chose_payment_date')}</Text>
        </Styled.PrePaymentModalBodyColumn>
        <Divider direction="vertical" />
        <Styled.PrePaymentModalBodyColumn flex={3}>
          <PaymentDateInput />
        </Styled.PrePaymentModalBodyColumn>
      </Styled.PrePaymentModalBodyContainer>

      <Space />
      {!!billBalanceAfterPrepayment && (
        <>
          <Space size="m" />
          <PrepaymentDifferenceLine difference={billBalanceAfterPrepayment} currency={currency} />
        </>
      )}
    </>
  )
}
