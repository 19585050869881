import { Color, DefaultValue } from '@design-system'

import styled from '@emotion/styled'

export const BillAttachmentPreviewWrapper = styled.div`
  height: 100%;
  border-radius: ${DefaultValue.BorderRadius};
  overflow: hidden;
  background-color: ${Color.DayGrey};
  border: 1px solid ${Color.Gray30};
  cursor: grab;
`

export const BillAttachmentWrapper = styled.div`
  width: 100%;
  position: relative;
  /* another aspect ratio required due to inner spacing */
  aspect-ratio: 1 / 1.38;
`
