import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const BillReadableAttachmentsWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-bottom: ${Spacing.XXL};
`

export const BillImageWrapper = styled.div`
  max-height: 100%;
`

export const BillReadableAttachmentsActions = styled.div`
  display: flex;
  margin-top: ${Spacing.M};
  justify-content: flex-end;
`

interface AttachmentDropzoneWrapperProps {
  aspectRatio: string
}

export const AttachmentDropzoneWrapper = styled.div<AttachmentDropzoneWrapperProps>`
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
  display: flex;
  width: 100%;
`
