import { Locale } from 'date-fns'
import { da, enUS, fr } from 'date-fns/locale'
import i18next from 'i18next'

const locales: Record<string, Locale> = {
  da,
  en: enUS,
  fr,
}

// @todo it can be just function (it doesn't use the state)
export const useLocale = (): Locale => {
  return locales[i18next.language] || locales.da
}
