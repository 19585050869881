import { EmberRoute } from '../../../enums/emberRoute'
import { reactRoute } from '../../../utils/routing/reactRoute'
import { EmptyViewType } from '../types/emptyViewType'

export const emptyViewRoutes: Partial<Record<EmptyViewType, EmberRoute | string>> = {
  bank: EmberRoute.Bank,
  bankNotConnected: EmberRoute.Bank,
  bankNotConnectedUnauthorized: EmberRoute.BankImport,
  contacts: EmberRoute.ContactsNew,
  einvoicesNotRegistered: reactRoute.uploads.getMainRoute(),
  einvoicesRegistered: reactRoute.uploads.getMainRoute(),
  invoices: EmberRoute.InvoicesNew,
  products: EmberRoute.ProductsNew,
  quotes: EmberRoute.QuotesNew,
  recurringInvoices: EmberRoute.RecurringInvoicesNew,
  transactions: EmberRoute.Transactions,
}
