import { ReceiptRejectionButton } from '@components'
import { Space } from '@design-system'

import { ReactElement } from 'react'

import { useAttachmentPreviewModal } from '../../../../context/attachmentPreviewModalContext'
import { ManageUploadButton } from './elements/ManageUploadButton'
import * as Styled from './styles'

export const AttachmentDetailsFooter = (): ReactElement => {
  const { handleEInvoiceRejection, isEDocument, attachmentId } = useAttachmentPreviewModal()

  return (
    <Styled.FooterWrapper>
      <ManageUploadButton />
      {isEDocument && (
        <>
          <Space size="s" />
          <ReceiptRejectionButton attachmentId={attachmentId} size="l" onSuccess={handleEInvoiceRejection} />
        </>
      )}
    </Styled.FooterWrapper>
  )
}
