import { Spacing } from '@design-system'

import styled from '@emotion/styled'

export const DescriptionAreaWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
`
