import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const AttachmentDetailsWrapper = styled.div`
  display: flex;
  border-left: 1px solid ${Color.Gray30};
  padding-left: ${Spacing.L};
  flex-shrink: 0;
  width: 300px;
`

export const Footer = styled.footer`
  margin-top: ${Spacing.L};
`
