import { NavItem, NavListItemWithBlockDescription, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchTaxRates } from '@modules-deprecated/app/taxrates/query-api'
import { SelectTaxRatesProps, TaxRate } from '@modules-deprecated/app/taxrates/types'

import { QueryKeys } from '../../../enums/queryKeys'
import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { getCurrentLocale } from '../../../utils'
import { TaxRateValue } from './types/taxRateValue'
import { filterTaxRates } from './utils/filterTaxRates'
import { getDisplayTitle } from './utils/getDisplayTitle'
import { getItems } from './utils/getItems'

interface TaxRateSelectProps
  extends SelectTaxRatesProps,
    Omit<SelectProps<TaxRateValue>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: TaxRate) => void
  locale?: LocaleIdentifier
}

export const TaxRateSelect = ({
  purchase,
  sale,
  onSelect,
  hasShortItemTitle = false,
  locale: inputLocale,
  ...selectProps
}: TaxRateSelectProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const locale: LocaleIdentifier = inputLocale || getCurrentLocale()

  const { data, isLoading } = useQuery(
    [QueryKeys.TaxRates, organization?.id],
    () => fetchTaxRates({ organizationId: organization?.id as string, isActive: true }),
    {
      enabled: !!organization?.id,
    },
  )

  const taxRatesFiltered = useMemo(() => {
    const taxRates = data?.taxRates || []
    const filters: SelectTaxRatesProps = { purchase, sale }
    return filterTaxRates(taxRates, filters)
  }, [purchase, sale, data])

  const items = useMemo(
    () => getItems({ taxRates: taxRatesFiltered, hasShortItemTitle, t, locale }),
    [taxRatesFiltered, hasShortItemTitle, t, locale],
  )

  const getDisplayValue = useCallback(
    (item: NavItem<TaxRateValue>) => getDisplayTitle({ taxRate: item.value, isShortTitle: hasShortItemTitle, locale }),
    [hasShortItemTitle, locale],
  )

  const handleSelect = useCallback(
    (id?: string, value?: TaxRateValue) => {
      const valueFiltered = omit(value, 'displayData')
      onSelect?.(id, valueFiltered)
    },
    [onSelect],
  )

  return (
    <Select
      {...selectProps}
      dropdownFetching={isLoading}
      dropdownItemRender={(props) => <NavListItemWithBlockDescription {...props} />}
      getDisplayValue={getDisplayValue}
      items={items}
      onSelect={handleSelect}
    />
  )
}
