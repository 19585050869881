import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { InvoiceDocumentType } from '../enums/invoiceDocumentType'
import { getInvoicePdfFileName } from '../utils/getInvoicePdfFileName'
import { useFetchInvoiceDownloadAsPdfData } from './useFetchInvoiceDownloadAsPdfData'

interface DownloadInvoiceAsPdfOptions {
  documentType: InvoiceDocumentType
  invoiceId: string
  invoiceNumber?: string
  onSuccess?: () => void
}

export const useDownloadInvoiceAsPdf = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const { fetch: fetchDownloadData, isProcessing } = useFetchInvoiceDownloadAsPdfData()

  const downloadInvoiceAsPdf = useCallback(
    ({ invoiceId, documentType, invoiceNumber, onSuccess }: DownloadInvoiceAsPdfOptions) => {
      fetchDownloadData(invoiceId, {
        onError: () => {
          notify({
            id: NotificationKeys.ExternalInvoiceDownloadAsPdf,
            message: t('error_saving'),
            variant: 'error',
          })
        },
        onSuccess: (data) => {
          const fileName = getInvoicePdfFileName({
            documentType,
            invoiceNumber,
            organizationName: organization?.name,
          })

          const blob = new Blob([data?.data], { type: 'application/pdf' })
          const downloadUrlFromBlob = URL.createObjectURL(blob)

          const downloadLink = document.createElement('a')
          downloadLink.download = fileName
          downloadLink.href = downloadUrlFromBlob

          downloadLink?.click()
          onSuccess?.()
        },
      })
    },
    [fetchDownloadData, organization?.name, t],
  )

  return { download: downloadInvoiceAsPdf, isDownloading: isProcessing }
}
