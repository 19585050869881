import { createContext, ReactElement, ReactNode, useContext } from 'react'

import { ContactBalance } from '@modules-deprecated/app/contacts'
import { BillType } from '@views/bills/enums/billType'
import { useFetchContact } from '@views/contacts/hooks/useFetchContact'
import { useFetchContactBalance } from '@views/contacts/hooks/useFetchContactBalance'
import { Contact } from '@views/contacts/types/contact'

import { Side } from '../../../../../enums/side'
import { useBillView } from '../../BillReadableForm'

interface ContextState {
  canUsePrepayment: boolean
  contact: Contact | undefined
  contactBalance: ContactBalance | undefined
}

const ContactBalanceContext = createContext<ContextState | undefined>(undefined)

interface ContactBalanceContextProps {
  contactId?: string
  currencyId?: string
  children?: ReactNode
}

export const ContactBalanceContextProvider = ({
  contactId,
  currencyId,
  children,
}: ContactBalanceContextProps): ReactElement => {
  const { contact } = useFetchContact(contactId)
  const { contactBalance } = useFetchContactBalance(contact?.id, currencyId)
  const { billData } = useBillView()

  const canUsePrepayment =
    !!contactBalance?.amount && contactBalance?.side === Side.Debit && billData?.bill.type === BillType.Bill

  return (
    <ContactBalanceContext.Provider
      value={{
        contact,
        canUsePrepayment,
        contactBalance,
      }}
    >
      {children}
    </ContactBalanceContext.Provider>
  )
}

export const useContactBalance = () => {
  const context = useContext(ContactBalanceContext)
  if (!context) {
    throw new Error('ContactBalanceContextProvider is missing in the module!')
  }

  return context
}
