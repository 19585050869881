import { AspectRatioVariant, BorderRadius, FilesPreview, Spacing } from '@design-system'

import { ReactElement } from 'react'

import { getImageClassName } from '../../../../../../utils/getClassName'
import { useAttachmentPreviewModal } from '../../context/attachmentPreviewModalContext'
import * as Styled from './styles'

export const AttachmentPreview = (): ReactElement => {
  const { files } = useAttachmentPreviewModal()

  return (
    <Styled.AttachmentPreviewWrapper>
      <FilesPreview
        aspectRatio={AspectRatioVariant.billAttachment}
        borderRadius={BorderRadius.L}
        className={getImageClassName()}
        files={files}
        fitToHeight
        isFloatingActionsView
        margin={Spacing.L}
        withDownloadButton
        withRotate
        withShadow
        zoomable
      />
    </Styled.AttachmentPreviewWrapper>
  )
}
