import { Button, ButtonsGroup, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useBankIntegration } from '@views/bankIntegration/contexts/bankIntegrationContext'

import { BankReconciliationSafe } from '../../../../../../../../../../assets/images'
import { ModalId } from '../../../../../../../../../../enums/modalId'
import { useSegment } from '../../../../../../../../../../hooks'
import { useBankLines } from '../../../../../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../../../../../contexts/billReconciliationContext'
import { BankLinesState } from '../../../../../../enums/bankLinesState'
import * as Styled from '../styles'

export const RenewBankConnection = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { renewConnection } = useBankIntegration()
  const { selectedAccountId } = useBillReconciliation()
  const { bankLines, setBankLinesState } = useBankLines()
  const { close: closeBankLinesModal } = useModal(ModalId.BillReconciliationModal)

  const handleRenewConnectionButtonClick = useCallback(() => {
    track('xxx - expenses - Bank line modal - renew bank connection button clicked')

    if (!selectedAccountId) {
      return
    }

    closeBankLinesModal()
    renewConnection()
  }, [closeBankLinesModal, renewConnection, selectedAccountId, track])

  const handleSkipButtonClick = useCallback(() => {
    track('xxx - expenses - Bank line modal - skip bank connection button clicked')

    if (!bankLines?.length) {
      setBankLinesState(BankLinesState.Empty)
      return
    }

    setBankLinesState(BankLinesState.BankLinesList)
  }, [bankLines?.length, setBankLinesState, track])

  useEffect(() => {
    track('xxx - expenses - Bank line modal - renew bank connection button shown')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.EmptyStateWrapper>
      <BankReconciliationSafe />
      <Space size="s" />
      <Text alignment="center">{t('bill.payment_reconcile.your_bank_needs_reconnecting')}</Text>
      <Space />
      <ButtonsGroup>
        <Button onClick={handleSkipButtonClick} variant="text">
          {t('skip')}
        </Button>
        <Button onClick={handleRenewConnectionButtonClick} icon="roundedArrows">
          {t('renew_connection')}
        </Button>
      </ButtonsGroup>
    </Styled.EmptyStateWrapper>
  )
}
