import { Route } from '@components'

import { ReactElement } from 'react'
import { Switch } from 'react-router-dom'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { ContactsEmptyState } from './elements/ContactsEmptyState'
import { ContactsList } from './elements/ContactsList'
import { ContactRoute } from './enum/contactRoute'
import { defaultContactsQuery } from './hooks/useContactsListFilters'

export const Contacts = withRouteLinkClickedListener((): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultContactsQuery),
  })

  return (
    <Switch>
      <Route isHashRouter component={ContactsList} exact path={ContactRoute.List} />
      <Route isHashRouter component={ContactsEmptyState} exact path={ContactRoute.ListEmpty} />
    </Switch>
  )
})
