interface GetZoomableContainerWidthProps {
  fitToWidth?: boolean
  isLoading: boolean
}

export const getZoomableContainerWidth = ({ fitToWidth, isLoading }: GetZoomableContainerWidthProps) => {
  switch (true) {
    case isLoading:
      return 'auto'
    case fitToWidth:
      return '100%'
    default:
      return 'fit-content'
  }
}
