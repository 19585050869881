import { useCallback } from 'react'

import { isLanguage, Language } from '../../../types/language'
import { languageToLocale } from '../../../utils/languageToLocale'
import { ModelDocumentType } from '../types/zervantSettingsModelDocumentType'
import { useZervantSettings } from './useZervantSettings'

export const useGetZervantDocumentSettings = (documentType: ModelDocumentType) => {
  const { data, isLoading } = useZervantSettings()

  const getDocumentModel = useCallback(
    () => data?.models.find((model) => model?.type === documentType),
    [data?.models, documentType],
  )

  const getFieldSetting = useCallback(
    (fieldName: string) => {
      const documentModel = getDocumentModel()
      const fieldSetting = documentModel?.fields?.find((field) => field?.name === fieldName)

      return fieldSetting?.default
    },
    [getDocumentModel],
  )

  const getInvoiceLocale = useCallback(() => {
    const invoicingLanguage = getFieldSetting('language')
    const language: Language = isLanguage(invoicingLanguage) ? invoicingLanguage : 'en'

    return languageToLocale[language]
  }, [getFieldSetting])

  const getDocumentColumns = useCallback(() => getDocumentModel()?.columns || [], [getDocumentModel])

  return { getDocumentColumns, getFieldSetting, getInvoiceLocale, isLoading }
}
