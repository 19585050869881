import { Icon } from '@design-system'

import { addDays } from 'date-fns'
import React, { ReactElement, useCallback, useState } from 'react'

import { PeriodMode } from '../../../../../enums/periodMode'
import { DateInputLocalized } from '../../../../DateInputLocalized'
import { CUSTOM_DATE_END_DATE_ID, CUSTOM_DATE_START_DATE_ID } from '../../constants/customDateIds'
import { usePeriodSelectContext } from '../../contexts/periodSelectContext'
import * as Styled from './styles'

export const CustomDate = (): ReactElement => {
  const [forceOpenEndDate, setForceOpenEndDate] = useState(false)
  const { minDate, maxDate, setPeriodValue, periodValue, periodMode, setIsCustomDate, disabledPeriodModes } =
    usePeriodSelectContext()

  const { startDate, endDate } = periodValue?.customDate || {}
  const isDateInputDisabled = disabledPeriodModes.includes(PeriodMode.CustomDate)

  const handleStartDateChanged = useCallback(
    (date?: Date) => {
      setIsCustomDate(true)

      // Open 'endDate' DateInput automatically
      // set default date to the 'startDate' + 1 day
      const endDateInitial = date ? addDays(date, 1) : undefined
      setForceOpenEndDate(true)

      if (periodValue?.customDate) {
        setPeriodValue({
          ...periodValue,
          customDate: {
            ...periodValue.customDate,
            startDate: date,
          },
        })
      } else {
        setPeriodValue({
          mode: periodMode,
          customDate: {
            startDate: date,
            endDate: endDateInitial,
          },
        })
      }
    },
    [periodMode, periodValue, setIsCustomDate, setPeriodValue],
  )

  const handleEndDateChanged = useCallback(
    (date?: Date) => {
      setIsCustomDate(true)

      if (periodValue?.customDate) {
        setPeriodValue({
          ...periodValue,
          customDate: {
            ...periodValue.customDate,
            endDate: date,
          },
        })
      } else {
        setPeriodValue({
          mode: periodMode,
          customDate: {
            startDate: undefined,
            endDate: date,
          },
        })
      }
    },
    [periodMode, periodValue, setIsCustomDate, setPeriodValue],
  )

  const handleEndDatePickerClose = useCallback(() => {
    setForceOpenEndDate(false)
  }, [])

  return (
    <Styled.CustomDateWrapper>
      <DateInputLocalized
        datePickerId={CUSTOM_DATE_START_DATE_ID}
        disabled={isDateInputDisabled}
        maxDate={endDate || maxDate}
        minDate={minDate}
        onChange={handleStartDateChanged}
        value={startDate}
      />
      <Styled.IconWrapper>
        <Icon icon="chevronRight" />
      </Styled.IconWrapper>
      <DateInputLocalized
        isOpen={forceOpenEndDate}
        datePickerId={CUSTOM_DATE_END_DATE_ID}
        disabled={isDateInputDisabled}
        maxDate={maxDate}
        minDate={startDate || minDate}
        onChange={handleEndDateChanged}
        onClose={handleEndDatePickerClose}
        value={endDate}
      />
    </Styled.CustomDateWrapper>
  )
}
