import { BrandProtectedComponent, ProtectedComponent } from '@components'
import { ModuleLayout, withModalConditionalRender } from '@design-system'

import qs from 'qs'
import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useLocation } from 'react-router-dom'

import { ExternalInvoicesSyncDashboardBanner } from '@features/externalInvoicesSync'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { ConnectionRenewedModal } from '@views/bankIntegration/elements/SuccessfulConnectionModal/ConnectionRenewedModal'
import { AgerasFinanceCustomerContextProvider } from '@views/financing'

import { EmberRoute } from '../../enums/emberRoute'
import { ModalId } from '../../enums/modalId'
import { Scope } from '../../enums/scope'
import { useSegment } from '../../hooks'
import { useEmberRouteUrl } from '../../hooks/routing/useEmberRouteUrl'
import { AnnualReports } from './banner/AnnualReports'
import { FinancingApplication } from './banner/FinancingApplication'
import { BankWidgetContextProvider } from './contexts/bankWidgetContext'
import { DashboardSettingsContextProvider } from './contexts/dashboardSettingsContext'
import { DashboardActions } from './elements/DashboardActions'
import { DashboardWidgetsEditAction } from './elements/DashboardWidgetsEditAction'
import { WidgetsList } from './elements/WidgetsList'

const BankConnectionRenewedModal = withModalConditionalRender(ConnectionRenewedModal)

export const Dashboard = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const location = useLocation()
  const { url: integrationsUrl } = useEmberRouteUrl(EmberRoute.Integrations)

  const searchParsed = qs.parse(location?.search || '', { ignoreQueryPrefix: true }) || {}
  const mobilePayQuery = searchParsed.mobilepaystatus

  useEffect(() => {
    if (!mobilePayQuery) {
      track('Dashboard Viewed (Client)')
    }
  }, [mobilePayQuery, track])

  if (mobilePayQuery && integrationsUrl) {
    return <Redirect to={integrationsUrl + location.search} />
  }

  const organizationId = organization?.id || ''

  return (
    <DashboardSettingsContextProvider>
      <BankIntegrationContextProvider organizationId={organizationId}>
        <BankWidgetContextProvider organizationId={organizationId}>
          <AgerasFinanceCustomerContextProvider organizationId={organizationId}>
            <ModuleLayout
              title={t('dashboard')}
              sideActions={<DashboardActions />}
              headerContent={
                <ProtectedComponent scopes={Scope.DashboardWrite}>
                  <DashboardWidgetsEditAction />
                </ProtectedComponent>
              }
            >
              <FinancingApplication />
              <AnnualReports />
              <BrandProtectedComponent brand="ageras">
                <ExternalInvoicesSyncDashboardBanner />
              </BrandProtectedComponent>
              <WidgetsList />
              <BankConnectionRenewedModal id={ModalId.BankConnectionSuccessModal} />
            </ModuleLayout>
          </AgerasFinanceCustomerContextProvider>
        </BankWidgetContextProvider>
      </BankIntegrationContextProvider>
    </DashboardSettingsContextProvider>
  )
}
