import { Metable } from '../../types/metable'
import { TaxReturn } from '../../types/taxReturn'
import { getCurrentLocale, getRequest, postRequest, putRequest } from '../../utils'
import { SalesTaxPayment } from './types/salesTaxPayment'
import { SalesTaxReturnPeriod } from './types/salesTaxReturnPeriod'

interface SalesTaxReturnPeriodsResponse {
  salesTaxReturns: TaxReturn[]
}

export const fetchTaxReturnPeriods = (organizationId: string): Promise<SalesTaxReturnPeriodsResponse> => {
  const locale = getCurrentLocale()
  return getRequest(
    `/v2/salesTaxReturns?organizationId=${organizationId}&sortProperty=startDate&sortDirection=DESC&pageSize=100&offset=0`,
    { 'accept-language': locale },
  )
}

export interface SalesTaxReturnPeriodDataResponse extends Metable {
  salesTaxReturn: SalesTaxReturnPeriod
}

export const fetchSalesTaxReturnPeriodData = (
  salesTaxReturnPeriodId: string,
): Promise<SalesTaxReturnPeriodDataResponse> => {
  const locale = getCurrentLocale()
  return getRequest(`/v2/salesTaxReturns/${salesTaxReturnPeriodId}`, { 'accept-language': locale })
}

export const updateSalesTaxReturnPeriodSettlement = (
  salesTaxReturnPeriodId: string,
  isSettled: boolean,
  customVatPayoutAmount?: number,
) => {
  const locale = getCurrentLocale()
  return putRequest(
    `/v2/salesTaxReturns/${salesTaxReturnPeriodId}`,
    {
      salesTaxReturn: {
        customVatPayoutAmount,
        id: salesTaxReturnPeriodId,
        isSettled,
      },
    },
    { 'accept-language': locale },
  )
}

export interface RegisterSalesTaxReturnPeriodPaymentPayload {
  accountId: string
  entryDate: string
  returnId: string
}

export const registerSalesTaxReturnPeriodPayment = (payload: RegisterSalesTaxReturnPeriodPaymentPayload) => {
  const locale = getCurrentLocale()
  return postRequest(
    `/v2/salesTaxPayments`,
    {
      salesTaxPayment: payload,
    },
    { 'accept-language': locale },
  )
}

export interface SalesTaxReturnPeriodPaymentsDataResponse extends Metable {
  salesTaxPayments: SalesTaxPayment[]
}

export const fetchSalesTaxReturnPeriodPayments = (
  salesTaxReturnPeriodId: string,
): Promise<SalesTaxReturnPeriodPaymentsDataResponse> => {
  return getRequest(`/v2/salesTaxPayments?returnId=${salesTaxReturnPeriodId}&isVoided=false`)
}

export const voidSalesTaxReturnPeriodPayment = (salesTaxReturnPeriodPaymentId: string) => {
  const locale = getCurrentLocale()
  return putRequest(
    `/v2/salesTaxPayments/${salesTaxReturnPeriodPaymentId}`,
    {
      salesTaxPayment: {
        id: salesTaxReturnPeriodPaymentId,
        isVoided: true,
      },
    },
    { 'accept-language': locale },
  )
}
