export enum CookieKeys {
  AttachmentIdsForBulkEdit = 'attachment-ids-for-bulk-edit',
  BillyApiAccessToken = 'billy_api_accessToken',
  BillyApiExternalToken = 'billy_api_external_token',
  BulkQuery = 'bulk-query',
  InvoicesListQuery = 'invoices-list-query',
  InvoicingInvoicesListQuery = 'invoicing-invoices-list-query',
  QuotesListQuery = 'quotes-list-query',
  TransactionsListQuery = 'transactions-list-query',
}
