import { TFunction } from 'i18next'

import { organizationSubscriptionPlanTKeys } from '@modules-deprecated/app/organization/enums/organizationSubscriptionPlanTKeys'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

export const getPlanName = (subscriptionPlan: SubscriptionPlan, t: TFunction) => {
  if (subscriptionPlan in organizationSubscriptionPlanTKeys) {
    return t(organizationSubscriptionPlanTKeys[subscriptionPlan])
  }
}
