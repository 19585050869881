import { Color, getDefaultTransition } from '@design-system'

import { transparentize } from 'polished'

export default {
  table: {
    boxShadow: `0 2px 1px ${Color.Gray30}`,

    thead: {
      borderBottom: `1px solid ${Color.Gray30}`,

      tr: {
        boxShadow: `0 1px 3px ${transparentize(0.8, Color.Gray90)}`,
        color: Color.DeepSeaGreen,
      },
    },
    tbody: {
      cursor: 'pointer',
    },
    tr: {
      borderBottom: `1px solid ${Color.Gray30}`,
      transition: getDefaultTransition('background'),
    },
    td: {
      padding: 1,
    },
    'tbody tr:hover, tbody tr:hover > *': {
      background: transparentize(0.75, Color.Gray60),
    },
  },
}
