import { Button, Text } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Alignment } from '../types'
import * as Styled from './styles'

export type BulkActionsProps = {
  active?: boolean
  align?: Alignment
  onDeleteRow?: () => void
  onDone?: () => void
  onToggle: (active: boolean) => void
  selectedRows?: number
  selectedRowsText?: (selectedRows: number) => string
  totalRows: number
  totalRowsText: (totalRows: number) => string
  skipClosingOnDelete?: boolean
}

export const BulkActions = ({
  active,
  align = 'left',
  onDeleteRow = () => null,
  onDone = () => null,
  onToggle,
  selectedRows = 0,
  selectedRowsText,
  skipClosingOnDelete,
  totalRows,
  totalRowsText,
  ...rest
}: BulkActionsProps) => {
  const { t } = useTranslation()

  const handleDeleteRow = () => {
    onDeleteRow()

    if (!skipClosingOnDelete) {
      onToggle(false)
    }
  }

  const handleDone = () => {
    onDone()
    onToggle(false)
  }

  const selectedRowsTextComputed = selectedRowsText
    ? selectedRowsText(selectedRows)
    : t('components.infinitetable.selected_rows', { count: selectedRows })

  return (
    <Styled.BulkActionsWrapper align={align} {...rest}>
      {active ? (
        <>
          <Text>{selectedRowsTextComputed}</Text>
          <Styled.ButtonsWrapper>
            <Button danger disabled={!selectedRows} onClick={handleDeleteRow} size="m">
              {t('delete')}
            </Button>
            <Button onClick={handleDone} size="m">
              {t('done')}
            </Button>
          </Styled.ButtonsWrapper>
        </>
      ) : (
        <>
          {totalRowsText && <Text>{totalRowsText(totalRows)}</Text>}
          <Styled.ButtonsWrapper>
            <Button onClick={() => onToggle(true)} size="m">
              {t('edit')}
            </Button>
          </Styled.ButtonsWrapper>
        </>
      )}
    </Styled.BulkActionsWrapper>
  )
}
