import { css } from '@emotion/core'

import { DividerWrapperProps } from '../types/dividerWrapperProps'

type DividerCommonProps = Pick<DividerWrapperProps, 'dashed' | 'direction' | 'dividerColor'>

export const getPseudoElementCommonStyles = ({ dashed, direction, dividerColor }: DividerCommonProps) => css`
  content: '';

  ${direction === 'vertical'
    ? `border-right: 1px ${dashed ? 'dashed' : 'solid'} ${dividerColor};`
    : `border-bottom: 1px ${dashed ? 'dashed' : 'solid'} ${dividerColor};`}
`
