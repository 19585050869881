import { DateInputLocalized, RegisterPaymentModal } from '@components'
import { FormItemsGroup } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../hooks'
import { useBillRegisterPaymentModal } from '../../context/billRegisterPaymentModalContext'
import { RegisterPaymentFormSchema } from '../../utils/formData'

export const ModalForm = (): ReactElement => {
  const { t } = useTranslation()
  const { currentBillFormValues, Form } = useBillRegisterPaymentModal()
  const { FormItem } = useFormContext<RegisterPaymentFormSchema>()

  return (
    <Form>
      <FormItemsGroup itemsInRow={4}>
        <FormItem
          label={t('bill.register_payment.form.payment_date')}
          name="paymentDate"
          render={({ field }) => <DateInputLocalized {...field} minDate={currentBillFormValues?.billDate} />}
        ></FormItem>
        <FormItem
          itemColumnEnd={5}
          itemColumnStart={2}
          label={t('bill.register_payment.form.payment_account')}
          name="paymentAccount"
          render={({ field: { value, onChange, ...props } }) => (
            <RegisterPaymentModal.AccountSelect
              {...props}
              onSelect={(id, value) => onChange(value)}
              selectedId={value?.id}
            />
          )}
        ></FormItem>
      </FormItemsGroup>
    </Form>
  )
}
