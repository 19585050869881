import React, { ChangeEvent, KeyboardEvent, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { KeyboardKey } from '../../../../enums/keyboardKey'
import { Timeout } from '../../../../types/timeout'
import { Icon } from '../../../Icon'
import { Input, InputRef } from '../../../Input'
import * as Styled from './styles'

const FOCUS_TIMEOUT = 100

interface SearchProps {
  onChange?: (value: string) => void
}

export const Search = ({ onChange }: SearchProps): ReactElement => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const focusTimeout = useRef<Timeout>()

  useEffect(() => {
    focusTimeout.current = setTimeout(() => {
      inputRef.current?.focus()
    }, FOCUS_TIMEOUT)

    return () => {
      if (focusTimeout.current) {
        clearTimeout(focusTimeout.current)
      }
    }
  }, [])

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setValue(value)
    onChange && onChange(value)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    // prevent moving cursor to beginning
    if (event.key === KeyboardKey.ArrowUp || event.key === KeyboardKey.ArrowDown) {
      event.preventDefault()
    }
  }

  return (
    <Styled.SearchWrapper>
      <Input
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={`${t('search')}...`}
        prefix={<Icon icon="magnifyingGlass" />}
        ref={inputRef}
        spellCheck={false}
        value={value}
      />
    </Styled.SearchWrapper>
  )
}
