import { PublicAccountSelect } from '@components'
import { Checkbox, Input, Modal, ModalFooter, ModalHeader } from '@components-deprecated'
import { Button, Text } from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useEffect } from 'react'
import { ErrorOption, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { AccountGroupSelector, CurrencySelector, TaxRateSelector } from '@modules-deprecated/app'
import { Account, AccountGroup } from '@modules-deprecated/app/accounts/types'
import { BankSelector } from '@modules-deprecated/app/banks/elements'
import { Currency } from '@modules-deprecated/app/currencies/types'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { ChartOfAccountId } from '@modules-deprecated/app/organization/enums/chartOfAccountId'

import { APIError } from '../../../../../../utils'
import { saveAccount, SaveAccountPayload, updateAccount } from '../../query-api'
import { submitFailureCallback } from '../../utils/submitFailureCallback'
import { PublicAccountSelectWrapper } from './elements/PublicAccountSelectWrapper'
import * as Styled from './styles'
import { AddAccountFormInputs, AllowedField, EmberSaveAccountProps } from './types/types'
import {
  filterSaveAccountPayload,
  getSaveAccountInitialData,
  getSaveAccountPayload,
  getValidationSchema,
} from './utils'

interface EditAccountModalProps {
  account?: Account
  group?: AccountGroup
  isOpen?: boolean
  onClose?: () => void
  onSave?: (response?: EmberSaveAccountProps) => void
  allowedFields?: AllowedField[]
}

export const EditAccountModal = ({
  isOpen = false,
  onClose = () => null,
  onSave = () => null,
  account,
  group,
  allowedFields,
  ...rest
}: EditAccountModalProps) => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const form = useForm<AddAccountFormInputs>({
    defaultValues: getSaveAccountInitialData(account, group?.id),
    resolver: yupResolver(getValidationSchema(allowedFields)),
  })

  const { setError } = form

  const submitSuccessCallback = () => {
    const { bankSwift, bankIban } = form.getValues()
    onSave({
      bankSwift,
      bankIban,
    })
    onClose()
  }

  const onSaveOrUpdateErrorCallback = useCallback(
    (attributeName?: keyof SaveAccountPayload, errorMessage?: string) => {
      if (!attributeName) {
        return
      }

      const errorOption: ErrorOption = { message: errorMessage || '' }
      if (attributeName === 'accountNo') {
        setError('accountNo', errorOption)
      } else if (attributeName === 'name') {
        setError('accountName', errorOption)
      }
    },
    [setError],
  )

  const updateAccountMutation = useMutation((payload: Partial<SaveAccountPayload>) => updateAccount(payload), {
    onSuccess: submitSuccessCallback,
    onError: (error: APIError) => {
      submitFailureCallback(error, t, onSaveOrUpdateErrorCallback)
    },
  })

  const saveAccountMutation = useMutation((payload: SaveAccountPayload) => saveAccount(payload), {
    onSuccess: submitSuccessCallback,
    onError: (error: APIError) => {
      submitFailureCallback(error, t, onSaveOrUpdateErrorCallback)
    },
  })

  const { isBankAccount, isPaymentEnabled, currency } = form.watch()
  const selectedAccountGroup = form.watch('accountGroup')
  const shouldShowPaymentControls =
    organization?.chartOfAccountId === ChartOfAccountId.Manual
      ? selectedAccountGroup?.natureId === 'liability' ||
        selectedAccountGroup?.natureId === 'asset' ||
        group?.natureId === 'liability' ||
        group?.natureId === 'asset' ||
        account?.isPaymentEnabled
      : selectedAccountGroup?.allowPaymentAmounts || group?.allowPaymentAmounts // either selected account group has payments turned on

  const isFieldDisabled = useCallback(
    (fieldName: AllowedField) => {
      if (!allowedFields || isEmpty(allowedFields)) {
        return false
      }

      return !allowedFields.includes(fieldName)
    },
    [allowedFields],
  )

  useEffect(() => {
    form.setValue('publicAccountId', undefined)
  }, [form, selectedAccountGroup?.natureId])

  useEffect(
    () => {
      if (isBankAccount && !currency?.id && organization) {
        form.setValue('currency', { id: organization.baseCurrencyId } as Currency)
      }
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBankAccount, organization],
  )

  useEffect(
    () => {
      form.reset(getSaveAccountInitialData(account, group?.id))
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [account, isOpen],
  )

  const submit = () => {
    form.handleSubmit((data: AddAccountFormInputs) => {
      const payload = getSaveAccountPayload(data, organization?.id, account?.id)
      const payloadFiltered = filterSaveAccountPayload(payload, allowedFields)

      if (account) {
        updateAccountMutation.mutate(payloadFiltered)
      } else {
        saveAccountMutation.mutate(payloadFiltered)
      }
    })()
  }

  return (
    <Modal isOpen={isOpen} {...rest} onClose={onClose} variant="wide" width={600}>
      <ModalHeader heading={account ? t('add_account_modal.title_edit') : t('add_account_modal.title_create') || ''} />
      <Styled.ModalBodyScrollable>
        <Text variant="h2">{t('add_account_modal.basic_information')}</Text>
        <FormProvider {...form}>
          <Styled.FormSection>
            <Styled.GridContainer>
              <AccountGroupSelector
                disabled={isFieldDisabled('accountGroup')}
                formControl={form.control}
                label={t('add_account_modal.account_type')}
                name="accountGroup"
                required
                silent
              />
              <Styled.TextContainer>
                <Styled.HelperText variant="small"> {t('add_account_modal.type.description')} </Styled.HelperText>
              </Styled.TextContainer>
              <Input
                disabled={isFieldDisabled('accountNo')}
                formControl={form.control}
                label={t('add_account_modal.account_number')}
                name="accountNo"
                required
                silent
              />
              <Styled.TextContainer>
                <Styled.HelperText variant="small">
                  {t('add_account_modal.account_number.description')}
                </Styled.HelperText>
              </Styled.TextContainer>
              <Input
                formControl={form.control}
                name="accountName"
                label={t('add_account_modal.account_name')}
                required
                silent
              />
              <Styled.EmptyCell />

              {shouldShowPaymentControls && (
                <>
                  <Checkbox
                    disabled={isFieldDisabled('isBankAccount')}
                    formControl={form.control}
                    label={t('add_account_modal.bank_account')}
                    name="isBankAccount"
                  />
                  <Styled.EmptyCell />
                  <Checkbox
                    disabled={isFieldDisabled('isPaymentEnabled')}
                    formControl={form.control}
                    label={t('add_account_modal.enable_payments')}
                    name="isPaymentEnabled"
                  />
                  <Styled.EmptyCell />
                </>
              )}

              <Input
                disabled={isFieldDisabled('description')}
                formControl={form.control}
                label={t('add_account_modal.description')}
                name="description"
                silent
                type="textarea"
              />
              <Styled.TextContainer>
                <Styled.HelperText variant="small">{t('add_account_modal.description.description')} </Styled.HelperText>
              </Styled.TextContainer>

              <TaxRateSelector
                disabled={isBankAccount || isPaymentEnabled || isFieldDisabled('vatRate')}
                formControl={form.control}
                label={t('add_account_modal.vat_rate')}
                name="vatRate"
                placeholder=""
                preselectedId={account?.taxRateId}
                organizationId={organization?.id}
                silent
              />
              <Styled.TextContainer>
                <Styled.HelperText variant="small"> {t('add_account_modal.vat_rate.description')} </Styled.HelperText>
              </Styled.TextContainer>

              <PublicAccountSelectWrapper
                formControl={form.control}
                name="publicAccountId"
                label={t('add_account_modal.public_account')}
                render={(value, onSelect) => {
                  return (
                    <PublicAccountSelect
                      onSelect={onSelect}
                      selectedId={value}
                      natureId={selectedAccountGroup?.natureId}
                      disabled={isFieldDisabled('publicAccountId')}
                    />
                  )
                }}
              />

              <Styled.TextContainer>
                <Styled.HelperText variant="small">
                  {t('add_account_modal.public_account.description')}{' '}
                </Styled.HelperText>
              </Styled.TextContainer>
            </Styled.GridContainer>
          </Styled.FormSection>

          {isBankAccount && (
            <Styled.FormSection>
              <Text variant="h2">{t('add_account_modal.bank_information')}</Text>
              <Styled.GridContainer>
                <CurrencySelector
                  disabled={isFieldDisabled('currency')}
                  formControl={form.control}
                  label={t('add_account_modal.currency')}
                  name="currency"
                  required
                  silent
                />
                <Styled.EmptyCell />

                <BankSelector
                  countryId="DK"
                  disabled={isFieldDisabled('bank')}
                  formControl={form.control}
                  label={t('add_account_modal.bank')}
                  name="bank"
                />
                <Styled.EmptyCell />

                <Input
                  disabled={isFieldDisabled('routingNumber')}
                  formControl={form.control}
                  label={t('add_account_modal.routing_number')}
                  name="routingNumber"
                />
                <Styled.EmptyCell />

                <Input
                  disabled={isFieldDisabled('bankAccountNo')}
                  formControl={form.control}
                  label={t('add_account_modal.bank_account_number')}
                  name="bankAccountNo"
                  type="number"
                />
                <Styled.EmptyCell />

                <Input
                  disabled={isFieldDisabled('bankSwift')}
                  formControl={form.control}
                  label={t('add_account_modal.swift')}
                  name="bankSwift"
                />
                <Styled.EmptyCell />

                <Input
                  disabled={isFieldDisabled('bankIban')}
                  formControl={form.control}
                  label={t('add_account_modal.iban')}
                  name="bankIban"
                />
                <Styled.EmptyCell />
              </Styled.GridContainer>
            </Styled.FormSection>
          )}
        </FormProvider>
      </Styled.ModalBodyScrollable>
      <ModalFooter justifyContent="flex-end">
        <Button variant="text" onClick={onClose}>
          {t('add_account_modal.cancel')}
        </Button>
        <Button variant="primary" onClick={submit}>
          {t('add_account_modal.save_account')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}
