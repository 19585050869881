import { Locale } from 'date-fns'
import { da, de, enUS, fi, fr, nl, sv } from 'date-fns/locale'

import { Language } from '../types/language'

export const languageToDateLocale: Record<Language, Locale> = {
  da,
  de,
  en: enUS,
  fi,
  fr,
  nl,
  sv,
}
