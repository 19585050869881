import { useModal } from '@design-system'

import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../enums/modalId'
import { getCurrentLocale } from '../../../utils'
import { localeToLanguage } from '../../../utils/localeToLanguage'
import { initiateAiiaBankConnection } from '../query-api'

export const useInitiateBankConnection = () => {
  const { organization } = useUserOrganization()
  const { open: openErrorModal } = useModal(ModalId.BankConnectionErrorModal)
  const locale = getCurrentLocale()

  const {
    mutate: connectToBank,
    isLoading: isProcessing,
    ...rest
  } = useMutation(
    () =>
      initiateAiiaBankConnection({
        organizationId: organization?.id as string,
        redirectUrl: window.location.href,
        locale: localeToLanguage[locale],
      }),
    {
      onSuccess: ({ authUrl }) => {
        if (authUrl) {
          window.location.replace(authUrl)
        }
      },
      onError: openErrorModal,
    },
  )

  return {
    isProcessing,
    connectToBank,
    ...rest,
  }
}
