import { DEFAULT_FORMATTER_LOCALE } from '../../constants/defaultFormatterLocale'
import { getFormattedNumber } from './getFormattedNumber'

export const getCurrencyNumber = (value: number, currency: string, locale?: string) => {
  // Right now it's a simple concatenation to be used wherever we do something like this,
  // so we can then switch to Intl.NumberFormat once we have it all in place

  // const options: Intl.NumberFormatOptions = {
  //   currency,
  //   currencyDisplay: 'code',
  //   currencySign: 'standard',
  //   style: 'currency',
  // }

  const formattedNumber = getFormattedNumber(value, locale || DEFAULT_FORMATTER_LOCALE)

  return `${formattedNumber} ${currency}`
}
