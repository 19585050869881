import styled from '@emotion/styled'

import { rowStyles } from '../styles'

interface TableRowStylesProps {
  columns: string
  rowSpacing?: number
}

export const TableRow = styled.tr<TableRowStylesProps>`
  ${rowStyles};
  grid-column-gap: ${({ rowSpacing }) => rowSpacing || 30}px;
  grid-template-columns: ${({ columns }) => columns};
`

interface TableHeaderTextWrapperProps {
  sortProperty?: string
}

export const TableHeaderTextWrapper = styled.span<TableHeaderTextWrapperProps>`
  margin-right: 5px;

  ${({ sortProperty }) =>
    sortProperty &&
    `
  &:hover {
    cursor: ${sortProperty ? 'pointer' : 'default'};
    text-decoration: ${sortProperty ? 'underline' : 'none'};
  }
    `}
`
