import { AccountSelect, DateInputLocalized, ProcessingOverlay } from '@components'
import { Color, FormItemsGroup } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../hooks'
import { useOrganizationAccountingSettings } from '../../contexts/organizationAccountingSettingsContext'
import { OrganizationAccountingFormSchema } from '../../utils/organizationAccountingSettingsFormData'
import * as Styled from './styles'

export const BankSyncSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<OrganizationAccountingFormSchema>()
  const { isProcessing, isLoading } = useOrganizationAccountingSettings()

  return (
    <Styled.FormFieldsWrapper>
      {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
      <FormItemsGroup>
        <FormItem
          name="bankSyncStartDate"
          label={t('settings.organization.accounting.bank_sync_start_date')}
          render={({ field }) => <DateInputLocalized allowClear {...field} />}
        />
        <FormItem
          name="defaultBankFeeAccountId"
          label={t('settings.organization.accounting.bank_fee_account')}
          render={({ field: { onChange, value, ...rest } }) => (
            <AccountSelect {...rest} selectedId={value} onSelect={onChange} dropdownSize="fitTrigger" />
          )}
        />
      </FormItemsGroup>
    </Styled.FormFieldsWrapper>
  )
}
