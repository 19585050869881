import { ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react'

import { Asable } from '../../../types/asable'
import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { DEFAULT_FORMATTER_LOCALE } from '../../constants/defaultFormatterLocale'
import { NumberFormat } from '../../utils/NumberFormat'
import { FormattedCurrencyNumber } from './elements/FormattedCurrencyNumber'
import { FormattedNumberElement } from './elements/FormattedNumberElement'
import { FormattedPercentNumber } from './elements/FormattedPercentNumber'

export interface FormattedNumberProps extends Asable {
  asString?: boolean
  locale?: LocaleIdentifier
  options?: Intl.NumberFormatOptions
  value: number
}

type FormattedNumberType = ForwardRefExoticComponent<FormattedNumberProps & RefAttributes<HTMLDivElement>> & {
  Currency: typeof FormattedCurrencyNumber
  Percent: typeof FormattedPercentNumber
}

export const FormattedNumber = (({
  as = 'span',
  asString = false,
  locale = DEFAULT_FORMATTER_LOCALE,
  value,
}: FormattedNumberProps): ReactNode => {
  return (
    <FormattedNumberElement as={as} asString={asString}>
      {NumberFormat.getFormattedNumber(value, locale)}
    </FormattedNumberElement>
  )
}) as FormattedNumberType

FormattedNumber.Currency = FormattedCurrencyNumber
FormattedNumber.Percent = FormattedPercentNumber

// export corresponding prop types:
export type { FormattedPercentNumberProps } from './elements/FormattedPercentNumber'
export type { FormattedCurrencyNumberProps } from './elements/FormattedCurrencyNumber'
