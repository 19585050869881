import { useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { Organization } from '@modules-deprecated/app/organization/types/organization'
import { useCurrentUser, User } from '@modules-deprecated/app/user'

import { getAppBuildTheme } from '../../utils/getAppBuildTheme'
import { trackError } from '../../utils/trackError'
import { waitForSegment } from '../../utils/waitForSegment'

interface PageProps {
  pageName?: string
  payload?: Record<string, any>
}

const theme = getAppBuildTheme()

const SEGMENT_WRITE_KEY = window.ENV?.SEGMENT_WRITE_KEY || process.env.SEGMENT_WRITE_KEY

interface CustomDefaultData {
  organization?: Organization
  user?: Pick<User, 'id'>
}

export const useSegment = () => {
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const showAnalyticsNotDefinedError = () => {
    trackError('Segment is not defined')
  }

  const identificationId = organization?.globalId

  const setAnonymousId = (anonymousId: string) => {
    if (!window.analytics) {
      showAnalyticsNotDefinedError()
      return
    }

    try {
      if (window.analytics.setAnonymousId) {
        window.analytics.setAnonymousId(anonymousId)
      } else if (SEGMENT_WRITE_KEY) {
        window.analytics.load(SEGMENT_WRITE_KEY)
        window.analytics.page()
        window.analytics.setAnonymousId(anonymousId)
      }
    } catch (error) {
      trackError(error)
    }
  }

  const trackRaw = useCallback(
    (
      event: string,
      payload?: Record<string, any>,
      // eslint-disable-next-line no-undef
      options?: SegmentAnalytics.SegmentOpts,
    ) => {
      if (!window.analytics) {
        showAnalyticsNotDefinedError()
        return
      }

      try {
        window.analytics.track(event, payload, options)
      } catch (error) {
        trackError(error)
      }
    },
    [],
  )

  const identifyAnonymously = () => {
    const id = uuidv4()
    window.analytics.identify(id)
  }

  const getDefaultPayload = useCallback(
    (customDefaultData?: CustomDefaultData) => {
      const organizationToTrack = customDefaultData?.organization || organization
      const idToTrack = organizationToTrack?.globalId || identificationId

      /* eslint-disable @typescript-eslint/naming-convention */
      const defaultPayload = {
        id: idToTrack,
        organization_id: idToTrack,
        name: organizationToTrack?.name,
        email: organizationToTrack?.ownerUmbrellaId ? '' : organizationToTrack?.email,
        billing_plan: organizationToTrack?.subscriptionProductPlan,
        billing_period: organizationToTrack?.subscriptionPeriod,
        is_trial: organizationToTrack?.isTrial,
        brand: theme,
        current_user_id: user?.globalId,
        user_id: idToTrack,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        client: 'webapp',
      }

      return defaultPayload
    },
    [identificationId, organization, user?.globalId],
  )

  const getScreenContext = useCallback(() => {
    return {
      screen: {
        width: window.screen.width,
        height: window.screen.height,
        density: window.devicePixelRatio,
      },
    }
  }, [])

  const track = useCallback(
    (event: string, payload?: Record<string, any>, customDefaultData?: CustomDefaultData) => {
      const defaultPayload = getDefaultPayload(customDefaultData)

      /* eslint-enable @typescript-eslint/naming-convention */
      trackRaw(event, { ...defaultPayload, ...payload })
    },
    [getDefaultPayload, trackRaw],
  )

  const pageRaw = useCallback((pageName?: string, payload?: Record<string, any>) => {
    if (!window.analytics) {
      showAnalyticsNotDefinedError()
      return
    }

    try {
      window.analytics.page(undefined, pageName || 'Webapp Page', payload)
    } catch (error) {
      trackError(error)
    }
  }, [])

  const page = useCallback(
    ({ pageName, payload }: PageProps) => {
      const contextProperty = getScreenContext()

      pageRaw(pageName, { brand: theme, context: contextProperty, ...payload })
    },
    [getScreenContext, pageRaw],
  )

  return {
    identifyAnonymously,
    page,
    pageRaw,
    setAnonymousId,
    track,
    trackRaw,
    waitForInitialization: waitForSegment,
  }
}
