interface GetInvoicePdfFileNameOptions {
  documentType: string
  invoiceNumber: string | undefined
  organizationName: string | undefined
}

export const getInvoicePdfFileName = ({
  documentType,
  invoiceNumber,
  organizationName,
}: GetInvoicePdfFileNameOptions) => {
  let name = documentType.toUpperCase()

  if (invoiceNumber) {
    name += ` ${invoiceNumber.toUpperCase()}`
  }

  if (organizationName) {
    name += ` - ${organizationName}`
  }

  return `${name}.pdf`
}
