import { ExportType } from '@components'

import qs from 'qs'

import { LocaleIdentifier } from '../types/localeIdentifier'
import { apiUrl, getAccessToken } from './api'
import { getCurrentLocale } from './getCurrentLocale'

interface GetExportDownloadUrlProps {
  exportUrl: string
  organizationId: string
  type: ExportType
  queryParams?: Record<string, any>
}

interface CommonParams {
  accessToken: string | undefined
  acceptLanguage: LocaleIdentifier
}

export const getExportDownloadUrl = ({
  exportUrl,
  organizationId,
  type,
  queryParams,
}: GetExportDownloadUrlProps): string => {
  const commonParams: CommonParams = {
    accessToken: getAccessToken(),
    acceptLanguage: getCurrentLocale(),
  }

  const combinedParams = qs.stringify({
    ...(queryParams || {}),
    ...commonParams,
  })

  return `${apiUrl}/v2/organizations/${organizationId}/${exportUrl}.${type}?${combinedParams}`
}
