import { useReactEmberRouterConnection } from '../../../../hooks/useReactEmberRouterConnection'
import { useSegmentPageEvent } from '../../../../hooks/useSegmentPageEvent'

export const GlobalApplication = () => {
  useReactEmberRouterConnection()
  useSegmentPageEvent()

  // Here add anything that needs to be always present in DOM
  return null
}
