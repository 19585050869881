import { getAccountSelectDisplayTitle, getTaxRateSelectDisplayTitle } from '@components'
import { amountToDisplayValue, Divider, FormItem, FormItemsGroup, SkeletonText, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useAccounts } from '@modules-deprecated/app/accounts'
import { useTaxRates } from '@modules-deprecated/app/taxrates'

import { getCurrentLocale } from '../../../../../../utils'
import { BillLine as BillLineType } from '../../../../types/billLine'
import { useBillView } from '../../contexts/billViewContext'
import { getInclVatAmount } from '../../utils/getInclVatAmount'
import { BillBookkeepingTag } from '../BillBookkeepingTag'
import * as Styled from './styles'

interface BillLineProps {
  billLine?: Partial<BillLineType>
}

export const BillLine = ({ billLine }: BillLineProps): ReactElement => {
  const { t } = useTranslation()
  const { taxRates = [] } = useTaxRates()
  const { accounts = [] } = useAccounts()
  const { isFetching, billData } = useBillView()

  const { bill } = billData || {}
  const { description, bookkeepingTag, accountId, taxRateId } = billLine || {}

  const descriptionText = bookkeepingTag && description ? `— ${description}` : description
  const expenseCategory = accountId ? accounts?.find((account) => account.id === accountId) : undefined
  const taxRate = taxRateId ? taxRates?.find((taxRate) => taxRate.id === taxRateId) : undefined
  const inclVatAmount = bill && billLine ? getInclVatAmount(bill, billLine) : undefined
  const locale = getCurrentLocale()

  return (
    <>
      <Space size="s" />
      <Divider />
      <Space size="s" />

      <Styled.BillLineWrapper>
        <FormItemsGroup>
          <FormItem label={t('bill.view.form.description')}>
            {bookkeepingTag && <BillBookkeepingTag bookkeepingTag={JSON.parse(bookkeepingTag)} />}
            {isFetching ? <SkeletonText /> : <Text>{descriptionText}</Text>}
          </FormItem>
        </FormItemsGroup>
        <FormItemsGroup>
          <FormItem label={t('bill.view.form.expense_category')}>
            {isFetching ? (
              <SkeletonText />
            ) : (
              <Text>{expenseCategory ? getAccountSelectDisplayTitle(expenseCategory) : '—'}</Text>
            )}
          </FormItem>
        </FormItemsGroup>
        <FormItemsGroup itemsInRow={2}>
          <FormItem label={t('bill.view.form.tax_rate')}>
            {isFetching ? (
              <SkeletonText />
            ) : (
              <Text>{taxRate ? getTaxRateSelectDisplayTitle({ taxRate, locale }) : '—'}</Text>
            )}
          </FormItem>
          <FormItem label={t('bill.view.form.incl_vat_amount')} labelAlignment="right">
            {isFetching ? (
              <Styled.SkeletonTextRightAligned width={100} />
            ) : (
              <Text alignment="right" weight="medium">
                {inclVatAmount !== undefined ? amountToDisplayValue(inclVatAmount) : '—'}
              </Text>
            )}
          </FormItem>
        </FormItemsGroup>
      </Styled.BillLineWrapper>
    </>
  )
}
