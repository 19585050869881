import { Flex, NumberFormat, Space } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { InvoiceDocumentType } from '../../../../../../../../enums/invoiceDocumentType'
import { useGetZervantDocumentSettings } from '../../../../../../../../hooks/useGetZervantDocumentSettings'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { getInvoiceSummaryTotals } from '../../../../../../utils/getInvoiceSummaryTotals'
import { InvoiceSummaryLine } from './elements/InvoiceSummaryLine'
import { InvoiceSummaryTaxes } from './elements/InvoiceSummaryTaxes'
import { InvoiceSummaryTotal } from './elements/InvoiceSummaryTotal'
import * as Styled from './styles'

export const InvoiceSummary = (): ReactElement => {
  const { t } = useTranslation()
  const { getInvoiceLocale } = useGetZervantDocumentSettings(InvoiceDocumentType.Invoice)
  const locale = getInvoiceLocale()
  const { getValues, watch } = useFormContext<InvoiceFormSchema>()
  const currency = getValues('currency')
  const invoiceLines = watch('invoiceLines')

  const { grossTotal, netTotal, taxSummary, taxTotal } = getInvoiceSummaryTotals(invoiceLines)
  const shouldDisplayTotalVat = taxSummary.length > 1

  const getDisplayedCurrency = useCallback(
    (value: number) => {
      return NumberFormat.getCurrencyNumber(value, currency, locale)
    },
    [currency, locale],
  )

  return (
    <Flex alignItems="flex-end" flexDirection="column">
      <Styled.InvoiceSummaryContent>
        <InvoiceSummaryLine
          label={t('external_invoices.editor.summary.total_excluding_vat')}
          text={getDisplayedCurrency(netTotal)}
        />
        <Space size="s" />
        <InvoiceSummaryTaxes currency={currency} locale={locale} taxSummary={taxSummary} />
        {shouldDisplayTotalVat && (
          <InvoiceSummaryLine
            label={t('external_invoices.editor.summary.total_vat')}
            text={getDisplayedCurrency(taxTotal)}
          />
        )}
        <Space />
        <InvoiceSummaryTotal text={getDisplayedCurrency(grossTotal)} />
      </Styled.InvoiceSummaryContent>
    </Flex>
  )
}
