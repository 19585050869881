import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'

interface TabButtonsGroupWrapperProps {
  fullWidth?: boolean
}

export const TabButtonsGroupWrapper = styled.div<TabButtonsGroupWrapperProps>`
  display: inline-flex;
  gap: ${Spacing.XXXS};
  padding: ${Spacing.XXS};
  border-radius: ${BorderRadius.L};
  background-color: ${transparentize(0.6, Color.Gray40)};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`
