import React, { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLazyImage } from '../../../../hooks/useLazyImage'
import { getThumbnailUrl } from '../../../../utils/getThumbnailUrl'
import { Image } from '../../../Image'
import { SkeletonBox } from '../../../SkeletonBox'
import { FileComponentProps } from '../../types'
import { getSizeString } from '../../utils/getSizeString'
import { shouldRefetchImage } from '../../utils/shouldRefetchImage'
import { FileErrorThumbnail } from '../FileThumbnails'
import * as Styled from './styles'

const DEFAULT_ALT = 'Image Preview'

export const ImagePreview = memo(
  ({
    alt = DEFAULT_ALT,
    circle = false,
    fitToHeight,
    fitType,
    height = 0,
    onError,
    onLoad,
    src,
    width = 0,
    fitToWidth,
  }: FileComponentProps): ReactElement => {
    const { t } = useTranslation()
    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)
    const [isImageLoaded, setIsImageLoaded] = useState(false)

    useEffect(() => {
      const shouldRefetch = shouldRefetchImage(imageWidth, imageHeight, width, height)

      if ((!height && !width) || !shouldRefetch) {
        return
      }

      setImageHeight(fitToHeight ? height : 0)
      setImageWidth(fitToWidth ? width : 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [height, width])

    const shouldFetchImage = Boolean(imageWidth || imageHeight)

    const finalSrc = useMemo(
      () =>
        shouldFetchImage ? getThumbnailUrl(src, { size: getSizeString(imageWidth, imageHeight), fit: fitType }) : '',
      [shouldFetchImage, src, imageWidth, imageHeight, fitType],
    )

    const { loading, src: currentSrc, isError } = useLazyImage({ src: finalSrc, onError })

    const handleImageLoad = useCallback(() => {
      setIsImageLoaded(true)
      onLoad?.()
    }, [onLoad])

    return (
      <>
        {isError ? (
          <Styled.ErrorWrapper>
            <FileErrorThumbnail title={t('attachments.thumbnail_error.cant_load')} />
          </Styled.ErrorWrapper>
        ) : (
          <>
            {!isImageLoaded && <SkeletonBox fullHeight fullWidth />}
            {!loading && (
              <Styled.ImageWrapper circle={circle} fitToHeight={fitToHeight} isLoading={!isImageLoaded}>
                <Image src={currentSrc} alt={alt} objectFit={fitType} onLoad={handleImageLoad} />
              </Styled.ImageWrapper>
            )}
          </>
        )}
      </>
    )
  },
)
