import { NumberFormat } from '../../../formatters/utils/NumberFormat'
import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { limitValue } from './limitValue'

interface GetFormattedValueOptions {
  value: string | number | undefined
  max?: number
  min?: number
  isDisplayValueFormat?: boolean
  isFallbackToZero?: boolean
  locale?: LocaleIdentifier
}

export interface GetFormattedValueResponse {
  value: string
  hasValueBeenLimited: boolean
}

export const getFormattedValue = ({
  value,
  isDisplayValueFormat,
  isFallbackToZero,
  max,
  min,
  locale = 'en_US',
}: GetFormattedValueOptions): GetFormattedValueResponse => {
  const valueParsed = typeof value === 'number' ? value : NumberFormat.parseToNumber(value, locale)
  const { valueLimited, hasValueBeenLimited } = limitValue({ value: valueParsed, min, max })
  let formattedValue = valueLimited ? String(valueLimited) : ''

  if (!formattedValue && isFallbackToZero) {
    formattedValue = '0'
  }

  formattedValue = isDisplayValueFormat
    ? NumberFormat.getFormattedNumber(valueLimited || 0, locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    : formattedValue

  return { value: formattedValue, hasValueBeenLimited }
}
