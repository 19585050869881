import { NavItem, Select } from '@design-system'

import { useCallback } from 'react'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { invoiceLineUnits } from '../../../../../../../../../../../../constants/invoiceLineUnits'
import { InvoiceUnit } from '../../../../../../../../../../../../enums/invoiceUnit'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineUnitProps = CommonInvoiceLineFieldProps

export const InvoiceLineUnit = ({ lineIndex }: InvoiceLineUnitProps) => {
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  const unitItems: NavItem<InvoiceUnit>[] = invoiceLineUnits.map((unit) => ({
    id: unit,
    // at the later stage, when invoice language is introduced,
    // children value will be translated
    children: unit,
    value: unit,
  }))

  const handleDropdownOpen = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Unit dropdown opened')
  }, [track])

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.unit`}
      render={({ field: { onChange, value, ...rest } }) => {
        return (
          <Select
            {...rest}
            items={unitItems}
            onDropdownOpen={handleDropdownOpen}
            onSelect={(_id?: string, value?: InvoiceUnit) => {
              onChange?.(value)
              track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Unit selected', { option: value })
            }}
            selectedId={value}
            selectLook={false}
            withSearch={false}
          />
        )
      }}
    />
  )
}
