import { SearchInput } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import {
  defaultChooseAttachmentModalFilters,
  useChooseAttachmentModal,
} from '../../../../contexts/chooseAttachmentModalContext'

export const SearchAction = (): ReactElement => {
  const {
    filters: { searchQuery },
    setFilters,
  } = useChooseAttachmentModal()

  const isInitialValueSet = useRef(false)
  const [searchValue, setSearchValue] = useState(searchQuery)
  const [isInputVisible, setIsInputVisible] = useState(!!searchQuery)
  const { t } = useTranslation()

  // Update default search value on init if it's already set in `searchQuery
  useUpdateEffect(() => {
    if (isInitialValueSet.current) {
      return
    }

    if (!searchValue && searchQuery) {
      setSearchValue(searchQuery)
      setIsInputVisible(true)
    }
  }, [searchQuery])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilters({ searchQuery: event.target.value, page: defaultChooseAttachmentModalFilters.page })
    },
    [setFilters],
  )

  const handleBlur = useCallback(() => {
    setIsInputVisible(false)
  }, [])

  const handleFocus = useCallback(() => {
    isInitialValueSet.current = true
  }, [])

  const handleClear = useCallback(() => {
    setFilters({ searchQuery: undefined, page: defaultChooseAttachmentModalFilters.page })
  }, [setFilters])

  return (
    <SearchInput
      focused={isInputVisible}
      onBlur={handleBlur}
      onChangeDebounced={handleChange}
      onClear={handleClear}
      onFocus={handleFocus}
      placeholder={`${t('search')}...`}
      selectOnFocus
      spellCheck={false}
      value={searchValue}
    />
  )
}
