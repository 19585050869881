import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { getBohrScanResultFromAttachmentFile } from '@views/bills/elements/BillEditableForm/utils/getBohrScanResultFromAttachmentFile'

import { QueryKeys } from '../../../enums/queryKeys'
import { BohrScanResult } from '../../../types/bohrScanResult'
import { fetchVoucherFile } from '../services/query-api'

interface UseBohrScanResult {
  data: BohrScanResult | undefined
  isLoading: boolean
}

export const useBohrScanResult = (fileId?: string): UseBohrScanResult => {
  const { isLoading, isIdle, data } = useQuery(
    [QueryKeys.BohrScanResult, fileId],
    () => fetchVoucherFile(fileId || ''),
    {
      enabled: !!fileId,
    },
  )

  const { file } = data || {}
  const bohrData = useMemo((): BohrScanResult | undefined => {
    if (!file || !file.dataJson) {
      return
    }

    return getBohrScanResultFromAttachmentFile(file)
  }, [file])

  return {
    data: bohrData,
    isLoading: isLoading || isIdle,
  }
}
