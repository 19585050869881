import { Color } from '@design-system'

import cc from 'classcat'
import React from 'react'

import { Text } from '../Typography/Text'
import * as Styled from './styles'

export type ErrorMessageProps = {
  className?: string
  error:
    | {
        type?: string
        message?: string
      }
    | undefined
}

export const ErrorMessage = ({ className, error }: ErrorMessageProps) => {
  if (!error) {
    return null
  }
  return (
    <Styled.ErrorContainer className={cc([className, 'form-error-message'])}>
      <Text color={Color.Red}>{error.message}</Text>
    </Styled.ErrorContainer>
  )
}
