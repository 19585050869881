import { isBefore } from 'date-fns'
import { useMemo } from 'react'

import { ExternalInvoicesSyncStatus } from '@features/externalInvoicesSync/enums/externalInvoicesSyncStatus'
import { useFetchExternalInvoicesSync } from '@features/externalInvoicesSync/hooks/useFetchExternalInvoicesSync'
import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useOrganizationBrand } from '../../../hooks/useOrganizationBrand'
import { useFetchZervantCompany } from './useFetchZervantCompany'

const latestAllowedCreatedTime = new Date('2024-08-15')

export const useShouldDisplayExternalInvoicesSyncInitiateAction = () => {
  const { organization } = useUserOrganization()
  const { isBrandAgeras } = useOrganizationBrand()
  const { syncData } = useFetchExternalInvoicesSync()
  const { company: zervantCompany } = useFetchZervantCompany()

  const shouldDisplayExternalInvoicesSyncInitiateAction = useMemo(() => {
    const isSyncNotInitiatedYet = syncData?.syncStatus === ExternalInvoicesSyncStatus.NotInitiated
    const zervantCompanyCreatedTime = zervantCompany?.insertTime
    const isCountryFrance = organization?.countryId === 'FR'
    const isCompanyCreatedBeforeLatestAllowedTime = zervantCompanyCreatedTime
      ? isBefore(new Date(zervantCompanyCreatedTime), latestAllowedCreatedTime)
      : false

    return isSyncNotInitiatedYet && isCompanyCreatedBeforeLatestAllowedTime && isCountryFrance && isBrandAgeras
  }, [isBrandAgeras, organization?.countryId, syncData?.syncStatus, zervantCompany?.insertTime])

  return { shouldDisplayExternalInvoicesSyncInitiateAction }
}
