import { ButtonProtected, FileButtonProtected } from '@components'
import { Button, ButtonsGroup, IconButton, ModalConfirmation, useModal, useSidePanel } from '@design-system'

import Cookies from 'js-cookie'
import qs from 'qs'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AttachmentFilterType } from '@features/attachments/enums/attachmentFiltertype'
import { useUploadAttachments } from '@features/attachments/hooks/useUploadAttachments'
import { useUserOrganization } from '@modules-deprecated/app/organization'
import { OrganizationFeature, useOrganizationFeature } from '@views/settings/routes/OrganizationFeaturesSettings'

import { CookieKeys } from '../../../../enums/cookieKeys'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useSegment } from '../../../../hooks'
import {
  BulkNavigationQueryParams,
  defaultQuery as defaultBulkQuery,
} from '../../../../hooks/useBulkNavigationQueryParams'
import { getOrganizationViewUrl } from '../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useAttachmentsFilters } from '../../hooks/useAttachmentsFilters'
import { useGetFirstReceipt } from '../../hooks/useGetFirstReceipt'
import { useReceiptsDelete } from '../../hooks/useReceiptsDelete'
import * as Styled from './styles'

const DELETE_CONFIRMATION_MODAL_ID = 'delete-receipts-confirmation'

interface ReceiptsListSideActionsProps {
  selectedReceiptsIds: string[]
  setSelectedReceiptsIds: (selectedReceiptsIds: string[]) => void
}

export const ReceiptsListSideActions = ({
  selectedReceiptsIds,
  setSelectedReceiptsIds,
}: ReceiptsListSideActionsProps): ReactElement => {
  const { hasFeature } = useOrganizationFeature()
  const { open: openSidePanel } = useSidePanel()
  const { t } = useTranslation()
  const history = useHistory()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal } =
    useModal(DELETE_CONFIRMATION_MODAL_ID)
  const [{ type, sortBy, sortDirection }] = useAttachmentsFilters()
  const { uploadFiles, isUploading } = useUploadAttachments({
    onSuccess: () => {
      track('File Uploaded (Client)', {
        context: TrackingContext.Uploads,
      })
    },
  })
  const { attachment: firstAttachment } = useGetFirstReceipt({ type })
  const { isDeleting, deleteReceipts } = useReceiptsDelete({
    onSuccess: () => {
      setSelectedReceiptsIds([])
      closeDeleteConfirmationModal()
    },
  })

  const hasSelectedReceipts = selectedReceiptsIds.length > 0
  const isEInvoicesActive = type === AttachmentFilterType.EDocument
  const selectedReceiptsCount = selectedReceiptsIds.length

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteConfirmationModal()
  }, [openDeleteConfirmationModal])

  const handleDeleteModalOkClick = useCallback(() => {
    deleteReceipts(selectedReceiptsIds)
  }, [deleteReceipts, selectedReceiptsIds])

  const handleFillReceipts = useCallback(() => {
    const bulkQuery: BulkNavigationQueryParams = {
      ...defaultBulkQuery,
      bulkSortDirection: sortDirection,
      bulkSortProperty: sortBy,
      bulkUploadType: type,
    }

    if (!organization?.url) {
      return
    }

    if (hasSelectedReceipts) {
      Cookies.set(CookieKeys.AttachmentIdsForBulkEdit, qs.stringify(selectedReceiptsIds))

      const bulkEditAttachmentUrl = getOrganizationViewUrl(
        organization?.url,
        reactRoute.bills.getEditBulkAttachmentsRoute(selectedReceiptsIds[0]),
        bulkQuery,
      )

      history.push(bulkEditAttachmentUrl)
      return
    }

    if (firstAttachment) {
      const bulkEditAttachmentUrl = getOrganizationViewUrl(
        organization?.url,
        reactRoute.bills.getEditBulkAttachmentsRoute(firstAttachment.id),
        bulkQuery,
      )

      history.push(bulkEditAttachmentUrl)
    }
  }, [
    firstAttachment,
    hasSelectedReceipts,
    history,
    organization?.url,
    selectedReceiptsIds,
    sortDirection,
    sortBy,
    type,
  ])

  const handleScoutsButtonClick = useCallback(() => {
    track('xxx - scout side panel opened')
    openSidePanel()
  }, [openSidePanel, track])

  return (
    <>
      <Styled.ReceiptsListSideActionsWrapper>
        <Styled.ButtonsGroupWrapper>
          <ButtonsGroup>
            {selectedReceiptsCount > 0 && (
              <Button danger icon="trash" onClick={handleDeleteButtonClick}>
                {t('delete_receipts')}
              </Button>
            )}
            {!isEInvoicesActive && (
              <FileButtonProtected
                scopes={Scope.UploadsWrite}
                icon="picture"
                loading={isUploading}
                multiple
                onFilesSelect={uploadFiles}
                trackingContext={TrackingContext.Uploads}
              >
                {t('upload_files')}
              </FileButtonProtected>
            )}
            <ButtonProtected
              icon="bulkProcess"
              onClick={handleFillReceipts}
              disabled={!firstAttachment}
              variant="secondary"
              scopes={[Scope.BillWrite, Scope.BulkWorkflows]}
              trackingContext={TrackingContext.Uploads}
            >
              {hasSelectedReceipts ? t('receipts.fill_in_selected') : t('receipts.fill_in_all')}
            </ButtonProtected>
            {hasFeature(OrganizationFeature.EmailScanning) && (
              <IconButton
                aria-label={t('settings.organization.features.email_scanning.title')}
                icon="chainDisconnected"
                onClick={handleScoutsButtonClick}
                variant="secondary"
              />
            )}
          </ButtonsGroup>
        </Styled.ButtonsGroupWrapper>
      </Styled.ReceiptsListSideActionsWrapper>

      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={DELETE_CONFIRMATION_MODAL_ID}
        message={t('receipts.delete_modal.message', { count: selectedReceiptsCount })}
        okLabel={t('receipts.delete_modal.ok_label')}
        okLoading={isDeleting}
        onOk={handleDeleteModalOkClick}
        title={t('receipts.delete_modal.title', { count: selectedReceiptsCount })}
      />
    </>
  )
}
