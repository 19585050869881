import { Flex, ModuleLayout } from '@design-system'

import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CookieKeys } from '../../../../enums/cookieKeys'
import { useSegment } from '../../../../hooks'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { InvoiceEditorContextProvider } from './contexts/InvoiceEditorContext'
import { InvoiceEditorFormContextProvider } from './contexts/invoiceEditorFormContext'
import { InvoiceEditorVatRatesContextProvider } from './contexts/InvoiceEditorVatRatesContext'
import { InvoiceEditorContent } from './elements/InvoiceEditorContent'
import { InvoiceEditorPreviewToggle } from './elements/InvoiceEditorPreviewToggle'
import { InvoiceEditorSideActions } from './elements/InvoiceEditorSideActions'
import * as Styled from './styles'

export const InvoiceEditor = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { url: invoicesUrl } = useOrganizationViewUrl(reactRoute.invoices.getMainRoute())

  const storedInvoicesListQuery = Cookies.get(CookieKeys.InvoicingInvoicesListQuery) || ''

  const handleBackButtonClick = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Back arrow button clicked')
  }, [track])

  return (
    <InvoiceEditorContextProvider>
      <InvoiceEditorVatRatesContextProvider>
        <InvoiceEditorFormContextProvider>
          <ModuleLayout
            headerContent={
              <Styled.InvoiceEditorPreviewToggleWrapper>
                <Flex justifyContent="center" flexGrow={1}>
                  <InvoiceEditorPreviewToggle />
                </Flex>
              </Styled.InvoiceEditorPreviewToggleWrapper>
            }
            fullHeight
            backButtonTo={invoicesUrl && `${invoicesUrl}${storedInvoicesListQuery}`}
            onBackButtonClick={handleBackButtonClick}
            sideActions={<InvoiceEditorSideActions />}
            title={t('external_invoices.editor.new.title')}
            withBackButton
          >
            <InvoiceEditorContent />
          </ModuleLayout>
        </InvoiceEditorFormContextProvider>
      </InvoiceEditorVatRatesContextProvider>
    </InvoiceEditorContextProvider>
  )
}
