import { DateInputLocalized, InputNumberLocalized } from '@components'
import { Color, Divider, FormItem, FormItemsGroup, Space, Text } from '@design-system'

import { isAfter } from 'date-fns'
import { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useManualPayment } from '../../contexts/manualPaymentContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillAccountSelector } from '../BillAccountSelector'
import { BillSummary } from '../BillSummary'
import { DifferenceSelector } from '../DifferenceSelector'
import { LinkToBankLines } from '../MultipleBillsModalFooter/elements/LinkToBankLines'

export const ManualPaymentModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { currencyId, paymentDate, setPaymentDate } = useBillReconciliation()
  const { selectedBills } = useMultipleBills()
  const { hasManualPaymentDifference, manualPaymentAmount, manualPaymentDifference, setManualPaymentAmount } =
    useManualPayment()

  const newestBillDate = useMemo(() => {
    const sortedBills = selectedBills.toSorted((billA, billB) => {
      if (billA?.entryDate && billB?.entryDate) {
        const billAEntryDate = new Date(billA?.entryDate)
        const billBEntryDate = new Date(billB?.entryDate)

        return isAfter(billAEntryDate, billBEntryDate) ? -1 : 1
      }
      return 1
    })

    return sortedBills[0]?.entryDate
  }, [selectedBills])

  const minDate = newestBillDate ? new Date(newestBillDate) : new Date()

  const handlePaymentAmountChange = (amount?: number) => {
    setManualPaymentAmount(amount || 0)
  }

  useEffect(() => {
    track('xxx - expenses - Manual payment modal opened')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onBlurDateInput = useCallback(() => {
    setPaymentDate(paymentDate)
  }, [paymentDate, setPaymentDate])

  const dateInputError = useMemo((): ReactNode => {
    return !paymentDate && <Text color={Color.Red}>{t('bill.payment_reconcile.prepayment.chose_payment_date')}</Text>
  }, [paymentDate, t])

  const amountInputError = useMemo((): ReactNode => {
    return (
      !manualPaymentAmount && <Text color={Color.Red}>{t('bill.payment_reconcile.prepayment.chose_payment_date')}</Text>
    )
  }, [manualPaymentAmount, t])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <BillAccountSelector />
      <Space />
      <FormItemsGroup itemsInRow={4}>
        <FormItem label={t('bill.payment_reconcile.payment_date')} error={dateInputError}>
          <DateInputLocalized
            name="paymentDate"
            minDate={minDate}
            onChange={setPaymentDate}
            value={paymentDate || undefined}
            onBlur={onBlurDateInput}
          />
        </FormItem>

        <FormItem itemColumnEnd={5} itemColumnStart={2} label={t('amount')} error={amountInputError}>
          <InputNumberLocalized
            name="manualPaymentAmount"
            value={manualPaymentAmount}
            defaultValue={manualPaymentAmount}
            onChange={handlePaymentAmountChange}
            suffix={currencyId && <Text colorVariant="secondary">{currencyId}</Text>}
          />
        </FormItem>
      </FormItemsGroup>
      {hasManualPaymentDifference && <DifferenceSelector difference={manualPaymentDifference} />}
      <LinkToBankLines />
    </>
  )
}
