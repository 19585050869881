import { AttributionTrackingParameters, AttributionTrackingParametersKey } from '../types/agerasTrackingAnalyticsData'
import { trackError } from './trackError'

interface AnalyticsData {
  analytics: string
}

const NO_SCRIPT_ERROR = 'Analytics data script not loaded'

/* eslint-disable @typescript-eslint/naming-convention */
const parametersTranslations = {
  ad_group: 'utm_adgr',
  ad: 'utm_ad',
  campaign: 'utm_campaign',
  content: 'utm_content',
  device: 'utm_device',
  devices: 'utm_devices',
  keyword: 'utm_kw',
  match_type: 'utm_mt',
  medium: 'utm_medium',
  network: 'utm_network',
  placement: 'utm_placement',
  source: 'utm_source',
  term: 'utm_term',
  type: 'utm_type',
}
/* eslint-enable @typescript-eslint/naming-convention */

export const renameKeys = (
  originalObject: AttributionTrackingParameters,
  translationDictionary: Record<AttributionTrackingParametersKey, string>,
) => {
  return (Object.keys(originalObject) as AttributionTrackingParametersKey[]).reduce(
    (result, key) => {
      const newKey: string = translationDictionary[key] || key
      result[newKey] = originalObject[key]
      return result
    },
    {} as Record<string, string>,
  )
}

export const createUrlWithParams = (landingPageUrl: string, params: Record<string, string>) => {
  const url = new URL(landingPageUrl)
  const searchParams = new URLSearchParams(params)

  url.search = searchParams.toString()
  return url.toString()
}

export const getSignupAnalyticsData = (): AnalyticsData | undefined => {
  if (!window.ageras_src) {
    trackError(NO_SCRIPT_ERROR)
    return
  }

  const sourceData = window.ageras_src.getSourceData()

  if (!sourceData?.first_touch) {
    return undefined
  }

  const attribution = sourceData.first_touch.attribution
  const landingPage = sourceData.first_touch.entry_url
  attribution.device = attribution.device || sourceData.first_touch.device_type
  const translatedAttributions = renameKeys(attribution, parametersTranslations)
  const translatedUrl = createUrlWithParams(landingPage, translatedAttributions)

  return translatedUrl ? { analytics: translatedUrl } : undefined
}

export const removeSignupAnalyticsData = () => {
  if (!window.ageras_src) {
    trackError(NO_SCRIPT_ERROR)
    return
  }

  window.ageras_src.purge()
}
