import { Flex, getDisplayDate, Text } from '@design-system'

import { ReactElement } from 'react'

import { BillType } from '@views/bills/enums/billType'

import { getCurrentLocale } from '../../../../../../../../../../utils'
import { BillDescription } from '../../../../../../../../routes/BillsList/elements/BillDescription'
import { Bill } from '../../../../../../../../types/bill'
import { getBillAmountToDisplayValue } from '../../../../../../../../utils/getBillAmountToDisplayValue'
import { useBillReconciliation } from '../../../../../../contexts/billReconciliationContext'
import * as Styled from './styles'

interface BillsListItemContentProps {
  bill: Bill
}

export const BillsListItemContent = ({ bill }: BillsListItemContentProps): ReactElement => {
  const { balance, entryDate, lineDescription, type, voucherNo } = bill
  const { currencyId } = useBillReconciliation()

  const locale = getCurrentLocale()
  const shouldShowAmount = !!balance && !!currencyId
  const isCreditNote = type === BillType.CreditNote

  return (
    <Styled.BillsListItemContentWrapper>
      <Flex justifyContent="space-between">
        <Styled.DetailsWrapper>
          <Text colorVariant="secondary">{entryDate ? getDisplayDate(new Date(entryDate), locale) : '-'}</Text>
          <Text colorVariant="secondary">#{voucherNo}</Text>
          <BillDescription isCreditNote={isCreditNote}>{lineDescription}</BillDescription>
        </Styled.DetailsWrapper>
        {shouldShowAmount && (
          <Text>{getBillAmountToDisplayValue({ amount: balance, currency: currencyId, isCreditNote })}</Text>
        )}
      </Flex>
    </Styled.BillsListItemContentWrapper>
  )
}
