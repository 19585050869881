import { useExternalInvoiceTransactions } from '../../hooks/useExternalInvoiceTransactions'
import { InvoiceTransactionLink } from '../InvoiceTransactionLink'

export const InvoiceTransactionsList = () => {
  const { transactions, isLoading } = useExternalInvoiceTransactions()

  if (isLoading) {
    return null
  }

  return transactions?.map((transaction) => (
    <InvoiceTransactionLink
      transactionId={transaction.id}
      transactionNo={transaction.transactionNo}
      key={transaction.id}
    />
  ))
}
