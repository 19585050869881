import { useCallback } from 'react'

import { DEFAULT_INVOICE_VAT_PREDEFINED_TAG } from '../constants/defaultInvoiceVatPredefinedTag'
import { useInvoiceEditorVatRates } from '../contexts/InvoiceEditorVatRatesContext'

export const useGetVatRate = () => {
  const { vatRates } = useInvoiceEditorVatRates()

  const getFallbackVatRate = useCallback(() => {
    const defaultVatRate = vatRates.find((rate) => rate?.predefinedTag === DEFAULT_INVOICE_VAT_PREDEFINED_TAG)
    const firstSalesVatRate = vatRates.find((taxRate) => !!taxRate.appliesToSales)

    return defaultVatRate || firstSalesVatRate
  }, [vatRates])

  const getVatRateById = useCallback(
    (vatRateId: string) => vatRates.find((rate) => rate?.id === vatRateId) || getFallbackVatRate(),
    [getFallbackVatRate, vatRates],
  )

  const getVatRateByPredefinedTag = useCallback(
    (predefinedVatRateTag?: string) =>
      vatRates.find((rate) => rate?.predefinedTag === predefinedVatRateTag) || getFallbackVatRate(),
    [getFallbackVatRate, vatRates],
  )

  return { getVatRateById, getVatRateByPredefinedTag }
}
