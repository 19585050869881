import qs from 'qs'
import { QueryKey } from 'react-query'
import { SortDirection } from 'src/enums/sortDirection'

import { Metable } from '../../types/metable'
import { deleteRequest, formatDate, getRequest, patchRequest, postRequest } from '../../utils'
import { SortProperty } from './enums/sortProperty'
import {
  BankConnection,
  BankConnectionOld,
  BankLine,
  BankLineComment,
  BankLineMatch,
  BankLineSubjectAssociation,
  ReceiptState,
} from './types'

export interface BankLinesResponse extends Metable {
  bankLines: BankLine[]
}

export interface BankLinesQueryParams {
  accountId: string
  dueDateTo?: Date
  includeNullDueDate?: boolean
  isReconciled?: boolean
  pageSize?: number
  receiptState?: ReceiptState
  sortDirection?: SortDirection
  sortProperty?: SortProperty
}

const BANK_LINES_API_BASE = '/v2/bankLines'

export const fetchBankLines = (params: BankLinesQueryParams): Promise<BankLinesResponse> => {
  return getRequest(`${BANK_LINES_API_BASE}?${qs.stringify({ ...params })}`)
}

export interface BankLineMatchesQueryParams {
  accountId: string
  approvedTimePeriod?: string
  include?: string
  isApproved?: boolean
  sortDirection?: SortDirection
  sortProperty?: 'entryDate' | 'sideAmount' | 'approvedTime'
  entryDatePeriod?: string
}

interface FetchBankLineMatchesFromMultipleAccountsProps {
  paramsList: BankLineMatchesQueryParams[]
}

interface BankLineMatchesFromMultipleAccountsQuery {
  queryKey: [QueryKey, FetchBankLineMatchesFromMultipleAccountsProps]
}

export interface BankLineMatchesResponse extends Metable {
  bankLineMatches: BankLineMatch[]
}

export const fetchBankLineMatchesFromMultipleAccounts = async ({
  queryKey,
}: BankLineMatchesFromMultipleAccountsQuery): Promise<BankLineMatchesResponse[]> => {
  const [, { paramsList }] = queryKey

  return Promise.all(paramsList.map((params) => getRequest(`/v2/bankLineMatches?${qs.stringify(params)}`)))
}

export const updateBankLine = async (bankLine: Partial<BankLine>) => {
  return patchRequest(BANK_LINES_API_BASE, {
    bankLines: [bankLine],
  })
}

export const createBankLineComment = async (bankLineComment: BankLineComment) => {
  return postRequest('/v2/bankLineComments', { bankLineComment })
}

export const updateBankLines = (bankLines: BankLine[]): Promise<void> => patchRequest('/v2/bankLines', { bankLines })

export const fetchBankLinesForAccount = (queryParams: Partial<BankLinesQueryParams>): Promise<BankLinesResponse> => {
  const params: Partial<BankLinesQueryParams> = {
    pageSize: 200,
    ...queryParams,
  }

  return getRequest(`/v2/bankLines?${qs.stringify(params)}`)
}

export interface FetchBillSubjectAssociationsResponse extends Metable {
  bankLineSubjectAssociations: BankLineSubjectAssociation[]
}

export const fetchBillSubjectAssociations = async (billId: string): Promise<FetchBillSubjectAssociationsResponse> => {
  const queryParams = qs.stringify({
    subjectReference: `bill:${billId}`,
  })
  const bankLineMatch = await getRequest(`/v2/bankLineSubjectAssociations/?${queryParams}`)
  return bankLineMatch
}

export const deleteBankLineSubjectAssociations = async (bankLineSubjectAssociationsIds: string[]): Promise<void> => {
  for (const id of bankLineSubjectAssociationsIds) {
    await deleteRequest(`/v2/bankLineSubjectAssociations/${id}`)
  }
}

export const fetchBankConnections = (organizationId: string): Promise<BankConnection[]> => {
  return getRequest(`/banking/${organizationId}/accounts`)
}

export const fetchOldBankConnections = (organizationId: string): Promise<BankConnectionOld[]> => {
  return getRequest(`/organizations/${organizationId}/bankConnections`)
}

interface AccountBalanceResult {
  balance: number
}

export const fetchAccountBalance = (
  accountId: string,
  currency: string,
  endDate?: Date,
): Promise<AccountBalanceResult> => {
  const endDateFormatted = formatDate(endDate, 'yyyy-MM-dd')

  return getRequest(`/v2/accounts/${accountId}/balance?currencyId=${currency}&endDate=${endDateFormatted}`)
}
