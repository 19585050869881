export enum SubscriptionPlan {
  AccountantPackageSeatBilly = 'accountant_package_seat_billy',
  AgerasBasic = 'ageras_basic',
  AgerasComplete = 'ageras_complete',
  AgerasFree = 'ageras_free',
  AgerasPlus = 'ageras_plus',
  AgerasStart = 'ageras_start',
  AgerasAccounting = 'agerasAccounting',
  APIPartner = 'apipartner',
  Archive = 'archive',
  Basic = 'basic',
  BasicPaid = 'basic_paid',
  Complete = 'complete',
  Employee = 'employee',
  Free = 'free',
  FriendsofBilly = 'friendofbilly',
  InternalBasicPaid = 'internal_basic_paid',
  InternalCore = 'internal_core',
  InternalCore10PackSeat = 'internal_core_10_pack_seat',
  InternalPlus = 'internal_plus',
  InternalPlusExternal = 'internal_plus_external',
  InternalSmart = 'internal_smart',
  InternalLicense = 'internallicense',
  None = 'none',
  NonProfit = 'nonprofit',
  Plus = 'plus',
  PlusTrial = 'plustrial',
  Premium = 'premium',
  PremiumInternal = 'premium_internal',
  PremiumTest = 'premiumtest',
  PremiumTrial = 'premiumtrial',
  Pro = 'pro',
  ProInternal = 'pro_internal',
  SpecialFree = 'specialfree',
}
