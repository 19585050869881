import { Color } from '../../../../../../enums/color'
import { Divider } from '../../../../../Divider'
import { Space } from '../../../../../Space'

export const ElementsDivider = () => (
  <>
    <Space size="s" horizontal />
    <Divider direction="vertical" dividerColor={Color.Gray40} />
    <Space size="s" horizontal />
  </>
)
