import { Country } from '../types/country'
import { LocaleIdentifier } from '../types/localeIdentifier'

/* eslint-disable @typescript-eslint/naming-convention */
export const localeToCountryCode: Record<LocaleIdentifier, Country> = {
  da_DK: 'dk',
  de_DE: 'de',
  en_US: 'us',
  fi_FI: 'fi',
  fr_FR: 'fr',
  nl_NL: 'nl',
  sv_SE: 'se',
}
/* eslint-enable @typescript-eslint/naming-convention */
