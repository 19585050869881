import { differenceInDays } from 'date-fns/esm'
import sortBy from 'lodash/sortBy'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { TaxReturn } from '../../../../../types/taxReturn'
import { useFetchSalesTaxReturnPeriods } from '../../../../salesTaxReturns/hooks/useFetchSalesTaxReturnPeriods'
import { fetchSalesTaxReturnAccountReport, SalesTaxReturnAccountReportResponse } from '../query-api'

interface UseVatPeriod {
  salesTaxReturnPeriod?: TaxReturn
  accountReport?: SalesTaxReturnAccountReportResponse
  isLoading?: boolean
  isError?: boolean
}

const MAX_NUMBER_DUE_DAYS = 20

export const useVatPeriod = (organizationId?: string): UseVatPeriod => {
  const [salesTaxReturnPeriod, setSalesTaxReturnPeriod] = useState<TaxReturn>()

  const {
    salesTaxReturnPeriods,
    isLoading: arePeriodsLoading,
    isError: taxReturnPeriodsError,
  } = useFetchSalesTaxReturnPeriods()

  const {
    data: accountReport,
    isLoading: isPeriodLoading,
    isError: taxReturnPeriodError,
  } = useQuery(
    [QueryKeys.SalesTaxReturn, salesTaxReturnPeriod?.id],
    () =>
      fetchSalesTaxReturnAccountReport({
        salesTaxReturnId: salesTaxReturnPeriod?.id || '',
        noHtml: true,
        noRaw: true,
      }),
    {
      enabled: !!salesTaxReturnPeriod,
    },
  )

  useEffect(() => {
    const canPeriodBeSettled = (period: TaxReturn): boolean => {
      const reportDeadlineDate = new Date(period.reportDeadline)
      const todaysDate = new Date()
      return !period.isSettled && differenceInDays(todaysDate, reportDeadlineDate) <= MAX_NUMBER_DUE_DAYS
    }

    const findTaxReturnPeriodToDisplay = (periods: TaxReturn[]) => {
      const periodsThatCanBeSettled = periods.filter(canPeriodBeSettled)
      const periodsThatCanBeSettledSortedByDate = sortBy(periodsThatCanBeSettled, 'reportDeadline')
      setSalesTaxReturnPeriod(periodsThatCanBeSettledSortedByDate[0])
    }

    if (salesTaxReturnPeriods) {
      findTaxReturnPeriodToDisplay(salesTaxReturnPeriods)
    }
  }, [salesTaxReturnPeriods])

  const isLoading = arePeriodsLoading || isPeriodLoading
  const isError = taxReturnPeriodsError || taxReturnPeriodError

  return { isLoading, isError, accountReport, salesTaxReturnPeriod }
}
