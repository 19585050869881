import { NavItem, Select } from '@design-system'

import isEmpty from 'lodash/isEmpty'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useSegment } from '../../../../../../../../../../hooks'
import { useFetchContacts } from '../../../../../../../../../contacts/hooks/useFetchContacts'
import { Contact } from '../../../../../../../../../contacts/types/contact'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { InvoiceReceiverDropdownFooter } from './elements/InvoiceReceiverDropdownFooter'

export const InvoiceReceiver = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { contacts, isLoading } = useFetchContacts({ isCustomer: true })

  const contactItems: NavItem<Partial<Contact>>[] = useMemo(
    () =>
      contacts.map((contact) => ({
        id: contact?.id,
        children: contact?.name,
        value: contact,
      })),
    [contacts],
  )

  const handleDropdownOpen = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Form - Contact dropdown opened')
  }, [track])

  return (
    <FormItem
      name="receiver"
      render={({ field: { onChange, value, ...rest } }) => {
        return (
          <Select
            {...rest}
            dropdownFetching={isLoading}
            dropdownFooter={<InvoiceReceiverDropdownFooter />}
            dropdownSize="fitTrigger"
            items={contactItems}
            onDropdownOpen={handleDropdownOpen}
            onSelect={(id?: string, value?: Partial<Contact>) => {
              // we need undefined specifically for validation purposes and select can
              // trigger onSelect with no action on the element and provide and empty object
              onChange(!isEmpty(value) ? value : undefined)
              track('XXX - Invoicing Core - Invoice Editor - Form - Contact selected', { contactId: id })
            }}
            placeholder={t('external_invoices.editor.form.field.send_to.select_placeholder')}
            selectedId={value?.id}
            selectLook={false}
          />
        )
      }}
    />
  )
}
