import { ModalProps, notify, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { useEmberTransitionTo } from '../../../../hooks/routing/useEmberTransitionTo'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { TransferOrganizationOwnershipContextProvider } from './contexts/transferOrganizationOwnershipContext'
import { OrganizationOwnershipModalContent } from './elements/OrganizationOwnershipModalContent'

interface TransferOrganizationOwnershipModalProps extends ModalProps {
  umbrellaOrganization?: UmbrellaOrganization
}

export const TransferOrganizationOwnershipModal = ({
  umbrellaOrganization,
  ...modalProps
}: TransferOrganizationOwnershipModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close } = useModal(modalProps.id)
  const { transitionTo } = useEmberTransitionTo()

  const { url: dashboardUrl } = useOrganizationViewUrl(reactRoute.dashboard.getMainRoute())

  const onTransferSuccess = useCallback(() => {
    close()
    if (!umbrellaOrganization) {
      if (dashboardUrl) {
        transitionTo(dashboardUrl)
      }
      notify({
        id: NotificationKeys.TransferOwnership,
        message: t('transfer_ownership_modal.success.organization'),
        variant: 'success',
      })
    }
  }, [close, dashboardUrl, umbrellaOrganization, t, transitionTo])

  return (
    <TransferOrganizationOwnershipContextProvider
      onTransferSuccess={onTransferSuccess}
      organization={organization}
      umbrellaOrganization={umbrellaOrganization}
    >
      <OrganizationOwnershipModalContent {...modalProps} />
    </TransferOrganizationOwnershipContextProvider>
  )
}
