import { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchAccounts } from './query-api'
import { Account } from './types'

interface ContextState {
  accounts: Account[] | undefined
  isLoading: boolean
}

const AccountsContext = createContext<ContextState | undefined>(undefined)

interface AccountsContextProps {
  children?: ReactNode
  organizationId?: string
}

export const AccountsContextProvider = ({
  children,
  organizationId = '',
}: AccountsContextProps): ReactElement | null => {
  const { data, isLoading } = useQuery([QueryKeys.Accounts, organizationId], () => fetchAccounts(organizationId), {
    enabled: !!organizationId,
  })

  return (
    <AccountsContext.Provider
      value={{
        accounts: data?.accounts,
        isLoading,
      }}
    >
      {children}
    </AccountsContext.Provider>
  )
}

export const useAccounts = () => {
  const context = useContext(AccountsContext)

  if (!context) {
    throw new Error('AccountsContextProvider is missing in the module!')
  }

  return context
}
