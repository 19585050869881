import { DEFAULT_FORMATTER_LOCALE } from '../../constants/defaultFormatterLocale'
import { LocaleSeparators } from './types/LocaleSeparators'
import { getNumberFormatLocale } from './utils/getNumberFormatLocale'

export const getNumberSeparators = (locale?: string): LocaleSeparators => {
  const intlLocale = getNumberFormatLocale(locale || DEFAULT_FORMATTER_LOCALE)
  const exampleNumber = 1234.6789
  const formatted = new Intl.NumberFormat(intlLocale).format(exampleNumber)
  const separatorsString = formatted.replace(/\d/g, '') // removed all digits
  const thousandSeparator = separatorsString.length > 1 ? separatorsString[0] : ''
  const decimalSeparator = separatorsString.slice(-1) || '.'

  return { decimalSeparator, thousandSeparator }
}
