import styled from '@emotion/styled'

import { getButtonHoverStyles } from '../../utils/getButtonHoverStyles'
import { getButtonWrapperStyles } from '../../utils/getButtonWrapperStyles'

interface ButtonWrapperProps {
  borderRadius: string
}

export const PrevButtonWrapper = styled.div<ButtonWrapperProps>`
  ${getButtonWrapperStyles()};
  left: 0;
  border-top-left-radius: ${({ borderRadius }) => borderRadius};
  border-bottom-left-radius: ${({ borderRadius }) => borderRadius};

  ${getButtonHoverStyles()}
`

export const NextButtonWrapper = styled.div<ButtonWrapperProps>`
  ${getButtonWrapperStyles()};
  right: 0;
  justify-content: end;
  border-top-right-radius: ${({ borderRadius }) => borderRadius};
  border-bottom-right-radius: ${({ borderRadius }) => borderRadius};

  ${getButtonHoverStyles()}
`
