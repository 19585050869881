import uniqueId from 'lodash/uniqueId'
import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react'

import { ToggleGroupItem } from './elements/ToggleGroupItem'
import * as Styled from './styles'
import { ToggleGroupItem as ToggleGroupItemType } from './types'

export interface ToggleGroupProps {
  defaultValue?: string
  items: ToggleGroupItemType[]
  name?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  value?: string
}

export const ToggleGroup = ({ defaultValue, items, name, onChange, value }: ToggleGroupProps): ReactElement => {
  const [checkedValue, setCheckedValue] = useState<string | undefined>(defaultValue ?? value)
  const toggleGroupName = name || uniqueId('toggle-group-')

  const handleToggleGroupChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCheckedValue(event.currentTarget.value)
      onChange?.(event)
    },
    [onChange],
  )

  useEffect(() => {
    if (value !== undefined) {
      setCheckedValue(value)
    }
  }, [checkedValue, value])

  return (
    <Styled.ToggleGroupWrapper>
      {items.map((item) => (
        <ToggleGroupItem
          groupCheckedValue={checkedValue}
          item={item}
          key={item.value}
          name={toggleGroupName}
          onChange={handleToggleGroupChange}
        />
      ))}
    </Styled.ToggleGroupWrapper>
  )
}
