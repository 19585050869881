import { BorderRadius, Color, pxToNumber, rwd, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { Box, BoxProps, Flex, FlexProps } from 'rebass'

import { Themable } from '../../../types/themable'
import { Container as AbsoluteContainer } from '../Container'
import { StackLayout, StackLayoutBox, StackLayoutBoxProps } from '../StackLayout'

const HORIZONTAL_PADDING = 30
const SECTIONS_DISTANCE = 30

const SMALL_GAP = pxToNumber(Spacing.S)
const BIG_GAP = 100

const SMALL_GAP_OFFSET = BIG_GAP / 4 - SMALL_GAP / 2
const BIG_GAP_OFFSET = BIG_GAP / 2 - SMALL_GAP / 2

export const MainContainer = styled(Flex)`
  position: relative;
  flex: 1 0 auto;
`

export const LoadingContainer = styled.div<Themable>`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.ds.colors.base.background};
`

export const FormFileStackLayout = styled(StackLayout)`
  height: 100%;
  padding-right: ${HORIZONTAL_PADDING}px;
  padding-left: ${HORIZONTAL_PADDING}px;
  flex: 1 0 auto;

  ${rwd.equalOrLessThan('large')} {
    padding-bottom: 0;
  }
`

type FormFilesLayoutBoxProps = {
  fourInRow?: boolean
}

export const FormFilesLayoutBox = styled(StackLayoutBox)<FormFilesLayoutBoxProps>`
  display: grid;
  grid-template-columns: ${({ fourInRow }) => (fourInRow ? '1fr 1fr 1fr 1fr' : '1fr 1fr')};
  grid-template-rows: min-content;
  gap: ${Spacing.S};
  width: calc(100% - 5px);

  ${rwd.equalOrLessThan('large')} {
    column-gap: ${({ fourInRow }) => (fourInRow ? `${SMALL_GAP}px` : `${BIG_GAP}px`)};
    margin-right: 0;

    ${({ fourInRow }) => {
      if (fourInRow) {
        return `
          > * {
            &:first-of-type {
              margin-right: ${SMALL_GAP_OFFSET}px;
            }

            &:nth-of-type(2) {
              margin-left: -${SMALL_GAP_OFFSET}px;
              margin-right: ${BIG_GAP_OFFSET}px;
            }

            &:nth-of-type(3) {
              margin-left: ${BIG_GAP_OFFSET}px;
              margin-right: -${SMALL_GAP_OFFSET}px;
            }

            &:last-of-type {
              margin-left: ${SMALL_GAP_OFFSET}px;
            }
          }
        `
      }
    }}
  }
`

export const FormContainer = styled(StackLayout)`
  position: relative;

  ${rwd.equalOrLessThan('large')} {
    height: 60%;
  }
`

type FilesContainerProps = StackLayoutBoxProps & Themable

export const FilesContainer = styled(StackLayoutBox)<FilesContainerProps>`
  height: 100%;
  padding-bottom: 35px;
  position: relative;

  ${rwd.equalOrLessThan('large')} {
    width: calc(100% + 60px);
    height: 40%;
    padding: 0 ${HORIZONTAL_PADDING}px;
    background-color: ${({ theme }) => theme.colors.bg.soft};
    margin-top: 20px;
    margin-left: -${HORIZONTAL_PADDING}px;
    margin-right: -${HORIZONTAL_PADDING}px;
  }
`

export const FormAbsoluteContainer = styled(AbsoluteContainer)`
  width: calc(100% - ${SECTIONS_DISTANCE}px);
  padding-right: ${Spacing.S};
  right: ${SECTIONS_DISTANCE}px;

  ${rwd.equalOrLessThan('large')} {
    width: calc(100% + ${HORIZONTAL_PADDING}px);
    padding-top: 10px;
    padding-right: ${HORIZONTAL_PADDING}px;
    margin-right: ${HORIZONTAL_PADDING}px;
  }
`

export const FilesAbsoluteContainer = styled(AbsoluteContainer)`
  max-height: 100%;

  ${rwd.greaterThan('large')} {
    overflow: hidden;
    padding-bottom: 35px;
  }

  ${rwd.equalOrLessThan('large')} {
    padding-top: 10px;
  }
`

interface FileWrapperProps extends BoxProps, Themable {
  fullHeight?: boolean
  isError?: boolean
}

export const FileWrapper = styled(Box)<FileWrapperProps>`
  border: 1px solid ${Color.DayGrey};
  padding: ${Spacing.S};
  border-radius: ${BorderRadius.L};
  background-color: ${Color.White};

  ${({ isError }) =>
    isError &&
    `
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
  `}

  ${rwd.greaterThan('large')} {
    height: 100%;
  }

  ${rwd.equalOrLessThan('large')} {
    width: 640px;
    aspect-ratio: 1.41;
    align-self: center;
    border-color: ${({ theme }) => theme.colors.shade10};
  }
`

type FileMockProps = FlexProps & Themable

export const FileMock = styled(Flex)<FileMockProps>`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bg.soft};
`
