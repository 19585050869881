import { parse } from 'date-fns'

import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { getDateFormat } from './getDateFormat'

const SPECIAL_CHARACTERS_REGEXP = /[^\w ]/g

export const getParsedDateFromString = (value: string, locale: LocaleIdentifier): Date => {
  const valueClean = value.replace(SPECIAL_CHARACTERS_REGEXP, '')
  return parse(valueClean, getDateFormat(valueClean, locale), new Date())
}
