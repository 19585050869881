import { trackError } from '../utils/trackError'

interface Userflow {
  start: (id: string, options?: StartOptions) => Promise<void>
}

interface StartOptions {
  once?: boolean
}

export const useUserflow = (): Userflow => {
  const start = (id: string, options?: StartOptions) => {
    if (!window.userflow) {
      trackError('Trying to start Userflow content, but Userflow library is not present.')
      return
    }

    return window.userflow.start(id, options)
  }

  return {
    start,
  }
}
