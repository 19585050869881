import { Route, RouteProtected } from '@components'

import { Redirect, Switch } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { Dashboard } from '@views/dashboard'

import { Scope } from '../../enums/scope'
import { useOrganizationSource } from '../../hooks'
import { getOrganizationViewUrl } from '../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../utils/routing/reactRoute'
import { BillEdit } from '../bills/routes/BillEdit'
import { BillsList } from '../bills/routes/BillsList'
import { BillsListEmpty } from '../bills/routes/BillsListEmpty'
import { BillView } from '../bills/routes/BillView'
import { CreditorsList } from '../creditors'
import { DebtorsList } from '../debtors/routes/DebtorsList'
import { InvoiceEditor } from '../externalInvoices/routes/InvoiceEditor'
import { InvoicesList as ExternalInvoicesList } from '../externalInvoices/routes/InvoicesList'
import { InvoiceView } from '../externalInvoices/routes/InvoiceView'
import { FindAccountant } from '../findAccountant'
import { InvoicesList } from '../invoices/routes/InvoicesList'
import { InvoicesListEmpty } from '../invoices/routes/InvoicesListEmpty'
import { ReceiptsList } from '../receipts/routes'
import { SalaryIntegration } from '../salaryIntegration'
import { ThingsToDo } from '../thingsToDo'
import { useRedirectHashUrls } from './hooks/useRedirectHashUrls'

export const ViewContainer = () => {
  const { organization } = useUserOrganization()
  useRedirectHashUrls()
  const { isSourceZervant } = useOrganizationSource()

  if (!organization) {
    return null
  }

  return (
    <Switch>
      {/* Redirect to Dashboard from main organization page */}
      <Route exact path={getOrganizationViewUrl(organization.url, '')}>
        <Redirect to={getOrganizationViewUrl(organization.url, reactRoute.dashboard.getMainRoute())} />
      </Route>

      {/* Dashboard */}
      <Route
        component={Dashboard}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.dashboard.getMainRoute())}
      />

      {/* Debtors */}
      <Route
        component={DebtorsList}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.debtors.getMainRoute())}
      />

      {/* Creditors */}
      <Route
        component={CreditorsList}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.creditors.getMainRoute())}
      ></Route>

      {/* Find accountant */}
      <RouteProtected
        scopes={Scope.FindAccountant}
        component={FindAccountant}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.findAccountant.getMainRoute())}
      />

      {/* Bills */}
      <RouteProtected
        scopes={Scope.BillRead}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getMainRoute())}
      >
        <BillsList />
      </RouteProtected>
      <RouteProtected
        scopes={Scope.BillRead}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getListEmptyRoute())}
      >
        <BillsListEmpty />
      </RouteProtected>

      <RouteProtected
        scopes={Scope.BillWrite}
        exact
        path={[
          reactRoute.bills.getNewBillRoute(),
          reactRoute.bills.getDuplicateRoute(),
          reactRoute.bills.getEditBillRoute(),
          reactRoute.bills.getEditBulkAttachmentsRoute(),
          reactRoute.bills.getEditBulkRoute(),
          reactRoute.bills.getCreateCreditNoteRoute(),
          reactRoute.bills.getNewBillFromAttachmentRoute(),
          reactRoute.bills.getNewBillFromContactRoute(),
        ].map((route) => getOrganizationViewUrl(organization.url, route))}
      >
        <BillEdit />
      </RouteProtected>

      <RouteProtected
        scopes={Scope.BillWrite}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getNewCreditNoteRoute())}
      >
        <BillEdit isNewCreditNote />
      </RouteProtected>

      <RouteProtected
        scopes={Scope.BillRead}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.bills.getViewBillRoute())}
      >
        <BillView />
      </RouteProtected>

      {/* Things to do  */}
      <Route path={getOrganizationViewUrl(organization.url, reactRoute.thingsToDo.getMainRoute())}>
        <ThingsToDo />
      </Route>

      {/* Uploads */}
      <Route
        component={ReceiptsList}
        exact
        path={getOrganizationViewUrl(organization.url, reactRoute.uploads.getMainRoute())}
      />

      {/* Salary  */}
      <Route path={getOrganizationViewUrl(organization.url, reactRoute.salary.getMainRoute())}>
        <SalaryIntegration />
      </Route>

      {/* Invoices */}
      <Route exact path={getOrganizationViewUrl(organization.url, reactRoute.invoices.getListEmptyRoute())}>
        <InvoicesListEmpty />
      </Route>
      <Route exact path={getOrganizationViewUrl(organization.url, reactRoute.invoices.getMainRoute())}>
        {isSourceZervant ? <ExternalInvoicesList /> : <InvoicesList />}
      </Route>
      {isSourceZervant && (
        <>
          <Route exact path={getOrganizationViewUrl(organization.url, reactRoute.invoices.getViewInvoiceRoute())}>
            <InvoiceView />
          </Route>
          <Route exact path={getOrganizationViewUrl(organization.url, reactRoute.invoices.getNewInvoiceRoute())}>
            <InvoiceEditor />
          </Route>
        </>
      )}
    </Switch>
  )
}
