import { Route } from '@components'
import { Container } from '@components-deprecated'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { CommonFormDataContextProvider } from '../../contexts/commonFormDataContext'
import { useInboxOrganizationId } from '../../contexts/inboxOrganizationIdContext'
import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { AccountsContextProvider } from '../app/accounts'
import { contactsRequested } from '../app/contacts/action-creators'
import { UserOrganizationContextProvider } from '../app/organization'
import { accountsRequest, taxRatesRequest } from './action-creators'
import { BillCreate, JournalCreate, PartnerInbox, VoucherView } from './pages'
import { InboxRoutes } from './types'

type InboxProps = {
  customerView?: boolean
  umbrellaId?: string
  userOrganizationId?: string
}

export const Inbox = withRouteLinkClickedListener(({ customerView, umbrellaId, userOrganizationId }: InboxProps) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useInboxOrganizationId()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  // Inbox is used in both umbrella and organization. The path differs for umbrella="/inbox/voucher-inbox/" and org="/"
  const urlPath = umbrellaId ? `${path}/` : path

  useEffect(() => {
    if (customerView && selectedOrganizationId !== userOrganizationId) {
      setSelectedOrganizationId(userOrganizationId)
    }
  }, [customerView, selectedOrganizationId, setSelectedOrganizationId, userOrganizationId])

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    dispatch(taxRatesRequest(selectedOrganizationId))
    dispatch(accountsRequest(selectedOrganizationId))
    dispatch(
      contactsRequested({
        organizationId: selectedOrganizationId,
        page: { offset: 0 },
        filters: { isVendor: true, text: null },
      }),
    )
  }, [dispatch, selectedOrganizationId])

  return (
    <UserOrganizationContextProvider organizationId={selectedOrganizationId}>
      <AccountsContextProvider organizationId={selectedOrganizationId}>
        <CommonFormDataContextProvider>
          <Container>
            <Switch>
              <Route isHashRouter exact path={path}>
                <PartnerInbox customerView={customerView} umbrellaId={umbrellaId} />
              </Route>
              <Route isHashRouter exact path={`${urlPath}:voucherId/${InboxRoutes.ViewVoucher}`}>
                {selectedOrganizationId && <VoucherView customerView={customerView} />}
              </Route>

              <Route isHashRouter exact path={`${urlPath}:voucherId/${InboxRoutes.CreateBill}`}>
                {customerView ? <Redirect to="/" /> : <BillCreate customerView={customerView} />}
              </Route>
              <Route isHashRouter exact path={`${urlPath}:voucherId/${InboxRoutes.CreateJournal}`}>
                {customerView ? <Redirect to="/" /> : selectedOrganizationId && <JournalCreate />}
              </Route>
            </Switch>
          </Container>
        </CommonFormDataContextProvider>
      </AccountsContextProvider>
    </UserOrganizationContextProvider>
  )
})
