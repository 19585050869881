import React, { ReactElement, ReactNode } from 'react'

import { Spacing } from '../../enums/spacing'
import { useForm } from '../Form'
import * as Styled from './styles'
import { GridTemplateValue } from './types/gridTemplateValue'

const DEFAULT_SPACING = Spacing.S
const DEFAULT_ROW_SPACING = Spacing.S
const DEFAULT_COLUMN_SPACING = Spacing.M
const DEFAULT_ITEMS_IN_ROW = 1

export interface FormItemsGroupProps {
  children: ReactNode
  columnSpacing?: Spacing
  itemsTemplate?: GridTemplateValue[]
  itemsInRow?: number
  rowSpacing?: Spacing
  spacing?: Spacing
}

export const FormItemsGroup = ({
  children,
  columnSpacing,
  itemsInRow,
  itemsTemplate,
  rowSpacing,
  spacing,
  ...rest
}: FormItemsGroupProps): ReactElement => {
  const { layout: formLayout } = useForm()

  const spacingSize = spacing || formLayout.spacing || DEFAULT_SPACING
  const rowSpacingSize = rowSpacing || formLayout.rowSpacing || DEFAULT_ROW_SPACING
  const columnSpacingSize = columnSpacing || formLayout.columnSpacing || DEFAULT_COLUMN_SPACING
  const itemsInRowAmount = itemsInRow || formLayout.itemsInRow || DEFAULT_ITEMS_IN_ROW

  return (
    <Styled.FormItemsGroup
      columnSpacing={columnSpacingSize}
      itemsInRow={itemsInRowAmount}
      itemsTemplate={itemsTemplate}
      rowSpacing={rowSpacingSize}
      spacing={spacingSize}
      {...rest}
    >
      {children}
    </Styled.FormItemsGroup>
  )
}
