import { Modal, useModal } from '@design-system'

import React, { ReactElement, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@views/contacts/types/contact'

import { ModalId } from '../../../enums/modalId'
import { ContactModalContextProvider } from './contexts/contactModalContext'
import { ContactTypeSwitcher } from './elements/ContactTypeSwitcher'
import { ModalFooter } from './elements/ModalFooter'
import { SupplierForm } from './elements/SupplierForm'

interface CreateContactModalProps {
  defaultValues?: Partial<Contact>
  isSupplier?: boolean
  onContactCreate?: (contact: Contact) => void
}

const AUTO_FOCUS_TIMEOUT = 1000

export const CreateContactModal = ({
  defaultValues,
  onContactCreate,
  isSupplier = true,
}: CreateContactModalProps): ReactElement => {
  const { t } = useTranslation()
  const modalRef = useRef<HTMLDivElement>(null)
  const { isOpen } = useModal(ModalId.CreateContactModal)

  // Autofocus on first input if contact is not in the organization(defaultValues)
  const autoFocusOnFirstInput = !(defaultValues?.registrationNo && !defaultValues?.id)
  const modalTitle = isSupplier ? t('create_contact_modal.title.vendor') : t('create_contact_modal.title.client')

  useEffect(() => {
    if (isOpen && !autoFocusOnFirstInput) {
      setTimeout(() => {
        const inputs = modalRef?.current?.getElementsByTagName('input')

        // Focus on second input
        inputs?.[1]?.focus()
      }, AUTO_FOCUS_TIMEOUT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Modal autofocusOnInput={autoFocusOnFirstInput} id={ModalId.CreateContactModal} ref={modalRef} size="s">
      <ContactModalContextProvider
        defaultValues={defaultValues}
        isSupplier={isSupplier}
        onContactCreate={onContactCreate}
      >
        <Modal.Header closable id={ModalId.CreateContactModal} title={modalTitle}>
          <ContactTypeSwitcher />
        </Modal.Header>
        <Modal.Body>{isSupplier && <SupplierForm />}</Modal.Body>
        <Modal.Footer>
          <ModalFooter />
        </Modal.Footer>
      </ContactModalContextProvider>
    </Modal>
  )
}
