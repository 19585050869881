import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'

export const organizationSubscriptionPlanTKeys: Record<SubscriptionPlan, string> = {
  [SubscriptionPlan.AccountantPackageSeatBilly]: 'organization_subscription.accountant_package_seat_billy',
  [SubscriptionPlan.AgerasBasic]: 'organization_subscription.ageras_basic',
  [SubscriptionPlan.AgerasComplete]: 'organization_subscription.complete',
  [SubscriptionPlan.AgerasFree]: 'organization_subscription.ageras_free',
  [SubscriptionPlan.AgerasPlus]: 'organization_subscription.ageras_plus',
  [SubscriptionPlan.AgerasStart]: 'organization_subscription.ageras_start',
  [SubscriptionPlan.AgerasAccounting]: 'organization_subscription.agerasAccounting',
  [SubscriptionPlan.APIPartner]: 'organization_subscription.apipartner',
  [SubscriptionPlan.Archive]: 'organization_subscription.archive',
  [SubscriptionPlan.Basic]: 'organization_subscription.basic',
  [SubscriptionPlan.BasicPaid]: 'organization_subscription.basic_paid',
  [SubscriptionPlan.Complete]: 'organization_subscription.complete',
  [SubscriptionPlan.Employee]: 'organization_subscription.employee',
  [SubscriptionPlan.Free]: 'organization_subscription.free',
  [SubscriptionPlan.FriendsofBilly]: 'organization_subscription.friendofbilly',
  [SubscriptionPlan.InternalBasicPaid]: 'organization_subscription.internal_basic_paid',
  [SubscriptionPlan.InternalCore]: 'organization_subscription.internal_core',
  [SubscriptionPlan.InternalCore10PackSeat]: 'organization_subscription.internal_core_10_pack_seat',
  [SubscriptionPlan.InternalPlus]: 'organization_subscription.internal_plus',
  [SubscriptionPlan.InternalPlusExternal]: 'organization_subscription.internal_plus_external',
  [SubscriptionPlan.InternalSmart]: 'organization_subscription.internal_smart',
  [SubscriptionPlan.InternalLicense]: 'organization_subscription.internallicense',
  [SubscriptionPlan.None]: 'organization_subscription.none',
  [SubscriptionPlan.NonProfit]: 'organization_subscription.nonprofit',
  [SubscriptionPlan.Plus]: 'organization_subscription.plus',
  [SubscriptionPlan.PlusTrial]: 'organization_subscription.plus',
  [SubscriptionPlan.Premium]: 'organization_subscription.premium',
  [SubscriptionPlan.PremiumInternal]: 'organization_subscription.premium_internal',
  [SubscriptionPlan.PremiumTest]: 'organization_subscription.premiumtest',
  [SubscriptionPlan.PremiumTrial]: 'organization_subscription.premium',
  [SubscriptionPlan.Pro]: 'organization_subscription.pro',
  [SubscriptionPlan.ProInternal]: 'organization_subscription.pro_internal',
  [SubscriptionPlan.SpecialFree]: 'organization_subscription.specialfree',
}
