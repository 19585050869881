const isError = (errorOrMessage: Error | string | unknown): errorOrMessage is Error => {
  return errorOrMessage instanceof Error
}

export const trackError = (errorOrMessage: Error | string | unknown): void => {
  let error: Error

  if (typeof errorOrMessage === 'string') {
    error = new Error(errorOrMessage)
  } else if (isError(errorOrMessage)) {
    error = errorOrMessage
  } else {
    error = new Error(String(errorOrMessage))
  }

  if (window?.DD_RUM?.addError) {
    window.DD_RUM.addError(error)
    return
  }

  // eslint-disable-next-line no-console
  console.error(error)
}
