import styled from '@emotion/styled'

export const FormHeader = styled.header`
  display: flex;
  align-items: center;
`

export const BillFormWrapper = styled.div``

export const BillLine = styled.li``

export const BillLineTitleWrapper = styled.header`
  display: flex;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
`

export const ItemWrapper = styled.div``
