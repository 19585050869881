import { Color, Icon, pxToNumber, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Input as RebassInput, InputProps as RebassInputProps } from '@rebass/forms'
import debounce from 'lodash/debounce'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from 'rebass'

import { useOnClickOutside } from '../../hooks'
import { reactClass } from '../../utils'

interface IconWrapperProps {
  disabled?: boolean
}

const IconWrapper = styled.figure<IconWrapperProps>`
  display: flex;
  height: 100%;
  position: absolute;
  top: 0;
  right: ${Spacing.M};
  align-items: center;
  z-index: 1;
  cursor: pointer;
`

export type SearchBoxIconProps = RebassInputProps & {
  clearable?: boolean
  disabled?: boolean
  onValueChange: (value: string) => void
  placeholder?: string
  defaultValue?: string
  isActive?: boolean
  isFocused?: boolean
  activeBackgroundOverride?: string
  backgroundOverride?: string
  iconColorOverride?: string
  inActiveIconColorOverride?: string
  color?: string
}

export const SearchBoxIcon = ({
  clearable = true,
  disabled,
  onValueChange,
  placeholder,
  defaultValue,
  activeBackgroundOverride,
  backgroundOverride,
  iconColorOverride,
  inActiveIconColorOverride,
  isActive = false,
  isFocused = false,
  color = 'inherit',
  ...rest
}: SearchBoxIconProps) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLElement>(null)
  const [inputValue, setInputValue] = useState('')
  const [isActivated, setIsActivated] = useState<boolean>(false)
  const debouncedOnChange = useMemo(() => debounce(onValueChange, 250), [onValueChange])

  useOnClickOutside(containerRef, () => {
    if (inputRef.current?.value) {
      return
    }

    setIsActivated(false)
  })

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedOnChange(e.target.value)
      setInputValue(e.target.value)
    },
    [debouncedOnChange],
  )

  const handleClear = () => {
    if (inputRef.current) {
      onValueChange('')
      setInputValue('')
      inputRef.current.value = ''
      inputRef.current.focus()
    }
  }

  const handleToggle = () => {
    if (!isActivated) {
      setIsActivated(true)
      inputRef.current && inputRef.current.focus()
    }
  }

  useEffect(() => {
    if (isFocused) {
      inputRef.current && inputRef.current.focus()
    }
  }, [isFocused])

  const activeOrActivatedInputClass = reactClass('active-or-activated-input')
  return (
    <Box
      ref={containerRef}
      css={css`
        position: relative;
        height: 40px;
      `}
      {...rest}
    >
      <IconWrapper>
        {!inputValue && <Icon icon="magnifyingGlass" onClick={handleToggle} />}
        {!!inputValue && clearable && <Icon icon="xSign" onClick={handleClear} />}
      </IconWrapper>

      <Box
        css={css`
          width: calc(100% + ${pxToNumber(Spacing.S) * 2}px);
          margin: -${Spacing.S};
          padding: ${Spacing.S};
          overflow: hidden;
        `}
      >
        <RebassInput
          className={isActivated || isActive ? activeOrActivatedInputClass : ''}
          ref={inputRef}
          disabled={disabled}
          placeholder={placeholder || `${t('search')}...`}
          onChange={handleOnChange}
          defaultValue={defaultValue || ''}
          css={css`
            padding-right: 30px;
            background: ${isActive ? Color.White : 'transparent'};
            transition: all 200ms ease;

            &.${activeOrActivatedInputClass} {
              pointer-events: inherit;
              transform: translateX(0px);
              opacity: 1;
              width: 120px;
            }
            &:not(.${activeOrActivatedInputClass}) {
              pointer-events: none;
              transform: translateX(80px);
              opacity: 0;
              width: 20px;
            }
          `}
        />
      </Box>
    </Box>
  )
}
