import debounce from 'lodash/debounce'
import { useMemo } from 'react'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { TRACKING_DELAY } from '../../../../../../../../constants/trackingDelay'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { useUpdateDependentLineValues } from '../../hooks/useUpdateDependentLineValues'
import { CurrencyInput } from '../CurrencyInput'

type InvoiceLineUnitPriceProps = CommonInvoiceLineFieldProps

export const InvoiceLineUnitPrice = ({ lineIndex }: InvoiceLineUnitPriceProps) => {
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { handleUnitPriceChange } = useUpdateDependentLineValues(lineIndex)

  const handleDebouncedInputNumberChange = useMemo(
    () =>
      debounce((price: number) => {
        track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Price changed', {
          price,
        })
      }, TRACKING_DELAY),
    [track],
  )

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.unitPrice`}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <CurrencyInput
            {...props}
            onChange={(unitPrice) => {
              handleUnitPriceChange(unitPrice)
              onChange(unitPrice)
              handleDebouncedInputNumberChange(unitPrice)
            }}
            value={value}
          />
        )
      }}
    />
  )
}
