import { DateFormatter } from '@components'
import { amountToDisplayValue, Flex, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useAttachmentPreviewModal } from '../../../../context/attachmentPreviewModalContext'
import { AttachmentInfo } from '../AttachmentInfo'

export const BillDetails = (): ReactElement => {
  const { supplier, documentDate, amount } = useAttachmentPreviewModal()
  const { t } = useTranslation()

  return (
    <Flex flexDirection="column">
      <Text weight="bold">{t('attachment_preview_modal.details.bill_subtitle')}</Text>
      <Space size="xxs" />
      <AttachmentInfo title={t('attachment_preview_modal.details.vendor_label')}>
        <Text>{supplier || '-'}</Text>
      </AttachmentInfo>
      <AttachmentInfo title={t('attachment_preview_modal.details.document_date_label')}>
        <Text>{documentDate ? <DateFormatter value={documentDate} /> : '-'}</Text>
      </AttachmentInfo>
      <AttachmentInfo title={t('attachment_preview_modal.details.amount_label')}>
        <Text>{amount ? amountToDisplayValue(amount) : '-'}</Text>
      </AttachmentInfo>
    </Flex>
  )
}
