import { useBillReconciliationSuggestions } from '../../hooks/useBillReconciliationSuggestions'
import { BillOverviewReconciliationSuggestionContent } from '../BillOverviewReconciliationSuggestionContent'
import { useBillView } from '../BillReadableForm'

export const BillOverviewReconciliationSuggestion = () => {
  const { billData, isFetching: isFetchingBill } = useBillView()
  const { suggestions, isLoading: isLoadingSuggestions } = useBillReconciliationSuggestions(billData?.bill)
  const bill = billData?.bill
  const isLoading = isLoadingSuggestions || isFetchingBill

  if (isLoading || !suggestions?.length || bill?.isPaid) {
    return null
  }

  return <BillOverviewReconciliationSuggestionContent />
}
