import { postRequest } from '../../../utils/api'
import { AccessUrlPayload } from './types/accessUrlPayload'
import { OnboardUserPayload } from './types/onboardUserPayload'

const LENDING_SERVICE_URL = window.ENV.LENDING_SERVICE_URL || ''

export const requestFrodaAccessUrl = async (accessUrlPayload: AccessUrlPayload): Promise<string> => {
  const response = await postRequest(LENDING_SERVICE_URL + '/lending/access-token', accessUrlPayload)
  return response.url
}

export const onboardUserToFroda = async (onboardUserPayload: OnboardUserPayload): Promise<string> => {
  const response = await postRequest(LENDING_SERVICE_URL + '/lending/onboarding', onboardUserPayload)
  return response.url
}
