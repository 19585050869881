import { AspectRatioVariant } from '../enums/aspectRatioVariant'
import { AspectRatio } from '../types'

const scales = {
  [AspectRatioVariant.billAttachment]: 0.7,
}

interface GetRotationStylesProps {
  aspectRatio?: AspectRatio
  fitToWidth?: boolean
  rotation: number
}

const isScalableAspectRatio = (value: string): value is keyof typeof scales => value in scales

export const getRotationStyles = ({ aspectRatio, rotation, fitToWidth }: GetRotationStylesProps) => {
  const isScaleNeeded =
    (rotation === 90 || rotation === 270) && fitToWidth && aspectRatio && isScalableAspectRatio(aspectRatio)
  const scale = isScaleNeeded ? ` scale(${scales[aspectRatio]})` : ''

  return `transform: rotate(${rotation}deg)${scale};`
}
