import { DropzoneFullScreenProtected, DropzoneProtected, ProtectedComponent } from '@components'
import { AspectRatioVariant, Attachment, AttachmentFile, Flex, SkeletonBox, useModal } from '@design-system'

import React, { ReactElement, useCallback, useLayoutEffect } from 'react'
import { FileRejection } from 'react-dropzone'
import { useLocation } from 'react-router-dom'

import { useUploadFiles } from '@features/attachments/hooks/useUploadFiles'

import { ModalId } from '../../../../enums/modalId'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useSegment } from '../../../../hooks'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { useFormContext, useWatch } from '../../../../hooks/useForm'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useBillAttachmentPageContext } from '../../contexts/billAttachmentPageContext'
import { BillState } from '../../enums/billState'
import { BillAttachmentPreviewBlocked } from '../BillAttachmentPreviewBlocked'
import { BillEditableAttachmentsActions } from '../BillEditableAttachmentsActions'
import { BillEditableAttachmentsPreview } from '../BillEditableAttachmentsPreview'
import { useBillForm } from '../BillEditableForm'
import { useBohrAutoComplete } from '../BillEditableForm/hooks/useBohrAutoComplete'
import { BillFormSchema } from '../BillEditableForm/utils/formData'
import { useDeleteAttachment } from './hooks/useDeleteAttachment'

export const BillEditableAttachments = (): ReactElement => {
  const [currentPageIndex, setCurrentPageIndex] = useBillAttachmentPageContext()
  const { control, setValue, getValues } = useFormContext<BillFormSchema>()
  const billState = useWatch({ control, name: 'state' })
  const { billFormView, isFetching } = useBillForm()
  const { track } = useSegment()
  const location = useLocation()
  const { deleteAttachment } = useDeleteAttachment()
  const { autoCompleteWithBohrData, resetAutoCompletedWithBohrData } = useBohrAutoComplete()
  const { url: billsNewUrl } = useOrganizationViewUrl(reactRoute.bills.getNewBillRoute())
  const { isOpen: isChooseFromUploadsModalOpen } = useModal(ModalId.AttachmentsModal)

  const isNewBillRoute = location.pathname === billsNewUrl
  const attachmentsFiles = useWatch({ control, name: 'attachmentsFiles', defaultValue: [] })

  const setFormAttachmentsFiles = useCallback(
    (file: AttachmentFile) => {
      const currentAttachmentsFiles = getValues('attachmentsFiles') || []
      const shouldAutoCompleteForm = billState !== BillState.Approved
      setValue('attachmentsFiles', currentAttachmentsFiles.concat(file), { shouldDirty: true })
      setCurrentPageIndex(currentAttachmentsFiles.length)

      if (file.attachmentId) {
        const currentattachmentsFilesChosen = getValues('attachmentsFilesChosen') || []
        setValue('attachmentsFilesChosen', [...currentattachmentsFilesChosen, file])
      }

      if (shouldAutoCompleteForm && !currentAttachmentsFiles.length && isAuthorized(Scope.VoucherScanRead)) {
        autoCompleteWithBohrData(file)
      }
    },
    [getValues, billState, setValue, setCurrentPageIndex, autoCompleteWithBohrData],
  )

  const { isUploading, uploadFiles } = useUploadFiles({
    onUpload(file: AttachmentFile) {
      setFormAttachmentsFiles(file)
    },
    onSuccess() {
      track('File Uploaded (Client)', {
        context: TrackingContext.CreateBillUpload,
      })

      track('xxx - expense - create expense - receipt uploaded', { billPage: billFormView })
    },
    options: {
      'x-should-scan': true,
    },
  })

  const handleFilesSelected = useCallback(
    (files: File[]) => {
      uploadFiles(files)
    },
    [uploadFiles],
  )

  const handleAttachmentSelected = useCallback(
    (attachment: Attachment) => {
      setFormAttachmentsFiles({ attachmentId: attachment.id, ...attachment.file })
    },
    [setFormAttachmentsFiles],
  )

  const handleFilesDropped = useCallback(
    (filesAccepted: File[], filesRejected?: FileRejection[]) => {
      uploadFiles(filesAccepted, filesRejected)
    },
    [uploadFiles],
  )

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      const { attachmentsFilesUpdated } = deleteAttachment(fileId)

      if (!attachmentsFilesUpdated.length) {
        resetAutoCompletedWithBohrData()
      }

      if (currentPageIndex > 0) {
        setCurrentPageIndex(currentPageIndex - 1)
      }
    },
    [deleteAttachment, currentPageIndex, resetAutoCompletedWithBohrData, setCurrentPageIndex],
  )

  useLayoutEffect(() => {
    if (isNewBillRoute) {
      setCurrentPageIndex(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewBillRoute])

  return (
    <Flex flexDirection="column">
      {isFetching ? (
        <SkeletonBox aspectRatio={AspectRatioVariant.billAttachment} />
      ) : (
        <>
          {!isUploading && !!attachmentsFiles?.length ? (
            <ProtectedComponent scopes={Scope.BillAttachmentRead} unauthorizedNode={<BillAttachmentPreviewBlocked />}>
              <BillEditableAttachmentsPreview
                onDelete={handleDeleteFile}
                onEdit={handleAttachmentSelected}
                files={attachmentsFiles}
              />
            </ProtectedComponent>
          ) : (
            <div style={{ height: '600px' }}>
              <DropzoneProtected
                scopes={Scope.BillAttachmentWrite}
                onDrop={handleFilesDropped}
                isUploading={isUploading}
                trackingContext={TrackingContext.CreateBillUpload}
                customTrigger={
                  <BillEditableAttachmentsActions
                    onAttachmentSelect={handleAttachmentSelected}
                    onFilesSelect={handleFilesSelected}
                  />
                }
              />
            </div>
          )}
        </>
      )}

      {!isChooseFromUploadsModalOpen && (
        <DropzoneFullScreenProtected scopes={Scope.BillAttachmentWrite} onDrop={handleFilesDropped} />
      )}
    </Flex>
  )
}
