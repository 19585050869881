import { Badge } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const BetaBadge = (): ReactElement => {
  const { t } = useTranslation()

  return <Badge>{t('beta')?.toUpperCase()}</Badge>
}
