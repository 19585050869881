import { Route } from '@components'

import { ReactElement } from 'react'
import { Switch } from 'react-router-dom'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { QuotesRoute } from './enums/quotesRoute'
import { QuotesList } from './routes/QuotesList'
import { defaultQuery } from './routes/QuotesList/hooks/useQuotesListFilters'
import { QuotesListEmpty } from './routes/QuotesListEmpty'

export const Quotes = withRouteLinkClickedListener((): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultQuery),
  })

  return (
    <Switch>
      <Route isHashRouter component={QuotesList} exact path={QuotesRoute.List} />
      <Route isHashRouter component={QuotesListEmpty} exact path={QuotesRoute.ListEmpty} />
    </Switch>
  )
})
