import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { DEFAULT_LOCALE } from '../../../constants/defaultLocale'
import { SortDirection } from '../../../enums/sortDirection'
import { useQueryParams } from '../../../hooks/useQueryParams'
import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { getCurrentLocale } from '../../../utils'
import { ContactBalanceSortProperty } from '../enums/contactBalanceSortProperty'
import { getQueryDate } from '../utils/getQueryDate'

interface ContactBalanceListFilters {
  acceptLanguage: LocaleIdentifier
  date?: string
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: ContactBalanceSortProperty
}

type UseContactBalanceListFiltersResponse = [
  ContactBalanceListFilters,
  (filters: Partial<ContactBalanceListFilters>) => void,
]

export const defaultQuery: ContactBalanceListFilters = {
  acceptLanguage: DEFAULT_LOCALE,
  date: getQueryDate(),
  page: 1,
  pageSize: undefined,
  searchQuery: undefined,
  sortDirection: SortDirection.Asc,
  sortProperty: ContactBalanceSortProperty.ContactName,
}

export const useContactBalanceListFilters = (): UseContactBalanceListFiltersResponse => {
  const locale = getCurrentLocale()
  const [pageSize] = useItemsPerPage()
  const [queryParams, setQueryParams] = useQueryParams<ContactBalanceListFilters>({
    defaultQuery: {
      ...defaultQuery,
      acceptLanguage: locale,
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `pageSize` comes as string. It needs to be always `number` type.
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
