import { useModalMultiple } from '@design-system'

import { useMutation } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../enums/modalId'
import { getCurrentLocale } from '../../../utils'
import { localeToLanguage } from '../../../utils/localeToLanguage'
import { syncBankConnection } from '../query-api'

export const useSyncBankConnection = () => {
  const locale = getCurrentLocale()
  const { organization } = useUserOrganization()
  const { open: openModal, close: closeModal } = useModalMultiple([
    ModalId.BankConnectionErrorModal,
    ModalId.BankConnectionSuccessModal,
    ModalId.BankConnectionExpiredModal,
  ])

  const { mutate: syncAccountBankConnection, isLoading } = useMutation(
    (aiiaBankAccountId: string) =>
      syncBankConnection({
        aiiaBankAccountId,
        locale: localeToLanguage[locale],
        organizationId: organization?.id as string,
        redirectUrl: window.location.href,
      }),
    {
      onSuccess: ({ authUrl }) => {
        if (authUrl) {
          window.location.replace(authUrl)
        } else {
          closeModal(ModalId.BankConnectionExpiredModal)
          openModal(ModalId.BankConnectionSuccessModal)
        }
      },
      onError: () => openModal(ModalId.BankConnectionErrorModal),
    },
  )

  return {
    isLoading,
    syncAccountBankConnection,
  }
}
