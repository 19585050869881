import React, { memo, MouseEvent, ReactElement, useCallback, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useKey } from 'react-use'

import { PORTAL_CLASS_NAME } from '../../../../constants/portalClassName'
import { Icon } from '../../../Icon'
import { PreviewFile } from '../../types'
import { FilesPreviewContent } from '../FilesPreviewContent'
import * as Styled from './styles'

interface FullScreenPreviewProps {
  className: string
  files: PreviewFile[]
  onClose?: () => void
  visible?: boolean
}

export const FullScreenPreview = memo(
  ({ className, onClose, files, visible }: FullScreenPreviewProps): ReactElement => {
    const contentRef = useRef<HTMLDivElement>(null)

    const handleEscPress = useCallback(() => {
      if (visible) {
        onClose && onClose()
      }
    }, [onClose, visible])

    const handleBackgroundClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        const clickedElement = event.target as HTMLElement

        if (
          contentRef.current &&
          contentRef.current !== clickedElement &&
          !contentRef.current.contains(clickedElement)
        ) {
          onClose && onClose()
        }
      },
      [contentRef, onClose],
    )

    useKey('Escape', handleEscPress, undefined, [visible])

    return ReactDOM.createPortal(
      <Styled.FullScreenWrapper className={PORTAL_CLASS_NAME} onClick={handleBackgroundClick} visible={visible}>
        <Styled.ModalWrapper ref={contentRef}>
          <Styled.CloseWrapper onClick={onClose}>
            <Icon icon="xSign" size="large" />
          </Styled.CloseWrapper>
          <FilesPreviewContent
            className={className}
            files={files}
            fitToHeight
            loading=""
            width="100%"
            withDownloadButton
            withRotate
          />
        </Styled.ModalWrapper>
      </Styled.FullScreenWrapper>,
      document.body,
    )
  },
)
