import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import { useCallback } from 'react'

import { useFormContext } from '../../../../../../../../../../../../../../../hooks'
import { Product } from '../../../../../../../../../../../../../types/product'
import { InvoiceUnit } from '../../../../../../../../../../../enums/invoiceUnit'
import { useGetVatRate } from '../../../../../../../../../../../hooks/useGetVatRate'
import { InvoiceFormSchema } from '../../../../../../../../../../../types/invoiceFormSchema'
import { isInvoiceUnitType } from '../elements/InvoiceLineProductName/utils/isInvoiceUnitType'
import { getAmountExcludingVat } from '../utils/getAmountExcludingVat'
import { getAmountIncludingVat } from '../utils/getAmountIncludingVat'
import { getTotalAmount } from '../utils/getTotalAmount'
import { getUnitPrice } from '../utils/getUnitPrice'

export const useUpdateDependentLineValues = (lineIndex: number) => {
  const { getValues, setValue } = useFormContext<InvoiceFormSchema>()
  const { getVatRateById, getVatRateByPredefinedTag } = useGetVatRate()

  const handleProductChange = useCallback(
    (product: Partial<Product> | undefined) => {
      if (isEmpty(product)) {
        return
      }

      const productVatRate = getVatRateByPredefinedTag(product?.vatRateId)
      const { quantity } = getValues('invoiceLines')[lineIndex]

      setValue(`invoiceLines.${lineIndex}.productName`, product?.name)
      setValue(`invoiceLines.${lineIndex}.productType`, product?.productType)
      setValue(`invoiceLines.${lineIndex}.unitPrice`, product?.price)
      setValue(
        `invoiceLines.${lineIndex}.totalExcludingVat`,
        product?.price ? getTotalAmount(product.price, quantity) : 0,
      )
      setValue(
        `invoiceLines.${lineIndex}.totalIncludingVat`,
        product?.total ? getTotalAmount(product.total, quantity) : 0,
      )
      setValue(`invoiceLines.${lineIndex}.vatRateId`, productVatRate?.id)
      setValue(`invoiceLines.${lineIndex}.vatRatePercent`, productVatRate?.rate)
      setValue(`invoiceLines.${lineIndex}.unit`, isInvoiceUnitType(product?.unit) ? product.unit : InvoiceUnit.Unit)
    },
    [getValues, getVatRateByPredefinedTag, lineIndex, setValue],
  )

  const handleUnitPriceChange = useCallback(
    (unitPrice?: number) => {
      if (isNil(unitPrice)) {
        return
      }

      const { quantity, vatRatePercent } = getValues('invoiceLines')[lineIndex]
      const totalExcludingVat = getTotalAmount(unitPrice, quantity)
      const totalIncludingVat = getAmountIncludingVat(totalExcludingVat, vatRatePercent)

      setValue(`invoiceLines.${lineIndex}.totalExcludingVat`, totalExcludingVat)
      setValue(`invoiceLines.${lineIndex}.totalIncludingVat`, totalIncludingVat)
    },
    [getValues, lineIndex, setValue],
  )

  const handleQuantityChange = useCallback(
    (quantity?: number) => {
      if (isNil(quantity)) {
        return
      }

      const { unitPrice, vatRatePercent } = getValues('invoiceLines')[lineIndex]

      const totalExcludingVat = getTotalAmount(unitPrice, quantity)
      const totalIncludingVat = getAmountIncludingVat(totalExcludingVat, vatRatePercent)

      setValue(`invoiceLines.${lineIndex}.totalExcludingVat`, totalExcludingVat)
      setValue(`invoiceLines.${lineIndex}.totalIncludingVat`, totalIncludingVat)
    },
    [getValues, lineIndex, setValue],
  )

  const handleTaxExcludingVatChange = useCallback(
    (totalExcludingVat?: number) => {
      if (isNil(totalExcludingVat)) {
        return
      }

      const { vatRatePercent } = getValues('invoiceLines')[lineIndex]
      const totalIncludingVat = getAmountIncludingVat(totalExcludingVat, vatRatePercent)

      setValue(`invoiceLines.${lineIndex}.totalIncludingVat`, totalIncludingVat)
    },
    [getValues, lineIndex, setValue],
  )

  const handleTaxIncludingVatChange = useCallback(
    (totalIncludingVat?: number) => {
      if (isNil(totalIncludingVat)) {
        return
      }

      const { quantity, vatRatePercent } = getValues('invoiceLines')[lineIndex]
      const totalExcludingVat = getAmountExcludingVat(totalIncludingVat, vatRatePercent)
      const unitPrice = getUnitPrice(totalExcludingVat, quantity)

      setValue(`invoiceLines.${lineIndex}.totalExcludingVat`, totalExcludingVat)
      setValue(`invoiceLines.${lineIndex}.unitPrice`, unitPrice)
    },
    [getValues, lineIndex, setValue],
  )

  const handleVatRateChange = useCallback(
    (vatRateId?: string) => {
      if (isNil(vatRateId)) {
        return
      }

      const vatRate = getVatRateById(vatRateId)

      if (vatRate) {
        const { totalExcludingVat } = getValues('invoiceLines')[lineIndex]
        const totalIncludingVat = getAmountIncludingVat(totalExcludingVat, vatRate.rate)

        setValue(`invoiceLines.${lineIndex}.vatRateId`, vatRate.id)
        setValue(`invoiceLines.${lineIndex}.vatRatePercent`, vatRate.rate)
        setValue(`invoiceLines.${lineIndex}.totalIncludingVat`, totalIncludingVat)
      }
    },
    [getValues, getVatRateById, lineIndex, setValue],
  )

  return {
    handleProductChange,
    handleQuantityChange,
    handleTaxExcludingVatChange,
    handleTaxIncludingVatChange,
    handleUnitPriceChange,
    handleVatRateChange,
  }
}
