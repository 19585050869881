import { getGlobalBilly } from './globalBilly'
import { trackError } from './trackError'

let hashRouteParams: Record<string, string> = {}
let routeParams: Record<string, string> = {}

export const getEmberRouteParams = () => {
  try {
    const billyApp = getGlobalBilly()
    const emberRouter = billyApp?.__container__?.lookup('router:main')?.router

    if (emberRouter?.state?.params) {
      return { ...emberRouter.state.params }
    }
  } catch (error) {
    trackError(error)
  }

  return {}
}

export const getReactHashRouteParams = () => {
  if (!window.location.hash) {
    hashRouteParams = {}
  }

  return { ...hashRouteParams }
}

export const getReactRouteParams = () => {
  return { ...routeParams }
}

export const setReactHashRouteParams = (params: Record<string, string>) => {
  hashRouteParams = { ...params }
}

export const setReactRouteParams = (params: Record<string, string>) => {
  routeParams = { ...params }
}

export const getRouteParams = () => {
  return { ...getEmberRouteParams(), ...getReactHashRouteParams(), ...getReactRouteParams() }
}
