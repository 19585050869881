import { ContactSelect } from '@components'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Contact } from '@views/contacts/types/contact'

import { BillFormFieldsChangeSource } from '../../../../../../enums/billFormFieldsChangeSource'
import { useFormContext, useWatch } from '../../../../../../hooks'
import { BillState } from '../../../../enums/billState'
import { useBillForm } from '../../contexts/billFormContext'
import { useVendorAutoComplete } from '../../hooks/useVendorAutoComplete'
import { BillFormSchema } from '../../utils/formData'

interface BillVendorProps {
  onCreate?: () => void
  withReferenceNumberField?: boolean
}

export const BillVendor = ({ onCreate, withReferenceNumberField }: BillVendorProps): ReactElement => {
  const { t } = useTranslation()
  const { control, FormItem } = useFormContext<BillFormSchema>()
  const { isCreditNoteFromBill } = useBillForm()
  const billState = useWatch({ control, name: 'state' })
  const creditedBillId = useWatch({ control, name: 'creditedBillId' })
  const { autoCompleteWithVendorData } = useVendorAutoComplete()

  const handleContactChange = useCallback(
    // both adding and removing vendor cause the form to change autocompleted values, to remove or change it accordingly
    (contact: Partial<Contact> | undefined) => {
      autoCompleteWithVendorData(contact, BillFormFieldsChangeSource.Vendor)
    },
    [autoCompleteWithVendorData],
  )

  return (
    <FormItem
      name="vendor"
      label={t('bill.edit.form.vendor')}
      itemColumnStart={withReferenceNumberField ? 1 : undefined}
      itemColumnEnd={withReferenceNumberField ? 4 : undefined}
      render={({ field: { value, onChange, ...props } }) => (
        <ContactSelect
          {...props}
          allowClear
          allowCreateNew
          isSupplier
          onCreateNew={onCreate}
          onSelect={(id?: string, value?: Partial<Contact>) => {
            onChange(value)
            handleContactChange(value)
          }}
          selectedId={value?.id}
          selectedValue={value}
          disabled={billState === BillState.Approved || isCreditNoteFromBill || !!creditedBillId}
          withSearch
        />
      )}
    />
  )
}
