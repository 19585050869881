import { Button } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../../../hooks'
import { useDownloadInvoiceAsPdf } from '../../../../../../hooks/useDownloadInvoiceAsPdf'
import { useInvoice } from '../../../../contexts/invoiceContext'

export const DownloadInvoiceAsPdfButton = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { invoice, documentType, invoiceId } = useInvoice()
  const { download, isDownloading } = useDownloadInvoiceAsPdf()

  const handleDownloadAsPDFButtonClick = useCallback(() => {
    download({ documentType, invoiceId, invoiceNumber: invoice?.invoiceNumber })
    track('XXX - Invoicing Core - Invoice View - Download as pdf button clicked')
  }, [track, download, documentType, invoiceId, invoice?.invoiceNumber])

  return (
    <Button
      icon="arrowPointingDown"
      loading={isDownloading}
      onClick={handleDownloadAsPDFButtonClick}
      variant="secondary"
    >
      {t('external_invoices.view.download_as_pdf')}
    </Button>
  )
}
