import { useMutation } from 'react-query'
import { APIError } from 'src/utils'

import { createPrepayment, CreatePrepaymentPayload, CreatePrepaymentResponseData } from '../query-api'

interface UseCreatePrepaymentProps {
  onError?: (error?: APIError, variables?: CreatePrepaymentPayload) => void
  onSuccess?: (data: CreatePrepaymentResponseData) => void
}

export const useCreatePrepayment = (props?: UseCreatePrepaymentProps) => {
  const { onError, onSuccess } = props || {}
  const {
    mutate: create,
    isLoading: isProcessing,
    ...rest
  } = useMutation(createPrepayment, {
    onSuccess,
    onError,
  })

  return { create, isProcessing, ...rest }
}
