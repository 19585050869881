import { DateInputLocalized } from '@components'
import { FormItem, Text } from '@design-system'

import { t } from 'i18next'
import { ReactElement, useEffect } from 'react'

import { useBillReconciliation } from '../../contexts/billReconciliationContext'

export const PaymentDateInput = (): ReactElement => {
  const { paymentDate, setPaymentDate } = useBillReconciliation()

  useEffect(() => {
    setPaymentDate(new Date())
  }, [setPaymentDate])

  return (
    <FormItem
      label={t('bill.payment_reconcile.payment_date')}
      error={
        !paymentDate ? (
          <Text colorVariant="danger">{t('bill.payment_reconcile.payment_date_required')}</Text>
        ) : undefined
      }
    >
      <DateInputLocalized
        allowClear={true}
        value={paymentDate ? new Date(paymentDate) : undefined}
        onChange={setPaymentDate}
      />
    </FormItem>
  )
}
