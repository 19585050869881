import { TFunction } from 'i18next'
import { z } from 'zod'

import { SendAsEmailFormSchema } from '../types/SendAsEmailFormSchema'

export const getSendAsEmailFormSchema = (t: TFunction): SendAsEmailFormSchema => {
  return z.object({
    emailSubject: z.string(),
    emailMessage: z.string(),
  })
}
