import { Attachment, Modal, ModalProps } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { AttachmentPreviewModalBody } from './AttachmentPreviewModalBody'
import { AttachmentPreviewModalContextProvider } from './context/attachmentPreviewModalContext'

interface AttachmentPreviewModalProps extends ModalProps {
  attachment: Attachment
  onFileSelect?: () => void
  setDescription?: (description: string) => void
}

export const AttachmentPreviewModal = ({
  attachment,
  id: modalId,
  onFileSelect,
}: AttachmentPreviewModalProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <AttachmentPreviewModalContextProvider attachment={attachment} modalId={modalId} onFileSelect={onFileSelect}>
      <Modal id={modalId} autofocusOnInput size="xl">
        <Modal.Header title={t('attachment_preview_modal.title')} />
        <Modal.Body>
          <AttachmentPreviewModalBody />
        </Modal.Body>
      </Modal>
    </AttachmentPreviewModalContextProvider>
  )
}
