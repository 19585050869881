import { Divider, SectionMessage, Space } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { useBankLines } from '../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillAccountSelector } from '../BillAccountSelector'
import { BillSummary } from '../BillSummary'
import { DifferenceSelector } from '../DifferenceSelector'
import { BankLines } from './elements/BankLines'
import { BankLinesActions } from './elements/BankLinesActions'
import { LinkToManualPayment } from './elements/LinkToManualPayment'

export const BankLinesModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { setPaymentDate } = useBillReconciliation()
  const { bankLinesDifference, hasBankLinesDifference, newestBankLineDate } = useBankLines()
  const { isCreditNoteSelected } = useMultipleBills()

  const showDifferenceSelector = hasBankLinesDifference && !isCreditNoteSelected
  const showExactAmountError = hasBankLinesDifference && isCreditNoteSelected

  useEffect(() => {
    track('xxx - expenses - Bank line modal opened')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (newestBankLineDate) {
      setPaymentDate(new Date(newestBankLineDate))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newestBankLineDate])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <BillAccountSelector />
      <Space />
      <BankLinesActions />
      <BankLines />
      {showDifferenceSelector && <DifferenceSelector difference={bankLinesDifference} />}
      {showExactAmountError && (
        <>
          <Space />
          <SectionMessage closable={false} icon="exclamationMarkCircle" variant="error">
            {t('bill.payment_reconcile.not_exact_match_error')}
          </SectionMessage>
        </>
      )}
      <LinkToManualPayment />
    </>
  )
}
