import { css, keyframes } from '@emotion/core'

const ANIMATION_TIME_MS = 1800

const getRotationKeyframes = () => keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const rotateAnimation = () => css`
  animation: ${getRotationKeyframes()} ${ANIMATION_TIME_MS}ms linear infinite;
`
