import { DateInputLocalized } from '@components'
import { IconButton, Space } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from 'rebass'

type DatePeriodChooserProps = {
  fromDate: Date | null
  toDate: Date | null
  onChangeFromDate: (fromDate: Date | null) => void
  onChangeToDate: (toDate: Date | null) => void
}

/**
 *
 */
export const DatePeriodChooser = ({ fromDate, toDate, onChangeFromDate, onChangeToDate }: DatePeriodChooserProps) => {
  const { t } = useTranslation()

  return (
    <Box>
      <Flex flexDirection={'row'} alignItems="center">
        <Box style={{ width: 80 }}>{t('date.from')}</Box>
        <DateInputLocalized
          datePickerPlacement="bottom-end"
          onChange={(date) => onChangeFromDate(date || null)}
          value={fromDate || undefined}
        />
        <Space horizontal size="xs" />
        <Flex style={{ flexShrink: 0 }}>
          <IconButton icon="trash" variant="text" size="l" onClick={() => onChangeFromDate(null)} />
        </Flex>
      </Flex>
      <Flex flexDirection={'row'} alignItems="center" mt={2}>
        <Box style={{ width: 80 }}>{t('date.to')}</Box>
        <DateInputLocalized
          datePickerPlacement="bottom-end"
          onChange={(date) => onChangeToDate(date || null)}
          value={toDate || undefined}
        />
        <Space horizontal size="xs" />
        <Flex style={{ flexShrink: 0 }}>
          <IconButton icon="trash" variant="text" size="l" onClick={() => onChangeToDate(null)} />
        </Flex>
      </Flex>
    </Box>
  )
}
