import {
  Button,
  Flex,
  FormItemsGroup,
  Input,
  notify,
  Radio,
  RadioGroup,
  SectionMessage,
  Space,
  Spacing,
  Text,
} from '@design-system'

import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useEmberRouter } from '../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { useEInvoiceContext } from '../../../../context/eInvoiceContext'
import { useSendInvoiceModal } from '../../../../context/sendInvoiceModalContext'
import { Identifier } from '../../../../enums/identifier'
import { useUpdateContact } from '../../../../hooks/useUpdateContact'
import { useVerifyRecipientIdentifier } from '../../../../hooks/useVerifyRecipientIdentifier'
import { VerificationResponse } from '../../../../query-api'
import { SendEInvoiceFormSchema } from '../../../../utils/eInvoiceFormData'
import { isForeignGln } from '../../../RegisterInternationallyFormModal/utils/isForeignGln'
import { CVR_LENGTH } from '../../constants/cvrLength'
import { GLN_LENGTH } from '../../constants/glnLength'
import { CvrFormItem } from '../../elements/CvrFormItem'
import { GlnFormItem } from '../../elements/GlnFormItem'
import * as Styled from './styles'

export const SendEInvoiceForm = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { navigate } = useEmberRouter()
  const { setIsIdentifierVerified, setIsIdentifierChecked, isPeppolVerified } = useEInvoiceContext()
  const { FormItem, control, setValue } = useFormContext<SendEInvoiceFormSchema>()
  const identifierType = useWatch({ control, name: 'identifierType' })
  const glnInput = useWatch({ control, name: 'gln' })
  const cvrInput = useWatch({ control, name: 'cvr' })

  const [canUpdateContactGln, setCanUpdateContactGln] = useState(false)

  const { contactId, contactEAN } = useSendInvoiceModal()

  const { updateContact } = useUpdateContact({
    onError: () => {
      notify({ id: NotificationKeys.UpdateContact, message: t('error_saving'), variant: 'error' })

      setIsIdentifierVerified(false)
    },
    onSuccess: () => {
      notify({
        id: NotificationKeys.UpdateContact,
        message: t('modal.send_invoice.contact_updated'),
        variant: 'success',
      })
      setCanUpdateContactGln(false)
    },
  })

  const { verify } = useVerifyRecipientIdentifier({
    onError: () => {
      setIsIdentifierVerified(false)
      setIsIdentifierChecked(true)
    },
    onSuccess: (response: VerificationResponse) => {
      const isIdentifierVerified = !!response.isVerified
      const isGlnUpdated = contactEAN !== glnInput
      const isUsingGln = identifierType === Identifier.GLN
      setCanUpdateContactGln(isGlnUpdated && isUsingGln && !!response.isVerified)
      setIsIdentifierVerified(isIdentifierVerified)
      setIsIdentifierChecked(true)
    },
  })

  // Should only set on component render
  useEffect(() => {
    setValue('gln', contactEAN)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (organization?.id && identifierType === Identifier.CVR && cvrInput?.length === CVR_LENGTH) {
      verify({ organizationId: organization.id, identifierType, cvr: cvrInput })
    }
  }, [cvrInput, identifierType, organization?.id, verify])

  useEffect(() => {
    // additional validation before sendig to backend
    if (identifierType === Identifier.GLN && glnInput?.length !== GLN_LENGTH) {
      setIsIdentifierVerified(false)
    }

    if (identifierType === Identifier.GLN && glnInput && isForeignGln(glnInput) && !isPeppolVerified) {
      setIsIdentifierVerified(true)
      setIsIdentifierChecked(true)
      return
    }

    if (organization?.id && identifierType === Identifier.GLN && glnInput?.length === GLN_LENGTH) {
      setIsIdentifierChecked(false)
      verify({ organizationId: organization.id, identifierType, gln: glnInput })
    }
  }, [
    glnInput,
    identifierType,
    organization?.id,
    setIsIdentifierChecked,
    setIsIdentifierVerified,
    verify,
    isPeppolVerified,
  ])

  const handleUpdateContactWithGln = useCallback(() => {
    if (glnInput) {
      updateContact({ contactId, contactEan: glnInput })
    }
  }, [contactId, glnInput, updateContact])

  const handleAddRegistrationNoClick = useCallback(() => {
    navigate(EmberRoute.SettingsOrganization)
  }, [navigate])

  const isMissingRegistrationNo = useMemo(() => {
    const registrationNo = organization?.registrationNo

    return !registrationNo || registrationNo.length < 8
  }, [organization?.registrationNo])

  return (
    <>
      {isMissingRegistrationNo && (
        <>
          <SectionMessage variant="error" showIcon={false}>
            <Flex alignContent="space-between">
              <Flex flexDirection="column" justifyContent="center">
                <Text inherit>{t('modal.send_invoice.missing.registration_no')}</Text>
              </Flex>

              <Styled.ButtonWrapper>
                <Button variant="secondary" onClick={handleAddRegistrationNoClick}>
                  {t('modal.send_invoice.btn.missing.registration_no')}
                </Button>
              </Styled.ButtonWrapper>
            </Flex>
          </SectionMessage>

          <Space />
        </>
      )}

      <FormItemsGroup rowSpacing={Spacing.XL}>
        <FormItem
          name="identifierType"
          render={({ field: { value, onChange, ...props } }) => (
            <RadioGroup
              spacing={Spacing.XXL}
              {...props}
              value={value}
              onChange={onChange}
              defaultValue={identifierType}
            >
              <Radio value="gln">{t('modal.send_invoice.form.radio.gln')}</Radio>
              <Radio value="cvr">{t('modal.send_invoice.form.radio.cvr')}</Radio>
            </RadioGroup>
          )}
        />

        {/*
         CvrFormItem and GlnFormItem either return ReactElement or null. Both will not return ReactElement at the same time
         Done like this, as having conditionally FormItems, rendered via ternary statement, does not work in this case, as both fields will always show the same value.
         */}
        <CvrFormItem />

        <GlnFormItem
          canUpdateContactGln={canUpdateContactGln}
          handleUpdateContactWithGln={handleUpdateContactWithGln}
        />

        <FormItem
          label={t('modal.send_invoice.form.order_reference.label')}
          name="orderReference"
          render={({ field }) => <Input {...field} />}
        />
      </FormItemsGroup>
    </>
  )
}
