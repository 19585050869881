import { Icon } from '@design-system'

import * as Styled from './styles'

interface CalendarButtonProps {
  disabled?: boolean
  onClick: () => void
}

export const CalendarButton = ({ disabled, onClick }: CalendarButtonProps) => (
  <Styled.CalendarButton onClick={onClick} disabled={disabled}>
    <Icon icon="calendar" />
  </Styled.CalendarButton>
)
