import { Color } from '@design-system'

import styled from '@emotion/styled'

import { Text as TextComponent } from '../../../Typography'

export const SavingContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Color.White};
`

export const Text = styled(TextComponent)`
  font-size: 16px;
`
