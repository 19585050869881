import { Button } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment, useZervantNavigation } from '../../../../../../../../hooks'
import { InvoiceEditorVersion } from '../../../../../../enums/invoiceEditorVersion'
import { useInvoice } from '../../../../contexts/invoiceContext'

export const ExternalViewInvoiceButton = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { invoiceId, invoice } = useInvoice()
  const { redirectToZervantInvoiceView } = useZervantNavigation()

  const handleButtonClick = useCallback(() => {
    track('XXX - Invoicing Core - Access invoice view in Zervant clicked')
    redirectToZervantInvoiceView(invoiceId)
  }, [invoiceId, redirectToZervantInvoiceView, track])

  if (!invoice || invoice.version === InvoiceEditorVersion.Ageras) {
    return null
  }

  return (
    <Button onClick={handleButtonClick} variant="secondary">
      {t('external_invoices.open_zervant_invoice_button.cta')}
    </Button>
  )
}
