import { useMemo } from 'react'

import { Scope } from '../../../../../enums/scope'
import { useOrganizationSource } from '../../../../../hooks'
import { isAuthorized } from '../../../../../utils/isAuthorized'

export const useShouldDisplayInvoicingSettingsTab = () => {
  const { isSourceBilly, isSourceZervant } = useOrganizationSource()

  const shouldDisplayInvoicingSettingsTab = useMemo(() => {
    if (isSourceBilly) {
      return isAuthorized(Scope.InvoiceWrite)
    } else if (isSourceZervant) {
      return true
    } else {
      return false
    }
  }, [isSourceBilly, isSourceZervant])

  return { shouldDisplayInvoicingSettingsTab }
}
