import { addDays } from 'date-fns'

import { convertDateToUTC } from '../../../../../../utils'
import { InvoiceDocumentType } from '../../../../enums/invoiceDocumentType'
import { Language } from '../../../../types/language'
import { ZervantSettingsModel } from '../../../../types/zervantSettingsModel'
import { MOCK_VISIBLE_COLUMNS } from '../../constants/mockVisibleColumns'
import { InvoiceForm } from '../../types/invoiceForm'
import { InvoicePayloadFields } from '../../types/invoicePayload/invoicePayloadFields'
import { getInvoiceNumberDisplayedValue } from '../getInvoiceNumberDisplayedValue'
import { getInvoiceLocale } from './getInvoiceLocale'

const getDueDate = (issueDate: Date, paymentTermDays: number) => {
  const dueDate = addDays(convertDateToUTC(issueDate), paymentTermDays)

  return dueDate.toISOString()
}

export const getFieldsPayload = (
  formValues: InvoiceForm,
  invoiceLanguage: Language,
  zervantSettingsModels?: ZervantSettingsModel[],
): InvoicePayloadFields => {
  const { footerNote, invoiceNumber, issueDate, message, paymentTerm, invoiceNumberPrefix } = formValues
  const invoiceNumberValue = getInvoiceNumberDisplayedValue(invoiceNumber, invoiceNumberPrefix)
  const invoiceModel = (zervantSettingsModels || []).find((model) => model.type === InvoiceDocumentType.Invoice)
  const titleField = (invoiceModel?.fields || []).find((field) => field?.name === 'title')
  const invoiceLocale = getInvoiceLocale(invoiceLanguage)

  return {
    locale: {
      name: 'locale',
      value: invoiceLocale,
    },
    footerText: {
      name: 'footerText',
      value: footerNote,
    },
    description: {
      name: 'description',
      value: message,
    },
    issueDate: {
      name: 'issueDate',
      value: convertDateToUTC(issueDate).toISOString(),
    },
    paymentTime: {
      name: 'paymentTime',
      value: paymentTerm,
    },
    dueDate: {
      name: 'dueDate',
      value: getDueDate(issueDate, paymentTerm),
    },
    invoiceNumber: {
      name: 'invoiceNumber',
      value: invoiceNumberValue,
    },
    title: {
      name: 'title',
      value: String(titleField?.default || ''),
    },
    columns: {
      name: 'columns',
      value: MOCK_VISIBLE_COLUMNS,
    },
  }
}
