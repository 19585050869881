import { Language } from '../types/language'
import { LocaleIdentifier } from '../types/localeIdentifier'

export const languageToLocale: Record<Language, LocaleIdentifier> = {
  da: 'da_DK',
  de: 'de_DE',
  en: 'en_US',
  fi: 'fi_FI',
  fr: 'fr_FR',
  nl: 'nl_NL',
  sv: 'sv_SE',
}
