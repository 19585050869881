import React, { ReactElement, ReactNode, useCallback } from 'react'

import { IconButton } from '../../../IconButton'
import { Text } from '../../../Text'
import { useModal } from '../../context/hooks/useModal'
import * as Styled from './styles'

interface ModalHeaderProps {
  children?: ReactNode
  closable?: boolean
  id?: string
  onBackButtonClick?: () => void
  title?: ReactNode
  withBackButton?: boolean
}

export const ModalHeader = ({
  children,
  closable,
  id,
  onBackButtonClick,
  title,
  withBackButton,
}: ModalHeaderProps): ReactElement => {
  const { close } = useModal(id as string) // we extend props for it in the Modal.tsx children, so id will be always there

  const handleCloseClick = useCallback(() => {
    if (closable) {
      close?.()
    }
  }, [closable, close])

  const handleBackButtonClick = useCallback(() => {
    onBackButtonClick?.()
  }, [onBackButtonClick])

  return (
    <Styled.ModalHeaderWrapper>
      {withBackButton && (
        <Styled.BackButtonWrapper onClick={handleBackButtonClick}>
          <IconButton icon="chevronLeft" size="m" />
        </Styled.BackButtonWrapper>
      )}
      {title && <Text variant="h2">{title}</Text>}
      {children}
      {closable && (
        <Styled.CloseButtonWrapper onClick={handleCloseClick}>
          <IconButton icon="xSign" />
        </Styled.CloseButtonWrapper>
      )}
    </Styled.ModalHeaderWrapper>
  )
}
