import { NavItem } from '../types/navItem'

export const findClosestMatchedNavItem = <T>(items: NavItem<T>[], inputValue: string) => {
  if (!inputValue) {
    return null
  }
  if (items.length === 0) {
    return null
  }

  const itemsWithoutAccessors = items.filter((item) => !item.accessor)

  const matchedWithChildren = itemsWithoutAccessors.find((item) =>
    (item.children as string).toLowerCase().includes(inputValue.toLowerCase()),
  )
  if (matchedWithChildren) {
    return matchedWithChildren
  }

  // search with broader options
  const matchedWithKeywords = itemsWithoutAccessors.find((item) =>
    item?.searchKeywords?.some((keyword) => keyword.toLowerCase().includes(inputValue.toLowerCase())),
  )
  if (matchedWithKeywords) {
    return matchedWithKeywords
  }

  return null
}
