import { InputNumber, InputNumberProps as InputNumberPropsDS } from '@design-system'

import { useLocale } from '../../hooks/useLocale'
import { isLanguage, Language } from '../../types/language'
import { LocaleIdentifier } from '../../types/localeIdentifier'
import { languageToLocale } from '../../utils/languageToLocale'

export type InputNumberLocalizedProps = InputNumberPropsDS

export const InputNumberLocalized = (props: InputNumberLocalizedProps) => {
  const { locale: inputLocale } = props
  const { code } = useLocale()

  const language: Language = isLanguage(code) ? code : 'en'
  const locale: LocaleIdentifier | undefined = inputLocale || languageToLocale[language]

  return <InputNumber {...props} locale={locale} />
}
