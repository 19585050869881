import { Input } from '@design-system'

import { useFormContext } from '../../../../../../../../../../../../../../hooks'
import { SendAsEmailFormSchema } from '../../../../types/SendAsEmailFormSchema'

export const EmailMessage = () => {
  const { FormItem } = useFormContext<SendAsEmailFormSchema>()

  return (
    <FormItem
      name="emailMessage"
      render={({ field: { onChange, ...rest } }) => {
        return (
          <Input
            {...rest}
            onChange={(subject) => {
              onChange(subject)
            }}
          />
        )
      }}
    />
  )
}
