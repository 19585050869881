import { TaxRateSelect } from '@components'
import { Input } from '@design-system'

import { useCallback } from 'react'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceDocumentType } from '../../../../../../../../../../../../../../enums/invoiceDocumentType'
import { useGetZervantDocumentSettings } from '../../../../../../../../../../../../../../hooks/useGetZervantDocumentSettings'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { useUpdateDependentLineValues } from '../../hooks/useUpdateDependentLineValues'

type InvoiceLineVatProps = CommonInvoiceLineFieldProps

export const InvoiceLineVat = ({ lineIndex }: InvoiceLineVatProps) => {
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { handleVatRateChange } = useUpdateDependentLineValues(lineIndex)
  const { getInvoiceLocale } = useGetZervantDocumentSettings(InvoiceDocumentType.Invoice)

  const locale = getInvoiceLocale()

  const handleDropdownOpen = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - VAT dropdown opened')
  }, [track])

  return (
    <>
      <FormItem
        name={`invoiceLines.${lineIndex}.vatRateId`}
        render={({ field: { value, onChange } }) => {
          return (
            <TaxRateSelect
              dropdownSize="xxl"
              hasShortItemTitle
              locale={locale}
              onDropdownOpen={handleDropdownOpen}
              onSelect={(_id, value) => {
                handleVatRateChange(value?.id)
                onChange(value?.id)
                track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - VAT rate selected', {
                  predefinedTag: value?.predefinedTag,
                })
              }}
              sale
              selectedId={value}
              selectLook={false}
              withSearch={false}
            />
          )
        }}
      />
      <Input name={`invoiceLines.${lineIndex}.vatRatePercent`} hidden />
    </>
  )
}
