import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { getRouteParams } from '../utils/routeParams'
import { getSimplifiedRouteNameForSegmentTracking, useSegment } from './useSegment'

const HASH_ROUTER_LOAD_SAFETY_TIMEOUT = 400

export const useSegmentPageEvent = () => {
  const location = useLocation()
  const { page: segmentPage } = useSegment()

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const routeParams = getRouteParams()
      const route = getSimplifiedRouteNameForSegmentTracking(location, routeParams)
      segmentPage({ payload: { route } })
    }, HASH_ROUTER_LOAD_SAFETY_TIMEOUT)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [location, segmentPage])
}
