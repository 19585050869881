import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { ImageProps as ImageComponentProps } from '../../../Image'

interface ImageProps extends ImageComponentProps {
  circle?: boolean
  isLoading?: boolean
  fitToHeight?: boolean
}

export const ImageWrapper = styled.div<ImageProps>`
  width: ${({ fitToHeight }) => (fitToHeight ? 'auto' : '100%')};
  height: ${({ fitToHeight }) => (fitToHeight ? '100%' : 'auto')};
  background-color: ${Color.White};

  ${({ circle }) => {
    if (circle) {
      return css`
        img {
          border-radius: 50%;
          width: auto;
          aspect-ratio: 1 / 1;
        }
      `
    }
  }};

  ${({ isLoading }) => {
    if (isLoading) {
      return `
        visibility: hidden;
      `
    }
  }};
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`
