import { useModalContentToggler } from '@components'
import { Button, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'

export const MultipleBillsModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { selectedBillsIds } = useMultipleBills()
  const { setActiveState, prevState } = useModalContentToggler()
  useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Multiple bill selection modal closed')
    },
  })

  const handleContinueToPaymentButtonClick = useCallback(() => {
    if (prevState === BillReconciliationModalStateId.Match && selectedBillsIds.length > 1) {
      setActiveState(BillReconciliationModalStateId.BankLines)
    } else {
      setActiveState(prevState)
    }

    track('xxx - expenses - Prepayment modal - continue payment clicked', {
      selectedBillsCount: selectedBillsIds?.length || 0,
    })
  }, [selectedBillsIds?.length, setActiveState, track, prevState])

  return (
    <Button onClick={handleContinueToPaymentButtonClick}>
      {t('bill.payment_reconcile.button.continue_to_payment')}
    </Button>
  )
}
