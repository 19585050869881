import React, { ReactElement } from 'react'

import { ReactComponent as ImageTasksDKComponent } from '../../../../../../assets/images/dashboard-empty-state/dk/tasks.svg'
import { ReactComponent as ImageTasksENComponent } from '../../../../../../assets/images/dashboard-empty-state/en/tasks.svg'
import { ReactComponent as ImageTasksFRComponent } from '../../../../../../assets/images/dashboard-empty-state/fr/tasks.svg'
import { LocaleCode } from '../../../../../../enums/localeCode'
import { useLocale } from '../../../../../../hooks/useLocale'
import { EmptyStateImage } from '../../../../elements/emptyStateImage'
import { Task } from '../../types/task'
import { TaskItem } from '../TaskItem'
import * as Styled from './styles'

const emptyImageByLanguage: Record<LocaleCode, ReactElement> = {
  [LocaleCode.DA]: <ImageTasksDKComponent />,
  [LocaleCode.EN_US]: <ImageTasksENComponent />,
  [LocaleCode.FR]: <ImageTasksFRComponent />,
}

export interface TaskListProps {
  tasks?: Task[]
  isInDraftMode: boolean
  handleConfirm: (taskId: string) => void
  onCreateTask: () => void
  handleApprove: (taskId: string) => void
  onUpdateDraftDescription: (description: string) => void
  onUpdateDraftDeadline: (deadline: Date) => void
  onTaskItemCheckboxClick: (taskId?: string) => void
}

export const TaskList = ({
  tasks,
  isInDraftMode,
  handleConfirm,
  onCreateTask,
  handleApprove,
  onUpdateDraftDescription,
  onUpdateDraftDeadline,
  onTaskItemCheckboxClick,
}: TaskListProps) => {
  const { code = LocaleCode.EN_US } = useLocale()
  const localeCode = code as LocaleCode

  if (tasks?.length || isInDraftMode) {
    return (
      <Styled.TaskList>
        {isInDraftMode && (
          <TaskItem
            autoFocus
            onConfirm={handleConfirm}
            onCreateTask={onCreateTask}
            onObserveInputChange={(event) => onUpdateDraftDescription(event.target.value)}
            onObserveDeadlineChange={(deadline: Date) => onUpdateDraftDeadline(deadline)}
            onApprove={handleApprove}
            onTaskItemCheckboxClick={onTaskItemCheckboxClick}
          />
        )}

        {tasks?.length
          ? tasks.map((task: Task, i: number) => (
              <TaskItem
                key={`${task.description}-${i}`}
                onTaskItemCheckboxClick={onTaskItemCheckboxClick}
                task={task}
                onConfirm={handleConfirm}
                onApprove={handleApprove}
              />
            ))
          : null}
      </Styled.TaskList>
    )
  }

  return <EmptyStateImage image={emptyImageByLanguage[localeCode]} />
}
