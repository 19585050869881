import { ProcessingOverlay } from '@components'
import { Color } from '@design-system'

import { ReactElement } from 'react'

import { useInvoiceEditor } from '../../contexts/InvoiceEditorContext'
import { useInvoiceEditorForm } from '../../contexts/invoiceEditorFormContext'
import { InvoiceEditorView } from '../../enums/invoiceEditorView'
import { useInvoiceEditorFormSubmit } from '../../hooks/useInvoiceEditorFormSubmit'
import { InvoiceEditorForm } from './elements/InvoiceEditorForm'
import { InvoiceEditorPreview } from './elements/InvoiceEditorPreview'

export const InvoiceEditorContent = (): ReactElement => {
  const { view } = useInvoiceEditor()
  const { isLoading } = useInvoiceEditorForm()
  const { isSubmitting } = useInvoiceEditorFormSubmit()

  const isPreviewSelected = view === InvoiceEditorView.Preview

  return (
    <>
      {(isLoading || isSubmitting) && <ProcessingOverlay color={Color.White} />}
      {isPreviewSelected ? <InvoiceEditorPreview /> : <InvoiceEditorForm />}
    </>
  )
}
