import { AccountSelect, InputNumberLocalized } from '@components'
import { FormItemsGroup } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { AccountNature } from '@modules-deprecated/app/accounts/types'

import { useFormContext } from '../../../../../../../../../../hooks/useForm'
import { CreateOrEditVatAccountFormSchema } from '../../utils/formData'
import { AccountTypeSelect } from './elements/AccountTypeSelect'

export const CreateOrEditVatAccountForm = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<CreateOrEditVatAccountFormSchema>()

  return (
    <>
      <FormItemsGroup itemsInRow={1}>
        <FormItem
          label={t('settings.organization.tax_return.modals.edit_account.account')}
          name="account"
          render={({ field: { value, onChange, ...props } }) => (
            <AccountSelect
              {...props}
              onSelect={(id, value) => onChange(value)}
              selectedId={value?.id}
              dropdownSize="fitTrigger"
              withSearch={false}
              natures={[AccountNature.Liability]}
            />
          )}
        />

        <FormItem
          label={t('settings.organization.tax_return.modals.edit_account.type')}
          name="type"
          render={({ field: { value, onChange, ...props } }) => (
            <AccountTypeSelect
              {...props}
              onSelect={(id, value) => onChange(value)}
              selectedId={value}
              dropdownSize="fitTrigger"
              withSearch={false}
            />
          )}
        />

        <FormItem
          label={t('settings.organization.tax_return.modals.edit_account.priority')}
          name="priority"
          render={({ field }) => (
            <InputNumberLocalized {...field} min={0} withCalculations={false} withFormatting={false} keyboard />
          )}
        />
      </FormItemsGroup>
    </>
  )
}
