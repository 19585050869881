import {
  DropdownNavList,
  DropdownNavListProps,
  findNavItem,
  getSelectTogglerIcon,
  Icon,
  Input,
  InputProps,
  NavItem,
  NavListItemWithBlockDescription,
  NavListItemWithBlockDescriptionValue,
} from '@design-system'

import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useFetchProducts } from '../../../../../../../../../../../../../../../../hooks/useFetchProducts'
import { Product } from '../../../../../../../../../../../../../../../../types/product'
import { NAV_ITEM_ID_ADD_PRODUCT_NAME } from './constants/navItemIdAddProductName'
import { getItems } from './utils/getItems'

export interface ProductValue extends Product, NavListItemWithBlockDescriptionValue {
  isProtected?: boolean
}

export interface ProductSelectProps
  extends Pick<DropdownNavListProps<ProductValue>, 'placement' | 'selectedId'>,
    Pick<
      InputProps,
      | 'alignment'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onChangeDebounced'
      | 'onFocus'
      | 'onKeyDown'
      | 'onKeyPress'
      | 'onMouseDown'
      | 'onPressEnter'
      | 'placeholder'
      | 'readOnly'
      | 'selectOnFocus'
      | 'size'
      | 'success'
      | 'suffix'
      | 'type'
    > {
  // Select props
  inputValue?: string
  onAddProductName?: (productName: string) => void
  onSelect?: (id?: string, value?: Partial<Product>) => void
  selectedValue?: Partial<Product>
  // DropdownNavList props
  dropdownId?: string
  dropdownMaxHeight?: DropdownNavListProps<ProductValue>['maxHeight']
  dropdownSize?: DropdownNavListProps<ProductValue>['size']
}

export const ProductSelect = ({
  inputValue: inputValueControlled,
  onAddProductName,
  onSelect,
  selectedId: selectedIdControlled,
  selectedValue: selectedValueControlled,
  // DropdownNavList props
  dropdownId,
  dropdownMaxHeight = 'default',
  dropdownSize = 'fitTrigger',
  placement = 'bottom-end',
  // Input props
  alignment = 'left',
  autoCompleted,
  autoFocus,
  bordered,
  className,
  disabled,
  error,
  focused = false,
  hidden,
  id,
  name,
  onBlur,
  onChange,
  onChangeDebounced,
  onFocus,
  onKeyPress,
  onMouseDown,
  onPressEnter,
  placeholder,
  readOnly,
  selectOnFocus,
  size,
  success,
  type,
}: ProductSelectProps): ReactElement => {
  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(focused)
  const [selectedId, setSelectedId] = useState<string | undefined>(
    selectedValueControlled?.id || selectedIdControlled || '',
  )
  const [selectedValue, setSelectedValue] = useState<Partial<Product> | undefined>(selectedValueControlled)
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState<string>(inputValueControlled || '')
  const isDropdownInsideClickedRef = useRef(false) // helper for not clearing data on blur, after selecting some item (check handleInputBlur)

  const togglerIcon = useMemo(
    () => getSelectTogglerIcon({ isOpen, isFocused, withSearch: true, withSearchOnly: false }),
    [isOpen, isFocused],
  )

  const { products, isLoading } = useFetchProducts()

  const items: NavItem<ProductValue>[] = useMemo(() => {
    return getItems({ products, searchQuery: inputValue, t })
  }, [inputValue, products, t])

  const selectItem = useCallback(
    (id: string | undefined, withSelectEvent?: boolean) => {
      const item = findNavItem(items, id)
      let idToSelect = id
      let valueToSelect

      if (item) {
        if (id === NAV_ITEM_ID_ADD_PRODUCT_NAME) {
          idToSelect = ''
          onAddProductName?.(inputValue)
        } else {
          const product = products.find(({ id: productId }) => productId === id)

          if (product) {
            valueToSelect = product
            setInputValue(product.name)
          }
        }
      } else {
        idToSelect = ''
        valueToSelect = undefined
        onAddProductName?.(inputValue)
      }

      setSelectedValue(valueToSelect)
      setSelectedId(idToSelect)
      setIsOpen(false)

      if (withSelectEvent) {
        onSelect?.(idToSelect, valueToSelect)
      }
    },
    [items, onAddProductName, inputValue, products, onSelect],
  )

  const handleDropdownMouseDown = useCallback(() => {
    isDropdownInsideClickedRef.current = true
  }, [])

  const handleItemClick = useCallback(
    (id: string) => {
      isDropdownInsideClickedRef.current = true
      selectItem(id, true)
    },
    [selectItem],
  )

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target.value

      setInputValue(value)
      onChange?.(event)

      if (value === '') {
        selectItem(undefined)
        setIsOpen(true)
      } else {
        setSelectedValue(undefined)
        onAddProductName?.(value)
      }
    },
    [onChange, selectItem, onAddProductName],
  )

  const handleInputBlur = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (isDropdownInsideClickedRef.current) {
        isDropdownInsideClickedRef.current = false
      } else {
        if (selectedId && selectedValue && selectedValue.name === inputValue) {
          setInputValue(selectedValue.name || '')
        } else {
          selectItem(undefined)
        }

        setIsOpen(false)
      }

      setIsFocused(false)
      onBlur?.(event)
    },
    [onBlur, selectedId, selectedValue, inputValue, selectItem],
  )

  const handleInputFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      setIsFocused(true)
      setIsOpen(true)
      onFocus?.(event)
    },
    [onFocus],
  )

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handlePressEnter = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      selectItem(NAV_ITEM_ID_ADD_PRODUCT_NAME)

      onPressEnter?.(event)
    },
    [onPressEnter, selectItem],
  )

  useEffect(() => {
    if (inputValueControlled !== undefined) {
      setInputValue(inputValueControlled)
    }
  }, [inputValueControlled])

  useEffect(
    () => {
      if (
        selectedValueControlled !== undefined &&
        !isEmpty(selectedValueControlled) &&
        !isEqual(selectedValueControlled, selectedValue)
      ) {
        setSelectedValue(selectedValueControlled)
        setSelectedId(selectedValueControlled.id)
        setInputValue(selectedValueControlled.name || '')
      }
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedValueControlled],
  )

  useEffect(() => {
    setIsFocused(focused)
  }, [focused])

  return (
    <DropdownNavList
      autoToggle={false}
      id={dropdownId}
      isFetching={isLoading}
      isOpen={isOpen}
      itemRender={(props) => <NavListItemWithBlockDescription {...props} />}
      items={items}
      maxHeight={dropdownMaxHeight}
      onClose={handleClose}
      onItemClick={handleItemClick}
      onMouseDown={handleDropdownMouseDown}
      placement={placement}
      selectedId={selectedId}
      size={dropdownSize}
      trigger={
        <Input
          alignment={alignment}
          autoComplete="off"
          autoCompleted={autoCompleted}
          autoFocus={autoFocus}
          bordered={bordered}
          className={className}
          disabled={disabled}
          error={error}
          focused={isFocused}
          hidden={hidden}
          id={id}
          name={name}
          onBlur={handleInputBlur}
          onChange={handleChange}
          onChangeDebounced={onChangeDebounced}
          onFocus={handleInputFocus}
          onKeyPress={onKeyPress}
          onMouseDown={onMouseDown}
          onPressEnter={handlePressEnter}
          placeholder={placeholder || t('external_invoices.editor.form.field.line.field.product_name.search_or_type')}
          readOnly={readOnly}
          selectLook={false}
          selectOnFocus={selectOnFocus}
          size={size}
          success={success}
          suffix={<Icon icon={togglerIcon} />}
          title={inputValue}
          truncate
          type={type}
          value={inputValue}
        />
      }
      subItemsMode="horizontal"
      withNavigation
    />
  )
}
