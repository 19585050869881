import { NavItem, Select, SelectProps } from '@design-system'

import { forwardRef, ReactElement, Ref, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useZervantSettings } from '../../../../../../../../../../hooks/useZervantSettings'
import { InvoicePaymentTermValue } from '../../types/invoicePaymentTermValue'
import { getItems } from './utils/getItems'

type InvoicePaymentTermSelectProps = Omit<SelectProps<InvoicePaymentTermValue>, 'items' | 'dropdownFetching'>

export const InvoicePaymentTermSelect = forwardRef(
  (selectProps: InvoicePaymentTermSelectProps, forwardedRef: Ref<HTMLDivElement | null>): ReactElement => {
    const { t } = useTranslation()
    const { data, isLoading } = useZervantSettings()

    const items: NavItem<InvoicePaymentTermValue>[] = useMemo(() => {
      const invoicePaymentTerms = data?.managedFields?.PAYMENT_TERMS

      return invoicePaymentTerms ? getItems(invoicePaymentTerms, t) : []
    }, [data?.managedFields?.PAYMENT_TERMS, t])

    return (
      <Select
        {...selectProps}
        dropdownFetching={isLoading}
        items={items}
        ref={forwardedRef}
        selectLook={false}
        withSearch={false}
      />
    )
  },
)
