import { useModal } from '@design-system'

import { createContext, ReactElement, useContext, useEffect, useState } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useBankIntegration } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { isPaidPlan } from '@views/settings/routes/OrganizationSubscriptionSettings/plansData'

import { ModalId } from '../../../../enums/modalId'
import { useOrganizationSubscription } from '../../../../hooks'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { DEFAULT_QUERY_PARAMS } from '../constants'
import { useFrodaOnboarding } from '../hooks/useFrodaOnboarding'
import { useGenerateFrodaRedirectUrl } from '../hooks/useGenerateFrodaRedirectUrl'
import { useGetOfferClick } from '../hooks/useGetOfferClick'
import { useFrodaHomeAccessUrl } from '../hooks/useRequestFrodaHomeAccessUrl'
import { useResetChatStates } from '../hooks/useResetChatStates'
import { FrodaRedirectionQueryParams } from '../types/frodaRedirectionQueryParams'
import { hideChats } from '../utils/hideChats'

interface FrodaFinanceLendingContextProps {
  homeAccessUrl: string | null
  isAccessUrlLoading: boolean
  isProcessing: boolean
  onboardUser: () => void
  onGetOfferClick: () => void
  parentUrl: string
  setIsProcessing: (value: boolean) => void
  setTermsAccepted: (value: boolean) => void
  termsAccepted: boolean
}

const FrodaFinanceLendingContext = createContext<FrodaFinanceLendingContextProps | undefined>(undefined)

export const FrodaFinanceLendingProviderContext = ({ children }: { children: ReactElement }) => {
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [queryParams] = useQueryParams<FrodaRedirectionQueryParams>({
    defaultQuery: DEFAULT_QUERY_PARAMS,
    withDefaultQueryInUrl: false,
  })

  const { organization } = useUserOrganization()
  const { connectedBank } = useBankIntegration()
  const { subscriptionPlan } = useOrganizationSubscription()
  const isOnPaidPlan = isPaidPlan(subscriptionPlan)

  const { open: openFrodaLendingEligibilityModal } = useModal(ModalId.FrodaLendingEligibilityModal)
  const { open: openFrodaProcessingModal, close: closeFrodaProcessingModal } = useModal(ModalId.FrodaProcessingModal)

  const { error_code: errorCode, token: redirectToken, auth_code: authCode } = queryParams
  const currentUrl = window.location.href.split('?')[0]
  const parentUrl = encodeURIComponent(currentUrl)

  const isEligibleForLending = isOnPaidPlan && !!connectedBank

  const { homeAccessUrl, isAccessUrlLoading, requestFrodaHomeAccessUrl, setHomeAccessUrl } = useFrodaHomeAccessUrl()

  const resetChatStates = useResetChatStates()

  const { onboardUser } = useFrodaOnboarding(openFrodaProcessingModal, closeFrodaProcessingModal, setHomeAccessUrl)

  const { onGetOfferClick } = useGetOfferClick({
    isEligibleForLending,
    organization,
    openFrodaLendingEligibilityModal,
    setIsProcessing,
    onboardUser,
  })

  useGenerateFrodaRedirectUrl(setHomeAccessUrl, parentUrl, redirectToken, errorCode, authCode)

  useEffect(() => {
    if (!errorCode && !authCode && !homeAccessUrl && isEligibleForLending && organization?.id) {
      requestFrodaHomeAccessUrl()
    }
  }, [isEligibleForLending, organization?.id, errorCode, authCode, homeAccessUrl, requestFrodaHomeAccessUrl])

  useEffect(() => {
    if (homeAccessUrl) {
      hideChats()
    }

    return () => {
      resetChatStates()
    }
  }, [homeAccessUrl, resetChatStates])

  return (
    <FrodaFinanceLendingContext.Provider
      value={{
        homeAccessUrl,
        isAccessUrlLoading,
        isProcessing,
        onboardUser,
        onGetOfferClick,
        parentUrl,
        setIsProcessing,
        setTermsAccepted,
        termsAccepted,
      }}
    >
      {children}
    </FrodaFinanceLendingContext.Provider>
  )
}

export const useFrodaFinanceLendingContext = () => {
  const context = useContext(FrodaFinanceLendingContext)
  if (!context) {
    throw new Error('useFrodaFinanceLendingContext must be used within FrodaFinanceLendingProviderContext')
  }
  return context
}
