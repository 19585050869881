import { notify } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'

import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'
import {
  createUmbrellaOrganizationSubscription,
  CreateUmbrellaOrganizationSubscriptionArgs,
} from '@views/signupGuide/query-api'

import { queryClient } from '../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../hooks'
import { APIError } from '../../../../../../../utils'
import { ProductPlan } from '../../../../umbrellaSubscriptions/types/productPlan'
import {
  getProductPlansForOrganization,
  updateConsolidatedBilling,
  UpdateConsolidatedBillingPayload,
} from '../../../query-api'
import {
  getDefaultValues,
  getValidationSchema,
  UmbrellaChangeSubscriptionForm,
} from '../utils/umbrellaChangeSubscriptionFormData'

interface ContextState {
  Form: FormComponent
  submitForm: () => void
  plans?: ProductPlan[]
  isLoadingPlans: boolean
}

const UmbrellaOrganizationChangeSubscriptionContext = createContext<ContextState | undefined>(undefined)

interface UmbrellaOrganizationChangeSubscriptionContextProps {
  umbrellaOrganization: UmbrellaOrganization
  isSubscriptionModalOpen: boolean
  onSubscriptionChanged: () => void
  children?: ReactNode
}

export const UmbrellaOrganizationChangeSubscriptionContextProvider = ({
  umbrellaOrganization,
  isSubscriptionModalOpen,
  onSubscriptionChanged,
  children,
}: UmbrellaOrganizationChangeSubscriptionContextProps): ReactElement => {
  const { umbrella } = useUmbrella()

  const umbrellaId = umbrella ? umbrella.id : ''
  const umbrellaOrganizationId = umbrellaOrganization.organizationId

  const { data: plansData, isLoading: isLoadingPlans } = useQuery(
    [QueryKeys.ProductPlans, umbrellaId, umbrellaOrganizationId],
    () => getProductPlansForOrganization(umbrellaId, umbrellaOrganizationId),
    {
      enabled: isSubscriptionModalOpen && !!umbrellaId && !!umbrellaOrganizationId,
    },
  )

  const { mutate: changeOrganizationSubscription } = useMutation(createUmbrellaOrganizationSubscription, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UmbrellaOrganizations)
    },
    onError: (error: APIError | undefined) => {
      notify({
        id: NotificationKeys.UmbrellaOrganizationChangeSubscription,
        message: error?.message,
        variant: 'error',
      })
    },
  })
  const { mutate: updateOrganizationConsolidatedBilling } = useMutation(updateConsolidatedBilling, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UmbrellaOrganizations)
    },
    onError: (error: APIError | undefined) => {
      notify({
        id: NotificationKeys.UmbrellaOrganizationUpdateConsolidatedBilling,
        message: error?.message,
        variant: 'error',
      })
    },
  })

  const handleChangeSubscription = useCallback(
    (values: UmbrellaChangeSubscriptionForm) => {
      if (!umbrellaOrganization || !umbrellaOrganizationId || !umbrellaId) {
        return
      }

      if (values.consolidatedBilling) {
        const subscriptionPayload: CreateUmbrellaOrganizationSubscriptionArgs = {
          organizationId: umbrellaOrganizationId,
          umbrellaId,
          plan: values.subscriptionProductPlan as string,
          period: umbrellaOrganization.subscriptionPeriod,
        }
        changeOrganizationSubscription(subscriptionPayload)
      } else {
        const consolidatedBillingPayload: UpdateConsolidatedBillingPayload = {
          organizationId: umbrellaOrganizationId,
          consolidatedBilling: values.consolidatedBilling,
        }
        updateOrganizationConsolidatedBilling(consolidatedBillingPayload)
      }

      onSubscriptionChanged()
    },
    [
      umbrellaOrganization,
      umbrellaOrganizationId,
      umbrellaId,
      onSubscriptionChanged,
      changeOrganizationSubscription,
      updateOrganizationConsolidatedBilling,
    ],
  )

  const { reset, Form, handleSubmit } = useForm({
    defaultValues: useMemo(() => getDefaultValues(umbrellaOrganization), [umbrellaOrganization]),
    validationSchema: useMemo(() => getValidationSchema(), []),
  })

  const submitForm = useCallback(() => {
    handleSubmit(handleChangeSubscription)()
  }, [handleSubmit, handleChangeSubscription])

  useEffect(() => {
    if (umbrellaOrganization) {
      reset(getDefaultValues(umbrellaOrganization))
    }
  }, [umbrellaOrganization, reset])

  return (
    <UmbrellaOrganizationChangeSubscriptionContext.Provider
      value={{ Form, submitForm, plans: plansData?.data, isLoadingPlans }}
    >
      {children}
    </UmbrellaOrganizationChangeSubscriptionContext.Provider>
  )
}

export const useUmbrellaOrganizationChangeSubscription = () => {
  const context = useContext(UmbrellaOrganizationChangeSubscriptionContext)

  if (!context) {
    throw new Error('UmbrellaOrganizationChangeSubscriptionContext is missing in the module!')
  }

  return context
}
