import { Badge, Button, NavListSeparator, Space } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export const InvoiceReceiverDropdownFooter = (): ReactElement => {
  const { t } = useTranslation()

  // For BETA testing phase button onClick is not implemented
  // 'active' button state is used with warning badge instead of 'disabled' due to special visual requirement

  return (
    <>
      <NavListSeparator />
      <Button icon="plusCircle" size="xl" variant="text" fullWidth active>
        {t('external_invoices.editor.form.field.send_to.create_new.label')}
        <Space size="s" horizontal />
        <Badge variant="warning">
          {t('external_invoices.editor.form.field.send_to.create_new.badge')?.toUpperCase()}
        </Badge>
      </Button>
    </>
  )
}
