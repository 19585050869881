import { useQuery } from 'react-query'

import { fetchAccounts } from '@modules-deprecated/app/accounts/query-api'

import { QueryKeys } from '../../../enums/queryKeys'

interface UseFindBankAccountIdProps {
  account?: string
  organizationId: string
}

// use accountId from Ember or pick up the first bank account in the list
export const useFindBankAccountId = ({ account, organizationId }: UseFindBankAccountIdProps) => {
  const { data } = useQuery([QueryKeys.Accounts, organizationId], () => fetchAccounts(organizationId), {
    enabled: !!organizationId && !account,
  })

  // finding a data for account sent from Ember OR picking up the first _bank_ account in the list
  const bankAccount = account
    ? data?.accounts?.find(({ id }) => id === account)
    : data?.accounts?.find(({ isBankAccount }) => isBankAccount)

  const accountId = account || bankAccount?.id || ''
  const accountCurrency = bankAccount?.currencyId || 'DKK'

  return {
    accountCurrency,
    accountId,
  }
}
