import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { CustomEvent } from '../enums/customEvent'
import { BrowserBackButtonBlockRequest } from '../types/BrowserBackButtonBlockRequest'
import { dispatchCustomEvent } from '../utils/customEvent'
import { getIsURLChangeStartedByEmberRouter, history } from '../utils/history'
import { useEmberTransitionTo } from './routing/useEmberTransitionTo'
import { CustomEventType, useCustomEventListener } from './useCustomEventListener'

const blockedBySources = new Set<string>()

export const useReactEmberRouterConnection = () => {
  const { transitionTo } = useEmberTransitionTo()
  const location = useLocation()
  const currentLocationKey = useRef(location.key)
  const previousLocationKey = useRef<string | undefined | null>(null)

  useEffect(() => {
    previousLocationKey.current = currentLocationKey.current
    currentLocationKey.current = location.key
  }, [location.key])

  const previousLocation = useRef(location.pathname)
  const shouldBlockBackButtonClick = useRef(false)

  useEffect(() => {
    previousLocation.current = location.pathname
  }, [location.pathname])

  useCustomEventListener<BrowserBackButtonBlockRequest>(
    CustomEvent.BlockBrowserBackButtonRequested,
    (event: CustomEventType<BrowserBackButtonBlockRequest>) => {
      if (!event.detail) {
        return
      }

      const { sourceId: blockerId, value: shouldBlock } = event.detail

      if (!blockerId) {
        return
      }

      if (shouldBlock) {
        blockedBySources.add(blockerId)
      } else {
        blockedBySources.delete(blockerId)
      }

      shouldBlockBackButtonClick.current = blockedBySources.size > 0
    },
  )

  useEffect(() => {
    // We catch the URL change and prevent it from happening
    // Then we use Ember's router to create transition
    // thanks to this Ember hooks like willTransition and didTransition will be called
    // and URL will be updated by Ember's router but using our history object
    // This code also makes dirty-route modal work properly
    const blockUrlChange = () =>
      history.block((location, action) => {
        const isURLChangeStartedByEmberRouter = getIsURLChangeStartedByEmberRouter()
        const addressChangeByBackButtonClick = action === 'POP' && location.pathname !== previousLocation.current
        const addressChangeByLinkClickOrFunction = location.pathname !== window.location.pathname

        if (!isURLChangeStartedByEmberRouter && shouldBlockBackButtonClick.current && addressChangeByBackButtonClick) {
          dispatchCustomEvent<boolean>(
            CustomEvent.BrowserNavigationButtonClickedAndBlocked,
            location.key === previousLocationKey.current,
          )
          return false
        }

        if (!isURLChangeStartedByEmberRouter && addressChangeByLinkClickOrFunction) {
          // when we change url more than once quickly
          // for example by going to a view that triggers redirect we might end up with a empty Ember view
          // moving transition to the next frame fixes this
          requestAnimationFrame(() => {
            transitionTo(`${location.pathname}${location.search}${location.hash}`)
          })

          // Prevent React Router from changing the URL
          return false
        }
      })

    const removeListener = blockUrlChange()

    return () => {
      removeListener()
    }
  }, [transitionTo])
}
