import { Button, Divider, Space } from '@design-system'

import { Fragment, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFieldArray, useFormContext, useSegment } from '../../../../../../../../../../hooks'
import { useInvoiceEditorForm } from '../../../../../../contexts/invoiceEditorFormContext'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { getDefaultLine } from '../../../../../../utils/getDefaultLine'
import { InvoiceLine } from './elements/InvoiceLine'
import { InvoiceLinesHeader } from './elements/InvoiceLinesHeader'

export const InvoiceLines = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { control } = useFormContext<InvoiceFormSchema>()
  const { predefinedInvoiceValues } = useInvoiceEditorForm()

  const {
    fields: lines,
    append: addLine,
    remove: removeLine,
    update: updateLine,
  } = useFieldArray({ control, name: 'invoiceLines' })

  const defaultLine = useMemo(() => getDefaultLine(predefinedInvoiceValues), [predefinedInvoiceValues])

  const handleAddLineClick = useCallback(() => {
    addLine(defaultLine)
    track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Add invoice line button clicked')
  }, [addLine, defaultLine, track])

  const handleRemoveLineClick = useCallback(
    (index: number) => () => {
      track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Remove invoice line button clicked')
      if (lines.length === 1) {
        updateLine(index, defaultLine)
      } else {
        removeLine(index)
      }
    },
    [defaultLine, lines.length, removeLine, track, updateLine],
  )

  return (
    <>
      <InvoiceLinesHeader />
      {lines.map((line, index) => {
        return (
          <Fragment key={line.id}>
            <Divider />
            <Space size="s" />
            <InvoiceLine index={index} onRemove={handleRemoveLineClick(index)} />
          </Fragment>
        )
      })}
      <Space />
      <Button fullWidth icon="plusCircle" onClick={handleAddLineClick} variant="secondary">
        {t('external_invoices.editor.form.field.invoice_lines.add_line')}
      </Button>
    </>
  )
}
