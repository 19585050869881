import {
  Form as FormDS,
  FormItemDecorated as FormItemDecoratedDS,
  FormItem as FormItemDS,
  FormItemProps as FormItemPropsDS,
  FormProps as FormPropsDS,
} from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import { zodResolver } from '@hookform/resolvers/zod'
import get from 'lodash/get'
import {
  forwardRef,
  ForwardRefExoticComponent,
  ReactElement,
  ReactNode,
  Ref,
  RefAttributes,
  useCallback,
  useMemo,
  useRef,
} from 'react'
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  CriteriaMode,
  FormProvider,
  Mode,
  Path,
  PathValue,
  UnpackNestedValue,
  useFieldArray as useFieldArrayForm,
  useFormContext as useFormContextForm,
  UseFormReturn,
  UseFormStateReturn,
  useForm as useHookForm,
  useWatch as useWatchForm,
} from 'react-hook-form'
import { Asserts as YupAsserts, AnyObjectSchema as YupSchema } from 'yup'
import { z, ZodSchema } from 'zod'

import { fixDefaultValues } from './utils/fixDefaultValues'
import { reset } from './utils/reset'
import { setValue } from './utils/setValue'

type SupportedSchema = YupSchema | ZodSchema
type AssertYupSchema<T> = T extends YupSchema ? YupAsserts<T> : never
type AssertZodSchema<T> = T extends ZodSchema ? z.infer<T> : never
type SchemaAsserts<T> = AssertYupSchema<T> | AssertZodSchema<T>

export type UseFormParams<T extends SupportedSchema> = {
  criteriaMode?: CriteriaMode
  defaultValues?: SchemaAsserts<T>
  delayError?: number
  mode?: Mode
  onSubmit?: (values: SchemaAsserts<T>) => any
  onSubmitError?: (error: unknown, setError: UseFormReturn<SchemaAsserts<T>>['setError']) => void
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit'
  validationSchema: T // this can be either a Yup or Zod schema
}

export type FormComponent = ForwardRefExoticComponent<FormPropsDS & RefAttributes<HTMLFormElement>>
export type FormControl<T extends SupportedSchema> = Control<SchemaAsserts<T>>

const isYupSchema = (schema: unknown): schema is YupSchema => {
  return typeof (schema as YupSchema)?.validateSync === 'function'
}

const isZodSchema = (schema: unknown): schema is ZodSchema => {
  return typeof (schema as ZodSchema)?.safeParse === 'function'
}

export const useForm = <T extends SupportedSchema>(params: UseFormParams<T>) => {
  const hookForm = useHookForm<SchemaAsserts<T>>({
    criteriaMode: params.criteriaMode,
    defaultValues: params.defaultValues,
    delayError: params.delayError,
    mode: params.mode,
    resolver: useMemo(() => {
      let resolver

      if (isYupSchema(params.validationSchema)) {
        resolver = yupResolver(params.validationSchema)
      }
      if (isZodSchema(params.validationSchema)) {
        resolver = zodResolver(params.validationSchema)
      }

      if (!resolver) {
        throw new Error('Unsupported schema type')
      }

      return resolver
    }, [params.validationSchema]),
    reValidateMode: params.reValidateMode || 'onChange',
  })

  fixDefaultValues(hookForm)

  const hookFormRef = useRef(hookForm)
  const paramsRef = useRef(params)

  hookFormRef.current = hookForm
  paramsRef.current = params

  const submitHandler = useMemo(
    () =>
      hookFormRef.current.handleSubmit((values: SchemaAsserts<T>) => {
        try {
          paramsRef.current.onSubmit?.(values)
        } catch (e) {
          paramsRef.current.onSubmitError?.(e, hookFormRef.current.setError)
        }
      }),
    [],
  )

  return {
    ...hookForm,
    submitHandler,
    setValue: useMemo(() => setValue(hookForm), [hookForm]),
    reset: useMemo(() => reset(hookForm), [hookForm]),
    Form: useMemo(() => {
      // eslint-disable-next-line prefer-arrow-callback
      return forwardRef(function UseForm(props: FormPropsDS, forwardedRef: Ref<HTMLFormElement>) {
        return (
          <FormProvider {...hookFormRef.current}>
            <FormDS noValidate ref={forwardedRef} {...props} onSubmit={submitHandler} />
          </FormProvider>
        )
      })
    }, [submitHandler]),
    // eslint-disable-next-line prefer-arrow-callback
    FormItem: useCallback(function UseFormItem<Name extends Path<SchemaAsserts<T>>>(
      props: Omit<FormItemProps<T, Name>, 'error'>,
    ) {
      return <FormItemDecoratedComponent {...props} />
    }, []),
  }
}

export const useFormContext = <T extends SupportedSchema>() => {
  const formContext = useFormContextForm<SchemaAsserts<T>>()

  return {
    ...formContext,
    setValue: useMemo(() => setValue(formContext), [formContext]),
    reset: useMemo(() => reset(formContext), [formContext]),
    // eslint-disable-next-line prefer-arrow-callback
    FormItem: useCallback(function UseFormItem<Name extends Path<SchemaAsserts<T>>>(
      props: Omit<FormItemProps<T, Name>, 'error'>,
    ) {
      return <FormItemDecoratedComponent {...props} />
    }, []),
  }
}

export const useFieldArray = useFieldArrayForm

export const useWatch = useWatchForm

// Helper components

function FormItemDecoratedComponent<T extends SupportedSchema, Name extends Path<SchemaAsserts<T>>>({
  name,
  render,
  ...formItemProps
}: Omit<FormItemProps<T, Name>, 'error'>) {
  const { control, formState, setValue } = useFormContextForm()
  const autoCompletedFields = useWatch({ control, name: 'autoCompletedFields' })
  const prevValueRef = useRef<UnpackNestedValue<PathValue<SchemaAsserts<T>, Name>>>()

  // @todo remove any in the following two lines
  const fieldError: any = get(formState.errors, name)
  const error = (fieldError?.id || fieldError?.name || fieldError) as any | undefined | string

  const isAutoCompleted = Boolean(autoCompletedFields?.[name])

  return (
    <FormItemDS {...formItemProps} name={name} error={error?.message}>
      {({ id }: FormItemDecoratedDS) => (
        <Controller<SchemaAsserts<T>, Name>
          control={control as any}
          name={name}
          render={(props) => {
            if (isAutoCompleted && prevValueRef.current !== props.field.value) {
              setValue('autoCompletedFields', { ...autoCompletedFields, [name]: false })
            }

            prevValueRef.current = props.field.value

            return render({
              ...props,
              // 'disconnect' is set to true to easily integrate legacy selectors which are wrapped redundantly with Controllers
              field: { ...props.field, autoCompleted: isAutoCompleted, id, error: !!error, disconnect: true },
            })
          }}
        />
      )}
    </FormItemDS>
  )
}

// Helper types

type FormItemProps<T extends SupportedSchema, Name extends Path<SchemaAsserts<T>>> = Omit<
  FormItemPropsDS,
  'children' | 'name'
> & {
  children?: ReactNode
  name: Name
  render: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<SchemaAsserts<T>, Name> & {
      autoCompleted: boolean
      disconnect: boolean
      error: boolean
      id: string
    }
    fieldState: ControllerFieldState
    formState: UseFormStateReturn<SchemaAsserts<T>>
  }) => ReactElement
}
