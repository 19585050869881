import { getDisplayDate, Text } from '@design-system'

import { ReactElement } from 'react'

import { getCurrentLocale } from '../../../../utils'
import * as Styled from './styles'

interface ReconciliationSuggestionLineProps {
  amount: string
  currency: string
  date: string
  title: string
}

export const ReconciliationSuggestionLine = ({
  amount,
  currency,
  date,
  title,
}: ReconciliationSuggestionLineProps): ReactElement => {
  const locale = getCurrentLocale()

  return (
    <Styled.ReconciliationSuggestionLineWrapper>
      <Styled.ReconciliationSuggestionDetails>
        <Text weight="bold">{title}</Text>
        <Text variant="micro" colorVariant="secondary">
          {getDisplayDate(new Date(date), locale)}
        </Text>
      </Styled.ReconciliationSuggestionDetails>
      <Styled.ReconciliationSuggestionDetails>
        <Text variant="normal" colorVariant="danger" weight="bold">
          {amount} {currency}
        </Text>
      </Styled.ReconciliationSuggestionDetails>
    </Styled.ReconciliationSuggestionLineWrapper>
  )
}
