import { ProtectedComponent } from '@components'
import { Space, Tab, TabsGroup } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../../../enums/scope'
import { AttachmentFilterType } from '../../../../../../enums/attachmentFiltertype'
import { AttachmentsCountBadge } from '../../../../../AttachmentsCountBadge'
import {
  defaultChooseAttachmentModalFilters,
  useChooseAttachmentModal,
} from '../../../../contexts/chooseAttachmentModalContext'
import * as Styled from './styles'

export const FilterAction = () => {
  const { t } = useTranslation()
  const {
    filters: { type },
    setFilters,
  } = useChooseAttachmentModal()

  const isUploadsActive = type === AttachmentFilterType.Upload
  const isEInvoicesActive = type === AttachmentFilterType.EDocument

  const handleUploadsFilterClick = useCallback(() => {
    setFilters({ type: AttachmentFilterType.Upload, page: defaultChooseAttachmentModalFilters.page })
  }, [setFilters])

  const handleEInvoicesFilterClick = useCallback(() => {
    setFilters({ type: AttachmentFilterType.EDocument, page: defaultChooseAttachmentModalFilters.page })
  }, [setFilters])

  return (
    <ProtectedComponent scopes={Scope.EDocumentRead}>
      <Styled.FilterActionWrapper>
        <TabsGroup>
          <Tab onClick={handleUploadsFilterClick} active={isUploadsActive}>
            {t('receipts.filters.type.uploads')}
            <Space size="xs" horizontal />
            <AttachmentsCountBadge documentFilterType={AttachmentFilterType.Upload} />
          </Tab>
          <Tab onClick={handleEInvoicesFilterClick} active={isEInvoicesActive}>
            {t('receipts.filters.type.e_invoices')}
            <Space size="xs" horizontal />
            <AttachmentsCountBadge documentFilterType={AttachmentFilterType.EDocument} />
          </Tab>
        </TabsGroup>
      </Styled.FilterActionWrapper>
    </ProtectedComponent>
  )
}
