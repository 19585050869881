import { useModal } from '@design-system'

import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../../../../../enums/modalId'
import { useForm } from '../../../../../../../hooks'
import { useActorMutations } from '../../../hooks/useActorMutations'
import { CreateActorResponseData } from '../../../query-api'
import { SimpleActor } from '../../../types/simpleActor'
import { AccessTokenForm, defaultValues, getValidationSchema } from '../utils/formData'
import { getPayloadFromAccessTokenForm } from '../utils/getPayloadFromAccessTokenForm'

interface ContextState {
  submitForm: () => void
  isProcessing: boolean
  actorId?: string
}

const defaultValue: ContextState = {
  submitForm: noop,
  isProcessing: false,
}

const CreateOrEditAccessTokenModalContext = createContext<ContextState>(defaultValue)

interface CreateOrEditAccessTokenModalContextProps {
  actor?: SimpleActor
  children: ReactNode
  onSuccessCreate: (actorId: string) => void
}

export const CreateOrEditAccessTokenModalContextProvider = ({
  actor,
  children,
  onSuccessCreate,
}: CreateOrEditAccessTokenModalContextProps): ReactElement => {
  const { organization } = useUserOrganization()
  const {
    Form,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    defaultValues,
    validationSchema: getValidationSchema(),
  })
  const { open: openViewAccessTokenModal } = useModal(ModalId.ViewAccessTokenModal)
  const { close: closeCreateOrEditAccessTokenModal } = useModal(ModalId.CreateOrEditAccessTokenModal)

  const handleCreateActorSuccess = useCallback(
    (actorId: string) => {
      onSuccessCreate(actorId)
    },
    [onSuccessCreate],
  )

  const { createActor, isCreatingActor, updateActor, isUpdatingActor } = useActorMutations({
    onSuccessCreate: (data: CreateActorResponseData) => {
      handleCreateActorSuccess(data?.oAuthActors[0]?.id)
      closeCreateOrEditAccessTokenModal()
      resetForm(defaultValues)
      openViewAccessTokenModal()
    },
    onSuccessUpdate: () => {
      closeCreateOrEditAccessTokenModal()
    },
  })

  const organizationId = organization?.id
  const isProcessing = isCreatingActor || isUpdatingActor

  const handleFormSubmit = useCallback(
    (values: AccessTokenForm) => {
      if (!organizationId) {
        return
      }
      const actorPayload = getPayloadFromAccessTokenForm(values, organizationId)

      if (actor?.id) {
        updateActor({ actorId: actor.id, payload: actorPayload })
      } else {
        createActor(actorPayload)
      }
    },
    [actor?.id, createActor, organizationId, updateActor],
  )
  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  useEffect(
    () => {
      if (actor) {
        resetForm({ name: actor.name, description: actor.description })
      } else {
        resetForm(defaultValues)
      }
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actor?.name, actor?.description],
  )

  return (
    <CreateOrEditAccessTokenModalContext.Provider value={{ submitForm, isProcessing, actorId: actor?.id }}>
      <Form>{children}</Form>
    </CreateOrEditAccessTokenModalContext.Provider>
  )
}

export const useCreateOrEditAccessTokenModal = () => useContext(CreateOrEditAccessTokenModalContext)
