import { BorderRadius, Color } from '@design-system'

import styled from '@emotion/styled'

export const InvoiceEditorPreviewWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.DayGrey};
  border: 1px solid ${Color.Gray30};
  border-radius: ${BorderRadius.XL} ${BorderRadius.XL} 0 0;
`
