import qs from 'qs'

import { Metable } from '../../../types/metable'
import { deleteV2Request, getCurrentLocale, getRequest, postRequest, postV2Request, putRequest } from '../../../utils'
import { OrganizationUser } from '../user'
import { AvailableOrganizationStates } from './enums/availableOrganizationStates'
import { InvitationType } from './enums/invitationType'
import { OrganizationStateNamespace } from './enums/organizationStateNamespace'
import { SubscriptionPeriod } from './enums/subscriptionPeriod'
import { SubscriptionPlan } from './enums/subscriptionPlan'
import { AccountingBasis } from './types/accountingBasis'
import { Organization } from './types/organization'
import { OrganizationInvitation } from './types/organizationInvitation'
import { OrganizationSettings } from './types/organizationSettings'
import { OrganizationUmbrella } from './types/organizationUmbrella'

export interface OrganizationResponseData {
  organization: Organization
}

export const fetchOrganization = (organizationId: string): Promise<OrganizationResponseData> => {
  return getRequest(`/v2/organizations/${organizationId}`)
}

export interface OrganizationSettingsResponseData {
  settings: OrganizationSettings
}

export const fetchOrganizationSettings = (organizationId: string): Promise<OrganizationSettingsResponseData> => {
  return getRequest(`/v2/organizations/${organizationId}/settings/billy`)
}

export type OrganizationSettingKey = keyof OrganizationSettings

export interface OrganizationSettingProps {
  key: OrganizationSettingKey
  organizationId: string
  value: string
}

export interface OrganizationSettingResponse extends Metable {
  settings: OrganizationSettings
}

export const setOrganizationSetting = ({
  organizationId,
  key,
  value,
}: OrganizationSettingProps): Promise<OrganizationSettingResponse> => {
  return putRequest(`/v2/organizations/${organizationId}/settings/billy/${key}`, { value })
}

export type SetMultipleOrganizationSettingsPayload = Partial<Record<OrganizationSettingKey, string>>

export interface SetMultipleOrganizationSettingsProps {
  organizationId: string
  payload: SetMultipleOrganizationSettingsPayload
}

export const setMultipleOrganizationSettings = ({
  organizationId,
  payload,
}: SetMultipleOrganizationSettingsProps): Promise<OrganizationSettingsResponseData> => {
  const locale = getCurrentLocale()
  return putRequest(`/v2/organizations/${organizationId}/settings/billy`, payload, { 'accept-language': locale })
}

interface OrganizationStateProps {
  organizationId: string
  organizationState: AvailableOrganizationStates
  value: string | null
  namespace: OrganizationStateNamespace
}

export const updateOrganizationState = ({
  organizationId,
  organizationState,
  value,
  namespace,
}: OrganizationStateProps) => {
  return putRequest(`/v2/organizations/${organizationId}/states/${namespace}/${organizationState}`, { value })
}

export const getOrganization = async (organizationId: string): Promise<Organization> => {
  const { organization }: { organization: Organization } = await getRequest(
    `/v2/organizations/${organizationId}`,
    {},
    true,
  )

  return organization
}

export const getOrganizationUsers = async (organizationId: string): Promise<OrganizationUser[]> => {
  const { data: users }: { data: OrganizationUser[] } = await getRequest(
    `/organizations/${organizationId}/users`,
    {},
    true,
  )

  return users
}

export interface OrganizationPayload {
  accountingBasis?: AccountingBasis
  bankSyncStartDate?: string | null
  baseCurrencyId?: string
  city?: string
  countryId?: string
  defaultBankFeeAccountId?: string
  defaultBillBankAccountId?: string
  defaultInvoiceBankAccountId?: string
  defaultSalesAccountId?: string
  defaultSalesTaxRulesetId?: string
  defaultTemplateId?: string
  defaultTaxMode?: string
  email: string
  emailAttachmentDeliveryMode?: string
  firstFiscalYearEnd?: string
  firstFiscalYearStart?: string
  fiscalLockingDate?: string | null
  fiscalYearEndMonth?: string
  hasBillVoucherNo?: boolean
  iconFileId?: string | null
  invoiceNoMode?: string
  isVatExempted?: boolean
  localeId?: string
  logoFileId?: string | null
  name?: string
  nextInvoiceNo?: number
  paymentTermsDays?: number
  paymentTermsMode?: string
  phone?: string
  registrationNo?: string
  street?: string
  salesTaxPeriod?: string
  subscriptionPlan: SubscriptionPlan
  subscriptionPeriod?: SubscriptionPeriod
  zipcode?: string
}

export interface CreateOrganizationPayload {
  organization: OrganizationPayload
  analytics?: string
}

// it returns a lot of other data in response but nothing needed to type at this point:
export interface CreateOrganizationResponseData {
  organizations: Organization[]
}

export const createOrganiztion = (payload: CreateOrganizationPayload): Promise<CreateOrganizationResponseData> =>
  postRequest('/v2/organizations', { organization: payload.organization, analytics: payload.analytics })

export type UpdateOrganizationPayload = Omit<OrganizationPayload, 'email' | 'subscriptionPlan'>
export type UpdateOrganizationResponseData = CreateOrganizationResponseData

export const updateOrganization = (
  organizationId: string,
  payload: UpdateOrganizationPayload,
): Promise<UpdateOrganizationResponseData> => {
  const locale = getCurrentLocale()
  return putRequest(`/v2/organizations/${organizationId}`, { organization: payload }, { 'accept-language': locale })
}

interface GetOrganizationUmbrellasResponseData {
  data: OrganizationUmbrella[]
}

export function getOrganizationUmbrellas(organizationId: string): Promise<GetOrganizationUmbrellasResponseData> {
  return getRequest(`/organizations/${organizationId}/umbrellas`)
}

interface GetOrganizationInvitations {
  organizationId: string
  type?: InvitationType
}

interface GetOrganizationInvitationsResponseData {
  data: OrganizationInvitation[]
}

export function getOrganizationInvitations({
  organizationId,
  type,
}: GetOrganizationInvitations): Promise<GetOrganizationInvitationsResponseData> {
  const queryParams = qs.stringify({
    ...(type ? { inviteeType: type } : {}),
  })

  return getRequest(`/organizations/${organizationId}/invitations?${queryParams}`)
}

export function deleteOrganizationInvitation(organizationId: string, invitationId: string): Promise<void> {
  return deleteV2Request({
    endpoint: `/organizations/${organizationId}/invitations/${invitationId}`,
    options: {
      contentType: 'application/vnd.billy.v2+json',
    },
  })
}

export function deleteOrganizationUmbrellaMember(organizationId: string, umbrellaId: string): Promise<void> {
  return deleteV2Request({
    endpoint: `/organizations/${organizationId}/umbrellas/${umbrellaId}`,
    options: {
      contentType: 'application/vnd.billy.v2+json',
    },
  })
}

export function deleteOrganizationUserMember(organizationId: string, userId: string): Promise<void> {
  return deleteV2Request({
    endpoint: `/organizations/${organizationId}/users/${userId}`,
    options: {
      contentType: 'application/vnd.billy.v2+json',
    },
  })
}

export interface PostOrganizationInvitationPayload {
  email: string
  userRole?: string
}

export async function postOrganizationInvitation(
  organizationId: string,
  payload: PostOrganizationInvitationPayload,
): Promise<void> {
  await postV2Request({
    endpoint: `/organizations/${organizationId}/invitations/user`,
    payload: { data: payload },
    options: {
      contentType: 'application/vnd.billy.v2+json',
    },
  })
}

export interface UpdateOrganizationOwnershipPayload {
  ownerUserId?: string
  ownerUmbrellaId?: string
}

export function updateOrganizationOwnership(
  organizationId: string,
  payload: UpdateOrganizationOwnershipPayload,
): Promise<void> {
  return putRequest(`/v2/organizations/${organizationId}/ownership`, { data: payload })
}
