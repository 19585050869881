import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Color } from '../../enums/color'
import { NextButtonWrapper, PrevButtonWrapper } from './elements/NavigationButtons/styles'
import { navigationButtonAnimation } from './utils/navigationButtonAnimation'

interface ButtonWrapperProps {
  borderRadius: string
}

export const CarouselWrapper = styled.div<ButtonWrapperProps>`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: ${({ borderRadius }) => borderRadius};

  &:hover {
    ${NextButtonWrapper}, ${PrevButtonWrapper} {
      opacity: 1;
      visibility: visible;
      animation: none;
    }

    ${NextButtonWrapper}, ${PrevButtonWrapper} {
      ${navigationButtonAnimation()}
    }

    ${NextButtonWrapper} {
      background: linear-gradient(
        to right,
        ${transparentize(1, Color.Gray40)} 0%,
        ${transparentize(0.8, Color.Gray60)} 100%
      );
    }
    ${PrevButtonWrapper} {
      background: linear-gradient(
        to left,
        ${transparentize(1, Color.Gray60)} 0%,
        ${transparentize(0.8, Color.Gray60)} 100%
      );
    }
  }
`
