import { useCallback } from 'react'
import { QueryKey, useQueryClient } from 'react-query'

import { useInvalidateBills } from '@views/bills/hooks/useInvalidateBills'

import { CustomEvent } from '../enums/customEvent'
import { QueryKeys } from '../enums/queryKeys'
import { CustomEventType, useCustomEventListener } from './useCustomEventListener'

type SubjectType = 'invoice' | 'bill'
interface PaymentChangedEventDetail {
  subjectId?: string
  subjectType?: SubjectType
}

const getInvalidateQueriesByTypeMap = (id: string): Record<SubjectType, QueryKey> => ({
  invoice: [QueryKeys.Invoice, id],
  bill: [QueryKeys.Bill, id],
})

export const useInvalidateQueriesCustomListener = () => {
  const queryClient = useQueryClient()
  const { invalidateBills } = useInvalidateBills()

  const handlePaymentChanged = useCallback(
    (event: CustomEventType<PaymentChangedEventDetail>) => {
      const subjectId = event.detail?.subjectId
      const subjectType = event.detail?.subjectType

      if (subjectId && subjectType) {
        const invalidateKey = getInvalidateQueriesByTypeMap(subjectId)[subjectType]
        queryClient.invalidateQueries(invalidateKey)
      }

      invalidateBills()
    },
    [queryClient, invalidateBills],
  )

  useCustomEventListener<PaymentChangedEventDetail>(CustomEvent.PaymentUpdated, handlePaymentChanged)
  useCustomEventListener<PaymentChangedEventDetail>(CustomEvent.PaymentSaved, handlePaymentChanged)
}
