import sortBy from 'lodash/sortBy'

import { AttachmentFile } from '../types/attachmentFile'

type Size = 'small' | 'medium' | 'large'

export const getFileThumbnail = (file: AttachmentFile, size: Size = 'large'): string => {
  if (!file.variants) {
    return file.downloadUrl
  }

  let variants: Omit<AttachmentFile, 'variants'>[] = []
  let downloadUrl = ''

  if (file.fileType === 'pdf') {
    variants = sortBy(file.variants, 'thumbnailNo')
    downloadUrl = variants[0]?.downloadUrl
  } else {
    variants = sortBy(file.variants, 'imageWidth')

    if (size === 'small') {
      downloadUrl = variants[0]?.downloadUrl
    } else if (size === 'medium') {
      downloadUrl = variants[Math.round(variants.length / 2)]?.downloadUrl
    } else if (size === 'large') {
      downloadUrl = variants[variants.length - 1]?.downloadUrl
    }
  }

  return downloadUrl || file.downloadUrl
}
