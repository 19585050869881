import { ButtonsGroup, DefaultValue, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { Alignment } from '../types'

type BulkActionsWrapperProps = {
  align: Alignment
}

export const BulkActionsWrapper = styled.div<BulkActionsWrapperProps>`
  display: flex;
  border-radius: ${DefaultValue.BorderRadius};
  padding: 0 ${Spacing.L} ${Spacing.L};
  align-items: center;
  justify-content: ${({ align }) => (align === 'left' ? 'flex-start' : 'flex-end')};
`

export const ButtonsWrapper = styled(ButtonsGroup)`
  margin-left: ${Spacing.L};
`
