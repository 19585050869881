import groupBy from 'lodash/groupBy'
import noop from 'lodash/noop'
import React, { Fragment, ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, Row, useFlexLayout, useRowSelect, useTable } from 'react-table'

import { SelectRowsContextProvider } from './contexts/selectRowsContext'
import { TableCell } from './elements/TableCell'
import { TableColumnsFiltering } from './elements/TableColumnsFiltering'
import { TableEmptyView } from './elements/TableEmptyView'
import { TableHeader } from './elements/TableHeader'
import { TableHeaderItem } from './elements/TableHeaderItem'
import { TablePagination, TablePaginationProps } from './elements/TablePagination'
import { TableRow } from './elements/TableRow'
import { TableRowExpander } from './elements/TableRowExpander'
import { TableStaticActionsHeaderItem } from './elements/TableStaticActionsHeaderItem'
import { InternalAccessor } from './enums/accessors'
import { useClientSidePagination } from './hooks/useClientSidePagination'
import { useHiddenColumns } from './hooks/useHiddenColumns'
import { ItemsPerPage, useItemsPerPage } from './hooks/useItemsPerPage'
import * as Styled from './styles'
import { TableColumn, TableSortedColumnState } from './types/column'
import { TableData } from './types/data'
import { SelectType } from './types/selectType'
import { generateMockTableData } from './utils/generateMockTableData'
import { getColumns } from './utils/getColumns'
import { getCorrectColumnId } from './utils/getCorrectColumnId'
import { getHiddenColumnsIds } from './utils/getHiddenColumnsIds'
import { getToggleableColumns } from './utils/getToggleableColumns'

const EXCLUDED_ACCESSORS = [...Object.values(InternalAccessor)]

interface IdProperty {
  id: string
}

interface BaseTableProps<T extends TableData, K = boolean> {
  bordered?: boolean
  columns: TableColumn<T>[]
  currentPage?: number
  data: T[]
  emptyRowInfo?: ReactNode
  groupRowsBy?: (row: Row<T>) => any
  isClientSidePagination?: boolean
  isLoading?: boolean
  isSelectable?: boolean
  // Defines how many items should be displayed when pagination is enabled. By default, it's set to `50`. Other recommended options: `10`, `25`, `100`.
  itemsPerPage?: ItemsPerPage
  onAllRowsSelect?: (rows: Row<T>[], isChecked: boolean) => void
  onItemsPerPageChange?: (itemsPerPage: ItemsPerPage) => void
  onPageChange?: (page: number) => void
  onRowClick?: (row: Row<T>) => void
  onRowSelect?: (row: Row<T>, isChecked: boolean) => void
  onSort?: (columnId: string, isDesc: boolean) => void
  rowSelectType?: SelectType
  scrollTopOnPageChange?: boolean
  skeletonItemsPerPage?: number
  sortedColumnId?: string
  totalPageItems?: number
  withColumnsFiltering?: K
  withEmptyRowInfo?: boolean
  withItemsPerPageSelect?: boolean
  withPagination?: boolean
  withSkeletonView?: boolean
  withStickyHeader?: boolean
  withTableSummary?: boolean
}

interface BaseTablePropsWithId<T extends TableData, K = boolean> extends BaseTableProps<T, K>, IdProperty {}

export type TableProps<T extends TableData, K = boolean> = K extends true
  ? BaseTablePropsWithId<T, K>
  : BaseTableProps<T, K> & Partial<IdProperty>

export const Table = <T extends TableData, K = boolean>({
  bordered = true,
  columns,
  currentPage,
  data,
  emptyRowInfo,
  groupRowsBy,
  id,
  isClientSidePagination,
  isLoading,
  isSelectable,
  itemsPerPage: itemsPerPageControlled,
  onAllRowsSelect,
  onItemsPerPageChange,
  onPageChange,
  onRowClick,
  onRowSelect,
  onSort,
  rowSelectType = 'checkbox',
  skeletonItemsPerPage,
  sortedColumnId = '',
  totalPageItems,
  withColumnsFiltering = true as unknown as K, // TODO: Figure it out why it has to be like this... :see_no_evil:
  withEmptyRowInfo = true,
  withItemsPerPageSelect,
  withPagination = true,
  withSkeletonView = true,
  withStickyHeader,
  withTableSummary = true,
}: TableProps<T, K>): ReactElement => {
  const isSkeletonView = isLoading && withSkeletonView

  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const [savedItemsPerPage, setItemsPerPage] = useItemsPerPage()
  const [expandedRowId, setExpandedRowId] = useState<string>()
  const [sortedColumn, setSortedColumn] = useState<TableSortedColumnState>(getCorrectColumnId(sortedColumnId))
  const { clientSidePage, clientSidePaginationData, setClientSidePage } = useClientSidePagination<T>({
    data,
    page: currentPage,
    isActive: isClientSidePagination,
    isSkeletonView,
    itemsPerPage: savedItemsPerPage,
  })

  useEffect(
    () => {
      if (withItemsPerPageSelect && itemsPerPageControlled !== undefined) {
        setItemsPerPage(itemsPerPageControlled)
      }
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [itemsPerPageControlled],
  )

  useEffect(() => {
    setSortedColumn(getCorrectColumnId(sortedColumnId))
  }, [sortedColumnId])

  const hiddenColumnsIds = useMemo(() => getHiddenColumnsIds<T>(columns), [columns])
  const { hiddenColumns, updateHiddenColumns } = useHiddenColumns<T>(id, hiddenColumnsIds)

  const memoizedColumns = useMemo(
    () => getColumns(columns),
    // `T` should be passed as an argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns],
  )

  const tableData = useMemo(() => {
    if (isSkeletonView) {
      return generateMockTableData(columns, skeletonItemsPerPage || savedItemsPerPage)
    }

    if (isClientSidePagination) {
      return clientSidePaginationData
    }

    return data
  }, [
    clientSidePaginationData,
    columns,
    data,
    isClientSidePagination,
    isSkeletonView,
    savedItemsPerPage,
    skeletonItemsPerPage,
  ])

  // `@ts-ignore` can't be used over object item... I had to block prettier formatting due to that
  // prettier-ignore
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { toggleHideColumn, allColumns, getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable<T>(
    {
      columns: memoizedColumns as Column<T>[],
      data: tableData,
      initialState: {
        hiddenColumns: ['selection', ...hiddenColumns, ...EXCLUDED_ACCESSORS],
      },
    },
    useFlexLayout,
    useRowSelect,
  )

  const rowsGroupped: Record<string, Row<T>[]> = useMemo(
    () => (groupRowsBy ? groupBy(rows, groupRowsBy) : { 0: rows }),
    [groupRowsBy, rows],
  )

  const handleHiddenColumnSelect = useCallback(
    (id: string, checked?: boolean) => {
      updateHiddenColumns(id, checked)
      // We need to hide unchecked columns, that's why !checked
      toggleHideColumn(id, !checked)
    },
    [toggleHideColumn, updateHiddenColumns],
  )

  const toggleableColumns = getToggleableColumns(allColumns)
  const withExpander = useMemo(() => columns.some(({ accessor }) => accessor === InternalAccessor.SubRow), [columns])
  const withStaticActions = useMemo(
    () => columns.some(({ accessor }) => accessor === InternalAccessor.StaticActions),
    [columns],
  )
  const isTableEmpty = withEmptyRowInfo && !isSkeletonView && !data?.length

  const handleExpandableRowClick = useCallback(
    (row: Row<T>) => {
      const rowId = row.original.id
      setExpandedRowId(rowId !== expandedRowId ? rowId : undefined)
    },
    // `T` should be passed as an argument to update because it's Typescript prop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandedRowId],
  )

  const handleSortClick = useCallback(
    (columnId: string, isDesc: boolean) => {
      if (onSort) {
        setSortedColumn({ id: columnId, isSortedDesc: isDesc })
        onSort(columnId, isDesc)
      }
    },
    [onSort],
  )

  const handleClientSidePage = useCallback(
    (page: number) => {
      if (setClientSidePage) {
        setClientSidePage(page)
      }

      onPageChange?.(page)
    },
    [onPageChange, setClientSidePage],
  )

  const handleItemsPerPageChange = useCallback(
    (newItemsPerPage: ItemsPerPage) => {
      if (isClientSidePagination) {
        setItemsPerPage(newItemsPerPage)
        onItemsPerPageChange?.(newItemsPerPage)
      } else {
        onItemsPerPageChange?.(newItemsPerPage)
      }
    },
    [isClientSidePagination, onItemsPerPageChange, setItemsPerPage],
  )

  const paginationProps: TablePaginationProps | undefined = useMemo(() => {
    const isServerSidePagination = !!onPageChange

    if (!(isServerSidePagination || isClientSidePagination)) {
      return undefined
    }

    return isClientSidePagination
      ? {
          currentPage: clientSidePage,
          itemsPerPage: savedItemsPerPage,
          onChange: handleClientSidePage,
          tableRef: tableWrapperRef,
          totalItems: isSkeletonView ? 0 : data.length,
        }
      : {
          currentPage,
          itemsPerPage: savedItemsPerPage,
          onChange: onPageChange || noop,
          tableRef: tableWrapperRef,
          totalItems: totalPageItems || 0,
        }
  }, [
    clientSidePage,
    currentPage,
    data.length,
    handleClientSidePage,
    isClientSidePagination,
    isSkeletonView,
    savedItemsPerPage,
    onPageChange,
    totalPageItems,
  ])
  const isFooterVisible = !!((withColumnsFiltering && id) || (withPagination && paginationProps))

  return (
    <SelectRowsContextProvider
      onSelect={onRowSelect}
      onSelectAll={onAllRowsSelect}
      rowsCount={rows.length}
      selectType={rowSelectType}
    >
      <Styled.TableWrapper
        {...getTableProps()}
        role={isSkeletonView ? 'loader' : 'table'}
        ref={tableWrapperRef}
        bordered={bordered}
      >
        {headerGroups.map(({ getHeaderGroupProps, headers }) => (
          <TableHeader<T>
            {...getHeaderGroupProps()}
            expandable={withExpander}
            isSelectable={isSelectable}
            isSticky={withStickyHeader}
            noContent={!rows.length && !isSkeletonView}
            rows={rows}
            rowSelectType={rowSelectType}
            bordered={bordered}
          >
            {headers.map((column) => {
              const columnProps = column.getHeaderProps()

              return (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                <TableHeaderItem
                  {...columnProps}
                  /* eslint-disable @typescript-eslint/ban-ts-comment */
                  // @ts-ignore
                  alignment={column.alignment}
                  columnId={column.id}
                  // @ts-ignore
                  fixedSize={column.fixedSize}
                  isSortedDesc={sortedColumn.isSortedDesc}
                  // @ts-ignore
                  sortable={column.canSort}
                  sorted={column.id === sortedColumn.id}
                  onSort={handleSortClick}
                  // @ts-ignore
                  truncate={column.truncate}
                  /* eslint-disable @typescript-eslint/ban-ts-comment */
                >
                  {column.render('Header')}
                </TableHeaderItem>
              )
            })}

            {withStaticActions && <TableStaticActionsHeaderItem />}
          </TableHeader>
        ))}
        <Styled.TableContent as={isTableEmpty ? 'ol' : undefined} {...getTableBodyProps()}>
          {isTableEmpty && <TableEmptyView emptyRowInfo={emptyRowInfo} />}

          {Object.entries(rowsGroupped).map(([key, rows]) => {
            return (
              <Styled.TableGroup key={key}>
                {rows.map((row, index) => {
                  const rowId = row.original.id
                  const hasSubRow = row.values[InternalAccessor.SubRow]
                  const isExpandable = !isSkeletonView && withExpander
                  const isRowExpanded = rowId !== undefined && expandedRowId !== undefined && rowId === expandedRowId
                  const urlExternal = isSkeletonView ? undefined : row.values[InternalAccessor.UrlExternal]
                  const urlInternal = isSkeletonView ? undefined : row.values[InternalAccessor.UrlInternal]

                  prepareRow(row)

                  return (
                    <Fragment key={isSkeletonView ? index : rowId}>
                      <TableRow
                        expandable={isExpandable}
                        expanded={isRowExpanded}
                        id={rowId}
                        isSelectable={isSelectable}
                        isSkeletonView={isSkeletonView}
                        onClick={isExpandable ? handleExpandableRowClick : onRowClick}
                        row={row}
                        selectType={rowSelectType}
                        urlExternal={urlExternal}
                        urlInternal={urlInternal}
                        withStaticActions={withStaticActions}
                      >
                        {hasSubRow && !isSkeletonView && (
                          <TableRowExpander expanded={isRowExpanded} isSelectable={isSelectable} />
                        )}
                        {row.cells.map((cell) => {
                          const column = cell.column as TableColumn<T>

                          return (
                            <TableCell {...cell.getCellProps()} cell={cell} isSkeletonView={isSkeletonView}>
                              {isSkeletonView ? (
                                <Styled.SkeletonCellMock alignment={column.alignment} />
                              ) : (
                                cell.render('Cell')
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                      {isRowExpanded && hasSubRow && !isSkeletonView && (
                        <Styled.TableSubRow>
                          {row.allCells.find(({ column }) => column.id === InternalAccessor.SubRow)?.render('Cell')}
                        </Styled.TableSubRow>
                      )}
                    </Fragment>
                  )
                })}
              </Styled.TableGroup>
            )
          })}
        </Styled.TableContent>
      </Styled.TableWrapper>
      {isFooterVisible && (
        <Styled.TableFooterActions>
          {withColumnsFiltering && id && (
            <TableColumnsFiltering
              columns={toggleableColumns}
              isLoading={isLoading}
              hiddenColumnsIds={hiddenColumns}
              onSelect={handleHiddenColumnSelect}
            />
          )}
          {withPagination && paginationProps && (
            <TablePagination
              {...paginationProps}
              isLoading={isSkeletonView}
              itemsPerPage={savedItemsPerPage}
              onSelectChange={handleItemsPerPageChange}
              tableRef={tableWrapperRef}
              withItemsPerPageSelect={withItemsPerPageSelect}
              withTableSummary={withTableSummary}
            />
          )}
        </Styled.TableFooterActions>
      )}
    </SelectRowsContextProvider>
  )
}
