import { Color, DefaultValue, Flex } from '@design-system'

import styled from '@emotion/styled'
import React, { cloneElement, isValidElement, ReactElement } from 'react'
import { Control, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Themable } from '../../types/themable'
import { trackError } from '../../utils/trackError'
import { IconAdd, IconTrash } from '../Icons'
import { FormLabel, FormLabelProps } from './FormLabel'

const isValidChildElement = (child: ReactElement) => {
  const { formControl } = child.props
  return isValidElement(child) && formControl
}

type FormGroupContainerProps = {
  horizontal?: boolean
}

export const FormGroupContainer = styled.div<FormGroupContainerProps>`
  flex: auto;
  ${({ horizontal }) => {
    if (horizontal) {
      return `
        display: flex;

        label {
          display: none;
        }

        > div {

          input {
            border-radius: 0;
            margin-left: -1px;

            &:focus {
              z-index: 1;
              position: relative;
            }
          }

          &:first-of-type {
            input {
              margin-left: 0px;
              border-top-left-radius: ${DefaultValue.BorderRadius};
              border-bottom-left-radius: ${DefaultValue.BorderRadius};
            }
          }

          &:last-of-type {
            input {
              border-top-right-radius: ${DefaultValue.BorderRadius};
              border-bottom-right-radius: ${DefaultValue.BorderRadius};
            }
          }
        }
      `
    }

    return `
      label {
        display: none;
      }

      > div {
        margin-bottom: 0;

        input {
          border-radius: 0;
          margin-top: -1px;

          &:focus {
            z-index: 1;
            position: relative;
          }
        }

        &:first-of-type {
          input {
            border-top-left-radius: ${DefaultValue.BorderRadius};
            border-top-right-radius: ${DefaultValue.BorderRadius};
          }
        }

        &:last-of-type {
          input {
            border-bottom-left-radius: ${DefaultValue.BorderRadius};
            border-bottom-right-radius: ${DefaultValue.BorderRadius};
          }
        }
      }
    `
  }};
`

const TrashIconContainer = styled.div`
  margin-left: 16px;
  padding-top: 5px;
  cursor: pointer;
  color: ${Color.Gray90};

  &:hover {
    color: ${Color.DeepSeaGreen};
  }
`

type AddIconContainerProps = FormLabelProps & Themable

const AddIconContainer = styled(FormLabel)<AddIconContainerProps>`
  display: flex;
  margin-top: 10px;
  align-items: flex-end;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.light};
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
  i {
    margin-right: 5px;
  }
`

type ControlledFormGroupProps = {
  addButtonLabel?: string
  editable?: boolean
  children: ReactElement[]
  defaultLineValues?: Record<string, any>
  formControl: Control<any>
  horizontal?: boolean
  label?: string
  name: string
  required?: boolean
}

export const ControlledFormGroup = ({
  addButtonLabel,
  editable,
  children,
  defaultLineValues,
  formControl,
  horizontal,
  label,
  name,
  required,
}: ControlledFormGroupProps): ReactElement => {
  const { t } = useTranslation()
  const { fields, append, remove } = useFieldArray({
    control: formControl,
    name: name as string,
  })

  return (
    <div>
      {label && <FormLabel required={required}>{label}</FormLabel>}
      {fields.map((item, index) => (
        <Flex key={item.id}>
          <FormGroupContainer horizontal={horizontal}>
            {children.map((childInput) => {
              if (!isValidChildElement(childInput)) {
                trackError(
                  'Not a valid type of element used in <ControlledFormGroup />. Please check children elements. Valid elements need to support formControl property.',
                )
                return null
              }

              const childName = `${name}[${index}].${childInput.props.name}`

              return cloneElement(childInput, {
                ...childInput.props,
                name: childName,
                key: childName,
                defaultValue: item[childInput.props.name],
                errorPath: childName,
              })
            })}
          </FormGroupContainer>
          {editable && fields.length > 1 && (
            <TrashIconContainer onClick={() => remove(index)}>
              <IconTrash width={14} />
            </TrashIconContainer>
          )}
        </Flex>
      ))}
      {editable && (
        <AddIconContainer onClick={() => append(defaultLineValues || {})}>
          <IconAdd color="inherit" width={13} />
          {addButtonLabel || t('add')}
        </AddIconContainer>
      )}
    </div>
  )
}
