import { useModalContentToggler } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useBillView } from '@views/bills/elements/BillReadableForm'
import { useBillReconciliationSuggestions } from '@views/bills/hooks/useBillReconciliationSuggestions'
import { CreatePrepaymentResponseData } from '@views/bills/query-api'
import { getPaymentRegistrationModalView } from '@views/bills/utils/getPaymentRegistrationModalView'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useContactBalance } from '../../contexts/contactBalanceContext'
import { PaymentRegistrationSuccessState } from '../../enums/paymentRegistrationSuccessState'
import { useRegisterPrepayment } from '../../hooks/useRegisterPrepayment'
import { getBillBalanceSum } from '../../utils/getBillBalanceSum'
import { notifyPaymentRegistrationSuccess } from '../../utils/notifyPaymentRegistrationSuccess'

export const PrepaymentModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { setActiveState } = useModalContentToggler()
  const { billData } = useBillView()
  const { billHasMatchSuggestion } = useBillReconciliationSuggestions(billData?.bill)
  const { contact, contactBalance } = useContactBalance()
  const { paymentDate } = useBillReconciliation()
  const { registerContactBalancePayment, isProcessing } = useRegisterPrepayment({
    contact,
    contactBalance,
    bill: billData?.bill,
    paymentDate,
    onSuccess: (data: CreatePrepaymentResponseData): void => {
      const billBalanceSum = getBillBalanceSum(data?.bills || [])
      if (billBalanceSum === 0) {
        notifyPaymentRegistrationSuccess(PaymentRegistrationSuccessState.RegisteredWithPrepayment, t)
        closePrepaymentModal()
        return
      }
      setActiveState(getPaymentRegistrationModalView(false, billHasMatchSuggestion))
    },
  })

  const { close: closePrepaymentModal } = useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Prepayment modal closed')
    },
  })

  const handleUsePrepaymentButtonClick = useCallback(() => {
    registerContactBalancePayment()
  }, [registerContactBalancePayment])

  const handleUseWithoutPrepaymentButtonClick = useCallback(() => {
    const nextModalViewId = getPaymentRegistrationModalView(false, billHasMatchSuggestion)
    setActiveState(nextModalViewId)
    track('xxx - expenses - Prepayment modal - pay without prepayment clicked')
  }, [billHasMatchSuggestion, setActiveState, track])

  return (
    <ButtonsGroup>
      <Button disabled={isProcessing} onClick={handleUseWithoutPrepaymentButtonClick} variant="secondary">
        {t('bill.payment_reconcile.button.pay_without_prepayment')}
      </Button>
      <Button disabled={isProcessing || !paymentDate} onClick={handleUsePrepaymentButtonClick}>
        {t('bill.payment_reconcile.button.use_prepayment')}
      </Button>
    </ButtonsGroup>
  )
}
