import { useCallback } from 'react'

import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { trackError } from '../../../../utils/trackError'

export const useGetOfferClick = ({
  isEligibleForLending,
  organization,
  openFrodaLendingEligibilityModal,
  setIsProcessing,
  onboardUser,
}: {
  isEligibleForLending: boolean
  organization: Organization | undefined
  openFrodaLendingEligibilityModal: () => void
  setIsProcessing: (loading: boolean) => void
  onboardUser: () => void
}) => {
  const onGetOfferClick = useCallback(async () => {
    if (!isEligibleForLending || !organization?.id) {
      openFrodaLendingEligibilityModal()
      return
    }

    setIsProcessing(true)

    try {
      onboardUser()
    } catch (error) {
      setIsProcessing(false)
      trackError(error)
    }
  }, [isEligibleForLending, organization?.id, openFrodaLendingEligibilityModal, setIsProcessing, onboardUser])

  return { onGetOfferClick }
}
