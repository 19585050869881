import {
  AttachmentFile,
  Color,
  FilesPreview,
  getFileThumbnail,
  Icon,
  SkeletonBox,
  Text,
  useModal,
} from '@design-system'

import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getClassName, getImageClassName } from '../../../../../../../../utils/getClassName'
import * as Styled from './styles'

interface AttachmentTileImageProps {
  attachmentPreviewModalId: string
  attachmentImageFile: AttachmentFile
  onClick?: () => void
}

export const AttachmentTileImage = ({
  attachmentPreviewModalId,
  attachmentImageFile,
  onClick,
}: AttachmentTileImageProps): ReactElement => {
  const [isAttachmentImageLoading, setIsAttachmentImageLoading] = useState(true)
  const { t } = useTranslation()
  const { open: openAttachmentPreviewModal } = useModal(attachmentPreviewModalId)

  const attachmentImageFileName = attachmentImageFile.fileName || undefined
  const attachmentImageFiles = useMemo(
    () => [{ src: getFileThumbnail(attachmentImageFile, 'medium') }],
    [attachmentImageFile],
  )

  const handleImageLoad = useCallback(() => {
    setIsAttachmentImageLoading(false)
  }, [])

  const handleImageClick = useCallback(() => {
    if (onClick) {
      onClick?.()
    } else {
      openAttachmentPreviewModal()
    }
  }, [onClick, openAttachmentPreviewModal])

  return (
    <Styled.AttachmentTileImageWrapper onClick={handleImageClick} title={attachmentImageFileName}>
      <Styled.AttachmentTilePreviewWrapper loading={isAttachmentImageLoading}>
        <FilesPreview
          className={getImageClassName()}
          files={attachmentImageFiles}
          fitToWidth
          onLoad={handleImageLoad}
          withNavigation={false}
          fitType="cover"
        />
      </Styled.AttachmentTilePreviewWrapper>
      <Styled.AttachmentHoverOverlay>
        <Text alignment="center" color={Color.Gray90} className={getClassName('label-deprecated')}>
          <Icon icon="eye" color="currentColor" /> {t('receipts.receipts_list.preview_attachment')}
        </Text>
      </Styled.AttachmentHoverOverlay>
      {isAttachmentImageLoading && <SkeletonBox fullHeight fullWidth />}
    </Styled.AttachmentTileImageWrapper>
  )
}
