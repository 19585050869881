import { memo, ReactElement, useEffect, useRef, useState } from 'react'

import { FileComponentProps } from '../../types'
import * as Styled from './styles'

export const HtmlPreview = memo(({ fitToHeight, onLoad, src }: FileComponentProps): ReactElement => {
  const [isHtmlLoaded, setIsHtmlLoaded] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) {
      const shadow = ref.current.attachShadow({ mode: 'open' })
      const wrapper = document.createElement('div')
      wrapper.innerHTML = src
      shadow.appendChild(wrapper)
      setIsHtmlLoaded(true)
      onLoad?.()
    }
  }, [onLoad, src])

  return <Styled.HtmlWrapper ref={ref} fitToHeight={fitToHeight} isLoading={!isHtmlLoaded} />
})
