import {
  Attachment,
  ButtonDropdown,
  IconButton,
  IconName,
  NavItem,
  Tooltip,
  useModal,
  withModalConditionalRender,
} from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ChooseAttachmentModal as ChooseAttachmentModalComponent } from '@features/attachments/elements/ChooseAttachmentModal'

import { ModalId } from '../../../../enums/modalId'
import { ActionMenuItem } from './enums/ActionMenuItem'
import * as Styled from './styles'

export interface AttachmentActionsDropdownMenuProps {
  areTooltipsHidden: boolean
  attachmentsIdsChosen?: string[]
  handleDeleteClick: () => void
  handleDownloadClick: () => void
  onFileFromUploadsSelect?: (attachment: Attachment) => void
  onTooltipShow: () => void
  tooltipDelay: number
  withDeleteButton?: boolean
  withDownloadButton?: boolean
}

const ChooseAttachmentModal = withModalConditionalRender(ChooseAttachmentModalComponent)

export const AttachmentActionsDropdownMenu = ({
  areTooltipsHidden,
  attachmentsIdsChosen,
  handleDeleteClick,
  handleDownloadClick,
  onFileFromUploadsSelect,
  onTooltipShow,
  tooltipDelay,
  withDeleteButton,
  withDownloadButton,
}: AttachmentActionsDropdownMenuProps): ReactElement => {
  const { t } = useTranslation()
  const { open: openChooseFromUploadsModal } = useModal(ModalId.AttachmentsModal)

  const items = useMemo(
    () =>
      [
        !!onFileFromUploadsSelect && {
          children: t('bill.attachments.add_new'),
          iconLeft: 'picture' as IconName,
          id: ActionMenuItem.Uploads,
          value: ActionMenuItem.Uploads,
        },
        withDownloadButton && {
          children: t('bill.attachments.download'),
          iconLeft: 'arrowPointingDown' as IconName,
          id: ActionMenuItem.Download,
          value: ActionMenuItem.Download,
        },
        withDeleteButton && {
          children: t('bill.attachments.delete'),
          iconLeft: 'trash' as IconName,
          id: ActionMenuItem.Delete,
          value: ActionMenuItem.Delete,
        },
      ].filter(Boolean) as NavItem<ActionMenuItem>[],
    [onFileFromUploadsSelect, t, withDeleteButton, withDownloadButton],
  )

  const handleFileFromUploadsSelect = useCallback(
    (attachment: Attachment) => {
      onFileFromUploadsSelect?.(attachment)
    },
    [onFileFromUploadsSelect],
  )

  const handleItemSelect = useCallback(
    (_: string, value: ActionMenuItem) => {
      switch (value) {
        case ActionMenuItem.Delete:
          handleDeleteClick()
          break
        case ActionMenuItem.Download:
          handleDownloadClick()
          break
        case ActionMenuItem.Uploads:
          openChooseFromUploadsModal()
          break
      }
    },
    [handleDeleteClick, handleDownloadClick, openChooseFromUploadsModal],
  )

  return (
    <>
      <ButtonDropdown
        dropdownWidth={215}
        items={items}
        onSelect={handleItemSelect}
        variant="secondary"
        placement="top-end"
        triggerElement={
          <Styled.MenuButtonWrapper>
            <Tooltip
              delay={tooltipDelay}
              forceHidden={areTooltipsHidden}
              label={t('more')}
              placement="top"
              onShow={onTooltipShow}
            >
              <IconButton variant="text" icon="threeDots" title={t('more')} size="m" />
            </Tooltip>
          </Styled.MenuButtonWrapper>
        }
      />
      {!!onFileFromUploadsSelect && (
        <ChooseAttachmentModal
          id={ModalId.AttachmentsModal}
          idsToSkip={attachmentsIdsChosen}
          onSelect={handleFileFromUploadsSelect}
          size="xl"
        />
      )}
    </>
  )
}
