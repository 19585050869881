import { useCallback } from 'react'

import { useInvoiceEditorVatRates } from '../contexts/InvoiceEditorVatRatesContext'
import { InvoiceForm } from '../types/invoiceForm'

export const useConvertedInvoiceValues = () => {
  const { vatRates, isLoading } = useInvoiceEditorVatRates()

  const getConvertedInvoiceValues = useCallback(
    (invoiceValues: InvoiceForm) => {
      const convertedInvoiceValues: InvoiceForm = {
        ...invoiceValues,
        invoiceLines: invoiceValues.invoiceLines.map((invoiceLine) => {
          const vatRatePredefinedTag = vatRates.find((vatRate) => invoiceLine.vatRateId === vatRate.id)?.predefinedTag

          return { ...invoiceLine, vatRateId: vatRatePredefinedTag || undefined }
        }),
      }

      return convertedInvoiceValues
    },
    [vatRates],
  )

  return { getConvertedInvoiceValues, isLoading }
}
