import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { InvoiceDocumentType } from '../../../../enums/invoiceDocumentType'
import { InvoiceEditorVersion } from '../../../../enums/invoiceEditorVersion'
import { Language } from '../../../../types/language'
import { ZervantSettings } from '../../../../types/zervantSettings'
import { ZervantSettingsModel } from '../../../../types/zervantSettingsModel'
import { InvoiceForm } from '../../types/invoiceForm'
import { InvoicePayload } from '../../types/invoicePayload'
import { getFieldsPayload } from './getFieldsPayload'
import { getInvoiceNumberPayload } from './getInvoiceNumberPayload'
import { getInvoicerPayload } from './getInvoicerPayload'
import { getLinesPayload } from './getLinesPayload'
import { getPaymentMeansPayload } from './getPaymentMeansPayload'
import { getReceiverPayload } from './getReceiverPayload'
import { getSummaryPayload } from './getSummaryPayload'

interface GetInvoicePayloadOptions {
  invoiceValues: InvoiceForm
  organization?: Organization
  zervantSettings?: ZervantSettings
}

const getInvoiceLanguage = (formValues: InvoiceForm, zervantSettingsModels?: ZervantSettingsModel[]): Language => {
  const invoiceModel = (zervantSettingsModels || []).find((model) => model.type === InvoiceDocumentType.Invoice)
  const settingsInvoiceLanguage = (invoiceModel?.fields || []).find((field) => field?.name === 'language')?.default
  const receiverInvoiceLanguage = formValues.receiver?.invoicingLanguage

  return (receiverInvoiceLanguage || settingsInvoiceLanguage || 'en') as Language
}

export const getInvoicePayload = ({
  invoiceValues,
  organization,
  zervantSettings,
}: GetInvoicePayloadOptions): InvoicePayload => {
  const invoiceLanguage = getInvoiceLanguage(invoiceValues, zervantSettings?.models)

  return {
    document: InvoiceDocumentType.Invoice,
    eInvoiceSending: false,
    fields: getFieldsPayload(invoiceValues, invoiceLanguage, zervantSettings?.models),
    invoiceNumber: getInvoiceNumberPayload(invoiceValues),
    invoicer: getInvoicerPayload(organization, zervantSettings?.values?.company),
    lines: getLinesPayload(invoiceValues),
    model: 'models.invoice.sales',
    paymentMeans: getPaymentMeansPayload(zervantSettings?.values?.paymentMethods),
    receiver: getReceiverPayload(invoiceValues, invoiceLanguage),
    summary: getSummaryPayload(invoiceValues),
    version: InvoiceEditorVersion.Ageras,
  }
}
