import { useModalContentToggler } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useInvalidateBills } from '../../../../hooks/useInvalidateBills'
import { useCreateBillsAndBankLinesReconciliation } from '../../../../hooks/useReconcileBillsAndBankLines'
import { ReconcilableParameters } from '../../../../query-api'
import { useBankLines } from '../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'
import { PaymentRegistrationSuccessState } from '../../enums/paymentRegistrationSuccessState'
import { convertBillsIdsToReconciliationSubjects } from '../../utils/convertBillsIdsToReconciliationSubjects'
import { notifyPaymentRegistrationError } from '../../utils/notifyPaymentRegistrationError'
import { notifyPaymentRegistrationSuccess } from '../../utils/notifyPaymentRegistrationSuccess'

export const BankLinesModalFooter = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { initialBill, selectedDifferenceType } = useBillReconciliation()
  const { bankLines, hasBankLinesDifference, selectedBankLinesIds } = useBankLines()
  const { selectedBillsIds, isCreditNoteSelected } = useMultipleBills()
  const { setActiveState } = useModalContentToggler()
  const { invalidateBills, invalidateBill } = useInvalidateBills()
  const { close: closeBillReconciliationModal } = useModal(ModalId.BillReconciliationModal, {
    onClose: () => {
      track('xxx - expenses - Bank line modal closed')
    },
  })

  const { create: createBillsAndBankLinesReconcilation, isProcessing } = useCreateBillsAndBankLinesReconciliation({
    onSuccess: () => {
      notifyPaymentRegistrationSuccess(PaymentRegistrationSuccessState.RegisteredAndReconciled, t)
      invalidateBills()
      invalidateBill(initialBill?.id)
      closeBillReconciliationModal()
    },
    onError: () => notifyPaymentRegistrationError(t),
  })

  const organizationId = organization?.id
  const isEnterPaymentButtonDisabled = useMemo(
    () => (hasBankLinesDifference && !selectedDifferenceType) || !selectedBankLinesIds?.length,
    [hasBankLinesDifference, selectedBankLinesIds?.length, selectedDifferenceType],
  )

  const handleEnterPaymentButtonClick = useCallback(() => {
    if (!selectedBankLinesIds?.length || !selectedBillsIds?.length || !organizationId) {
      return
    }

    if (selectedBankLinesIds?.length === 1) {
      track('xxx - expenses - Bank line modal - register payment clicked', {
        bankLinesCount: bankLines?.length || 0,
      })
    }

    if (selectedBankLinesIds?.length > 1) {
      track('xxx - expenses - Bank line modal - register multiple payments clicked', {
        selectedBankLinesCount: selectedBankLinesIds.length,
      })
    }

    const billReconciliationSubjects = convertBillsIdsToReconciliationSubjects(selectedBillsIds)
    const reconcilableParameters: ReconcilableParameters = {}

    if (selectedDifferenceType) {
      reconcilableParameters.differenceType = selectedDifferenceType
    }

    createBillsAndBankLinesReconcilation({
      bankLinesIds: selectedBankLinesIds,
      organizationId,
      subjects: billReconciliationSubjects,
      parameters: reconcilableParameters,
    })
  }, [
    bankLines?.length,
    createBillsAndBankLinesReconcilation,
    organizationId,
    selectedBankLinesIds,
    selectedBillsIds,
    selectedDifferenceType,
    track,
  ])

  const handleRegisterMultipleBillsButton = useCallback(() => {
    track('xxx - expenses - Bank line modal - register mutliple bills clicked')

    setActiveState(BillReconciliationModalStateId.MultipleBills)
  }, [setActiveState, track])

  return (
    <ButtonsGroup>
      {!isCreditNoteSelected && (
        <Button onClick={handleRegisterMultipleBillsButton} variant="text" icon="papersPile">
          {t('bill.payment_reconcile.button.register_multiple_bills')}
        </Button>
      )}
      <Button onClick={handleEnterPaymentButtonClick} disabled={isEnterPaymentButtonDisabled} loading={isProcessing}>
        {t('bill.payment_reconcile.button.enter_payment_and_reconcile')}
      </Button>
    </ButtonsGroup>
  )
}
