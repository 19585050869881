import { ReactZoomPanPinchState } from 'react-zoom-pan-pinch'

import { ZoomDirection } from '../enums/zoomDirection'

interface HandleZoomProps {
  direction: ZoomDirection
  setTransform: (newPositionX: number, newPositionY: number, newScale: number) => void
  transformState: ReactZoomPanPinchState
}

const ZOOM_LEVELS = [0.75, 1.0, 1.25, 1.5, 2.0, 4.0]

export const handleZoom = ({ direction, transformState, setTransform }: HandleZoomProps) => {
  const { scale, positionX, positionY } = transformState

  const currentIndex = ZOOM_LEVELS.findIndex((level) => level >= scale)

  let newScale = 1.0

  if (direction === ZoomDirection.zoomIn) {
    newScale = ZOOM_LEVELS[Math.min(currentIndex + 1, ZOOM_LEVELS.length - 1)]
  } else {
    newScale = ZOOM_LEVELS[Math.max(currentIndex - 1, 0)]
  }

  setTransform(positionX, positionY, newScale)
}
