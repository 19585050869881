import { trackError } from './trackError'

export const deprecatedComponentInfo = (deprecatedComponentName: string, correctComponentName: string) => {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  trackError(`You are using deprecated component <${deprecatedComponentName} />. \
Please use <${correctComponentName} /> instead.`)
}
