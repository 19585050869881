import React, { Fragment, ReactNode, useMemo, useRef } from 'react'

import { useMeasureDirty } from '../../../../hooks/useMeasureDirty'
import { useAdjustedDimensions } from '../../hooks/useAdjustedDimensions'
import { FileComponentProps, FileType, PreviewFile, RotatedFiles } from '../../types'
import { FilesPreviewContentProps } from '../FilesPreviewContent'
import { HtmlPreview } from '../HtmlPreview'
import { ImagePreview } from '../ImagePreview'
import { LazyPreview } from '../LazyPreview'
import { PdfReact } from '../PdfReact'
import * as Styled from './styles'

const formatToComponent: Record<FileType, React.MemoExoticComponent<React.ComponentType<FileComponentProps>>> = {
  [FileType.Image]: ImagePreview,
  [FileType.Pdf]: PdfReact,
  [FileType.Html]: HtmlPreview,
}

export interface FileContentPropsType extends FilesPreviewContentProps {
  actionsComponent?: ReactNode
  activeFileType: FileType
  activePdfPage: number
  file: PreviewFile
  handleLoad: () => void
  onPdfLoad: (arg: number) => void
  rotatedFiles?: RotatedFiles
}

export const FileContent = ({
  actionsComponent,
  activePdfPage,
  activeFileType,
  aspectRatio,
  borderRadius,
  className,
  circle,
  file,
  fitToHeight,
  fitToWidth,
  fitType = 'contain',
  handleLoad,
  lazyLoadOptions,
  margin,
  onError,
  onImageZoomClick,
  onPdfLoad,
  rotatedFiles,
  withLazyLoad,
  withModalZoom,
  withShadow,
  zoomable,
}: FileContentPropsType) => {
  const componentRef = useRef<HTMLDivElement>(null)
  const { height: componentHeight = 0, width: componentWidth } = useMeasureDirty(componentRef)

  const FileComponent = formatToComponent[activeFileType]
  const FileComponentContainer = withLazyLoad ? LazyPreview : Fragment
  const fileComponentContainerOptions = withLazyLoad ? { options: lazyLoadOptions } : {}
  const showActions = !!actionsComponent && !zoomable

  const { width, height } = useAdjustedDimensions({
    aspectRatio,
    componentHeight,
    componentWidth,
    fitToHeight,
    fitToWidth,
  })

  const currentFileRotation = useMemo(() => rotatedFiles?.[file.src], [file.src, rotatedFiles])

  return (
    <Styled.StickyWrapper
      aspectRatio={aspectRatio}
      borderRadius={borderRadius}
      className={className}
      clickable={withModalZoom}
      fitToHeight={fitToHeight}
      fitToWidth={fitToWidth}
      fitType={fitType}
      margin={margin}
      onClick={withModalZoom && onImageZoomClick ? onImageZoomClick : undefined}
      ref={componentRef}
      rotation={currentFileRotation}
      withShadow={withShadow}
    >
      <FileComponentContainer {...fileComponentContainerOptions}>
        <FileComponent
          aspectRatio={aspectRatio}
          activePage={activePdfPage}
          alt={file.alt}
          circle={circle}
          fitToHeight={fitToHeight}
          fitToWidth={fitToWidth}
          fitType={fitType}
          height={fitType === 'contain' ? height : 0}
          onError={onError}
          onLoad={handleLoad}
          onPdfLoad={onPdfLoad}
          rotation={currentFileRotation}
          src={file.src}
          width={width}
        />
      </FileComponentContainer>
      {showActions && actionsComponent}
    </Styled.StickyWrapper>
  )
}
