import { EmptyView, emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, useHistory } from 'react-router-dom'

import { Scope } from '../../../../enums/scope'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { useIsInvoicesListEmpty } from '../InvoicesList/hooks/useIsInvoicesListEmpty'

export const InvoicesListEmpty = (): ReactElement | null => {
  const { t } = useTranslation()
  const isInvoicesListEmpty = useIsInvoicesListEmpty()
  const history = useHistory()
  const { url: invoicesUrl } = useOrganizationViewUrl(reactRoute.invoices.getMainRoute())
  const { url: invoiceNewUrl } = useOrganizationViewUrl(reactRoute.invoices.getNewInvoiceRoute())

  const shouldSeeCreateButton = isAuthorized(Scope.InvoiceWrite)

  const handleEmptyViewClick = useCallback(() => {
    if (invoiceNewUrl) {
      history.push(invoiceNewUrl)
    }
  }, [history, invoiceNewUrl])

  const { animation, description, title, buttonText } = emptyViewVariants.invoices

  if (!invoicesUrl) {
    return null
  }

  if (!isInvoicesListEmpty) {
    return <Redirect to={invoicesUrl} />
  }

  return (
    <ModuleLayout title={t('invoices')}>
      <EmptyView
        animation={animation}
        onClick={handleEmptyViewClick}
        showCTA={shouldSeeCreateButton}
        tButton={buttonText}
        tDescription={description}
        tTitle={title}
      />
    </ModuleLayout>
  )
}
