import { notify } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { useDirtyRoute } from '../../../../../contexts/dirtyRouteContext'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useFormContext, useUserflow } from '../../../../../hooks'
import { getOrganizationViewUrl } from '../../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../../utils/routing/reactRoute'
import { InvoiceDocumentType } from '../../../enums/invoiceDocumentType'
import { useDownloadInvoiceAsPdf } from '../../../hooks/useDownloadInvoiceAsPdf'
import { FinalizeActionDropdownItem } from '../elements/InvoiceEditorSideActions/types/finalizeActionDropdownItem'
import { InvoiceForm } from '../types/invoiceForm'
import { InvoiceFormSchema } from '../types/invoiceFormSchema'
import { getInvoiceNumberDisplayedValue } from '../utils/getInvoiceNumberDisplayedValue'
import { useCreateAndApproveInvoice } from './useCreateAndApproveInvoice'

interface FinalizeActionOptions {
  formData: InvoiceForm
  invoiceId: number
  onSuccess?: (invoiceId: number) => void
}

type FinalizeAction = (props: FinalizeActionOptions) => void

interface ApproveAndCloseOptions {
  onSuccess?: (invoiceId: number) => void
}

type DownloadInvoiceAsPdfOptions = ApproveAndCloseOptions
interface SendAsEmailOptions extends ApproveAndCloseOptions {
  sendAsEmailFormData: any
}

const USERFLOW_EDITOR_FEEDBACK_FLOW_ID = '188c5cde-3315-4ac2-be29-d0734da678f4'

export const useInvoiceEditorFormSubmit = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { setDirty } = useDirtyRoute()
  const { organization } = useUserOrganization()
  const { start } = useUserflow()

  const { createAndApproveInvoice, isProcessing: isCreatingAndApproving } = useCreateAndApproveInvoice()
  const { download, isDownloading: isDownloadingPdf } = useDownloadInvoiceAsPdf()

  const { getValues } = useFormContext<InvoiceFormSchema>()

  const isSubmitting = isCreatingAndApproving || isDownloadingPdf

  const redirectToInvoiceView = useCallback(
    (invoiceId?: Number) => {
      if (invoiceId !== undefined) {
        history.push(
          `${getOrganizationViewUrl(
            organization?.url,
            reactRoute.invoices.getViewInvoiceRoute(String(invoiceId), InvoiceDocumentType.Invoice),
          )}`,
        )
      }
    },
    [history, organization?.url],
  )

  // "wrapper function" for all finalization actions
  const finalize = useCallback(
    (finalizeAction: FinalizeAction) => {
      const formData = getValues()
      setDirty(false)

      createAndApproveInvoice({
        formData,
        onSuccess: (invoiceId) => {
          queryClient.invalidateQueries(QueryKeys.InvoicesZervant)
          finalizeAction?.({ formData, invoiceId })
          notify({
            id: NotificationKeys.ExternalInvoiceApproved,
            message: t('external_invoices.invoice_approved'),
            variant: 'success',
          })
          start(USERFLOW_EDITOR_FEEDBACK_FLOW_ID, { once: true })
        },
      })
    },
    [createAndApproveInvoice, getValues, setDirty, start, t],
  )

  const approveAndClose = useCallback(
    ({ onSuccess }: ApproveAndCloseOptions = {}) => {
      finalize(({ invoiceId }: FinalizeActionOptions) => {
        onSuccess?.(invoiceId)
        redirectToInvoiceView(invoiceId)
      })
    },
    [finalize, redirectToInvoiceView],
  )

  const downloadAsPdf = useCallback(
    ({ onSuccess }: DownloadInvoiceAsPdfOptions = {}) => {
      finalize(({ invoiceId, formData }: FinalizeActionOptions) => {
        download({
          documentType: InvoiceDocumentType.Invoice,
          invoiceId: String(invoiceId),
          invoiceNumber: getInvoiceNumberDisplayedValue(formData.invoiceNumber, formData.invoiceNumberPrefix),
          onSuccess: () => onSuccess?.(invoiceId),
        })
        redirectToInvoiceView(invoiceId)
      })
    },
    [download, finalize, redirectToInvoiceView],
  )

  const sendAsEmail = useCallback(
    ({ onSuccess, sendAsEmailFormData }: SendAsEmailOptions) => {
      finalize(({ invoiceId, formData }: FinalizeActionOptions) => {
        onSuccess?.(invoiceId)
        // TODO:
        // implement a relevant request to the backend
      })
    },
    [finalize],
  )

  const submit: Record<FinalizeActionDropdownItem, Function> = useMemo(
    () => ({
      approveAndClose,
      downloadAsPdf,
      sendAsEmail,
    }),
    [approveAndClose, downloadAsPdf, sendAsEmail],
  )

  return {
    isSubmitting,
    submit,
  }
}
