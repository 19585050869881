export enum EmberRoute {
  Account = 'account',
  AccountsImport = 'accounts_import',
  AnnualReports = 'annual_reports',
  BalanceSheet = 'balance_sheet',
  Bank = 'bank_sync',
  BankAccounts = 'bank_accounts',
  BankConnections = 'bank_integrations',
  BankImport = 'bank_import',
  BankPayment = 'bank_payment',
  BankReact = 'bank_sync_react',
  ChartOfAccounts = 'accounts',
  Contact = 'contact',
  ContactBalancePayment = 'contact_balance_payment',
  Contacts = 'contacts',
  ContactsCustomer = 'contact.customer',
  ContactsImport = 'contacts_import',
  ContactsNew = 'contacts.new',
  ContactsSupplier = 'contact.supplier',
  Daybooks = 'daybooks',
  DaybookTransaction = 'daybook_transaction',
  Exports = 'exports',
  Financing = 'financing',
  Inbox = 'inbox',
  InboxVouchers = 'inbox.voucher_inbox',
  IncomeStatement = 'income_statement',
  Integrations = 'integrations',
  Invoice = 'invoice',
  InvoiceCreditNote = 'invoice.credit',
  InvoiceLateFee = 'invoice_late_fee',
  Invoices = 'invoices.index',
  InvoicesNew = 'invoices.new',
  InvoicesNewCreditNote = 'invoices.new_credit_note',
  InvoiceTemplate = 'template',
  Login = 'login',
  Logout = 'logout',
  Organization = 'organization',
  Organizations = 'organizations',
  Product = 'product',
  Products = 'products',
  ProductsNew = 'products.new',
  Quote = 'quote',
  Quotes = 'quotes.index',
  QuotesNew = 'quotes.new',
  RecurringInvoice = 'recurring_invoice',
  RecurringInvoices = 'recurring_invoices',
  RecurringInvoicesNew = 'recurring_invoices.new',
  Reports = 'reports_all',
  Root = '/',
  SalesTaxReturnSettings = 'sales_tax_return_settings',
  SalaryIntegration = 'salary',
  SettingsAccessTokens = 'access_tokens',
  SettingsAccounting = 'accounting_settings',
  SettingsApp = 'invoicing_settings',
  SettingsNotification = 'notification_settings',
  SettingsNotificationUmbrella = 'umbrella_notification_settings',
  SettingsOrganization = 'organization_info',
  SettingsOrganizationFeatures = 'organization_features_settings',
  SettingsOrganizationUsers = 'organization_users',
  SettingsPrivacy = 'privacy',
  SettingsPrivacyUmbrella = 'umbrella_privacy',
  SettingsSubscription = 'organization_subscription',
  SettingsSubscriptions = 'organization_subscription.index',
  SettingsSubscriptionsPlansSelection = 'organization_subscription.plans_selection',
  SettingsUser = 'user_info',
  SettingsUserUmbrella = 'umbrella_user_info',
  SettingsVatRates = 'vat_rates_settings',
  SignupGuide = 'signup-guide',
  TaxReturn = 'sales_tax_return',
  TaxReturns = 'sales_tax_returns',
  TaxReturnsSkat = 'sales_tax_returns_skat',
  Transaction = 'transaction',
  Transactions = 'transactions',
  TrialBalance = 'trial_balance',
  Umbrella = 'umbrella',
  UmbrellaOrganizations = 'umbrella_organizations',
  UmbrellaProfile = 'umbrella_profile',
  UmbrellaRoles = 'umbrella_roles',
  UmbrellaInvoices = 'umbrella_invoices',
  UmbrellaSubscriptions = 'umbrella_subscriptions',
  UmbrellaUsers = 'umbrella_users',
  Vouchers = 'vouchers',
}
