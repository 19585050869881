import { AccountSelect } from '@components'
import { Divider, FormItem, Space } from '@design-system'

import { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMatch } from '../../contexts/matchContext'
import { useMultipleBills } from '../../contexts/multipleBillsContext'
import { BillSummary } from '../BillSummary'
import { DifferenceSelector } from '../DifferenceSelector'
import { LinkToManualPayment } from './elements/LinkToManualPayment'
import { ReconciliationSuggestion } from './elements/ReconciliationSuggestion'

export const MatchModalBody = (): ReactElement => {
  const { t } = useTranslation()
  const { hasMatchDifference, matchDifference, matchSuggestion } = useMatch()
  const { initialBill } = useBillReconciliation()
  const { setSelectedBills, setSelectedBillsIds } = useMultipleBills()

  useEffect(() => {
    if (initialBill && initialBill?.id) {
      setSelectedBills?.([initialBill])
      setSelectedBillsIds?.([initialBill.id])
    }
  }, [initialBill, setSelectedBills, setSelectedBillsIds])

  return (
    <>
      <BillSummary />
      <Space />
      <Divider />
      <Space />
      <FormItem label={t('account')}>
        <AccountSelect disabled selectedId={matchSuggestion?.account_id} />
      </FormItem>
      <Space />
      <ReconciliationSuggestion matchSuggestion={matchSuggestion} />
      {hasMatchDifference && <DifferenceSelector difference={matchDifference} />}
      <LinkToManualPayment />
    </>
  )
}
