import { Link, SectionMessage, Text } from '@design-system'

import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'

import { LocaleCode } from '../../../../../../../../../../enums/localeCode'
import { LocalStorageKeys } from '../../../../../../../../../../enums/localStorageKeys'
import { useLocale } from '../../../../../../../../../../hooks/useLocale'

const FRENCH_SURVEY_LINK = 'https://t.maze.co/358967675'
const ENGLISH_SURVEY_LINK = 'https://t.maze.co/362555223'

export const InvoiceEditorBetaTestingBanner = () => {
  const { t } = useTranslation()
  const [isBannerClosed, setIsBannerClosed] = useLocalStorage<boolean>(
    LocalStorageKeys.HideInvoiceEditorBetaTestingBanner,
    false,
  )
  const locale = useLocale()

  const surveyLink = locale.code === LocaleCode.FR ? FRENCH_SURVEY_LINK : ENGLISH_SURVEY_LINK

  const handleCloseButtonClick = useCallback(() => {
    setIsBannerClosed(true)
  }, [setIsBannerClosed])

  if (isBannerClosed) {
    return null
  }

  return (
    <SectionMessage
      variant="info"
      icon="iSignCircle"
      onClose={handleCloseButtonClick}
      closable
      showIcon
      title={t('external_invoices.editor.form.banner.title')}
    >
      <Trans i18nKey="external_invoices.editor.form.banner.info">
        Certain features are still under development and may be unavailable at this stage.
        <br />
        Your feedback is invaluable to us - try it out and let us know{' '}
        <Text as="span" weight="bold" inheritColor>
          <Link href={surveyLink} target="_blank">
            what do you think!
          </Link>
        </Text>
      </Trans>
    </SectionMessage>
  )
}
