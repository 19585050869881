import debounce from 'lodash/debounce'
import { ReactElement, useCallback, useMemo } from 'react'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { TRACKING_DELAY } from '../../../../../../../../constants/trackingDelay'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { useUpdateDependentLineValues } from '../../hooks/useUpdateDependentLineValues'
import { ProductSelect } from './elements/ProductSelect'

type InvoiceLineProductNameProps = CommonInvoiceLineFieldProps

export const InvoiceLineProductName = ({ lineIndex }: InvoiceLineProductNameProps): ReactElement => {
  const { track } = useSegment()
  const { FormItem, setValue, watch } = useFormContext<InvoiceFormSchema>()
  const { handleProductChange } = useUpdateDependentLineValues(lineIndex)

  const productName = watch(`invoiceLines.${lineIndex}.productName`)

  const trackDebouncedInputTyping = useMemo(
    () =>
      debounce((value: string) => {
        track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Product name input typed,', {
          textLength: value.length,
        })
      }, TRACKING_DELAY),
    [track],
  )

  const handleAddProductName = useCallback(
    (name: string) => {
      setValue(`invoiceLines.${lineIndex}.productName`, name)
      setValue(`invoiceLines.${lineIndex}.productFromSearch`, null)
      trackDebouncedInputTyping(name)
    },
    [trackDebouncedInputTyping, lineIndex, setValue],
  )

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.productFromSearch`}
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <ProductSelect
            {...props}
            dropdownSize="fitTrigger"
            inputValue={productName || value?.name || ''}
            onAddProductName={handleAddProductName}
            onSelect={(id, product) => {
              handleProductChange(product)
              onChange(product)
              track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Product selected from the list', {
                productId: id,
              })
            }}
            selectedId={value?.id}
            selectedValue={value?.id ? value : undefined}
          />
        )
      }}
    />
  )
}
