import { format } from 'date-fns'

import { LocaleIdentifier } from '../../../types/localeIdentifier'

export const getDisplayDateFormat = (locale: LocaleIdentifier | undefined, withHour: boolean | undefined) => {
  const isUSLocale = locale === 'en_US'

  if (withHour) {
    return isUSLocale ? 'MM/dd/yyyy HH:mm' : 'dd/MM/yyyy HH:mm'
  }

  return isUSLocale ? 'MM/dd/yyyy' : 'dd/MM/yyyy'
}

export const getDisplayDate = (date: Date | undefined, locale: LocaleIdentifier | undefined) => {
  return date ? format(date, getDisplayDateFormat(locale, false)) : ''
}
