import intersection from 'lodash/intersection'
import sortBy from 'lodash/sortBy'
import { useMemo } from 'react'

import { InvoiceLineColumn } from '../../../../../../../../../types/invoiceLineColumn'
import { MOCK_VISIBLE_COLUMNS } from '../../../../../../../constants/mockVisibleColumns'
import { DISPLAYABLE_INVOICE_LINE_COLUMNS } from '../constants/displayableInvoiceLineColumns'
import { INVOICE_LINE_COLUMNS_ORDER } from '../constants/invoiceLineColumnsOrder'
import { getInvoiceLineColumnWidth } from '../utils/getInvoiceLineColumnWidth'

export const useInvoiceLinesColumns = () => {
  // to be replaced with proper data in the future
  const visibleColumns: InvoiceLineColumn[] = MOCK_VISIBLE_COLUMNS

  const sortedVisibleColumns: InvoiceLineColumn[] = useMemo(() => {
    const filteredVisibleColumns: InvoiceLineColumn[] = intersection(visibleColumns, DISPLAYABLE_INVOICE_LINE_COLUMNS)
    return sortBy(filteredVisibleColumns, (column) => INVOICE_LINE_COLUMNS_ORDER.indexOf(column))
  }, [visibleColumns])

  const columnItemsTemplate = useMemo(
    () => sortedVisibleColumns.map((column) => getInvoiceLineColumnWidth(column)),
    [sortedVisibleColumns],
  )

  return {
    columns: sortedVisibleColumns,
    columnItemsTemplate,
  }
}
