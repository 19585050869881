import { DateInputLocalized } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { BillFormSchema } from '../../utils/formData'

export const BillBillDate = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, control } = useFormContext<BillFormSchema>()
  const paymentDate = useWatch({ control, name: 'paymentDate' })

  return (
    <FormItem
      name="billDate"
      label={t('bill.edit.form.bill_date')}
      render={({ field }) => <DateInputLocalized maxDate={paymentDate} {...field} />}
    />
  )
}
