import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { InvoiceDocumentType } from '../../../enums/invoiceDocumentType'
import { fetchInvoiceHtml } from '../../../query-api'

export const useFetchInvoiceHtml = (invoiceId: string, documentType: InvoiceDocumentType) => {
  const { data, isLoading, ...rest } = useQuery(
    [QueryKeys.InvoiceZervantHtml, invoiceId, documentType],
    () => fetchInvoiceHtml(invoiceId, documentType),
    { enabled: !!invoiceId && !!documentType },
  )

  return { invoiceHtml: data?.data, isLoading, ...rest }
}
