import { useCallback, useState } from 'react'

import { trackError } from '../utils/trackError'

/**
 * @deprecated THIS HOOK IS DEPRECATED.  Please use "useLocalStorage" from "react-use" package
 */
export function useLocalStorage<ReturnType = any>(key: string, initialValue?: ReturnType) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      // Note - 'undefined' is received as a string from localStorage value
      return item && item !== 'undefined' ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      trackError(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: any) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore))
      } catch (error) {
        // A more advanced implementation would handle the error case
        trackError(error)
      }
    },
    [key, storedValue],
  )

  const removeValue = useCallback((key: string) => {
    try {
      // Remove to local storage
      window.localStorage.removeItem(key)
    } catch (error) {
      // A more advanced implementation would handle the error case
      trackError(error)
    }
  }, [])

  return [storedValue, setValue, removeValue]
}
