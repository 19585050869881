import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'

import { Product } from '../../../../../../../../../../../../../../../../../types/product'
import { NAV_ITEM_ID_ADD_PRODUCT_NAME } from '../constants/navItemIdAddProductName'
import { ProductValue } from '../ProductSelect'

interface GetItemsOptions {
  products: Product[]
  searchQuery?: string
  t: TFunction
}

export const getItems = ({ products, searchQuery, t }: GetItemsOptions): NavItem<ProductValue>[] => {
  const productItems = products.map((product) => ({
    id: product.id,
    children: product.name,
    value: {
      ...product,
      displayData: {
        title: product.name,
      },
    },
  }))

  const productItemsFiltered = !searchQuery
    ? productItems
    : productItems.filter((productItem) => productItem.children.toLowerCase().includes(searchQuery.toLowerCase()))

  const shouldDisplayDefaultItems = searchQuery && searchQuery?.length >= 1

  if (!shouldDisplayDefaultItems) {
    return productItemsFiltered
  }

  const defaultItems = [
    {
      accessor: NavItemAccessor.WithoutActiveState,
      children: t('external_invoices.editor.form.field.line.field.product_name.add_one_off'),
      id: NAV_ITEM_ID_ADD_PRODUCT_NAME,
      value: {
        displayData: {
          title: t('external_invoices.editor.form.field.line.field.product_name.add_one_off'),
        },
      } as ProductValue,
    },
    ...(productItemsFiltered.length
      ? [
          {
            accessor: NavItemAccessor.Separator,
            children: '',
            id: 'separator',
            value: {} as ProductValue,
          },
        ]
      : []),
  ]

  return [...defaultItems, ...productItemsFiltered]
}
