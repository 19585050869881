import { DocumentProps } from 'react-pdf'

import { AspectRatioVariant } from './enums/aspectRatioVariant'

export enum FileType {
  Image = 'image',
  Pdf = 'pdf',
  Html = 'html',
}

export type PreviewFile = {
  alt?: string
  id?: string
  src: string
  srcDownload?: string
  srcZoom?: string
}

export type FitType = 'contain' | 'cover'

export type RotatedFiles = Record<string, number>

export type DocumentLocalProps = Omit<DocumentProps, 'file'>

export type AspectRatio = `${number} / ${number}` | `${number}/${number}` | 'auto' | keyof typeof AspectRatioVariant

export interface FileComponentProps {
  activePage: number
  aspectRatio?: AspectRatio
  alt?: string
  circle?: boolean
  fitToHeight?: boolean
  fitToWidth?: boolean
  fitType: FitType
  height?: number
  onError?: () => void
  onLoad?: () => void
  onPdfLoad: (pagesCount: number) => void
  rotation?: number
  src: string
  width?: number
}

export interface FloatingActionsMenuProps {
  areTooltipsHidden: boolean
  handleDeleteClick: () => void
  handleDownloadClick: () => void
  onTooltipShow: () => void
  tooltipDelay: number
  withDeleteButton?: boolean
  withDownloadButton?: boolean
}
