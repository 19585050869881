import React, { ImgHTMLAttributes, ReactElement } from 'react'

import * as Styled from './styles'
import { ObjectFit } from './types/ObjectFit'

export interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  zoomable?: boolean
  // Use to set zoomed in image scale (for example "2" is x2 bigger than the original)
  zoomScale?: number
  // You can pass different image for the zoom, otherwise it's gonna use the same as the original
  zoomImageSrc?: string
  objectFit?: ObjectFit
}

export const Image = ({ className, objectFit = 'cover', ...imgProps }: ImageProps): ReactElement => {
  return (
    <Styled.ImageWrapper className={className}>
      <Styled.Image loading="lazy" objectFit={objectFit} {...imgProps} />
    </Styled.ImageWrapper>
  )
}
