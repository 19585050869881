import { DEFAULT_CURRENCY } from '../../constants/defaultCurrency'
import { InvoiceForm } from '../../types/invoiceForm'
import { InvoicePayloadSummary } from '../../types/invoicePayload/invoicePayloadSummary'
import { getInvoiceSummaryTotals } from '../getInvoiceSummaryTotals'

export const getSummaryPayload = (formValues: InvoiceForm): InvoicePayloadSummary => {
  return {
    currency: formValues?.currency || DEFAULT_CURRENCY,
    ...getInvoiceSummaryTotals(formValues.invoiceLines),
  }
}
