import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { FindAccountantContextProvider } from './contexts/findAccountantContext'
import { Layout } from './elements/Layout'
import { QuestionsSection } from './elements/QuestionsSection'
import { SummarySection } from './elements/SummarySection'

export const FindAccountant = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <FindAccountantContextProvider>
      <ModuleLayout title={t('find_accountant.title')}>
        <Layout>
          <QuestionsSection />
          <SummarySection />
        </Layout>
      </ModuleLayout>
    </FindAccountantContextProvider>
  )
}
