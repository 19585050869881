import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { Difference, DifferenceType } from '@modules-deprecated/bankReconciliation/types'

import { Side } from '../../../../../enums/side'
import { useSegment } from '../../../../../hooks'
import { useCreateManualPayment } from '../../../hooks/useCreateManualPayment'
import { useInvalidateBills } from '../../../hooks/useInvalidateBills'
import { getAmountBasedOnSide } from '../../../utils/getAmountBasedOnSide'
import { useBillView } from '../../BillReadableForm'
import { useMultipleBills } from '../contexts/multipleBillsContext'
import { PaymentRegistrationSuccessState } from '../enums/paymentRegistrationSuccessState'
import { getSubjectReferencesFromBills } from '../utils/getSubjectReferencesFromBills'
import { notifyPaymentRegistrationError } from '../utils/notifyPaymentRegistrationError'
import { notifyPaymentRegistrationSuccess } from '../utils/notifyPaymentRegistrationSuccess'

interface UseRegisterManualPaymentProps {
  cashSide?: Side
  selectedDifferenceType: DifferenceType | undefined
  paymentDate: string | undefined
  selectedAccountId: string
  manualPaymentAmount: number
  manualPaymentDifference: Difference
  onSuccess: () => void
}

export const useRegisterManualPayment = ({
  cashSide,
  selectedDifferenceType,
  paymentDate,
  selectedAccountId,
  manualPaymentAmount,
  manualPaymentDifference,
  onSuccess,
}: UseRegisterManualPaymentProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const { t } = useTranslation()
  const { track } = useSegment()
  const { invalidateBills, invalidateBill } = useInvalidateBills()
  const { billId } = useBillView()
  const { create: createManualPayment } = useCreateManualPayment({
    onSuccess: () => {
      notifyPaymentRegistrationSuccess(PaymentRegistrationSuccessState.Registered, t)
      invalidateBill(billId)
      invalidateBills()
      setIsProcessing(false)
      onSuccess()
    },
    onError: () => {
      notifyPaymentRegistrationError(t)
      setIsProcessing(false)
    },
  })
  const { organization } = useUserOrganization()
  const { selectedBills } = useMultipleBills()
  const subjectReferences = useMemo(() => getSubjectReferencesFromBills(selectedBills ?? []), [selectedBills])

  const registerManualPayment = useCallback(async () => {
    track('xxx - expenses - Manual payment modal - register payment clicked')
    setIsProcessing(true)
    let bankFeeProperties
    if (selectedDifferenceType === 'bankFee' && manualPaymentDifference.differenceAmount) {
      bankFeeProperties = {
        feeAmount: getAmountBasedOnSide(manualPaymentDifference.differenceAmount, Side.Credit),
        feeAccountId: organization?.defaultBankFeeAccountId as string,
      }
    }

    const manualPaymentPayload = {
      associations: subjectReferences,
      cashAccountId: selectedAccountId,
      cashAmount: manualPaymentAmount,
      cashSide: cashSide ?? Side.Credit,
      entryDate: paymentDate as string,
      organizationId: organization?.id as string,
      ...bankFeeProperties,
    }
    await createManualPayment(manualPaymentPayload)
  }, [
    cashSide,
    createManualPayment,
    manualPaymentAmount,
    manualPaymentDifference.differenceAmount,
    organization?.defaultBankFeeAccountId,
    organization?.id,
    paymentDate,
    selectedAccountId,
    selectedDifferenceType,
    subjectReferences,
    track,
  ])

  return {
    registerManualPayment,
    isProcessing,
  }
}
