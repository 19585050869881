import { amountToDisplayValue } from '@design-system'

import { useContactBalance } from '../../../contexts/contactBalanceContext'

interface UsePrepaymentModalDisplayValuesResponse {
  contactName?: string
  displayedContactBalanceAmount?: string
}

export const usePrepaymentModalDisplayValues = (): UsePrepaymentModalDisplayValuesResponse => {
  const { contact, contactBalance } = useContactBalance()

  const contactName = contact?.name
  const contactBalanceCurrencyId = contactBalance?.currencyId
  const contactBalanceAmount = contactBalance?.amount

  const displayedContactBalanceAmount = contactBalanceAmount
    ? amountToDisplayValue(contactBalanceAmount, false, contactBalanceCurrencyId)
    : undefined

  return {
    contactName,
    displayedContactBalanceAmount,
  }
}
