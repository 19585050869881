import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { getDefaultTransition } from '../../../../utils/getDefaultTransition'
import { getIconColorStyles } from '../../../Icon'

export const Control = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: ${DefaultValue.BorderRadius};
  align-items: center;
  justify-content: center;
  ${getDefaultTransition('background', 'border', 'box-shadow')}
`

export const NativeRadio = styled.input`
  opacity: 0;
  width: 1px;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

interface ToggleGroupItemWrapperProps {
  checked: boolean
}

export const ToggleGroupItemWrapper = styled.label<ToggleGroupItemWrapperProps>`
  display: flex;
  position: relative;
  cursor: pointer;
  ${getIconColorStyles(Color.Gray90)}

  ${({ checked }) =>
    checked &&
    css`
      ${Control} {
        border-color: ${Color.Gray50};
        background-color: ${Color.White};
        box-shadow: 0 0 4px 0 ${transparentize(0.2, Color.Gray60)};
      }

      ${getIconColorStyles(Color.DeepSeaGreen)}
    `}
`
