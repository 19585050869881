import { ReactNode } from 'react'

import { DEFAULT_FORMATTER_LOCALE } from '../../../../constants/defaultFormatterLocale'
import { NumberFormat } from '../../../../utils/NumberFormat'
import { FormattedNumberProps } from '../../FormattedNumber'
import { FormattedNumberElement } from '../FormattedNumberElement'

export type FormattedPercentNumberProps = FormattedNumberProps

export const FormattedPercentNumber = ({
  as = 'span',
  asString = false,
  locale = DEFAULT_FORMATTER_LOCALE,
  options,
  value,
}: FormattedPercentNumberProps): ReactNode => {
  return (
    <FormattedNumberElement as={as} asString={asString}>
      {NumberFormat.getPercentNumber(value, locale, options)}
    </FormattedNumberElement>
  )
}
