import styled from '@emotion/styled'

import { BorderRadius } from '../../../../enums/borderRadius'
import { Spacing } from '../../../../enums/spacing'
import { getShadow } from '../../../../utils/getShadow'
import { AspectRatio, FitType } from '../../types'
import { getRotationStyles } from '../../utils/getRotationStyles'

interface StickyWrapperProps {
  aspectRatio?: AspectRatio
  clickable?: boolean
  fitToHeight?: boolean
  fitToWidth?: boolean
  fitType?: FitType
  borderRadius?: BorderRadius
  margin?: Spacing
  rotation?: number
  withShadow?: boolean
}

export const StickyWrapper = styled.div<StickyWrapperProps>`
  display: flex;
  justify-content: center;
  width: 100%;

  overflow-x: hidden;
  overflow-y: ${({ aspectRatio, fitToHeight, fitType }) =>
    fitToHeight || fitType === 'cover' || aspectRatio ? 'hidden' : 'auto'};

  ${({ clickable }) => clickable && 'cursor: pointer'};
  ${({ fitToHeight, aspectRatio }) => fitToHeight && !aspectRatio && 'height: 100%'};
  ${({ withShadow }) => withShadow && getShadow('raisedStrong')};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ aspectRatio, fitToWidth, rotation }) => rotation && getRotationStyles({ aspectRatio, rotation, fitToWidth })}
`
