import { DateFormatter, Translate } from '@components'
import { InternalAccessor, TableCellContent, TableColumn } from '@design-system'

import { useMemo } from 'react'

import { UmbrellaOrganization } from '@modules-deprecated/app/organizations/types'

import { UmbrellaOrganizationTable } from '../../../types/umbrellaOrganizationTable'
import { UmbrellaOrganizationsListTableStaticActions } from '../../UmbrellaOganizationsListTableStaticActions'
import { UmbrellaOrganizationsListTableHoverActions } from '../../UmbrellaOrganizationsListTableHoverActions/UmbrellaOrganizationsListTableHoverActions'
import { SubscriptionCell } from '../elements/SubscriptionCell'

const DEFAULT_NULL_REPLACEMENT_VALUE = '-'

const renderNullableCell = ({ value }: { value: string | number | null }) => value ?? DEFAULT_NULL_REPLACEMENT_VALUE

export const useUmbrellaOrganizationsListTableColumns = (
  organizations: UmbrellaOrganization[],
): TableColumn<UmbrellaOrganizationTable>[] => {
  return useMemo(
    () => [
      {
        accessor: 'name',
        Header: <Translate value="umbrella_organizations.organizations_table.name_column" />,
        sortable: true,
        size: 'xxl',
        truncate: true,
      },
      {
        accessor: 'owner',
        Header: <Translate value="umbrella_organizations.organizations_table.owner_column" />,
        Cell: ({ value }) => <TableCellContent truncate>{value.name}</TableCellContent>,
        sortable: true,
        size: 'xxl',
        truncate: true,
      },
      {
        accessor: 'isTerminated',
        Header: <Translate value="umbrella_organizations.organizations_table.subscription_column" />,
        Cell: ({ data, row }) => {
          return <SubscriptionCell umbrellaOrganization={data[row.id] as UmbrellaOrganizationTable} />
        },
        size: 'l',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'reconcilableBankLines',
        Header: <Translate value="umbrella_organizations.organizations_table.open_banklines" />,
        Cell: renderNullableCell,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'orphanAttachments',
        Header: <Translate value="umbrella_organizations.organizations_table.uploads" />,
        Cell: renderNullableCell,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'draftDaybookTransactions',
        Header: <Translate value="umbrella_organizations.organizations_table.draft_postings" />,
        Cell: renderNullableCell,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'lastTransactionTime',
        Header: <Translate value="umbrella_organizations.organizations_table.last_transaction" />,
        size: 'm',
        Cell: ({ value }) => (value ? <DateFormatter value={value} /> : DEFAULT_NULL_REPLACEMENT_VALUE),
        sortable: true,
        truncate: true,
      },
      {
        accessor: InternalAccessor.HoverActions,
        Header: '',
        Cell: ({ row, data }) => {
          const { url } = data[row.id] as UmbrellaOrganizationTable

          return <UmbrellaOrganizationsListTableHoverActions umbrellaOrganizationUrl={url} />
        },
        sortable: false,
      },
      {
        accessor: InternalAccessor.StaticActions,
        Header: '',
        Cell: ({ row, data }) => {
          const { staticActions, organizationId } = data[row.id] as UmbrellaOrganizationTable
          const umbrellaOrganization = organizations.find(
            (organization) => organization.organizationId === organizationId,
          )

          return umbrellaOrganization ? (
            <UmbrellaOrganizationsListTableStaticActions
              umbrellaOrganization={umbrellaOrganization}
              staticActions={staticActions}
            />
          ) : (
            <></>
          )
        },
        sortable: false,
      },
    ],
    [organizations],
  )
}
