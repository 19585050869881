import { Flex } from '@design-system'

import { ReactElement, useMemo } from 'react'

import { InvoiceHtmlPreview } from '../../../../elements/InvoiceHtmlPreview'
import { useInvoice } from '../../contexts/invoiceContext'
import { useFetchInvoiceHtml } from '../../hooks/useFetchInvoiceHtml'
import * as Styled from './styles'

export const InvoicePreview = (): ReactElement | null => {
  const { documentType, invoiceId } = useInvoice()
  const { invoiceHtml } = useFetchInvoiceHtml(invoiceId, documentType)

  const htmlFiles = useMemo(() => (invoiceHtml ? [invoiceHtml] : []), [invoiceHtml])

  return (
    <Styled.InvoiceHtmlPreviewWrapper>
      <Flex justifyContent="center">
        <InvoiceHtmlPreview htmlFiles={htmlFiles} />
      </Flex>
    </Styled.InvoiceHtmlPreviewWrapper>
  )
}
