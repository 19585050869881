import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import {
  useBillSubjectAssociations,
  useDeleteBankLineSubjectAssociations,
} from '@modules-deprecated/bankReconciliation/hooks'
import { DifferenceType } from '@modules-deprecated/bankReconciliation/types'

import { PaymentRegistrationSuccessState } from '../elements/BillReconciliationModal/enums/paymentRegistrationSuccessState'
import { notifyPaymentRegistrationError } from '../elements/BillReconciliationModal/utils/notifyPaymentRegistrationError'
import { notifyPaymentRegistrationSuccess } from '../elements/BillReconciliationModal/utils/notifyPaymentRegistrationSuccess'
import { useCreateBillReconciliationSuggestion } from './useAddBillReconciliationSuggestion'
import { useInvalidateBills } from './useInvalidateBills'

interface UseReconcileBillProps {
  billId?: string
  matchId?: string
  onSuccess?: () => void
  selectedDifferenceType?: DifferenceType
}

export const useReconcileBill = ({ billId, matchId, onSuccess, selectedDifferenceType }: UseReconcileBillProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const { billSubjectAssociations } = useBillSubjectAssociations({ billId })
  const { deleteAssociations } = useDeleteBankLineSubjectAssociations()
  const { organization } = useUserOrganization()
  const { t } = useTranslation()
  const { create: createBillReconciliationSuggestion } = useCreateBillReconciliationSuggestion()
  const { invalidateBills, invalidateBill } = useInvalidateBills()

  const reconcileBill = async () => {
    if (!matchId || !billId) {
      return
    }

    setIsProcessing(true)

    const associationIds = billSubjectAssociations.map(({ id }) => id)

    await deleteAssociations(associationIds, {
      onSuccess: () => {
        createBillReconciliationSuggestion(
          {
            matchId,
            billId,
            differenceType: selectedDifferenceType,
            defaultBankFeeAccountId: organization?.defaultBankFeeAccountId,
          },
          {
            onSuccess: () => {
              notifyPaymentRegistrationSuccess(PaymentRegistrationSuccessState.RegisteredAndReconciled, t)
              invalidateBill(billId)
              invalidateBills()
              setIsProcessing(false)
              onSuccess?.()
            },
            onError: () => {
              notifyPaymentRegistrationError(t)
              setIsProcessing(false)
            },
          },
        )
      },
    })
  }

  return {
    reconcileBill,
    isProcessing,
  }
}
