import { createContext, ReactElement, ReactNode, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../../../../../../../../hooks'
// import { useInvoiceEditorFormSubmit } from '../../../../../hooks/useInvoiceEditorFormSubmit'
import { SendAsEmailForm } from '../elements/SendAsEmailModalComponent/types/SendAsEmailForm'
import { SendAsEmailFormSchema } from '../elements/SendAsEmailModalComponent/types/SendAsEmailFormSchema'
import { getDefaultValues } from '../elements/SendAsEmailModalComponent/utils/getDefaultValues'
import { getSendAsEmailFormSchema } from '../elements/SendAsEmailModalComponent/utils/getSendAsEmailFormSchema'

interface ContextState {
  isSubmitting: boolean
  submitForm: () => void
}

const SendAsEmailFormContext = createContext<ContextState | undefined>(undefined)

interface SendAsEmailFormProviderProps {
  children?: ReactNode
}

export const SendAsEmailFormProvider = ({ children }: SendAsEmailFormProviderProps): ReactElement => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  // const { submit } = useInvoiceEditorFormSubmit()

  const { Form, handleSubmit } = useForm<SendAsEmailFormSchema>({
    defaultValues: getDefaultValues(),
    validationSchema: getSendAsEmailFormSchema(t),
  })

  const handleSendAsEmailFormSubmit = useCallback((formData: SendAsEmailForm) => {
    setIsSubmitting(true)
    // eslint-disable-next-line no-console
    console.log('formData:', formData)

    // TODO: use when we have the form ready
    // submit.sendAsEmail({
    //   sendAsEmailFormData: formData
    // })
  }, [])

  const submitForm = useCallback(() => {
    handleSubmit(handleSendAsEmailFormSubmit)()
  }, [handleSubmit, handleSendAsEmailFormSubmit])

  return (
    <SendAsEmailFormContext.Provider value={{ isSubmitting, submitForm }}>
      <Form>{children}</Form>
    </SendAsEmailFormContext.Provider>
  )
}

export const useSendAsEmailForm = () => {
  const context = useContext(SendAsEmailFormContext)

  if (!context) {
    throw new Error('SendAsEmailForm is missing in the module!')
  }

  return context
}
