import Cookies from 'js-cookie'
import React, { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

import { CookieKeys } from '../../../enums/cookieKeys'
import { getAttachmentIdsForBulkEdit } from '../utils/getAttachmentIdsForBulkEdit'

interface ContextState {
  attachmentIdsToEdit: string[]
}

const BulkAttachmentsContext = createContext<ContextState | undefined>(undefined)

interface BillsEmberConnectionContextProps {
  children?: ReactNode
}

export const BulkAttachmentsContextProvider = ({ children }: BillsEmberConnectionContextProps): ReactElement => {
  const attachmentIdsToEdit = useMemo(() => {
    const attachmentIdsForBulkEdit = Cookies.get(CookieKeys.AttachmentIdsForBulkEdit) || ''
    Cookies.remove(CookieKeys.AttachmentIdsForBulkEdit)
    return getAttachmentIdsForBulkEdit(attachmentIdsForBulkEdit)
  }, [])

  return <BulkAttachmentsContext.Provider value={{ attachmentIdsToEdit }}>{children}</BulkAttachmentsContext.Provider>
}

export const useBulkAttachmentsContext = () => {
  const context = useContext(BulkAttachmentsContext)

  if (!context) {
    throw new Error('BulkAttachmentsContext is missing in the module!')
  }

  return context
}
