import { Flex, Text } from '@design-system'

import { useTranslation } from 'react-i18next'

import * as Styled from './styles'

interface InvoiceSummaryTotalProps {
  text: string
}

export const InvoiceSummaryTotal = ({ text }: InvoiceSummaryTotalProps) => {
  const { t } = useTranslation()

  return (
    <Styled.InvoiceSummaryTotal>
      <Flex justifyContent="space-between">
        <Text variant="normal" weight="bold">
          {t('external_invoices.editor.summary.total')}
        </Text>
        <Text variant="h3" weight="bold">
          {text}
        </Text>
      </Flex>
    </Styled.InvoiceSummaryTotal>
  )
}
