import { InputNumberLocalized } from '@components'

import debounce from 'lodash/debounce'
import { useMemo } from 'react'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { InvoiceDocumentType } from '../../../../../../../../../../../../../../enums/invoiceDocumentType'
import { useGetZervantDocumentSettings } from '../../../../../../../../../../../../../../hooks/useGetZervantDocumentSettings'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { TRACKING_DELAY } from '../../../../../../../../constants/trackingDelay'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'
import { useUpdateDependentLineValues } from '../../hooks/useUpdateDependentLineValues'

type InvoiceLineQuantityProps = CommonInvoiceLineFieldProps

export const InvoiceLineQuantity = ({ lineIndex }: InvoiceLineQuantityProps) => {
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()
  const { handleQuantityChange } = useUpdateDependentLineValues(lineIndex)
  const { getInvoiceLocale } = useGetZervantDocumentSettings(InvoiceDocumentType.Invoice)
  const locale = getInvoiceLocale()

  const handleDebouncedInputNumberChange = useMemo(
    () =>
      debounce((quantity: number) => {
        track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Quantity changed', {
          quantity,
        })
      }, TRACKING_DELAY),
    [track],
  )

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.quantity`}
      render={({ field: { onChange, ...rest } }) => {
        return (
          <InputNumberLocalized
            {...rest}
            keyboard
            locale={locale}
            withCalculations={false}
            min={0}
            onChange={(quantity) => {
              handleQuantityChange(quantity)
              onChange(quantity)
              handleDebouncedInputNumberChange(quantity)
            }}
          />
        )
      }}
    />
  )
}
