import { useCallback, useState } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { queryClient } from '../../../../../config/queryClient'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { APIError } from '../../../../../utils'
import { trackError } from '../../../../../utils/trackError'
import { ConnectToMobilePayResponse } from '../../../query-api'
import { useConnectToMobilePay } from './useConnectToMobilePay'
import { useCreateMobilePayPaymentMethod } from './useCreateMobilePayPaymentMethod'

interface UseActivateMobilePayProps {
  onSuccess?: () => void
  onError?: (error?: APIError) => void
}

export const useActivateMobilePay = ({ onSuccess, onError }: UseActivateMobilePayProps) => {
  const [isProcessing, setIsProcessing] = useState(false)
  const { organization } = useUserOrganization()

  const { connect: connectToMobilePay } = useConnectToMobilePay({
    onSuccess: (data: ConnectToMobilePayResponse) => {
      const mobilePayAuthorizationLink = data?.url

      if (mobilePayAuthorizationLink) {
        window.open(mobilePayAuthorizationLink, '_self')
      }

      setIsProcessing(false)
      onSuccess?.()
    },
    onError: (error) => {
      setIsProcessing(false)
      onError?.(error)
      trackError(error)
    },
  })

  const onCreatePaymentMethodSuccess = async (paymentMethodId?: string) => {
    queryClient.invalidateQueries(QueryKeys.PaymentMethods)

    if (organization?.id && organization?.registrationNo && paymentMethodId) {
      // unable to test from this step on, authentication error, has to be tested on production
      connectToMobilePay({
        organizationId: organization.id,
        registrationNumber: organization?.registrationNo,
        paymentMethodId,
      })
    } else {
      setIsProcessing(false)
    }
  }

  const { createMobilePayPaymentMethod } = useCreateMobilePayPaymentMethod({
    onSuccess: onCreatePaymentMethodSuccess,
    onError: (error?: APIError) => {
      setIsProcessing(false)
      onError?.(error)
    },
  })

  const activateMobilePay = useCallback(
    (mobilePayAccountId: string | undefined) => {
      setIsProcessing(true)
      createMobilePayPaymentMethod(mobilePayAccountId)
    },
    [createMobilePayPaymentMethod],
  )

  return { activateMobilePay, isProcessing }
}
