import findIndex from 'lodash/findIndex'
import findLastIndex from 'lodash/findLastIndex'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useKey } from 'react-use'
import { SearchResultItem } from 'src/components/GlobalSearch/types/searchResultItem'

import { NON_SELECTABLE_ACCESSORS } from '../constants/nonSelectableAccessors'
import { NavItem } from '../types/navItem'
import { NavItemAccessor } from '../types/navItemAccessor'
import { NavItemNavigable } from '../types/navItemNavigable'
import { findClosestMatchedNavItem } from '../utils/findClosestMatchedNavItem'

const getClosestNonAccessorIndex = <T extends NavItemNavigable>(
  items: T[],
  currentIndex: number,
  direction: 'up' | 'down',
) => {
  if (direction === 'down') {
    return findIndex(
      items,
      (item) => !NON_SELECTABLE_ACCESSORS.includes(item.accessor as NavItemAccessor),
      currentIndex,
    )
  }

  return findLastIndex(
    items,
    (item) => !NON_SELECTABLE_ACCESSORS.includes(item.accessor as NavItemAccessor),
    currentIndex,
  )
}

const getInitialIndex = <T extends NavItemNavigable>(items: T[]) => {
  return getClosestNonAccessorIndex(items, 0, 'down')
}

const getInitialItemId = (selectedId?: string) => {
  return selectedId || ''
}

interface UseNavItemsNavigationProps<T> {
  isActive?: boolean
  items: T[]
  selectedId?: string
  searchValue?: string
  shouldSearchForBestMatched?: boolean
}

const areItemsNavItems = <Y>(items: (NavItemNavigable | NavItem<Y> | SearchResultItem)[]): items is NavItem<Y>[] => {
  return items.every((item) => 'id' in item && 'value' in item && 'children' in item)
}

export const useNavItemsNavigation = <T extends NavItemNavigable | NavItem<Y> | SearchResultItem, Y>({
  isActive,
  items,
  selectedId = '',
  searchValue = '',
  shouldSearchForBestMatched = false,
}: UseNavItemsNavigationProps<T>): string | undefined => {
  const initialCurrentId = getInitialItemId(selectedId)
  const [currentId, setCurrentId] = useState<string>(initialCurrentId)
  const target = isActive ? window : null

  const canNavigate = useMemo(() => {
    return items.some((item) => {
      if ('accessor' in item) {
        return !NON_SELECTABLE_ACCESSORS.includes(item.accessor as NavItemAccessor)
      }
      // when the accessor property does not exist return true
      // so the GlobalSearch results can also be navigated
      return true
    })
  }, [items])

  useEffect(() => {
    // Selects a value when the user presses TAB or ENTER, based on the search value
    if (shouldSearchForBestMatched && items.length && areItemsNavItems(items)) {
      const closestMatchedItem = findClosestMatchedNavItem(items, searchValue)
      setCurrentId(closestMatchedItem?.id || '')
    }
    // If no search input, then the selected value stays on tabbing away (case when shouldSearchForBestMatched is false).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, searchValue, shouldSearchForBestMatched])

  useEffect(() => {
    const isCurrentItemExist = items.find((item) => item.id === currentId)

    if (!isCurrentItemExist) {
      setCurrentId('')
    }
    // we should wait for the currentId to be updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentId])

  useEffect(() => {
    const currentId = getInitialItemId(selectedId)

    setCurrentId(currentId)
  }, [isActive, selectedId])

  const handleArrowUpPress = useCallback(
    (event: KeyboardEvent) => {
      if (!isActive || !canNavigate) {
        return
      }

      event.preventDefault()

      setCurrentId((previousId) => {
        const previousIndex = items.findIndex((item) => item.id === previousId)
        const nextIndex = getClosestNonAccessorIndex(items, previousIndex - 1, 'up')
        return (nextIndex === -1 ? items[items.length - 1]?.id : items[nextIndex]?.id) || ''
      })
    },
    [isActive, canNavigate, items],
  )

  const handleArrowDownPress = useCallback(
    (event: KeyboardEvent) => {
      if (!isActive || !canNavigate) {
        return
      }

      event.preventDefault()

      setCurrentId((previousId) => {
        const previousIndex = items.findIndex((item) => item.id === previousId)
        const nextIndex = getClosestNonAccessorIndex(items, previousIndex + 1, 'down')
        return (nextIndex === -1 ? items[getInitialIndex(items)]?.id : items[nextIndex]?.id) || ''
      })
    },
    [isActive, canNavigate, items],
  )

  useKey('ArrowUp', handleArrowUpPress, { target }, [target, currentId, items])
  useKey('ArrowDown', handleArrowDownPress, { target }, [target, currentId, items])

  return currentId
}
