import { ButtonsGroup } from '@design-system'

import { DownloadInvoiceAsPdfButton } from './elements/DownloadInvoiceAsPdfButton'
import { ExternalViewInvoiceButton } from './elements/ExternalViewInvoiceButton'

export const InvoiceViewActions = () => (
  <ButtonsGroup>
    <DownloadInvoiceAsPdfButton />
    <ExternalViewInvoiceButton />
  </ButtonsGroup>
)
