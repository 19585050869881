import { ReactNode } from 'react'

import { Asable } from '../../../../../types/asable'
import * as Styled from './styles'

interface FormattedNumberElementProps extends Asable {
  children: ReactNode
  asString?: boolean
}

export const FormattedNumberElement = ({ as, children, asString }: FormattedNumberElementProps): ReactNode => {
  return asString ? (
    children
  ) : (
    <Styled.FormattedNumberElementWrapper as={as}>{children}</Styled.FormattedNumberElementWrapper>
  )
}
