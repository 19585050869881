import qs from 'qs'

import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { Metable } from '../../../types/metable'
import { getRequest } from '../../../utils'
import { Country } from './types'

export interface FetchCountriesResponseData extends Metable {
  countries: Country[]
}

export const fetchCountries = (locale?: LocaleIdentifier): Promise<FetchCountriesResponseData> => {
  const queryParams = locale ? qs.stringify({ localize: locale }) : ''
  return getRequest(`/v2/countries${queryParams ? `?${queryParams}` : ''}`, undefined, false)
}
