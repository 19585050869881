import React, { ReactElement } from 'react'

import * as Styled from './styles'

export interface SkeletonBoxProps {
  aspectRatio?: string
  fullWidth?: boolean
  fullHeight?: boolean
  height?: number
  width?: number
}

export const SkeletonBox = ({ ...props }: SkeletonBoxProps): ReactElement => <Styled.SkeletonBox {...props} />
