import { Icon, IconName, Text } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'

import { NotificationSettingsLineValue, UpdateUserNotificationSettingsPayload } from '@modules-deprecated/app/user'

import { getImageClassName } from '../../../../../../utils/getClassName'
import { getImgPath } from '../../../../../../utils/getImgPath'
import { getThumbnailUrl } from '../../../../../../utils/getThumbnailUrl'
import { useOrganizationNotificationSettings } from '../../context/organizationNotificationSettings'
import { NotificationSettingsTable } from '../NotificationSettingsTable'
import * as Styled from './styles'

export const OrganizationNotificationSettings = (): ReactElement | null => {
  const { name, organizationId, iconUrl, isSettingsOpen, updateSettingsChange } = useOrganizationNotificationSettings()
  const buttonIcon: IconName = isSettingsOpen ? 'chevronUp' : 'chevronDown'

  const iconSrc = useMemo(
    () => (iconUrl ? getThumbnailUrl(iconUrl) : getImgPath('/modals/default-organization-icon.png')),
    [iconUrl],
  )

  const handleRowClick = useCallback(
    () => {
      const value: NotificationSettingsLineValue = isSettingsOpen ? 'no' : 'yes'
      const changedNotificationSettings: UpdateUserNotificationSettingsPayload = {
        organizationId,
        payload: {
          organizationSpecific: value,
        },
      }

      if (value === 'yes') {
        changedNotificationSettings.payload = {
          organizationSpecific: 'yes',
        }
      }

      updateSettingsChange(changedNotificationSettings)
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSettingsOpen],
  )

  if (!organizationId) {
    return null
  }

  return (
    <Styled.OrganizationNotificationSettingsWrapper>
      <Styled.HeaderWrapper onClick={handleRowClick}>
        <Styled.HeaderFigure className={getImageClassName()}>
          <Styled.IconWrapper alt="icon" src={iconSrc} />
        </Styled.HeaderFigure>
        <Styled.TextWrapper>
          <Text variant="h3">{name}</Text>
        </Styled.TextWrapper>
        <Icon size="large" icon={buttonIcon} />
      </Styled.HeaderWrapper>
      {isSettingsOpen && <NotificationSettingsTable />}
    </Styled.OrganizationNotificationSettingsWrapper>
  )
}
