import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { Language } from '../../../../types/language'
import { LocaleIdentifier } from '../../../../types/localeIdentifier'
import { locales } from '../../../../utils/i18n'
import { localeToCountryCode } from '../../../../utils/localeToCountryCode'
import { CountryFlag } from '../../../CountryFlag'

/* eslint-disable @typescript-eslint/naming-convention */
const localeToLanguageTKey: Partial<Record<LocaleIdentifier, string>> = {
  da_DK: 'settings.user.language.da_DK',
  en_US: 'settings.user.language.en_US',
  fr_FR: 'settings.user.language.fr_FR',
}
/* eslint-enable @typescript-eslint/naming-convention */

export const getLanguageItems = (t: TFunction): NavItem<LocaleIdentifier>[] => {
  return Object.keys(locales)
    .map((language): NavItem<LocaleIdentifier> | undefined => {
      const locale = locales[language as Language]
      const countryFromLocale = localeToCountryCode[locale]
      const tKey = localeToLanguageTKey[locale]

      return !tKey
        ? undefined
        : {
            id: locale,
            children: t(tKey),
            title: countryFromLocale,
            iconLeft: <CountryFlag countryCode={countryFromLocale} />,
            value: locale,
          }
    })
    .filter((item): item is NavItem<LocaleIdentifier> => item !== undefined)
}
