import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const InfoLineContainer = styled.div`
  padding: ${Spacing.XXS};
  display: flex;
  justify-content: space-between;
`

export const GridLine = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  & > * {
    padding: ${Spacing.XXS} ${Spacing.S};
    min-width: 110px;
  }

  & > *:not(:first-of-type) {
    text-align: right;
  }
`

export const AccountDataContainer = styled.div`
  flex: auto;
  max-width: 320px;

  & > *:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
  }
`

export const BalanceTable = styled.div`
  max-width: 470px;

  & > *:nth-of-type(odd) {
    background-color: ${Color.Blue5};
  }
`
