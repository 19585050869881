import { Button, ButtonsGroup, Space, Text } from '@design-system'

import { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useInitiateBankConnection } from '@views/bankIntegration/hooks/useInitiateBankConnection'

import { BankReconciliationConnection } from '../../../../../../../../../../assets/images'
import { useSegment } from '../../../../../../../../../../hooks'
import { useBankLines } from '../../../../../../contexts/bankLinesContext'
import { useBillReconciliation } from '../../../../../../contexts/billReconciliationContext'
import { BankLinesState } from '../../../../../../enums/bankLinesState'
import * as Styled from '../styles'

export const ConnectBank = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  // todo, WIP functionality
  const { selectedAccountId } = useBillReconciliation()
  const { bankLines, setBankLinesState } = useBankLines()
  const { connectToBank, isProcessing: isAiiaInitiating } = useInitiateBankConnection()

  const handleConnectBankButtonClick = useCallback(() => {
    track('xxx - expenses - Bank line modal - connect bank button clicked')

    if (!selectedAccountId) {
      return
    }

    connectToBank()
  }, [connectToBank, selectedAccountId, track])

  const handleSkipButtonClick = useCallback(() => {
    track('xxx - expenses - Bank line modal - skip bank connection button clicked')

    if (!bankLines?.length) {
      setBankLinesState(BankLinesState.Empty)
      return
    }

    setBankLinesState(BankLinesState.BankLinesList)
  }, [bankLines?.length, setBankLinesState, track])

  useEffect(() => {
    track('xxx - expenses - Bank line modal - connect bank button shown')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Styled.EmptyStateWrapper>
      <BankReconciliationConnection />
      <Space size="s" />
      <Text variant="h3" alignment="center">
        {t('bill.payment_reconcile.connect_your_bank')}
      </Text>
      <Space size="s" />
      <Text alignment="center">{t('empty_state.bank_not_connected.description')}</Text>
      <Space />
      <ButtonsGroup>
        <Button onClick={handleSkipButtonClick} disabled={isAiiaInitiating} variant="text">
          {t('skip')}
        </Button>
        <Button onClick={handleConnectBankButtonClick} loading={isAiiaInitiating} icon="roundedArrows">
          {t('connect_bank')}
        </Button>
      </ButtonsGroup>
    </Styled.EmptyStateWrapper>
  )
}
