import { AccountSelect, useModalContentToggler } from '@components'
import { FormItem, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Account } from '@modules-deprecated/app/accounts/types'

import { ModalId } from '../../../../../../enums/modalId'
import { useSegment } from '../../../../../../hooks'
import { useBillsEmberConnection } from '../../../../contexts/billsEmberConnectionContext'
import { useBillView } from '../../../../elements/BillReadableForm'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMatch } from '../../contexts/matchContext'
import { useModalContentsOperations } from '../../contexts/modalContentsOperationsContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'

export const BillAccountSelector = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { activeState, setActiveState } = useModalContentToggler()
  const { selectedAccountId, setSelectedAccountId } = useBillReconciliation()
  const { resetValues } = useModalContentsOperations()
  const { matchSuggestion } = useMatch()
  const { registerPayment } = useBillsEmberConnection()
  const { billId } = useBillView()
  const { close: closeBillReconciliationModal } = useModal(ModalId.BillReconciliationModal)

  const isMatchModalState = activeState === BillReconciliationModalStateId.Match
  const isBankLinesModalState = activeState === BillReconciliationModalStateId.BankLines

  const handleAccountChange = useCallback(
    (id?: string, value?: Account) => {
      if (!id) {
        return
      }

      const isCurrencyDifferentThanDanish = value?.currencyId !== 'DKK'

      if (isCurrencyDifferentThanDanish) {
        closeBillReconciliationModal()
        registerPayment({ accountId: id, billId })
        return
      }

      resetValues()
      setSelectedAccountId(id)

      const event = isBankLinesModalState
        ? 'xxx - expenses - Bank line modal - account changed'
        : 'xxx - expenses - Manual payment modal - account changed'

      track(event)

      if (value?.isBankAccount && value?.isPaymentEnabled) {
        setActiveState(BillReconciliationModalStateId.BankLines)
      }

      if (value?.isBankAccount === false) {
        setActiveState(BillReconciliationModalStateId.ManualPayment)
      }
    },
    [
      billId,
      closeBillReconciliationModal,
      isBankLinesModalState,
      registerPayment,
      resetValues,
      setActiveState,
      setSelectedAccountId,
      track,
    ],
  )

  return (
    <FormItem label={t('account')}>
      <AccountSelect
        disabled={isMatchModalState}
        dropdownSize="fitTrigger"
        isPaymentEnabled
        onSelect={handleAccountChange}
        selectedId={isMatchModalState ? matchSuggestion?.account_id : selectedAccountId}
      />
    </FormItem>
  )
}
