import { ContactType } from '../../../../../contacts/types/contactType'
import { Language } from '../../../../types/language'
import { contactTypeMap } from '../../constants/contactTypeMap'
import { ZervantContactType } from '../../enums/zervantContactType'
import { InvoiceForm } from '../../types/invoiceForm'
import { InvoicePayloadContact } from '../../types/invoicePayload/invoicePayloadContact'

const getZervantContactType = (type?: ContactType): ZervantContactType =>
  contactTypeMap[type || ContactType.Company] || ZervantContactType.Company

export const getReceiverPayload = (
  formValues: InvoiceForm,
  invoiceLanguage: Language,
): InvoicePayloadContact | undefined => {
  const { receiver } = formValues

  if (!receiver || !receiver?.externalId) {
    return undefined
  }

  const {
    cityText,
    contactNo,
    countryId,
    email,
    externalId,
    id,
    name,
    paymentTermsDays,
    registrationNo,
    street,
    type,
    vatNo,
    zipcodeText,
  } = receiver || {}

  const receiverAddress = {
    city: cityText,
    country: countryId,
    pobox: zipcodeText,
    streetAddress1: street,
  }

  return {
    address: receiverAddress,
    businessId: registrationNo,
    companyName: name,
    customerNumber: Number(contactNo || ''),
    customerType: getZervantContactType(type),
    displayName: name,
    email: email || '',
    // externalId in Zervant is Billy's id and Zervant's id
    // is Billy's externalId, that's why we switch types here
    externalContactId: id,
    firstName: name, // TODO: handle names
    id: Number(externalId || ''),
    language: invoiceLanguage,
    lastName: '',
    paymentTerm: paymentTermsDays,
    vatNumber: vatNo,
  }
}
