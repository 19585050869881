import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { BankIntegrationContextProvider } from '@views/bankIntegration/contexts/bankIntegrationContext'
import { BankConnectionStatusActions } from '@views/bankIntegration/elements/BankConnectionStatusActions'
import { useGetBill } from '@views/bills/hooks/useGetBill'

import { Scope } from '../../../../enums/scope'
import { useOrganizationViewUrl } from '../../../../hooks/routing/useOrganizationViewUrl'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { reactRoute } from '../../../../utils/routing/reactRoute'
import { BillAttachmentPageContextProvider } from '../../contexts/billAttachmentPageContext'
import { BillReadableActions } from '../BillReadableActions'
import { BillViewContextProvider } from '../BillReadableForm'
import { BillReadableLayout } from '../BillReadableLayout'
import { BillReadableTitle } from '../BillReadableTitle'
import { ContactBalanceContextProvider } from '../BillReconciliationModal/contexts/contactBalanceContext'

interface BillReadableProps {
  billId: string
}

export const BillReadable = ({ billId }: BillReadableProps): ReactElement => {
  const location = useLocation()
  const { url: billsUrl } = useOrganizationViewUrl(reactRoute.bills.getMainRoute())
  const { organization } = useUserOrganization()
  const { data: billData } = useGetBill(billId)

  const showBillActions = isAuthorized(Scope.BillWrite)

  return (
    <BillAttachmentPageContextProvider>
      <BillViewContextProvider billId={billId}>
        <BankIntegrationContextProvider organizationId={organization?.id as string}>
          <BankConnectionStatusActions />
          <ContactBalanceContextProvider
            contactId={billData?.bill?.contactId || undefined}
            currencyId={billData?.bill?.currencyId || undefined}
          >
            <ModuleLayout
              fullHeight
              backButtonTo={billsUrl && `${billsUrl}${location.search}`}
              sideActions={showBillActions ? <BillReadableActions /> : null}
              title={<BillReadableTitle />}
              withBackButton
            >
              <BillReadableLayout />
            </ModuleLayout>
          </ContactBalanceContextProvider>
        </BankIntegrationContextProvider>
      </BillViewContextProvider>
    </BillAttachmentPageContextProvider>
  )
}
