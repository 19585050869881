import { Badge, Button, ButtonsGroup, Space } from '@design-system'

import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { CookieKeys } from '../../../../../../enums/cookieKeys'
import { useSegment } from '../../../../../../hooks'
import { useOrganizationViewUrl } from '../../../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../../../utils/routing/reactRoute'
import { ExternalViewInvoicesButton } from './elements/ExternalViewInvoicesButton'

export const InvoicesListSideActions = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { url: invoicesNewUrl } = useOrganizationViewUrl(reactRoute.invoices.getNewInvoiceRoute())

  const handleCreateInvoiceClick = useCallback(() => {
    Cookies.set(CookieKeys.InvoicingInvoicesListQuery, location.search)
    track('XXX - Invoicing Core - Invoices List - Create new invoice clicked')
  }, [location.search, track])

  return (
    <ButtonsGroup>
      <Button icon="plusCircle" onClick={handleCreateInvoiceClick} to={invoicesNewUrl}>
        {t('invoices.side_actions.create_invoice')}
        <Space size="s" horizontal />
        <Badge>{t('beta')?.toUpperCase()}</Badge>
      </Button>
      <ExternalViewInvoicesButton />
    </ButtonsGroup>
  )
}
