import { Link } from '@design-system'

import { EmberRoute } from '../../../../../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../../../../../hooks/routing/useEmberRouteUrl'

interface InvoiceTransactionLinkProps {
  transactionId: string
  transactionNo: number | null
}

export const InvoiceTransactionLink = ({ transactionId, transactionNo }: InvoiceTransactionLinkProps) => {
  const { url: transactionUrl } = useEmberRouteUrl(EmberRoute.Transaction, transactionId)

  return (
    <>
      <Link to={transactionUrl} withAnimation>
        {transactionNo}
      </Link>{' '}
    </>
  )
}
