import { ReactNode, useCallback, useRef } from 'react'
import {
  ReactZoomPanPinchContentRef,
  ReactZoomPanPinchContextState,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch'

import { AspectRatio } from '../../types'
import { checkBounds } from '../../utils/checkBounds'
import { getZoomableContainerWidth } from '../../utils/getZoomableContainerWidth'

/* Library height/width wrappers rewrites */
const ZoomWrappersStyles = { width: '100%', height: '100%' }

interface ZoomableWrapperProps {
  actionsComponent: ReactNode
  aspectRatio?: AspectRatio
  children: ReactNode
  fitToWidth?: boolean
  isLoading: boolean
  zoomable: boolean
}

export const ZoomableWrapper = ({
  actionsComponent,
  aspectRatio,
  children,
  fitToWidth,
  isLoading,
  zoomable,
}: ZoomableWrapperProps) => {
  const ref = useRef<ReactZoomPanPinchContentRef>(null)

  const enforceBounds = useCallback(
    (state: ReactZoomPanPinchContextState) => {
      if (!ref) {
        return
      }

      const {
        instance: { transformState },
      } = state

      const { isOutsideBounds, x, y, scale } = checkBounds(transformState)

      if (isOutsideBounds) {
        ref?.current?.setTransform(x, y, scale)
      }
    },
    [ref],
  )

  const ZoomContentStyles = {
    aspectRatio: (isLoading && aspectRatio) || 'auto',
    height: '100%',
    margin: '0px auto',
    width: getZoomableContainerWidth({ fitToWidth, isLoading }),
  }

  if (!zoomable) {
    return children
  }

  return (
    <TransformWrapper
      disabled={!zoomable}
      doubleClick={{
        mode: 'reset',
      }}
      maxScale={4.0}
      minScale={0.75}
      limitToBounds={false}
      onPanningStop={enforceBounds}
      ref={ref}
    >
      <TransformComponent contentStyle={ZoomContentStyles} wrapperStyle={ZoomWrappersStyles}>
        {children}
      </TransformComponent>
      {actionsComponent}
    </TransformWrapper>
  )
}
