import { Attachment, getFileThumbnail, PreviewFile, useModal } from '@design-system'

import debounce from 'lodash/debounce'
import React, {
  ChangeEvent,
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQueryClient } from 'react-query'

import { AttachmentType } from '@features/attachments/enums/attachmentType'
import { useUpdateAttachment } from '@features/attachments/hooks/useUpdateAttachment'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { trackError } from '../../../../../utils/trackError'

interface ContextState {
  amount?: number
  attachmentId?: string
  createdDate: string | undefined
  description: string
  documentDate: string | undefined
  fileName: string
  files: PreviewFile[]
  handleDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void
  handleEInvoiceRejection: () => void
  handleManageUploadButtonClick: () => void
  isEDocument: boolean
  saveStatus: SaveAttachmentStatus
  supplier?: string
}

const AttachmentPreviewModalContext = createContext<ContextState | undefined>(undefined)

type SaveAttachmentStatus = 'idle' | 'saving' | 'saved' | 'error'

interface AttachmentPreviewModalContextProps {
  attachment?: Attachment
  children: ReactNode
  modalId: string
  onFileSelect?: () => void
}

export const AttachmentPreviewModalContextProvider = ({
  attachment,
  children,
  modalId,
  onFileSelect,
}: AttachmentPreviewModalContextProps): ReactElement => {
  const { amount, createdTime, documentDate, id, file, supplier, type, comment } = attachment || {}
  const [description, setDescription] = useState<string>(comment || '')
  const [saveStatus, setSaveStatus] = useState<SaveAttachmentStatus>('idle')
  const queryClient = useQueryClient()
  const { close } = useModal(modalId)

  useEffect(() => {
    setDescription(comment || '')
  }, [comment])

  const { update } = useUpdateAttachment({
    onSuccess: async () => {
      await queryClient.invalidateQueries(QueryKeys.AttachmentsList)
      setSaveStatus('saved')
      setTimeout(() => setSaveStatus('idle'), 2000)
    },
    onError: (error) => {
      trackError(error)

      setSaveStatus('error')
      setTimeout(() => setSaveStatus('idle'), 5000)
    },
  })

  const isEDocument = type === AttachmentType.EDocument
  const [documentDateParsed] = documentDate ? documentDate.split('T') : []
  const [createdDateParsed] = createdTime ? createdTime.split('T') : []

  const files: PreviewFile[] = useMemo(
    () =>
      file
        ? [
            {
              src: file.isPdf ? file.downloadUrl : getFileThumbnail(file, 'medium'),
              srcZoom: file.isPdf ? file.downloadUrl : getFileThumbnail(file, 'large'),
              srcDownload: file.downloadUrl,
            },
          ]
        : [],
    [file],
  )

  const debouncedUpdate = useMemo(
    () =>
      debounce((newComment: string) => {
        if (!id) {
          return
        }
        update({ id, comment: newComment })
      }, 1000),
    [update, id],
  )

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (saveStatus !== 'saving') {
        setSaveStatus('saving')
      }
      const value = event.target.value
      setDescription?.(value)
      debouncedUpdate(value)
    },
    [debouncedUpdate, saveStatus, setDescription],
  )

  const handleManageUploadButtonClick = useCallback(() => {
    onFileSelect?.()
    close()
  }, [onFileSelect, close])

  const handleEInvoiceRejection = useCallback(() => {
    close()
  }, [close])

  return (
    <AttachmentPreviewModalContext.Provider
      value={{
        amount,
        attachmentId: id,
        createdDate: createdDateParsed,
        description,
        documentDate: documentDateParsed,
        fileName: file?.fileName || '-',
        files,
        handleDescriptionChange,
        handleEInvoiceRejection,
        handleManageUploadButtonClick,
        isEDocument,
        saveStatus,
        supplier,
      }}
    >
      {children}
    </AttachmentPreviewModalContext.Provider>
  )
}

export const useAttachmentPreviewModal = () => {
  const context = useContext(AttachmentPreviewModalContext)

  if (!context) {
    throw new Error('AttachmentPreviewModalContextProvider is missing in the module!')
  }

  return context
}
