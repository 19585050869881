import { useCallback, useState } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useCurrentUser } from '@modules-deprecated/app/user'

import { trackError } from '../../../../utils/trackError'
import { COMPANY_NAME } from '../constants'
import { requestFrodaAccessUrl } from '../query-api'

export const useFrodaHomeAccessUrl = () => {
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()

  const [homeAccessUrl, setHomeAccessUrl] = useState<string>('')
  const [isAccessUrlLoading, setIsAccessUrlLoading] = useState<boolean>(false)

  const requestFrodaHomeAccessUrl = useCallback(async () => {
    if (!organization?.id || !user?.locale) {
      return
    }

    try {
      setIsAccessUrlLoading(true)
      const url = await requestFrodaAccessUrl({
        externalId: organization.id,
        companyName: COMPANY_NAME,
        locale: user.locale.split('_')[0],
      })
      setHomeAccessUrl(url)
    } catch (error) {
      trackError(error)
    } finally {
      setIsAccessUrlLoading(false)
    }
  }, [organization?.id, user?.locale])

  return {
    homeAccessUrl,
    isAccessUrlLoading,
    requestFrodaHomeAccessUrl,
    setHomeAccessUrl,
  }
}
