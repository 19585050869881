import { AccountSelect, CustomDraggableItemProps, InputNumberLocalized } from '@components'
import { Divider, IconButton, Spacing, Text, Tooltip } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../../hooks'
import { DeductionSourceSelect } from '../../../../../shared'
import { CreateOrEditVatRateFormSchema } from '../../../../utils/formData'
import * as Styled from './styles'

export interface DeductionComponentProps extends CustomDraggableItemProps {
  disabled?: boolean
  enabledFields?: {
    share?: boolean
  }
}

export const DeductionComponent = ({ onDelete, index, disabled }: DeductionComponentProps): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<CreateOrEditVatRateFormSchema>()

  return (
    <Styled.DeductionComponentWrapper>
      <Styled.FormItemsGroup itemsTemplate={[2, 1, 4, 2, 5, 1]} columnSpacing={Spacing.S}>
        <FormItem
          name={`deductionComponents.${index}.share`}
          render={({ field }) => (
            <InputNumberLocalized
              {...field}
              suffix={<Text colorVariant="secondary">%</Text>}
              withFormatting={false}
              disabled={disabled}
            />
          )}
        />
        <Text variant="large">
          {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.deduction_component.of')}
        </Text>
        <FormItem
          name={`deductionComponents.${index}.source`}
          render={({ field: { value, onChange, ...props } }) => (
            <DeductionSourceSelect
              {...props}
              onSelect={(id, value) => onChange(value)}
              selectedId={value}
              disabled={disabled}
            />
          )}
        />
        <Text variant="large">
          {t('settings.organization.vat_rates.modals.create_or_edit_vat_rate.deduction_component.goes_to')}
        </Text>
        <FormItem
          name={`deductionComponents.${index}.accountId`}
          render={({ field: { value, onChange, ...props } }) => (
            <AccountSelect
              {...props}
              onSelect={(id, value) => {
                onChange(value?.id)
              }}
              placeholder={t('create_contact_modal.form.contact_standard_settings.expense_category.placeholder')}
              selectedId={value}
              disabled={disabled}
            />
          )}
        />
        <Tooltip label={t('bill.edit.form.delete_line')} placement="top">
          <IconButton icon="trash" onClick={onDelete} disabled={disabled} />
        </Tooltip>
      </Styled.FormItemsGroup>
      <Divider />
    </Styled.DeductionComponentWrapper>
  )
}
