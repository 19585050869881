import { BorderRadius, Color, DefaultValue, getDefaultTransition, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { FORMS_INNER_SHADOW } from '../../styles-deprecated'

const DEFAULT_GAP_SIZE = 10

export const FormRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  gap: ${DEFAULT_GAP_SIZE}px;
`

export const Error = styled.div`
  margin-top: -${DEFAULT_GAP_SIZE * 2}px;
  grid-column: 1 / -1;
`

interface InputProps {
  error?: boolean
}

export const Input = styled.input<InputProps>`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: ${BorderRadius.XS};
  border: 1px solid ${({ error }) => (error ? Color.Red : Color.Gray60)};
  box-shadow: ${FORMS_INNER_SHADOW};
  background-color: ${Color.White};
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  ${getDefaultTransition('background-color', 'border', 'box-shadow')};

  ${({ error }) =>
    !error &&
    css`
      &:hover {
        color: ${Color.DeepSeaGreen};
        border-color: ${Color.DeepSeaGreen};
        background-color: ${Color.Gray10};
      }
    `}
  &:active {
    color: ${Color.DeepSeaGreen};
    border-color: ${Color.Gray50};
    background-color: ${Color.Gray20};
    box-shadow: none;
  }
  &:checked {
    border-color: ${Color.Green};
    color: ${Color.DeepSeaGreen};
    background-color: ${({ error }) => (error ? Color.Red : Color.ElectricGreen)};
    box-shadow: none;

    &:before {
      content: '';
      width: 10px;
      height: 10px;
      position: absolute;
      top: 1px;
      left: 2px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.87012L3.66667 8.37012L9 2.37012' stroke='black' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }
    &:hover {
      color: ${Color.Lavender};
      border-color: ${Color.DeepSeaGreen};
      background-color: ${({ error }) => (error ? Color.Red : Color.DeepSeaGreen)};

      &:before {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.87012L3.66667 8.37012L9 2.37012' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
    &:active {
      border-color: ${Color.Green110};
      color: ${Color.White};
      background-color: ${({ error }) => (error ? Color.Red : Color.Green110)};
      box-shadow: none;
    }
  }
  &:disabled {
    color: ${Color.Gray50};
    border-color: ${Color.Gray30};
    background-color: ${Color.Gray20};
    cursor: not-allowed;

    &:before {
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.87012L3.66667 8.37012L9 2.37012' stroke='silver' stroke-width='1.5'/%3E%3C/svg%3E%0A");
    }

    &,
    &:hover {
      border-color: ${Color.Gray40};
      background-color: ${Color.Gray30};
    }
    &:checked {
      color: ${Color.Gray50};
      border-color: ${Color.Gray30};
      background-color: ${Color.Gray20};
    }
  }
`

export const DateCheckboxInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-grow: 1;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`

interface DateCheckboxWrapperProps {
  disabled?: boolean
}

export const DateCheckboxWrapper = styled.div<DateCheckboxWrapperProps>`
  display: flex;
  border-left-color: transparent;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.Gray50};
  border-top-right-radius: ${DefaultValue.BorderRadius};
  border-bottom-right-radius: ${DefaultValue.BorderRadius};
  padding: ${Spacing.S} ${Spacing.M};

  label span {
    display: none;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${Color.Gray50};
      border-color: ${Color.Gray40};
      background-color: ${Color.Gray20};
      box-shadow: none;

      &::placeholder: {
        color: ${Color.Gray50};
      }
    `}
`

export const ErrorContainer = styled.div`
  &:not:empty: {
    margin-bottom: ${Spacing.M};
  }
`
