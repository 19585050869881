import React, { forwardRef, MouseEvent, ReactElement, Ref, useCallback } from 'react'
import { DropzoneOptions } from 'react-dropzone'

import { useDropzoneInput } from '../../hooks/useDropzoneInput'
import { Button, ButtonProps } from '../Button'
import { ACCEPT_DEFAULT } from '../Dropzone'
import { useTranslateRejectedFilesErrors } from '../Dropzone/hooks/useTranslateRejectedFilesErrors'

export interface FileButtonProps
  extends Omit<ButtonProps, 'onDragEnter' | 'onDrop' | 'onDragLeave' | 'onDragOver'>,
    Omit<
      DropzoneOptions,
      | 'onDragEnter'
      | 'onDrop'
      | 'onDragLeave'
      | 'onDragOver'
      | 'noDrag'
      | 'onDropAccepted'
      | 'onDropRejected'
      | 'onError'
    > {
  isBlocked?: boolean
  onFilesSelect: DropzoneOptions['onDrop']
  onFilesSelectAccepted?: DropzoneOptions['onDropAccepted']
  onFilesSelectRejected?: DropzoneOptions['onDropRejected']
}

export const FileButton = forwardRef((props: FileButtonProps, forwardedRef: Ref<HTMLButtonElement>): ReactElement => {
  const {
    isBlocked,
    onFilesSelect,
    onFilesSelectAccepted,
    onFilesSelectRejected,
    accept = ACCEPT_DEFAULT,
    multiple,
    minSize,
    maxSize,
    maxFiles,
    preventDropOnDocument,
    noClick,
    noKeyboard,
    noDragEventsBubbling,
    getFilesFromEvent,
    onFileDialogCancel,
    validator,
    onClick,
    children,
    ...buttonProps
  } = props

  const { handleDrop: handleFilesSelect, handleDropRejected: handleFilesSelectRejected } =
    useTranslateRejectedFilesErrors({
      onDrop: onFilesSelect,
      onDropRejected: onFilesSelectRejected,
      maxFiles,
      maxSize,
      minSize,
      accept,
    })

  const { inputRef, inputProps } = useDropzoneInput({
    isBlocked,
    onFilesSelect: handleFilesSelect,
    onFilesSelectAccepted,
    onFilesSelectRejected: handleFilesSelectRejected,
    accept,
    multiple,
    minSize,
    maxSize,
    maxFiles,
    preventDropOnDocument,
    noClick,
    noKeyboard,
    noDragEventsBubbling,
    getFilesFromEvent,
    onFileDialogCancel,
    validator,
  })

  const handleButtonClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (inputRef?.current && !isBlocked) {
        // The name needs to be changed before triggering click. It's required to make it work with file that have same the file name as previous uploaded file.
        inputRef.current.value = ''
        inputRef.current?.click()
      }

      onClick?.(event)
    },
    [inputRef, isBlocked, onClick],
  )

  return (
    <>
      <Button ref={forwardedRef} onClick={handleButtonClick} {...buttonProps}>
        {children}
      </Button>
      <input ref={inputRef} {...inputProps} />
    </>
  )
})
