import { css, keyframes } from '@emotion/core'

const activeDotAnimation = keyframes`
  0%, 100% { transform: scaleY(1); }
  25% { transform: scaleY(0.75); }
`

const inactiveDotAnimation = keyframes`
  0%, 100% { transform: scaleX(1) scaleY(1); }
  25% { transform: scaleX(0.85) scaleY(1.25); }
`

export const paginationDotAnimation = (active: boolean) => css`
  animation: ${active ? activeDotAnimation : inactiveDotAnimation} 0.6s cubic-bezier(0.23, 1, 0.32, 1);
`
