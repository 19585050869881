import { notify } from '@design-system'

import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from 'react-query'
import { usePrevious } from 'react-use'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { trackError } from '../../../utils/trackError'
import {
  deleteVouchers as deleteVoucherList,
  DeleteVoucherProps,
  fetchVouchersList,
  VoucherListResponse,
} from '../services/query-api'
import { Pagination, Voucher, VoucherListQueryParams } from '../types'

const DEFAULT_VOUCHERS_LIST_DATA: VoucherListResponse = {
  vouchers: [],
  pagination: { total: 0, limit: 0, offset: 0 },
}

export type DeleteVouchers = UseMutateFunction<void[], unknown, DeleteVoucherProps[]>

interface UseVouchersProps extends VoucherListQueryParams {
  organizationId?: string
}

interface UseVouchersResponse {
  deleteVouchers: DeleteVouchers
  isLoading: boolean
  pagination: Pagination
  refetchVouchers: () => void
  vouchers: Voucher[]
}

export const useVouchers = ({ organizationId, ...params }: UseVouchersProps): UseVouchersResponse => {
  const { t } = useTranslation()
  const deletedVouchersAmount = useRef(0)
  const queryClient = useQueryClient()
  const { isLoading, data } = useQuery(
    [QueryKeys.VouchersList, organizationId, params],
    () =>
      fetchVouchersList({
        // Warning disabled during the eslint warning cleanup.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        queryKey: [QueryKeys.VouchersList, { organizationId: organizationId!, params }],
      }),
    {
      enabled: !!organizationId,
      onError: (error) => {
        trackError(error)
        notify({
          id: NotificationKeys.VoucherInboxListFetch,
          message: t('voucher.inbox.toast.voucher_list_fetch_failed', { organizationId }),
          variant: 'error',
        })
      },
    },
  )
  const { mutate: deleteVouchers } = useMutation(
    (vouchersToDelete: DeleteVoucherProps[]) => {
      deletedVouchersAmount.current = vouchersToDelete.length
      return deleteVoucherList(vouchersToDelete)
    },
    {
      onError: () => {
        notify({
          id: NotificationKeys.VoucherInboxBulkDelete,
          message: t('voucher.inbox.toast.voucher_deleted_failed'),
          variant: 'error',
        })
      },
      onSuccess: () => {
        notify({
          id: NotificationKeys.VoucherInboxBulkDelete,
          message: t('voucher.inbox.toast.voucher_deleted_in_bulk_success', { count: deletedVouchersAmount.current }),
          variant: 'success',
        })
      },
      onSettled: () => {
        refetchVouchers()
      },
    },
  )

  const previousData = usePrevious(data)
  const { pagination, vouchers } = useMemo(
    () =>
      data || {
        ...DEFAULT_VOUCHERS_LIST_DATA,
        pagination: previousData?.pagination
          ? { ...previousData?.pagination }
          : { ...DEFAULT_VOUCHERS_LIST_DATA.pagination },
      },
    // Update vouchers only on `data` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const refetchVouchers = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.VouchersList)
  }, [queryClient])

  return {
    deleteVouchers,
    isLoading,
    pagination,
    refetchVouchers,
    vouchers,
  }
}
