import cc from 'classcat'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'

import { reactClass } from '../../../utils'
import { TableRowComponent } from '../TableRow/TableRow'
import { TableColumn, TableRow, TableTotalRow } from '../types'
import * as Styled from './styles'

export type TableBodyProps<T extends TableRow> = {
  className?: string
  columns: TableColumn<T>[]
  columnsLayout: string
  data?: T[]
  isRowSelectionDisabled?: (row: T) => boolean
  onFetch?: (offset: number) => void
  onRowSelect?: (row: T, selected: boolean) => void
  onSelect?: (row: T, index: number) => void
  rowSpacing?: number
  selectable?: boolean
  total?: number | null
  rowTotal?: TableTotalRow<T>
}

type LoaderProps = {
  columnCount: number
}

export const Loader = ({ columnCount = 1 }: LoaderProps) => {
  const { t } = useTranslation()

  return (
    <tr>
      <Styled.LoaderCell className={reactClass('infinite-table-loader')} columnCount={columnCount}>
        {t('components.infinitetable.loading')}
      </Styled.LoaderCell>
    </tr>
  )
}

const Trigger = React.forwardRef<HTMLTableSectionElement>((_, ref) => (
  <Styled.TableFooter className={reactClass('infinite-table-trigger')} key="infinite-table-trigger" ref={ref} />
))

export const TableBody = <T extends TableRow>({
  className,
  columns,
  columnsLayout,
  data,
  isRowSelectionDisabled = () => false,
  onFetch = () => null,
  onRowSelect = () => null,
  onSelect,
  rowSpacing,
  rowTotal,
  selectable,
  total,
  ...rest
}: TableBodyProps<T>) => {
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [ref, inView] = useInView()

  const fetch = () => {
    if (loading || done) {
      return
    }
    setLoading(true)
    onFetch(data?.length || 0)
  }

  const receive = () => {
    if (!data) {
      setDone(false)
      return
    }
    setLoading(false)
    setDone(data.length === total)
  }

  useEffect(receive, [data, total])
  useEffect(
    fetch,
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inView],
  )

  return (
    <>
      <tbody className={cc([className, reactClass('infinite-table-body')])} {...rest}>
        {(data || []).map((row: T, index: number) => (
          <TableRowComponent<T>
            key={row.id}
            row={row}
            index={index}
            columns={columns}
            columnsLayout={columnsLayout}
            onSelect={onSelect}
            onRowSelect={onRowSelect}
            rowSpacing={rowSpacing}
            selectable={selectable}
            hoverable={!!onSelect || !!row.linkTo}
            isRowSelectionDisabled={isRowSelectionDisabled(row)}
          />
        ))}
        {rowTotal && (
          <TableRowComponent<T>
            className="total"
            key={rowTotal.id}
            row={rowTotal}
            rowSpacing={rowSpacing}
            index={0}
            columns={columns}
            columnsLayout={columnsLayout}
            selectable={false}
            hoverable={false}
            totalLabelColumnName={rowTotal.labelColumnName}
          />
        )}
        {!done && <Loader columnCount={columns.length} />}
      </tbody>
      {!loading && !done && <Trigger ref={ref} />}
    </>
  )
}
