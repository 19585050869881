import { Flex } from '@design-system'

import React, { ReactElement } from 'react'

import { AttachmentDetails } from './elements/AttachmentDetails/AttachmentDetails'
import { AttachmentPreview } from './elements/AttachmentPreview'

export const AttachmentPreviewModalBody = (): ReactElement => {
  return (
    <Flex>
      <AttachmentPreview />
      <AttachmentDetails />
    </Flex>
  )
}
