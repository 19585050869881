import React, { Children, ReactElement, ReactNode } from 'react'

import * as Styled from './styles'
import { Side } from './types/side'

interface ColumnsLayoutProps {
  asymmetrical?: boolean
  children: ReactNode
  fullHeight?: boolean
  stickySide?: Side
}

export const TwoColumnsLayout = ({
  asymmetrical,
  children,
  fullHeight,
  stickySide = 'left',
}: ColumnsLayoutProps): ReactElement => {
  const [leftSide, rightSide] = Children.toArray(children)

  return (
    <Styled.TwoColumnsLayoutWrapper fullHeight={fullHeight}>
      <Styled.LeftSide asymmetrical={asymmetrical} sticky={stickySide === 'left'}>
        {leftSide}
      </Styled.LeftSide>
      <Styled.RightSide asymmetrical={asymmetrical} sticky={stickySide === 'right'}>
        {rightSide}
      </Styled.RightSide>
    </Styled.TwoColumnsLayoutWrapper>
  )
}
