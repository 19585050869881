import { createElement } from 'react'
import { Route as RouterRoute, RouteProps as RouterRouteProps, useParams } from 'react-router-dom'

import { setReactHashRouteParams, setReactRouteParams } from '../../utils/routeParams'

export interface RouteProps extends Omit<RouterRouteProps, 'render'> {
  isHashRouter?: boolean
}

const RouteContentWrapper = ({ children, isHashRouter, ...props }: RouteProps) => {
  const params = useParams()

  if (isHashRouter) {
    setReactHashRouteParams(params)
  } else {
    setReactRouteParams(params)
  }

  return <>{children}</>
}

export const Route = ({ component, isHashRouter = false, children, ...props }: RouteProps) => {
  return (
    <RouterRoute {...props}>
      <RouteContentWrapper isHashRouter={isHashRouter}>
        {component ? createElement(component, props) : children}
      </RouteContentWrapper>
    </RouterRoute>
  )
}
