import { Route } from '@components'

import { ReactElement } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { SalesTaxReturnEmptyView } from './elements/SalesTaxReturnEmptyView/SalesTaxReturnEmptyView'
import { SalesTaxReturnLoadingView } from './elements/SalesTaxReturnLoadingView/SalesTaxReturnLoadingView'
import { SalesTaxReturnPeriodView } from './elements/SalesTaxReturnPeriodView/SalesTaxReturnPeriodView'
import { useFetchSalesTaxReturnPeriods } from './hooks/useFetchSalesTaxReturnPeriods'

export const SalesTaxReturns = (): ReactElement => {
  const { salesTaxReturnPeriods, isLoading } = useFetchSalesTaxReturnPeriods()
  const emberQuery = useGetQueryFromEmberRoute()

  const hasSalesTaxReturnData = salesTaxReturnPeriods?.length

  return (
    <Switch>
      <Route isHashRouter exact path={'/:salesTaxReturnPeriodId'} component={SalesTaxReturnPeriodView} />
      <Route isHashRouter exact path={'/'}>
        {isLoading && <SalesTaxReturnLoadingView />}
        {typeof emberQuery?.initialRoute === 'string' ? (
          <Redirect to={emberQuery.initialRoute} />
        ) : (
          hasSalesTaxReturnData && <Redirect to={`/${salesTaxReturnPeriods[0].id}`} />
        )}
        {!hasSalesTaxReturnData && !isLoading && <SalesTaxReturnEmptyView />}
      </Route>
    </Switch>
  )
}
