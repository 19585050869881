import React, { useCallback } from 'react'
import { useKey } from 'react-use'

import { Color } from '../../enums/color'
import { Direction } from '../../hooks/usePagination'
import { NavigationDirection } from '../../types/navigationDirection'
import { Flex } from '../Flex'
import { IconButton } from '../IconButton'
import { SkeletonText } from '../SkeletonText'
import { Text } from '../Text'
import * as Styled from './styles'

interface FilesPaginationProps {
  activePage: number
  amountOfPages: number
  canClickButton: (direction: Direction) => boolean
  isLoading: boolean
  navigationDirection?: NavigationDirection
  onPageChange: (direction: Direction) => void
}

export const FilesPagination = ({
  activePage,
  amountOfPages,
  canClickButton,
  navigationDirection,
  onPageChange,
  isLoading,
}: FilesPaginationProps) => {
  const isBackButtonDisabled = !canClickButton(Direction.Back)
  const isNextButtonDisabled = !canClickButton(Direction.Next)
  const isVerticalNavigation = navigationDirection === 'vertical'

  const handleButtonClick = useCallback(
    (direction: Direction, isDisabled: boolean) => {
      if (!isDisabled) {
        onPageChange(direction)
      }
    },
    [onPageChange],
  )

  const handleLeftKeyDown = useCallback(() => {
    if (isVerticalNavigation) {
      return
    }

    handleButtonClick(Direction.Back, isBackButtonDisabled)
  }, [handleButtonClick, isBackButtonDisabled, isVerticalNavigation])

  const handleRightKeyDown = useCallback(() => {
    if (isVerticalNavigation) {
      return
    }

    handleButtonClick(Direction.Next, isNextButtonDisabled)
  }, [handleButtonClick, isNextButtonDisabled, isVerticalNavigation])

  useKey('ArrowLeft', handleLeftKeyDown, undefined, [isBackButtonDisabled])
  useKey('ArrowRight', handleRightKeyDown, undefined, [isNextButtonDisabled])

  return (
    <Styled.FilesPaginationComponent>
      <IconButton
        size="m"
        variant="text"
        icon={isVerticalNavigation ? 'chevronUp' : 'chevronLeft'}
        disabled={isBackButtonDisabled}
        onClick={() => handleButtonClick(Direction.Back, isBackButtonDisabled)}
      />
      <Flex alignItems="center">
        {isLoading ? (
          <SkeletonText width={8} variant="micro" />
        ) : (
          <Text variant="large" color={Color.Gray90}>{`${activePage}`}</Text>
        )}
        <Text variant="large" color={Color.Gray90}>{`/${amountOfPages}`}</Text>
      </Flex>
      <IconButton
        size="m"
        variant="text"
        icon={isVerticalNavigation ? 'chevronDown' : 'chevronRight'}
        disabled={isNextButtonDisabled}
        onClick={() => handleButtonClick(Direction.Next, isNextButtonDisabled)}
      />
    </Styled.FilesPaginationComponent>
  )
}
