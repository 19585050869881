import { RefObject, useEffect, useState } from 'react'

interface UseFloatingActionsVisibilityProps {
  containerRef: RefObject<HTMLElement>
  isEnabled: boolean
  scrollableParentContainer?: Element | null
}

const VISIBILITY_MARGIN = 10

export const useFloatingActionsVisibility = ({
  containerRef,
  isEnabled,
  scrollableParentContainer,
}: UseFloatingActionsVisibilityProps) => {
  const [isContainerFullyVisible, setIsContainerFullyVisible] = useState(false)

  useEffect(() => {
    if (!isEnabled) {
      return
    }

    const handleScroll = () => {
      if (!scrollableParentContainer || !containerRef?.current) {
        setIsContainerFullyVisible(true)
        return
      }

      const containerRect = scrollableParentContainer.getBoundingClientRect()
      const componentRect = containerRef?.current.getBoundingClientRect()
      const isContainerBottomVisible = containerRect.bottom + VISIBILITY_MARGIN > componentRect.bottom

      setIsContainerFullyVisible(isContainerBottomVisible)
    }

    scrollableParentContainer?.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleScroll)
    handleScroll()

    return () => {
      scrollableParentContainer?.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleScroll)
    }
  }, [containerRef, isEnabled, scrollableParentContainer])

  return isContainerFullyVisible
}
