import { SectionMessage } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSessionStorage } from 'react-use'

import { useUserOrganization } from '../../../app/organization'
import { createVoucher } from '../../services/api'
import { CreateVoucherPayloadData, VoucherInboxState, VoucherOrigin } from '../../types'
import * as Styled from './styles'
import { ImageFile, UploadItem } from './types'

type BillUploadProps = {
  organizationId: string
  onUploadFinish?: (isAnyFileUploaded: boolean) => void
  scrollQuerySelector?: string
}

export const BillUpload = ({ onUploadFinish, organizationId, scrollQuerySelector }: BillUploadProps): ReactElement => {
  const { organization } = useUserOrganization()
  const [isBillEmailAddressClosed, setIsBillEmailAddressClosed] = useSessionStorage('voucher-list-email-section')
  const { t } = useTranslation()

  const billEmailAddress = organization?.billEmailAddress.replace('in.meneto.com', 'in.billysbilling.com')

  const handleEmailInfoClose = () => {
    setIsBillEmailAddressClosed(true)
  }

  const saveItemAsVoucher = async (item: UploadItem, itemFile?: ImageFile) => {
    const data: CreateVoucherPayloadData = {
      origin: VoucherOrigin.WEB_APP,
      inboxState: VoucherInboxState.RECEIVED,
    }

    if (itemFile) {
      data.files = [itemFile]
    }

    if (item.account?.id) {
      data.bankAccountId = item.account?.id
    }

    if (item.comment) {
      data.description = item.comment
    }

    return createVoucher(data, organizationId)
  }

  return (
    <>
      <Styled.FileUpload onItemUpload={saveItemAsVoucher} onUploadFinish={onUploadFinish} withAccountSelection />
      {billEmailAddress && !isBillEmailAddressClosed && (
        <Styled.SectionMessageWrapper>
          <SectionMessage variant="info" closable onClose={handleEmailInfoClose}>
            <Styled.Text>
              {t('attachments.scan_or_email_info')}{' '}
              <strong>
                <Styled.EmailLink href={`mailto:${billEmailAddress}`}>{billEmailAddress}</Styled.EmailLink>
              </strong>
            </Styled.Text>
          </SectionMessage>
        </Styled.SectionMessageWrapper>
      )}
    </>
  )
}
