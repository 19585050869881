/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use <Table /> from design-system instead
 */
import { SerializedStyles } from '@emotion/core'
import cc from 'classcat'
import React, { useCallback, useMemo, useState } from 'react'

import { reactClass } from '../../utils'
import { BulkActions, BulkActionsProps } from './BulkActions'
import defaultStyles from './defaultStyles'
import * as Styled from './styles'
import { TableBody } from './TableBody'
import { TableHead } from './TableHead/TableHead'
import { TableColumn, TableRow, TableTotalRow } from './types'

export type InfiniteTableProps<T extends TableRow> = {
  bulkActions?: Omit<BulkActionsProps, 'onToggle' | 'totalRows'> &
    Partial<Pick<BulkActionsProps, 'onToggle' | 'totalRows'>>
  className?: string
  columns: TableColumn<T>[]
  css?: SerializedStyles
  data?: T[]
  onFetch?: (offset: number) => void
  onHeaderHover?: (isHovered: boolean) => void
  onRowSelect?: (row: T, selected: boolean) => void
  onSelect?: (row: T, index: number) => void
  onSort?: (field: string) => void
  rowSpacing?: number
  selectable?: boolean
  stickyHeader?: boolean
  total?: number | null
  isRowSelectionDisabled?: (row: T) => boolean
  rowTotal?: TableTotalRow<T>
}

export const InfiniteTable = <T extends TableRow>({
  bulkActions,
  className,
  columns,
  css: styles,
  data,
  isRowSelectionDisabled = () => false,
  onFetch = () => null,
  onHeaderHover = () => null,
  onRowSelect = () => null,
  onSelect,
  onSort = () => null,
  rowSpacing,
  rowTotal,
  stickyHeader,
  total,
  ...rest
}: InfiniteTableProps<T>) => {
  const onFetchCallback = useCallback(
    (offset: number) => {
      onFetch(offset)
    },
    [onFetch],
  )

  const [isBulkActionsActive, setBulkActionsActive] = useState(false)

  const toggleBulkActions = useCallback(() => {
    setBulkActionsActive(!isBulkActionsActive)
    if (bulkActions?.onToggle) {
      bulkActions.onToggle(!isBulkActionsActive)
    }
  }, [bulkActions, isBulkActionsActive])

  const columnsLayout = useMemo(
    () => columns.map((col: TableColumn<T>) => col.customStyle?.colSize || defaultStyles[col.type].size).join(' '),
    [columns],
  )

  return (
    <Styled.InfiniteTableWrapper>
      {bulkActions && (
        <BulkActions
          active={isBulkActionsActive}
          totalRows={data?.length || 0}
          selectedRows={data?.map((item) => item.selected).length || 0}
          {...bulkActions}
          onToggle={toggleBulkActions}
        />
      )}
      <Styled.InfiniteTableComponent
        className={cc([className, reactClass('infinite-table'), { stickyHeader, selectable: isBulkActionsActive }])}
      >
        <TableHead<T>
          columns={columns}
          columnsLayout={columnsLayout}
          onHover={onHeaderHover}
          onSort={onSort}
          rowSpacing={rowSpacing}
        />
        <TableBody<T>
          columns={columns}
          columnsLayout={columnsLayout}
          data={data}
          onFetch={onFetchCallback}
          onSelect={onSelect}
          onRowSelect={onRowSelect}
          total={total}
          rowSpacing={rowSpacing}
          selectable={isBulkActionsActive}
          isRowSelectionDisabled={isRowSelectionDisabled}
          rowTotal={rowTotal}
        />
      </Styled.InfiniteTableComponent>
    </Styled.InfiniteTableWrapper>
  )
}
