import { Checkbox } from '@components-deprecated'
import { Button, Link } from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import React, { ReactElement, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { ErrorMessage } from '../ErrorMessage'
import { GuideFormWrapper } from '../GuideFormWrapper'
import { GuideHeader } from '../GuideHeader'
import { GuideLayout } from '../GuideLayout'
import { useHandleCreateUserSubmit } from './hooks/useHandleCreateUserSubmit'
import { PasswordChecks } from './PasswordChecks'
import * as Styled from './styles'
import { CreateUserForm, defaultValues, getValidationSchema } from './utils/formData'

export const CreateUser = (): ReactElement => {
  const { t } = useTranslation()
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [acceptTermsLabelText, setAcceptTermsLabelText] = useState('')
  const acceptTermsLabelRef = useRef<HTMLDivElement>(null)
  const { navigate } = useEmberRouter()
  const form = useForm<CreateUserForm>({
    defaultValues,
    resolver: useMemo(() => yupResolver(getValidationSchema(t)), [t]),
  })

  // Lifecycle

  useLayoutEffect(() => {
    setAcceptTermsLabelText(acceptTermsLabelRef?.current?.textContent || '')
  }, [])

  // Mutations

  const {
    submit,
    errorCode: submitErrorCode,
    isLoading: isSubmitting,
    isError: isSubmitError,
  } = useHandleCreateUserSubmit({ consentText: acceptTermsLabelText })

  // Computed values

  const passwordValue = form.watch('password')

  // Functions

  const handleFormSubmit = useCallback(
    (values: CreateUserForm) => {
      submit(values)
    },
    [submit],
  )

  const handlePasswordFocus = useCallback(() => {
    setIsPasswordFocused(true)
  }, [])

  const handlePasswordBlur = useCallback(() => {
    setIsPasswordFocused(false)
  }, [])

  // We need to listen for mousedown as sometimes browser autofills inputs
  // and this value is not visible for the form initially (safety resons)
  // so we don't display password validation initialy but on some random click
  const handleSignInMouseDown = useCallback(() => {
    navigate(EmberRoute.Login)
  }, [navigate])

  return (
    <GuideLayout autoFocus>
      <FormProvider {...form}>
        <GuideHeader
          title={t('signup_guide.create_user.title')}
          description={t('signup_guide.create_user.description')}
        />
        <GuideFormWrapper onSubmit={form.handleSubmit(handleFormSubmit)}>
          {(!!submitErrorCode || isSubmitError) && (
            <ErrorMessage
              errorCode={submitErrorCode}
              defaultMessage={t('signup_guide.create_user.error_signup_user')}
            />
          )}

          <Styled.Input
            formControl={form.control}
            name="emali" // typo in purpose (disabling native autofill)
            label={t('signup_guide.create_user.form.email.label')}
            placeholder={t('signup_guide.create_user.form.email.placeholder')}
            type="email"
            inputSize="xl"
            disabled={isSubmitting}
            autoComplete="new-password"
          />
          <Styled.Password
            formControl={form.control}
            name="password"
            label={t('signup_guide.create_user.form.password.label')}
            placeholder={t('signup_guide.create_user.form.password.placeholder')}
            onFocus={handlePasswordFocus}
            onBlur={handlePasswordBlur}
            inputSize="xl"
            disabled={isSubmitting}
            autoComplete="new-password"
            silent
          />

          {(isPasswordFocused || !!passwordValue) && (
            <Styled.PasswordChecksWrapper>
              <PasswordChecks value={passwordValue} />
            </Styled.PasswordChecksWrapper>
          )}

          <Styled.ButtonWrapper>
            <Button
              type="submit"
              onClick={form.handleSubmit(handleFormSubmit)}
              size="xl"
              loading={isSubmitting}
              fullWidth
            >
              {t('signup_guide.create_user.form.submit.label')}
            </Button>
          </Styled.ButtonWrapper>

          <Styled.AgreementsWrapper>
            <Checkbox
              formControl={form.control}
              name="acceptedTerms"
              label={
                <Styled.AcceptTermsLabelWrapper ref={acceptTermsLabelRef}>
                  <Trans i18nKey="signup_guide.create_user.form.acceptedTerms.label">
                    Accept Billy's
                    <Link
                      href="https://www.billy.dk/generelle-betingelser/"
                      target="_blank"
                      rel="noopener noreferrer"
                      withAnimation
                    >
                      terms
                    </Link>
                    <span>and</span>
                    <Link
                      href="https://www.billy.dk/generelle-betingelser/#databehandleraftale"
                      target="_blank"
                      rel="noopener noreferrer"
                      withAnimation
                    >
                      data
                    </Link>
                    processor agreement
                  </Trans>
                </Styled.AcceptTermsLabelWrapper>
              }
              disabled={isSubmitting}
            />

            <Checkbox
              formControl={form.control}
              name="acceptedMarketing"
              label={t('signup_guide.create_user.form.acceptedMarketing.label')}
              disabled={isSubmitting}
            />
          </Styled.AgreementsWrapper>
        </GuideFormWrapper>

        <Styled.Footer>
          <Styled.FooterLabelText colorVariant="secondary">
            {t('signup_guide.create_user.form.footer.have_account')}
          </Styled.FooterLabelText>
          <Button variant="secondary" onMouseDown={handleSignInMouseDown}>
            {t('signup_guide.create_user.form.footer.sign_in')}
          </Button>
        </Styled.Footer>
      </FormProvider>
    </GuideLayout>
  )
}
