import { Button } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../../../../../enums/scope'
import { isAuthorized } from '../../../../../../../../../../utils/isAuthorized'
import { useAttachmentPreviewModal } from '../../../../../../context/attachmentPreviewModalContext'

export const ManageUploadButton = (): ReactElement => {
  const { t } = useTranslation()
  const { handleManageUploadButtonClick } = useAttachmentPreviewModal()

  const isEnabled = isAuthorized(Scope.BillWrite)

  return (
    <Button disabled={!isEnabled} fullWidth onClick={handleManageUploadButtonClick} size="l">
      {t('attachment_preview_modal.footer.select_attachment')}
    </Button>
  )
}
