interface GetRatioAdjustedDimensionProps {
  aspectRatio: string
  dimension: number
}

export const getRatioAdjustedDimension = ({ aspectRatio, dimension }: GetRatioAdjustedDimensionProps): number => {
  const [numerator, denominator] = aspectRatio.split('/').map((part) => parseFloat(part.trim()))

  const ratio = numerator / denominator
  return dimension * ratio
}
