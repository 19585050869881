import { FormItem, Input } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useSegment } from '../../../../../../../../../../hooks'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { getInvoiceNumberDisplayedValue } from '../../../../../../utils/getInvoiceNumberDisplayedValue'

// for Beta, Invoice Number is read-only, later it will be changed to editable,
// Input will get a suffix with clickable edit icon, which will open InvoiceNumberModal
// the modal has already been developed, it will just need to be connected to the flow

export const InvoiceNumber = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { getValues } = useFormContext<InvoiceFormSchema>()

  const { invoiceNumberPrefix, invoiceNumber } = getValues()

  const displayedInvoiceNumber = useMemo(
    () => getInvoiceNumberDisplayedValue(invoiceNumber, invoiceNumberPrefix),
    [invoiceNumber, invoiceNumberPrefix],
  )

  const handleInputClick = useCallback(() => {
    track('XXX - Invoicing Core - Invoice Editor - Form - Invoice number clicked')
  }, [track])

  return (
    <>
      <FormItem label={t('external_invoices.editor.form.field.invoice_number.label')}>
        <Input value={displayedInvoiceNumber} readOnly onClick={handleInputClick} />
      </FormItem>
      <Input name="invoiceNumberPrefix" hidden />
      <Input name="invoiceNumber" hidden />
    </>
  )
}
