import uniqueId from 'lodash/uniqueId'
import { ChangeEvent } from 'react'

import { useForm } from '../../../Form'
import { Icon } from '../../../Icon'
import { Tooltip } from '../../../Tooltip'
import { ToggleGroupItem as ToggleGroupItemType } from '../../types/toggleGroupItem'
import * as Styled from './styles'

export interface ToggleGroupItemProps {
  groupCheckedValue?: string
  item: ToggleGroupItemType
  name: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const ToggleGroupItem = ({ groupCheckedValue, item, name, onChange }: ToggleGroupItemProps) => {
  const { icon, tooltipLabel, value } = item
  const { name: formName } = useForm()

  const isChecked = groupCheckedValue === value
  const id = `${formName}-${uniqueId('toggle-group-item-')}-${value}`

  return (
    <Styled.ToggleGroupItemWrapper checked={isChecked}>
      <Tooltip label={tooltipLabel} placement="top">
        <Styled.NativeRadio checked={isChecked} id={id} name={name} onChange={onChange} type="radio" value={value} />
        <Styled.Control>
          <Icon icon={icon} />
        </Styled.Control>
      </Tooltip>
    </Styled.ToggleGroupItemWrapper>
  )
}
