import { Flipper, FlipperProps, Text as FormattedText, Label } from '@components-deprecated'
import { amountToDisplayValue, Color, Flex, Spacing, Text } from '@design-system'

import i18next from 'i18next'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { SpecificState } from '../../../../types/reduxSaga-deprecated'
import { Account } from '../../../app/accounts/types'
import { bankBalanceRequested, setBalanceBarOpen } from '../../action-creators'
import { PrimoUltimoBalance } from '../../types'
import { NordicApiConnectionStatusLabel } from './../NordicApiConnectionStatusLabel'
import * as Styled from './styles'

export type BalanceBarProps = FlipperProps

const BankAccount = ({
  title,
  no,
  noBankAccount,
}: {
  title: string
  no: string | React.ReactNode
  noBankAccount: boolean
}) => (
  <Flex gap={Spacing.L}>
    <Text variant="normal" weight="medium" color={Color.Blue}>
      {noBankAccount ? <Label variant={'danger'}>{title}</Label> : title}
    </Text>
    <Text variant="normal" color={Color.Blue}>
      {no}
    </Text>
  </Flex>
)

const Outstanding = ({ title, amount = 0 }: { title: string; amount: number }) => (
  <Flex gap={Spacing.XS}>
    <Text color={Color.Gray90}>{title}:</Text>
    <Text color={Color.DeepSeaGreen} weight="medium">
      {amountToDisplayValue(amount)}
    </Text>
  </Flex>
)

const InfoLine = ({
  title,
  info,
  type = 'text',
}: {
  title: string | React.ReactNode
  info: string
  type?: 'text' | 'date' | 'currency'
}) => (
  <Styled.InfoLineContainer>
    <Text weight="medium" color={Color.Blue}>
      {title}
    </Text>
    <FormattedText variant="body1" type={type}>
      {info}
    </FormattedText>
  </Styled.InfoLineContainer>
)

const BankNameInfoLine = ({ account, isBalanceBarOpen }: { account: Account; isBalanceBarOpen: boolean }) => {
  const accountResolved = useSelector((state: SpecificState) => state.app.accountResolved)
  const noBankAccount = !account.name && !account.bankName
  const bankName = account.name || account.bankName || i18next.t('bankreconciliation.balancebar.no_account_selected')
  const bankKonto = `${account.accountNo || ''} ${account.bankAccountNo || ''}`

  if (!accountResolved) {
    return <div></div>
  }

  if (isBalanceBarOpen) {
    const name = (
      <>
        {noBankAccount ? <Label variant={'danger'}>{bankName}</Label> : bankName}
        <NordicApiConnectionStatusLabel account={account} ml={'5px'} />
      </>
    )

    return <InfoLine title={name} info={bankKonto} />
  }

  const konto = (
    <>
      {bankKonto}
      <NordicApiConnectionStatusLabel account={account} ml={'5px'} />
    </>
  )

  return <BankAccount title={bankName} no={konto} noBankAccount={noBankAccount} />
}

export const BalanceBar = ({ ...rest }: BalanceBarProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)

  const account = useSelector((state: SpecificState) => state.app.account || ({} as Account))
  const bankConnection = useSelector((state: SpecificState) => state.bankReconciliation.bankConnection)

  const latestBankLineEntryDate = useSelector(
    (state: SpecificState) => state.bankReconciliation.latestBankLineEntryDate,
  )
  const lastPullTime = bankConnection?.lastSynchronizedAt || latestBankLineEntryDate || undefined

  const primoUltimoBalance = useSelector((state: SpecificState) => state.bankReconciliation.primoUltimoBalance)
  const filters = useSelector((state: SpecificState) => state.bankReconciliation.filters)

  const { bankEndBalance, bankStartBalance, billyEndBalance, billyStartBalance } =
    (primoUltimoBalance as Required<PrimoUltimoBalance>) || {}

  const bankBalance = bankEndBalance
  const billyBalance = billyEndBalance

  const difference = billyBalance - bankBalance
  const startDifference = billyStartBalance - bankStartBalance
  const endDifference = billyEndBalance - bankEndBalance

  const toggleFlipSide = () => {
    setIsOpen((prevState: boolean) => !prevState)
    dispatch(setBalanceBarOpen({ isOpen: !isOpen }))
  }

  useEffect(() => {
    if (account?.id && filters) {
      dispatch(bankBalanceRequested())
    }
  }, [account, dispatch, filters])

  return (
    <Flipper onFlipSide={toggleFlipSide} {...rest}>
      <Flex justifyContent="space-between" width="100%">
        <BankNameInfoLine account={account} isBalanceBarOpen={isOpen} />

        <Flex justifyContent="flex-end" alignItems="center" gap={Spacing.XXL}>
          <Text color={Color.Blue}>{t('bankreconciliation.balancebar.endbalance')}</Text>
          <Outstanding title="Bank" amount={bankBalance} />
          <Outstanding title="Billy" amount={billyBalance} />
          <Outstanding title={t('bankreconciliation.balancebar.difference')} amount={difference} />
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" width="100%">
        <Styled.AccountDataContainer>
          <BankNameInfoLine account={account} isBalanceBarOpen={isOpen} />
          {!!lastPullTime && (
            <InfoLine title={t('bankreconciliation.balancebar.latestimportfrombank')} info={lastPullTime} type="date" />
          )}
        </Styled.AccountDataContainer>

        <Styled.BalanceTable>
          <Styled.GridLine>
            <Text> </Text>
            <Text weight="medium" color={Color.Blue}>
              Bank
            </Text>
            <Text weight="medium" color={Color.Blue}>
              Billy
            </Text>
            <Text weight="medium" color={Color.Blue}>
              {t('bankreconciliation.balancebar.difference')}
            </Text>
          </Styled.GridLine>
          <Styled.GridLine>
            <Text color={Color.Blue} weight="medium">
              {t('bankreconciliation.balancebar.startbalance')}
            </Text>
            <FormattedText variant="body1" type="currency">
              {bankStartBalance}
            </FormattedText>
            <FormattedText variant="body1" type="currency">
              {billyStartBalance}
            </FormattedText>
            <FormattedText variant="body1" type="currency">
              {startDifference}
            </FormattedText>
          </Styled.GridLine>
          <Styled.GridLine>
            <Text color={Color.Blue} weight="medium">
              {t('bankreconciliation.balancebar.endbalance')}
            </Text>
            <FormattedText variant="body1" type="currency">
              {bankEndBalance}
            </FormattedText>
            <FormattedText variant="body1" type="currency">
              {billyEndBalance}
            </FormattedText>
            <FormattedText variant="body1" type="currency">
              {endDifference}
            </FormattedText>
          </Styled.GridLine>
        </Styled.BalanceTable>
      </Flex>
    </Flipper>
  )
}
