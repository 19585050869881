import { Color, Spacing } from '@design-system'

import styled from '@emotion/styled'
import { FieldValue } from 'react-hook-form'

import { Theme } from '../../types/theme'
import { themeDependentCss } from './themeDependentCss'

interface DateInputWrapperProps {
  disabled?: boolean
  flex?: number
  error?: FieldValue<Error>
}

export const DateInputWrapper = styled.div<DateInputWrapperProps>`
  display: flex;
  position: relative;
  ${({ flex }) => flex && flex};

  ${({ theme }) => themeDependentCss(theme as Theme)}

  ${({ disabled, error }) =>
    !disabled &&
    !error &&
    `&:hover {
      input[type='text']:not(:focus) {
        border-color: ${Color.DeepSeaGreen};
      }
  }`}
`

interface CalendarButtonProps {
  disabled?: boolean
}

export const CalendarButton = styled.div<CalendarButtonProps>`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${Spacing.S} ${Spacing.M};
  width: 40px;
  height: 40px;
  z-index: 2;
  align-items: center;
  cursor: pointer;
  opacity: 1;

  ${({ disabled }) => disabled && `cursor: not-allowed; opacity: 0.5;`};
`

export const CalendarHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: ${Spacing.XXS} ${Spacing.XS};
  border-bottom: 1px solid ${Color.Gray50};
`

export const MonthHeader = styled(CalendarHeaderContainer)`
  flex: 1;
  border-right: 1px solid ${Color.Gray50};
`
