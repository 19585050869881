import styled from '@emotion/styled'

import { Spacing } from '../../enums/spacing'
import { DividerWrapperProps } from './types/dividerWrapperProps'
import { getDividerDirectionFlexStyles } from './utils/getDividerDirectionFlexStyles'
import { getPseudoElementCommonStyles } from './utils/getPseudoElementCommonStyles'

export const DividerWrapper = styled.div<DividerWrapperProps>`
  display: flex;
  text-align: center;
  ${({ direction, hasChildren }) => getDividerDirectionFlexStyles({ direction, hasChildren })};

  // Create the divider lines using pseudo-elements
  &:before {
    ${({ dashed, direction, dividerColor }) => getPseudoElementCommonStyles({ dashed, direction, dividerColor })}
    flex: ${({ orientation }) => (orientation === 'right' ? '10' : '1')};
  }

  &:after {
    ${({ dashed, direction, dividerColor }) => getPseudoElementCommonStyles({ dashed, direction, dividerColor })}
    flex: ${({ orientation }) => (orientation === 'left' ? '10' : '1')};
  }
`

export const TextContainer = styled.div`
  margin: 0 ${Spacing.M};
`
