import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { BankLinesQueryParams, fetchBankLinesForAccount } from '../query-api'
import { getBankLinesQueryKey } from '../utils/getBankLinesQueryKey'

export const useAccountBankLines = (queryParams: Partial<BankLinesQueryParams>, isEnabled = true) => {
  const queryKey = useMemo(() => getBankLinesQueryKey(queryParams), [queryParams])

  const { data, isLoading } = useQuery(queryKey, () => fetchBankLinesForAccount(queryParams), {
    enabled: isEnabled && !!queryParams.accountId,
  })

  return {
    bankLines: data?.bankLines,
    isLoading,
  }
}
