import { Button, ButtonsGroup, Textarea } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../enums/emberRoute'
import { useFormContext } from '../../../../hooks'
import { useEmberRouteUrl } from '../../../../hooks/routing/useEmberRouteUrl'
import { FindAccountantFormSchema } from '../../utils/formData'
import { isSummaryFilled } from '../../utils/isSummaryFilled'
import { Answers } from '../Answers'
import { BusinessInfo } from '../BusinessInfo'
import { BusinessSummary } from '../BusinessSummary'
import { Question } from '../Question'
import * as Styled from './styles'

export const QuestionsSection = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem, watch } = useFormContext<FindAccountantFormSchema>()
  const { url: settingsOrganizationUrl } = useEmberRouteUrl(EmberRoute.SettingsOrganization)

  const summary = watch('summary')

  return (
    <Styled.QuestionsSectionWrapper>
      <FormItem
        name="issue"
        render={({ field }) => {
          return (
            <Question
              isAnswered={!!field.value}
              title={t('find_accountant.questions.one')}
              questionNumber={1}
              content={
                <Answers
                  selectedOptions={field.value}
                  onChange={field.onChange}
                  options={[
                    t('find_accountant.questions.one.answer.one'),
                    t('find_accountant.questions.one.answer.two'),
                    t('find_accountant.questions.one.answer.three'),
                    t('find_accountant.questions.one.answer.four'),
                    t('find_accountant.questions.one.answer.five'),
                  ]}
                />
              }
            />
          )
        }}
      />
      <FormItem
        name="expectations"
        render={({ field }) => {
          return (
            <Question
              isAnswered={!!field.value}
              title={t('find_accountant.questions.two')}
              questionNumber={2}
              content={
                <Textarea
                  {...field}
                  placeholder={t('find_accountant.questions.two.placeholder')}
                  resizable={false}
                  size="xl"
                />
              }
            />
          )
        }}
      />
      <FormItem
        name="isBusinessInfoCorrect"
        render={({ field }) => {
          return (
            <Question
              isAnswered={field.value}
              sideActions={
                !field.value && (
                  <ButtonsGroup>
                    <Button to={settingsOrganizationUrl} size="m" variant="secondary">
                      {t('find_accountant.questions.three.edit')}
                    </Button>
                    <Button
                      icon="check"
                      onClick={() => {
                        field.onChange(true)
                      }}
                      size="m"
                    >
                      {t('find_accountant.questions.three.confirm')}
                    </Button>
                  </ButtonsGroup>
                )
              }
              title={t('find_accountant.questions.three')}
              questionNumber={3}
              content={<BusinessInfo />}
            />
          )
        }}
      />
      <FormItem
        name="summary"
        render={({ field }) => (
          <Question
            {...field}
            isAnswered={isSummaryFilled(summary)}
            title={t('find_accountant.questions.four')}
            questionNumber={4}
            content={<BusinessSummary />}
          />
        )}
      />
    </Styled.QuestionsSectionWrapper>
  )
}
