import { amountToDisplayValue } from '@design-system'

import cc from 'classcat'
import { format } from 'date-fns'
import get from 'lodash/get'
import numeral from 'numeral'
import React from 'react'

import { useLocale } from '../../../hooks/useLocale'
import { reactClass } from '../../../utils'
import { ColumnType, TableRow } from '../types'
import * as Styled from './styles'

export type TableCellProps<T> = {
  className?: string
  customStyle: object | undefined
  field: keyof T
  type: ColumnType
  render: Function | undefined
  row: {}
  isTotalLabel?: boolean
  truncate?: boolean
}

export const TableCell = <T extends TableRow>({
  className,
  field,
  isTotalLabel,
  render,
  row,
  type,
  customStyle = {},
  truncate,
  ...rest
}: TableCellProps<T>) => {
  const locale = useLocale()
  let content
  let currency

  switch (type) {
    case 'currency':
      currency = get(row, 'currency') || ''
      content = get(row, field) ? `${amountToDisplayValue(get(row, field))} ${currency}` : ''
      break
    case 'date':
      content = get(row, field) ? format(get(row, field), 'PP', { locale }) : ''
      break
    case 'number':
      content = get(row, field) ? numeral(get(row, field)).format('0.00') : ''
      break
    case 'text':
      content = get(row, field) ? get(row, field).toString() : ''
      break
    case 'custom':
      content = ''
      break
    default:
      // output text as default
      content = get(row, field) ? get(row, field).toString() : ''
  }

  if (render) {
    content = render(row)
  }

  return (
    <Styled.TableCell
      className={cc([className, reactClass('infinite-table-cell')])}
      style={{ ...customStyle }}
      title={typeof content === 'string' ? content : ''}
      type={type}
      {...rest}
    >
      <Styled.TableCellTextWrapper truncate={truncate}>{content}</Styled.TableCellTextWrapper>
    </Styled.TableCell>
  )
}
