import { Route } from '@components'

import { ReactElement } from 'react'
import { Switch } from 'react-router-dom'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { RecurringInvoicesRoute } from './enums/recurringInvoicesRoute'
import { RecurringInvoicesList } from './routes/RecurringInvoicesList'
import { RecurringInvoicesListEmpty } from './routes/RecurringInvoicesListEmpty'

export const ReccuringInvoices = withRouteLinkClickedListener((): ReactElement => {
  return (
    <Switch>
      <Route isHashRouter exact path={RecurringInvoicesRoute.List} component={RecurringInvoicesList} />
      <Route isHashRouter exact path={RecurringInvoicesRoute.ListEmpty} component={RecurringInvoicesListEmpty} />
    </Switch>
  )
})
