import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { BorderRadius } from '../../../../enums/borderRadius'
import { Color } from '../../../../enums/color'
import { DefaultValue } from '../../../../enums/defaultValue'
import { FlexProps } from '../../../Flex'

interface SectionWrapperProps extends FlexProps {
  aspectRatio?: string
  bordered?: boolean
  borderRadius?: BorderRadius
}

export const SectionWrapper = styled.div<SectionWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  position: relative;

  ${({ bordered, borderRadius = DefaultValue.BorderRadius }) => {
    if (bordered) {
      return css`
        border: 1px solid ${Color.Gray50};
        border-radius: ${borderRadius};
      `
    }
  }};
`
