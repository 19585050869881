import { Button, IconName } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { SubscriptionPlan } from '@modules-deprecated/app/organization/enums/subscriptionPlan'
import { useSyncBankConnection } from '@views/bankIntegration/hooks/useSyncBankConnection'

import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useLocalStorage } from '../../../../../../hooks'
import { useEmberRouteUrl } from '../../../../../../hooks/routing/useEmberRouteUrl'
import { useOrganizationViewUrl } from '../../../../../../hooks/routing/useOrganizationViewUrl'
import { reactRoute } from '../../../../../../utils/routing/reactRoute'

type ButtonActionType = 'error' | 'onPremiumPlan' | 'onBasicPlan' | 'inboxEnabled'

interface ButtonAction {
  tLabel: string
  action: () => void
  icon?: IconName
}

export interface ConnectionCTAProps {
  accountId?: string | null
  bankAccountId?: string | null
  isError: boolean
}

export const ConnectionCTA = ({ accountId, bankAccountId, isError }: ConnectionCTAProps): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, syncAccountBankConnection } = useSyncBankConnection()
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const history = useHistory()
  const [bankReconciliationFlag] = useLocalStorage<number>('labs-flag:reactBankReconciliation', 0)
  const { url: bankReconciliationUrl } = useEmberRouteUrl(EmberRoute.Bank, accountId)
  const { url: bankReconciliationDeprecatedUrl } = useEmberRouteUrl(EmberRoute.BankReact, accountId)
  const { url: settingsUrl } = useEmberRouteUrl(EmberRoute.SettingsSubscription)
  const { url: thingsToDoUrl } = useOrganizationViewUrl(reactRoute.thingsToDo.getMainRoute())

  const isInboxEnabled = accountId && organizationSettings?.isInboxEnabled === '1'
  const isPremiumPlan = accountId && organization?.subscriptionPlan === SubscriptionPlan.Premium

  const ctaButtonActions: Record<ButtonActionType, ButtonAction> = useMemo(
    () => ({
      error: {
        tLabel: 'dashboard.widget.bank-integration.renew_connection',
        action: () => {
          if (bankAccountId) {
            syncAccountBankConnection(bankAccountId)
          }
        },
        icon: 'roundedArrows',
      },
      inboxEnabled: {
        tLabel: 'dashboard.widget.bank-integration.go_to_things_to_do',
        action: () => {
          if (thingsToDoUrl) {
            history.push(thingsToDoUrl)
          }
        },
      },
      onPremiumPlan: {
        tLabel: 'dashboard.widget.bank-integration.go_to_bank_recon',
        action: () => {
          const bankReconciliationPageUrl = bankReconciliationFlag
            ? bankReconciliationDeprecatedUrl
            : bankReconciliationUrl

          if (accountId && bankReconciliationPageUrl) {
            history.push(bankReconciliationPageUrl)
          }
        },
      },
      onBasicPlan: {
        tLabel: 'dashboard.widget.bank-integration.upgrade_to_premium',
        action: () => {
          if (settingsUrl) {
            history.push(settingsUrl)
          }
        },
      },
    }),
    [
      accountId,
      bankAccountId,
      bankReconciliationDeprecatedUrl,
      bankReconciliationFlag,
      bankReconciliationUrl,
      history,
      settingsUrl,
      syncAccountBankConnection,
      thingsToDoUrl,
    ],
  )

  const buttonAction = useMemo((): ButtonAction => {
    if (isError) {
      return ctaButtonActions.error
    }

    if (isInboxEnabled) {
      return ctaButtonActions.inboxEnabled
    }

    if (isPremiumPlan) {
      return ctaButtonActions.onPremiumPlan
    }

    return ctaButtonActions.onBasicPlan
  }, [isError, isInboxEnabled, isPremiumPlan, ctaButtonActions])

  return (
    <>
      {organization && (
        <Button
          icon={buttonAction?.icon}
          loading={isLoading}
          onClick={buttonAction.action}
          variant={isError || !isPremiumPlan ? 'primary' : 'secondary'}
        >
          {t(buttonAction.tLabel)}
        </Button>
      )}
    </>
  )
}
