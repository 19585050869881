import { Flex, Text } from '@design-system'

import { ReactElement } from 'react'

interface InvoiceSummaryLineProps {
  label: string
  text: string
}

export const InvoiceSummaryLine = ({ label, text }: InvoiceSummaryLineProps): ReactElement => {
  return (
    <Flex justifyContent="space-between">
      <Text colorVariant="secondary" variant="micro">
        {label}
      </Text>
      <Text colorVariant="secondary" variant="micro">
        {text}
      </Text>
    </Flex>
  )
}
