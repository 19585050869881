import { SectionMessage, Space, Text } from '@design-system'

import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useBankIntegration } from '@views/bankIntegration/contexts/bankIntegrationContext'

import { formatDate } from '../../../../utils'
import { isOldBankConnection } from '../../../../utils/isOldBankConnection'

export const BankConnectionError = (): ReactElement => {
  const { connectedBank, isOrphaned, hasBankConnectionError } = useBankIntegration()
  const lastSynchronizedAt = isOldBankConnection(connectedBank)
    ? connectedBank.lastPullTime
    : connectedBank?.lastSynchronizedAt
  const formattedLastPullTime = formatDate(lastSynchronizedAt, 'yyyy-MM-dd, HH:mm')
  const { t } = useTranslation()

  const content = useMemo(
    () => {
      if (isOrphaned) {
        return <Text inheritColor>{t('bank_settings_modal.error.bank_account_orphaned')}</Text>
      }

      if (hasBankConnectionError) {
        return <Text inheritColor>{t('bank_settings_modal.error.connection_error')}</Text>
      }

      return (
        <>
          <Text inheritColor>{t('bank_settings_modal.error.expired_session')}</Text>
          <Space />
          <Text inheritColor>
            {t('bank_settings_modal.last_time_updated')} {formattedLastPullTime}
          </Text>
        </>
      )
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formattedLastPullTime, t],
  )

  return <SectionMessage variant="error">{content}</SectionMessage>
}
