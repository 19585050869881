import { KeyboardEvent, useCallback } from 'react'

import { KeyboardKey } from '../enums/keyboardKey'

export const useOnFocusedSubmit = (element: HTMLElement | null, callback: () => void) => {
  return useCallback(
    (event: KeyboardEvent) => {
      if (document.activeElement !== element) {
        return
      }

      if (event.key === KeyboardKey.Spacebar || event.key === KeyboardKey.Enter) {
        callback()
      }
    },
    [callback, element],
  )
}
