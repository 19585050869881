import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useDirtyRoute } from '../../../../../../../contexts/dirtyRouteContext'
import { useForm } from '../../../../../../../hooks'
import { useUpdateOrganizationInfoSettings } from '../hooks/useUpdateOrganizationInfoSettings'
import { defaultValues, getValidationSchema, OrganizationInfoForm } from '../utils/formData'

interface ContextState {
  submitForm: () => void
  isProcessing: boolean
  isLoading: boolean
}

const defaultValue: ContextState = {
  submitForm: noop,
  isProcessing: false,
  isLoading: false,
}

const OrganizationInfoSettingsContext = createContext<ContextState>(defaultValue)

interface OrganizationInfoSettingsContextProps {
  children: ReactNode
}

export const OrganizationInfoSettingsContextProvider = ({
  children,
}: OrganizationInfoSettingsContextProps): ReactElement => {
  const { organization, isLoading } = useUserOrganization()
  const { setDirty } = useDirtyRoute()
  const {
    Form,
    handleSubmit,
    reset: resetForm,
    formState: { isDirty },
  } = useForm({
    defaultValues,
    validationSchema: getValidationSchema(),
  })
  const { update, isProcessing } = useUpdateOrganizationInfoSettings()

  const handleFormSubmit = useCallback(
    (values: OrganizationInfoForm) => {
      update(values)
    },
    [update],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  useEffect(() => {
    if (organization?.id) {
      resetForm({
        city: organization?.city,
        countryId: organization?.countryId,
        email: organization?.email,
        fax: organization?.fax,
        name: organization?.name,
        phone: organization?.phone,
        registrationNo: organization?.registrationNo,
        street: organization?.street,
        zipcode: organization?.zipcode,
      })
    }
  }, [
    organization?.city,
    organization?.countryId,
    organization?.email,
    organization?.fax,
    organization?.id,
    organization?.name,
    organization?.phone,
    organization?.registrationNo,
    organization?.street,
    organization?.zipcode,
    resetForm,
  ])

  useEffect(() => {
    setDirty(isDirty)
  }, [isDirty, setDirty])

  return (
    <OrganizationInfoSettingsContext.Provider value={{ submitForm, isProcessing, isLoading }}>
      <Form>{children}</Form>
    </OrganizationInfoSettingsContext.Provider>
  )
}

export const useOrganizationInfoSettings = () => useContext(OrganizationInfoSettingsContext)
