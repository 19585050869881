import { Organization } from '@modules-deprecated/app/organization/types/organization'

import { CompanyValues } from '../../../../types/zervantSettingsValues'
import { InvoicePayloadInvoicer } from '../../types/invoicePayload/invoicePayloadInvoicer'

export const getInvoicerPayload = (
  organization: Organization | undefined,
  companyValues: CompanyValues | undefined,
): InvoicePayloadInvoicer | undefined => {
  if (!organization) {
    return undefined
  }

  const { city, countryId, externalId, isVatExempted, name, registrationNo, street, zipcode } = organization

  const defaultTradeName = companyValues?.tradeNames.find((tradeName) => tradeName?.default)
  const defaultTradeNameLogoId = defaultTradeName?.logo?.id
  const defaultTradeNameId = defaultTradeName?._id

  return {
    tradeName: {
      name,
      address: {
        city,
        pobox: zipcode,
        streetAddress1: street,
      },
      contact: {
        // the contact info is not synced from zervant to billy and we
        // don't have the exact information required, thus the empty strings
        name: {
          first: '',
          full: '',
          last: '',
        },
        email: '',
      },
      default: true,
      country: countryId,
      logo: { id: defaultTradeNameLogoId || '' },
      _id: defaultTradeNameId || '',
    },
    businessId: registrationNo,
    isNotVATLiable: isVatExempted,
    id: Number(externalId || ''),
  }
}
