import { DateFormatter, ThumbnailFormatter, Translate } from '@components'
import { Text } from '@components-deprecated'
import { InternalAccessor, notify, Table, TableColumn } from '@design-system'

import { useTheme } from 'emotion-theming'
import toNumber from 'lodash/toNumber'
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { VoucherStatus } from '@modules-deprecated/inbox/elements'
import { Voucher, VoucherInboxState } from '@modules-deprecated/inbox/types'
import { getNeedInfoReasons } from '@modules-deprecated/inbox/utils'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryParamKeys } from '../../../../enums/queryParamKeys'
import { TableIds } from '../../../../enums/tableIds'
import { useQueryParams } from '../../../../hooks/useQueryParams'
import { Theme } from '../../../../types/theme'
import { getThumbnailUrl } from '../../../../utils/getThumbnailUrl'
import { trackError } from '../../../../utils/trackError'
import { useMissingInformation } from '../../hooks/useMissingInformation'
import { MissingScreen } from '../MissingScreen'
import { MissingInformationView } from './MissingInformationView'

interface QueryParams {
  page?: number | string
}

const defaultQuery: QueryParams = {
  page: 1,
}

interface MissingInformationsView {
  thumbnail: string
  status: VoucherInboxState
  reason: string
  date: string
  message: string
  description: string
  id: string
}

const getThumbnailSrc = (voucher: Voucher): string =>
  voucher.fileReferences?.[0]?.url ? getThumbnailUrl(voucher.fileReferences[0].url as string, {}) : ''

export const MissingInformationsBase = (): ReactElement | null => {
  const { data, error, isError, isLoading } = useMissingInformation()
  const { t } = useTranslation()
  const theme = useTheme<Theme>()
  const needInfoReasons = useMemo(() => getNeedInfoReasons(t), [t])
  const [query, setQuery] = useQueryParams<QueryParams>({
    defaultQuery,
    key: QueryParamKeys.MissingInformationsTable,
    withDefaultQueryInUrl: false,
  })

  const columns: TableColumn<MissingInformationsView>[] = useMemo(
    () => [
      {
        Header: '',
        accessor: 'thumbnail',
        size: 'xs',
        Cell: ({ data, row }) => {
          const { thumbnail, description } = data[row.id]
          return <ThumbnailFormatter alt={description} src={thumbnail} />
        },
      },
      {
        Header: <Translate value="things_to_do.missing_information.table.header.status" />,
        accessor: 'status',
        size: 's',
        Cell: ({ value }) => <VoucherStatus state={value} />,
      },
      {
        Header: <Translate value="things_to_do.missing_information.table.header.reason" />,
        accessor: 'reason',
        size: 'l',
        Cell: ({ value }) => <Text color={theme.colors.red}>{needInfoReasons[value]}</Text>,
      },
      {
        Header: <Translate value="things_to_do.missing_information.table.header.date" />,
        accessor: 'date',
        Cell: ({ value }) => <DateFormatter value={value} />,
      },
      {
        Header: <Translate value="things_to_do.missing_information.table.header.message" />,
        accessor: 'message',
        size: 'xl',
      },
      {
        Header: '',
        accessor: InternalAccessor.SubRow,
        Cell: ({ value }) => <MissingInformationView voucherId={value} />,
      },
    ],
    [needInfoReasons, theme.colors.red],
  )

  const tableData: MissingInformationsView[] = useMemo(
    () =>
      data.vouchers.map((voucher) => ({
        thumbnail: getThumbnailSrc(voucher),
        status: voucher.inboxState,
        reason: voucher.stateReason || '',
        date: voucher.updatedTimestamp,
        message: voucher.stateComment || '',
        description: voucher.description,
        id: voucher.id,
        [InternalAccessor.SubRow]: voucher.id,
      })),
    [data.vouchers],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQuery({ page })
    },
    [setQuery],
  )

  useEffect(() => {
    if (isError) {
      notify({
        id: NotificationKeys.ThingsToDoMissingInfo,
        message: t('things_to_do.missing_information.error.list'),
        variant: 'error',
      })
      trackError(error)
    }
  }, [isError, error, t])

  if (!isLoading && tableData.length === 0) {
    return null
  }

  return (
    <MissingScreen title={<Translate value="things_to_do.missing_information" />}>
      <Table
        columns={columns}
        data={tableData}
        id={TableIds.MissingInformations}
        isClientSidePagination
        isLoading={isLoading}
        onPageChange={handlePageChange}
        currentPage={toNumber(query.page)}
        withItemsPerPageSelect
      />
    </MissingScreen>
  )
}

export const MissingInformations = React.memo(MissingInformationsBase)
