import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { GetUserResponseData, useCurrentUser, useUpdateUser } from '@modules-deprecated/app/user'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { ThemeLayoutMode } from '../../../../../types/themeLayoutMode'
import { trackError } from '../../../../../utils/trackError'

export const useThemeLayoutModeChange = () => {
  const queryClient = useQueryClient()
  const { user } = useCurrentUser()
  const { update } = useUpdateUser({
    onMutate: async (user) => {
      await queryClient.cancelQueries(QueryKeys.CurrentUser)
      const previousUser = queryClient.getQueryData(QueryKeys.CurrentUser) as GetUserResponseData

      const updatedUser: GetUserResponseData = {
        ...previousUser,
        user: { ...previousUser.user, themeMode: user.themeMode },
      } as GetUserResponseData

      queryClient.setQueryData(QueryKeys.CurrentUser, updatedUser)
      return previousUser
    },
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.CurrentUser)
    },
    onError: (error, _, context) => {
      if (context) {
        queryClient.setQueryData(QueryKeys.CurrentUser, context)
      }
      trackError(error)
    },
  })

  const changeThemeLayoutMode = useCallback(
    (themeLayoutMode: ThemeLayoutMode) => {
      if (!user) {
        return
      }

      update({
        id: user.id,
        themeMode: themeLayoutMode,
      })
    },
    [update, user],
  )

  return useMemo(() => ({ changeThemeLayoutMode }), [changeThemeLayoutMode])
}
