import { Route } from '@components'
import { ModuleLayout } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Switch } from 'react-router-dom'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { useLocalStorageUncontrolled } from '../../hooks/useLocalStorageUncontrolled'
import { REPORTS_ROUTE_STORAGE_KEY } from './constants/reportsRouteStorageKey'
import { ReportsNavigationTabs } from './elements/ReportsNavigationTabs'
import { ReportsSideActions } from './elements/ReportsSideActions'
import { ReportsRoute } from './enums/reportsRoute'
import { Balance } from './routes/Balance'
import { ProfitAndLoss } from './routes/ProfitAndLoss'
import { TrialBalance } from './routes/TrialBalance'
import { isRouteValid } from './utils/isRouteValid'

export const Reports = withRouteLinkClickedListener((): ReactElement => {
  const { t } = useTranslation()
  const { getItem } = useLocalStorageUncontrolled(REPORTS_ROUTE_STORAGE_KEY)
  const emberQuery = useGetQueryFromEmberRoute()
  const localStorageInitialRoute = getItem()

  const initialRoute =
    typeof emberQuery?.initialRoute === 'string' ? emberQuery?.initialRoute : localStorageInitialRoute

  return (
    <ModuleLayout
      title={t('reports')}
      headerBottomActions={<ReportsNavigationTabs />}
      sideActions={<ReportsSideActions />}
    >
      <Switch>
        <Route isHashRouter exact path="/">
          <Redirect to={initialRoute && isRouteValid(initialRoute) ? initialRoute : ReportsRoute.ProfitAndLoss} />
        </Route>
        <Route isHashRouter exact path={ReportsRoute.ProfitAndLoss} component={ProfitAndLoss} />
        <Route isHashRouter exact path={ReportsRoute.Balance} component={Balance} />
        <Route isHashRouter exact path={ReportsRoute.TrialBalance} component={TrialBalance} />
        <Route>
          <Redirect to={ReportsRoute.ProfitAndLoss} />
        </Route>
      </Switch>
    </ModuleLayout>
  )
})
