import { PeriodicLockReminderModal, ToastContainer } from '@components'

import { ReactElement, useEffect } from 'react'

import { queryClient } from '../../../../config/queryClient'
import { useAutomaticPageTitle } from '../../../../hooks/useAutomaticPageTitle'
import { useInvalidateQueriesCustomListener } from '../../../../hooks/useInvalidateQueriesCustomListener'
import { useInvalidateQueriesListener } from '../../../../hooks/useInvalidateQueriesListener'
import { useSetInitialQueryData } from './hooks/useSetInitialQueryData'

export const GlobalUser = (): ReactElement => {
  // Events listeners - mostly used for handling changes in Ember to invalidate some data in React
  // It has to be done in the Menu as it's the only module which is constantly rendered
  useInvalidateQueriesListener()
  useInvalidateQueriesCustomListener()

  useAutomaticPageTitle()
  // setting queries for user organization and accounts based on data already loaded by Ember
  useSetInitialQueryData()

  // clear cache on unmount - logout or leaving user organization for example to go to signup guide
  useEffect(() => {
    return () => {
      queryClient.clear()
    }
  }, [])

  // Here add anything that needs to be present in DOM when the user is logged in
  return (
    <>
      <ToastContainer />
      <PeriodicLockReminderModal />
    </>
  )
}
