import { Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

interface TwoColumnsLayoutWrapperProps {
  fullHeight?: boolean
}

export const TwoColumnsLayoutWrapper = styled.div<TwoColumnsLayoutWrapperProps>`
  display: flex;
  gap: ${Spacing.XXL};

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
`

interface SideProps {
  asymmetrical?: boolean
  sticky?: boolean
}

const Side = styled.div<SideProps>`
  display: flex;
  flex-direction: column;

  ${({ sticky }) =>
    sticky &&
    css`
      position: sticky;
      top: 0;
    `}
`

export const LeftSide = styled(Side)`
  flex-shrink: 1;

  width: ${({ asymmetrical }) => (asymmetrical ? '30%' : '50%')};
`

export const RightSide = styled(Side)`
  min-width: 582px;
  width: ${({ asymmetrical }) => (asymmetrical ? '70%' : '50%')};
`
