import { Alert, Button, Flex, Link, Spacing, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { getClassName } from '../../../../../../../../utils/getClassName'
import { ExternalInvoicesSyncJobStatus } from '../../../../../../enums/externalInvoicesSyncJobStatus'
import { useUpdateExternalInvoicesSync } from '../../../../../../hooks/useUpdateExternalInvoicesSync'
import { useSyncErrorTopBar } from '../../contexts/syncErrorTopBarContext'
import { notifySyncUpdateError } from '../../utils/notifySyncUpdateError'
import { SyncErrorTopBarSideActions } from './elements/SyncErrorTopBarSideActions'
import * as Styled from './styles'

export const SyncErrorTopBarContent = (): ReactElement => {
  const { t } = useTranslation()
  const { redirectAndOpenCreateVatRateModal, shouldDisplaySyncErrorActions, viewPossibleSyncErrorActions } =
    useSyncErrorTopBar()
  const { update: updateSyncJobStatus, isProcessing } = useUpdateExternalInvoicesSync({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.ExternalInvoicesSync)
    },
    onError: () => {
      notifySyncUpdateError(t)
    },
  })

  const handleCreateVatRatesButtonClick = useCallback(() => {
    redirectAndOpenCreateVatRateModal()
  }, [redirectAndOpenCreateVatRateModal])

  const handleResumeSyncButtonClick = useCallback(() => {
    updateSyncJobStatus(ExternalInvoicesSyncJobStatus.Retry)
  }, [updateSyncJobStatus])

  const handleViewPossibleActionsButtonClick = useCallback(() => {
    viewPossibleSyncErrorActions()
  }, [viewPossibleSyncErrorActions])

  return (
    <Alert
      className={getClassName('dark-theme-suspend')}
      variant="warning"
      sideActions={shouldDisplaySyncErrorActions ? <SyncErrorTopBarSideActions /> : undefined}
    >
      <Flex alignItems="center" gap={Spacing.S}>
        {t('external_invoices_sync_top_bar.error.unrecognized_vat_rates')}
        {shouldDisplaySyncErrorActions ? (
          <>
            <Text weight="bold">
              <Link onClick={handleCreateVatRatesButtonClick}>
                {t('external_invoices_sync_top_bar.error.create_vat_rates')}
              </Link>
            </Text>
            <Styled.VerticalDivider />
            <Text>{t('external_invoices_sync_top_bar.error.already_done')}</Text>
            <Button
              icon="roundedArrows"
              loading={isProcessing}
              onClick={handleResumeSyncButtonClick}
              size="m"
              variant="secondary"
            >
              {t('external_invoices_sync_top_bar.error.resume_syncing')}
            </Button>
          </>
        ) : (
          <Button onClick={handleViewPossibleActionsButtonClick} size="m" variant="secondary">
            {t('external_invoices_sync_top_bar.error.view_possible_actions')}
          </Button>
        )}
      </Flex>
    </Alert>
  )
}
