import { Divider, Flex, Spacing } from '@design-system'

import { ReactElement } from 'react'

import { AttachmentDetailsFooter } from './elements/AttachmentDetailsFooter/AttachmentDetailsFooter'
import { BillDetails } from './elements/BillDetails/BillDetails'
import { DescriptionArea } from './elements/DescriptionArea'
import { FileDetails } from './elements/FileDetails/FileDetails'
import * as Styled from './styles'

export const AttachmentDetails = (): ReactElement => {
  return (
    <Styled.AttachmentDetailsWrapper>
      <Flex flexDirection="column" gap={Spacing.L} flexGrow={1}>
        <FileDetails />
        <Divider />
        <BillDetails />
        <Divider />
        <DescriptionArea />
        <AttachmentDetailsFooter />
      </Flex>
    </Styled.AttachmentDetailsWrapper>
  )
}
