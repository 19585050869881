import { useEffect, useState } from 'react'

interface UseLazyImageProps {
  onError?: () => void
  src: string
}

const preloadedSrcList: string[] = []

export const useLazyImage = ({ onError, src }: UseLazyImageProps) => {
  const isPreloaded = src && preloadedSrcList.indexOf(src) !== -1

  const [loading, setLoading] = useState(!isPreloaded)
  const [currentSrc, updateCurrentSrc] = useState(isPreloaded ? src : undefined)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    if (!src) {
      return
    }
    if (isPreloaded) {
      updateCurrentSrc(src)
      setLoading(false)
    } else {
      if (!src) {
        return
      }

      const imageToLoad = new Image()
      imageToLoad.src = src

      imageToLoad.onload = () => {
        preloadedSrcList.push(src)
        updateCurrentSrc(src)
        setLoading(false)
      }

      imageToLoad.onerror = () => {
        setIsError(true)
        setLoading(false)
        onError?.()
      }

      return () => {
        imageToLoad.onload = null
        imageToLoad.onerror = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src])

  return { loading, src: currentSrc, isError }
}
