import { BorderRadius, FilesPreview, FileType, PreviewFile, SkeletonBox, Spacing } from '@design-system'

import { ReactElement, useMemo } from 'react'

import { getClassName } from '../../../../utils/getClassName'
import * as Styled from './styles'

const INVOICE_PREVIEW_INITIAL_HEIGHT = 1122
const INVOICE_PREVIEW_INITIAL_WIDTH = 794

interface InvoiceHtmlPreviewProps {
  htmlFiles: string[] | undefined
}

export const InvoiceHtmlPreview = ({ htmlFiles = [] }: InvoiceHtmlPreviewProps): ReactElement => {
  const previewFiles: PreviewFile[] = useMemo(() => htmlFiles.map((file) => ({ src: file })), [htmlFiles])

  if (previewFiles.length === 0) {
    return (
      <Styled.PreviewSkeletonBoxWrapper>
        <SkeletonBox height={INVOICE_PREVIEW_INITIAL_HEIGHT} width={INVOICE_PREVIEW_INITIAL_WIDTH} />
      </Styled.PreviewSkeletonBoxWrapper>
    )
  }

  return (
    <FilesPreview
      asType={FileType.Html}
      borderRadius={BorderRadius.L}
      className={getClassName('invoice-html-preview')}
      files={previewFiles}
      fitToHeight
      isFloatingActionsView
      margin={Spacing.L}
      withShadow
      zoomable
    />
  )
}
