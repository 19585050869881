import { useModalContentToggler } from '@components'
import { Link, Space, Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { BillReconciliationModalStateId } from '../../../../enums/billReconciliationModalStateId'

export const LinkToBankLines = (): ReactElement => {
  const { t } = useTranslation()
  const { setActiveState } = useModalContentToggler()

  const handleLinkClick = useCallback(() => {
    setActiveState(BillReconciliationModalStateId.BankLines)
  }, [setActiveState])

  return (
    <>
      <Space></Space>
      <Text colorVariant="secondary">
        {t('bill.payment_reconcile.do_you_want_reconcile')}{' '}
        <Text as="span">
          <Link onClick={handleLinkClick}>{t('bill.payment_reconcile.chose_from_open_bank_transactions')}</Link>
        </Text>
      </Text>
    </>
  )
}
