import { NumberFormat, Space } from '@design-system'

import { useTranslation } from 'react-i18next'

import { LocaleIdentifier } from '../../../../../../../../../../../../types/localeIdentifier'
import { InvoicePayloadTaxSummary } from '../../../../../../../../types/invoicePayload/invoicePayloadSummary'
import { InvoiceSummaryLine } from '../InvoiceSummaryLine'

interface InvoiceSummaryTaxesProps {
  currency: string
  locale: LocaleIdentifier
  taxSummary: InvoicePayloadTaxSummary[]
}

export const InvoiceSummaryTaxes = ({ currency, locale, taxSummary }: InvoiceSummaryTaxesProps) => {
  const { t } = useTranslation()

  return taxSummary.map(({ amount, percentage }) => {
    const formattedPercentage = NumberFormat.getPercentNumber(percentage, locale)

    return (
      <>
        <InvoiceSummaryLine
          label={t('external_invoices.editor.summary.vat', { percentage: formattedPercentage })}
          text={NumberFormat.getCurrencyNumber(amount, currency, locale)}
        />
        <Space size="s" />
      </>
    )
  })
}
