import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useOrganizationSource } from '../../../hooks'
import { fetchZervantCompany } from '../query-api'

export const useFetchZervantCompany = () => {
  const { isSourceZervant } = useOrganizationSource()

  const { data, ...rest } = useQuery(QueryKeys.ZervantCompany, () => fetchZervantCompany(), {
    enabled: isSourceZervant,
  })

  return { company: data?.data, ...rest }
}
