import { AxiosResponse } from 'axios'
import { useCallback } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchProducts, FetchProductsResponse } from '../query-api'

export const useFetchProducts = () => {
  // id converting is needed, because of mismatch between Zervant DB,
  // and what is expected in billy-webapp repository's components,
  // products fetched from Zervant have ids of a number type,
  // while generally in billy-webapp ids are strings,
  // for convenience it was decided to perform the conversion at the very beginning
  const convertProductIdsToString = useCallback((productsData: AxiosResponse<FetchProductsResponse, any>) => {
    const formattedProducts = productsData?.data.map((productsDataItem) => ({
      ...productsDataItem,
      id: String(productsDataItem.id),
    }))

    return { ...productsData, data: formattedProducts }
  }, [])

  const { data, ...rest } = useQuery(QueryKeys.Products, fetchProducts, { select: convertProductIdsToString })

  return { products: data?.data || [], ...rest }
}
