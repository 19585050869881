import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { Color } from '../../../../enums/color'
import { paginationDotAnimation } from '../../utils/paginationDotAnimation'

interface PaginationTrackProps {
  translateValue: number
}

export const PaginationTrack = styled.div<PaginationTrackProps>`
  display: flex;
  transition: transform 0.3s ease-in-out;
  transform: ${({ translateValue }) => `translateX(-${translateValue}px);`};
`

export const PaginationWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${Color.White};
  box-shadow: 0 2px 4px 0 ${transparentize(0.8, Color.Gray60)};
  border-radius: 30px;
  overflow: hidden;
  max-width: 75px;
`

interface PaginationItemProps {
  active: boolean
}

export const PaginationItem = styled.div<PaginationItemProps>`
  cursor: pointer;
  height: 6px;
  margin: 2px 3px;
  background-color: ${({ active }) => (active ? Color.DeepSeaGreen : Color.Gray30)};
  border-radius: 30px;
  border: none;
  width: ${({ active }) => (active ? '18px' : '6px')};
  transform-origin: center;
  transition:
    transform 0.3s ease-in-out,
    width 0.3s ease-in-out,
    background-color 0.5s ease-in-out;
  ${({ active }) => paginationDotAnimation(active)}
`
