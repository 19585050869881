import { ZervantLocale } from '../../../../enums/zervantLocale'
import { invoiceLanguages } from '../../constants/invoiceLanguages'
import { InvoiceLanguage } from '../../types/invoiceLanguage'
import { invoiceLanguageToLocale } from '../invoiceLanguageToLocale'

const isInvoiceLanguageType = (value?: string | number): value is InvoiceLanguage => {
  return typeof value === 'string' && invoiceLanguages.includes(value as InvoiceLanguage)
}

export const getInvoiceLocale = (invoiceLanguage?: string | number): ZervantLocale => {
  if (isInvoiceLanguageType(invoiceLanguage)) {
    return invoiceLanguageToLocale[invoiceLanguage]
  }

  return ZervantLocale.EN_GB
}
