import { Color, DefaultValue, Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

export const InfiniteTableWrapper = styled.div`
  position: relative;
  border-radius: ${DefaultValue.BorderRadius};
`

export const InfiniteTableComponent = styled.table`
  width: 100%;
  position: relative;
  z-index: 0;

  thead {
    color: ${Color.DeepSeaGreen};
    padding-bottom: 12px;
  }

  tr {
    padding-left: ${Spacing.XXL};
    padding-right: ${Spacing.XXL};
    border-bottom: 1px solid ${Color.Gray30};
    position: relative;

    &.total {
      border-top: 2px solid ${Color.Gray30};

      td {
        font-weight: bold;
      }
    }
  }

  td {
    padding-top: ${Spacing.XXS};
    padding-bottom: ${Spacing.XXS};
    white-space: nowrap;
  }

  tbody tr.hoverable:hover {
    background: ${transparentize(0.75, Color.Gray60)};
    cursor: pointer;
  }

  thead {
    tr {
      padding-top: ${Spacing.L};
      padding-bottom: ${Spacing.L};
      border-radius: ${DefaultValue.BorderRadius} ${DefaultValue.BorderRadius} 0 0;
      background-color: ${Color.White};
      box-shadow: 0 1px 3px ${transparentize(0.8, Color.Gray90)};
    }
  }

  tbody {
    background-color: ${Color.White};
  }

  &.stickyHeader {
    thead {
      tr {
        position: sticky;
        top: 0;
        z-index: 2;
        background-color: ${Color.White};
      }
    }
  }

  &.selectable {
    tr {
      padding-left: 35px;
    }
  }
`

export const rowStyles = css`
  display: grid;
  width: 100%;
  border-collapse: collapse;
  grid-template-rows: auto 1fr auto;
`
