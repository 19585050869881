import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useShouldConnectBank } from '@modals'
import { SortProperty } from '@modules-deprecated/bankReconciliation/enums/sortProperty'
import { useAccountBankLines } from '@modules-deprecated/bankReconciliation/hooks/useAccountBankLines'
import { BankLine, Difference } from '@modules-deprecated/bankReconciliation/types'
import { prefixAmount } from '@modules-deprecated/bankReconciliation/utils'

import { Side } from '../../../../../enums/side'
import { SortDirection } from '../../../../../enums/sortDirection'
import { getBillReconciliationDifference } from '../../../utils/getBillReconciliationDifference'
import { BankLinesSortingProperty } from '../elements/BankLinesModalBody/elements/BankLinesActions/types/bankLinesSortingProperty'
import { BankLinesState } from '../enums/bankLinesState'
import { filterAndSortBankLines } from '../utils/filterAndSortBankLines'
import { useBankLinesFilters } from './bankLinesFilteringContext'
import { useBillReconciliation } from './billReconciliationContext'
import { useMultipleBills } from './multipleBillsContext'

interface ContextState {
  bankLines?: BankLine[]
  bankLinesState: BankLinesState
  bankLinesDifference: Difference
  hasBankLinesDifference: boolean
  isLoading: boolean
  newestBankLineDate: string
  selectedBankLinesIds: string[]
  setBankLinesState: Dispatch<SetStateAction<BankLinesState>>
  setSelectedBankLinesIds: Dispatch<SetStateAction<string[]>>
}

const BankLinesContext = createContext<ContextState | undefined>(undefined)

interface BankLinesContextProps {
  children?: ReactNode
}

export const BankLinesContextProvider = ({ children }: BankLinesContextProps): ReactElement => {
  const [selectedBankLinesIds, setSelectedBankLinesIds] = useState<string[]>([])
  const { selectedAccount, selectedAccountId } = useBillReconciliation()
  const { selectedBillsAmountsSum, isCreditNoteSelected } = useMultipleBills()
  const { bankLines, isLoading: isLoadingBankLines } = useAccountBankLines(
    {
      accountId: selectedAccountId,
      isReconciled: false,
      sortDirection: SortDirection.Desc,
      sortProperty: SortProperty.EntryDate,
    },
    !!selectedAccountId,
  )
  const { bankLinesSearchQuery, bankLinesSortingProperty } = useBankLinesFilters()
  const [bankLinesState, setBankLinesState] = useState(BankLinesState.Empty)
  const {
    isLoading: isLoadingBankConnections,
    shouldConnectBank,
    shouldRenewBankConnection,
  } = useShouldConnectBank(selectedAccountId)

  const bankLinesFilteredAndSorted = useMemo(() => {
    return filterAndSortBankLines({
      bankLines,
      billsAmount: selectedBillsAmountsSum || undefined,
      searchQuery: bankLinesSearchQuery,
      side: isCreditNoteSelected ? Side.Debit : Side.Credit,

      sortingProperty: bankLinesSortingProperty,
    })
  }, [bankLines, bankLinesSearchQuery, bankLinesSortingProperty, isCreditNoteSelected, selectedBillsAmountsSum])

  const selectedBankLines = useMemo(() => {
    return bankLinesFilteredAndSorted?.filter((bankLine: BankLine) => selectedBankLinesIds.includes(bankLine.id))
  }, [bankLinesFilteredAndSorted, selectedBankLinesIds])

  const bankLinesDifference = useMemo(() => {
    const reconciliationBankLinesAmounts = selectedBankLines.map((bankLine) => {
      return isCreditNoteSelected ? prefixAmount(bankLine) : -prefixAmount(bankLine)
    })

    const reconciliationAmount = reconciliationBankLinesAmounts.reduce((sum, currentAmount) => sum + currentAmount, 0)

    return getBillReconciliationDifference({
      billsAmount: selectedBillsAmountsSum,
      reconciliationAmount,
    })
  }, [selectedBankLines, selectedBillsAmountsSum, isCreditNoteSelected])

  const hasBankLinesDifference = useMemo(() => {
    return !!bankLinesDifference?.differenceAmount
  }, [bankLinesDifference?.differenceAmount])

  const isSelectedBankAccount = !!selectedAccount?.isBankAccount
  const isLoading = isLoadingBankLines || isLoadingBankConnections

  const newestBankLineDate = useMemo(() => {
    const bankLinesSortedNewestToOldest = filterAndSortBankLines({
      bankLines: selectedBankLines,
      sortingProperty: BankLinesSortingProperty.DateDesc,
    })

    return bankLinesSortedNewestToOldest[0]?.entryDate
  }, [selectedBankLines])

  useEffect(() => {
    let bankLinesState = BankLinesState.Empty

    if (bankLinesFilteredAndSorted?.length) {
      bankLinesState = BankLinesState.BankLinesList
    }

    if (!isLoading && shouldConnectBank) {
      bankLinesState = BankLinesState.ConnectBank
    }

    if (!isLoading && shouldRenewBankConnection) {
      bankLinesState = BankLinesState.RenewBankConnection
    }

    setBankLinesState(bankLinesState)
  }, [
    selectedAccountId,
    bankLinesFilteredAndSorted?.length,
    isSelectedBankAccount,
    shouldConnectBank,
    shouldRenewBankConnection,
    isLoading,
  ])

  return (
    <BankLinesContext.Provider
      value={{
        bankLines: bankLinesFilteredAndSorted,
        bankLinesDifference,
        bankLinesState,
        hasBankLinesDifference,
        isLoading,
        newestBankLineDate,
        selectedBankLinesIds,
        setBankLinesState,
        setSelectedBankLinesIds,
      }}
    >
      {children}
    </BankLinesContext.Provider>
  )
}

export const useBankLines = () => {
  const context = useContext(BankLinesContext)

  if (!context) {
    throw new Error('BankLinesContextProvider is missing in the module!')
  }

  return context
}
