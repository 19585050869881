import {
  AspectRatioVariant,
  Attachment,
  AttachmentFile,
  BorderRadius,
  FilesPreview,
  ModalConfirmation,
  PreviewFile,
  Spacing,
  useModal,
} from '@design-system'

import { memo, ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AttachmentActionsDropdownMenu } from '@features/attachments/elements/AttachmentActions'
import { useBillAttachmentPageContext } from '@views/bills/contexts/billAttachmentPageContext'

import { getImageClassName } from '../../../../utils/getClassName'
import { BillAttachmentPreview } from '../BillAttachmentPreview'

interface BillAttachmentPreviewProps {
  onDelete?: (fileId: string) => void
  onEdit?: (attachment: Attachment) => void
  files: AttachmentFile[]
}

const DELETE_ATTACHMENT_CONFIRMATION_MODAL_ID = 'delete-file-confirmation'

export const BillEditableAttachmentsPreview = memo(
  ({ onDelete, onEdit, files }: BillAttachmentPreviewProps): ReactElement => {
    const [fileIdToDelete, setFileIdToDelete] = useState<string>()
    const { t } = useTranslation()
    const [currentPageIndex, setCurrentPageIndex] = useBillAttachmentPageContext()
    const { open: openDeleteConfirmation, close: closeDeleteConfirmation } = useModal(
      DELETE_ATTACHMENT_CONFIRMATION_MODAL_ID,
    )

    // Need to receive files from the BE to have downloadUrl
    const previewFiles: PreviewFile[] = useMemo(
      () => files.map((file) => ({ src: file?.downloadUrl, id: file?.id })),
      [files],
    )

    const attachmentFiles = useMemo(
      () =>
        files.reduce<string[]>((acc, file) => {
          if (file.attachmentId) {
            acc.push(file.attachmentId)
          }
          return acc
        }, []),
      [files],
    )

    const handleDeleteFile = useCallback(
      (file: PreviewFile) => {
        setFileIdToDelete(file.id)
        openDeleteConfirmation()
      },
      [openDeleteConfirmation],
    )

    const handlePageChange = useCallback(
      (index: number) => {
        setCurrentPageIndex(index)
      },
      [setCurrentPageIndex],
    )

    const handleDeleteModalOkClick = useCallback(() => {
      fileIdToDelete && onDelete?.(fileIdToDelete)
      setCurrentPageIndex(currentPageIndex > 0 ? currentPageIndex - 1 : 0)
      closeDeleteConfirmation()
    }, [closeDeleteConfirmation, currentPageIndex, fileIdToDelete, onDelete, setCurrentPageIndex])

    return (
      <BillAttachmentPreview>
        <FilesPreview
          actionsMenuItems={(props) => (
            <AttachmentActionsDropdownMenu
              onFileFromUploadsSelect={onEdit}
              attachmentsIdsChosen={attachmentFiles}
              {...props}
            />
          )}
          aspectRatio={AspectRatioVariant.billAttachment}
          borderRadius={BorderRadius.L}
          className={getImageClassName()}
          files={previewFiles}
          fitToHeight
          isFloatingActionsView
          margin={Spacing.L}
          navigationDirection="horizontal"
          onDelete={handleDeleteFile}
          onNavigation={handlePageChange}
          withShadow
          withDeleteButton
          withDownloadButton
          withRotate
          zoomable
        />
        <ModalConfirmation
          cancelLabel={t('cancel')}
          danger
          id={DELETE_ATTACHMENT_CONFIRMATION_MODAL_ID}
          message={t('bill.action.delete_attachment.message')}
          okLabel={t('bill.action.delete_attachment.ok_button')}
          onOk={handleDeleteModalOkClick}
          title={t('bill.action.delete_attachment.title')}
        />
      </BillAttachmentPreview>
    )
  },
)
