/*
 *  THIS COMPONENT IS DEPRECATED
 *  Please use 'DateInput' from design-system instead
 */
import { Input as InputRebass } from '@rebass/forms'
import cc from 'classcat'
import { format, isSameDay, isValid, parse } from 'date-fns'
import { da, enUS as en } from 'date-fns/locale'
import get from 'lodash/get'
import React, { FocusEvent, KeyboardEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { KeyboardKey } from '../../enums/keyboardKey'
import { useKeyDown, useOnClickOutside } from '../../hooks'
import { getCurrentLocale } from '../../utils/getCurrentLocale'
import { ConnectForm, ConnectFormType } from '../Form/ConnectForm'
import { ErrorMessage } from '../Form/ErrorMessage'
import { FormControl } from '../Form/FormControl'
import { FormLabel } from '../Form/FormLabel'
import { CalendarButton } from './CalendarButton'
import CalendarHeader from './CalendarHeader'
import './react-datepicker.css'
import * as Styled from './styles'

const getDateInputFormat = (inputValue: string) => {
  const locale = getCurrentLocale()
  const isUSLocale = locale === 'en_US'

  const dateInputFormatShort = isUSLocale ? 'MMdd' : 'ddMM'
  const dateInputFormatSemi = isUSLocale ? 'yyMMdd' : 'ddMMyy'
  const dateInputFormatFull = isUSLocale ? 'MMddyyyy' : 'ddMMyyyy'

  if (inputValue.length === dateInputFormatShort.length) {
    return dateInputFormatShort
  }

  if (inputValue.length === dateInputFormatSemi.length) {
    return dateInputFormatSemi
  }

  return dateInputFormatFull
}

export type DateInputComponentProps = Partial<ReactDatePickerProps> & {
  defaultValue?: Date
  disabled?: boolean
  disconnect?: boolean
  errorPath?: string
  flex?: number
  label?: string
  name: string
  onChange?: (date: Date) => void
  options?: {
    required?: boolean | string
    [key: string]: undefined | boolean | string | {}
  }
  silent?: boolean
  width?: number | string
}

const DateInputComponent = ({
  defaultValue,
  disabled,
  disconnect,
  errorPath,
  flex,
  label,
  name,
  onChange,
  options = {},
  required,
  silent,
  width,
  ...rest
}: DateInputComponentProps): ReactElement => {
  const { i18n } = useTranslation()
  const [date, setDate] = useState<Date>()
  const [rawValue, setRawValue] = useState('')
  const [isFocused, setFocus] = useState(false)
  const [showCalendar, setShowCalendar] = useState(false)
  const [keyboardNavigation, setKeyboardNavigation] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const pickerRef = useRef<DatePicker>(null)

  const locale = getCurrentLocale()
  const isUSLocale = locale === 'en_US'
  const dateFormat = isUSLocale ? 'MM/dd/yyyy' : 'dd/MM/yyyy'

  useEffect(() => {
    if (!date) {
      setDate(defaultValue)
      return
    }

    if (date && defaultValue && !isSameDay(defaultValue, date)) {
      setDate(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue])

  const toggleCalendar = () => setShowCalendar((v) => !v)
  const openCalendar = () => setShowCalendar(true)
  const closeCalendar = () => setShowCalendar(false)
  const disableKeyboardNavigation = useCallback(() => setKeyboardNavigation(false), [])
  const enableKeyboardNavigation = useCallback(() => setKeyboardNavigation(true), [])

  const handleChange = (date: Date) => {
    setDate(date)

    if (onChange) {
      onChange(date)
    }
  }

  const updateFromUserInput = (inputValue: string) => {
    if (!inputValue) {
      return
    }

    const parsedDate = parse(inputValue, getDateInputFormat(inputValue), new Date())
    const isValidDate = isValid(parsedDate)

    if (isValidDate) {
      handleChange(parsedDate)
    }
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    updateFromUserInput(event.target.value)
    setFocus(false)
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    // TAB key workaround for not blurring input field in that component
    // https://github.com/Hacker0x01/react-datepicker/issues/2058
    if (event.key === KeyboardKey.Tab) {
      pickerRef && pickerRef.current && pickerRef.current.setOpen(false)
    }
  }

  const handleChangeRaw = (event: FocusEvent<HTMLInputElement>) => {
    if (!event) {
      return
    }

    setRawValue(event.target.value)
  }

  const handleFocus = () => {
    setFocus(true)
  }

  useKeyDown('ArrowDown', () => {
    if (isFocused) {
      enableKeyboardNavigation()
    }
  })

  useOnClickOutside(wrapperRef, () => {
    updateFromUserInput(rawValue)
    closeCalendar()
  })

  const locales = {
    da,
    en,
  }

  return (
    <ConnectForm>
      {({ formState, register }: ConnectFormType) => {
        const errors = formState?.errors || {}
        const error = errorPath ? get(errors, errorPath) : errors[name]

        return (
          <FormControl ref={wrapperRef} width={width}>
            <FormLabel name={name} required={required}>
              {label}
            </FormLabel>
            <Styled.DateInputWrapper disabled={false} error={false} flex={flex}>
              <DatePicker
                ref={pickerRef}
                open={showCalendar}
                onCalendarOpen={openCalendar}
                onCalendarClose={closeCalendar}
                onChangeRaw={handleChangeRaw}
                onDayMouseEnter={disableKeyboardNavigation}
                customInput={<InputRebass />}
                preventOpenOnFocus
                selected={date}
                name={name}
                adjustDateOnChange
                onChange={handleChange}
                renderCustomHeader={CalendarHeader}
                disabledKeyboardNavigation={!keyboardNavigation}
                onBlur={handleBlur}
                onFocus={handleFocus}
                locale={locales[i18n.language]}
                disabled={disabled}
                className={cc({ invalid: error })}
                popperPlacement="bottom-end"
                showPopperArrow={false}
                dateFormat={dateFormat}
                onKeyDown={handleKeyDown}
                {...rest}
              />
              <InputRebass
                key={name}
                display="none"
                className={cc({ focus: showCalendar })}
                name={name}
                type="hidden"
                value={(date && format(date, 'yyyy-MM-dd')) || ''}
                {...(!disconnect && register ? register(name, { required, ...options }) : undefined)}
              />
              <CalendarButton onClick={disabled ? () => null : toggleCalendar} disabled={disabled} />
              {!silent && <ErrorMessage error={error} />}
            </Styled.DateInputWrapper>
          </FormControl>
        )
      }}
    </ConnectForm>
  )
}

// Controlled
export type DateInputControlledResult = Date

export type DateInputProps = DateInputComponentProps & {
  formControl?: Control<any>
  withChangeEvent?: boolean
}

export const DateInput = ({
  formControl,
  name = 'date-input',
  withChangeEvent,
  ...rest
}: DateInputProps): ReactElement => {
  if (!formControl) {
    return <DateInputComponent name={name} {...rest} />
  }

  return (
    <Controller
      render={({ field }) => (
        <DateInputComponent
          {...rest}
          name={field.name}
          onChange={(date: Date) => {
            field.onChange(date)
            withChangeEvent && rest.onChange && rest.onChange(date)
          }}
          defaultValue={field.value}
          disconnect
        />
      )}
      control={formControl}
      name={name}
    />
  )
}
