import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Document, pdfjs } from 'react-pdf'

import { trackError } from '../../../../utils/trackError'
import { DocumentLocalProps, FileType, PreviewFile } from '../types'
import { getFileType } from '../utils/getFileType'

interface RenderPdfBySrcProps {
  children: ReactNode
  documentProps: DocumentLocalProps
  src: string
}

interface ContextState {
  isLoading: boolean
  filesPages: Map<string, number>
  renderPdfBySrc: (args: RenderPdfBySrcProps) => void
  setIsLoading: Dispatch<SetStateAction<boolean>>
  totalPageCount: number
}

const PdfPreloadContext = createContext<ContextState | undefined>(undefined)

interface PdfPreloadContextProviderProps {
  files: PreviewFile[]
  children: ReactNode
}

export const PdfPreloadContextProvider = ({ files, children }: PdfPreloadContextProviderProps) => {
  const [filesPages, setFilesPages] = useState<Map<string, number>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalPageCount, setTotalPageCount] = useState<number>(0)

  const getPdfMetadata = useCallback(async (fileSrc: string) => {
    try {
      const pdf = await pdfjs.getDocument(fileSrc).promise
      return pdf.numPages
    } catch (error) {
      trackError(error)
      return 0
    }
  }, [])

  useEffect(() => {
    const getAllPagesData = async () => {
      const filesPagesCount = new Map()
      let total = 0

      for (const file of files) {
        const fileId = file.id || file.src
        const fileType = getFileType(file.src)

        if (fileType === FileType.Image) {
          filesPagesCount.set(fileId, 1)
        } else {
          const pages = await getPdfMetadata(file.src)
          filesPagesCount.set(fileId, pages)
        }
      }

      const sortedFilesPages = new Map(
        files.map((file) => {
          const fileId = file.id || file.src
          const size = filesPagesCount.get(fileId)
          total += size
          return [fileId, size]
        }),
      )

      setFilesPages(sortedFilesPages)
      setTotalPageCount(total)
      setIsLoading(false)
    }

    setIsLoading(true)
    getAllPagesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const renderPdfBySrc = useCallback(({ children, documentProps, src }: RenderPdfBySrcProps) => {
    const fileType = getFileType(src)

    if (fileType === FileType.Pdf) {
      return (
        <Document file={src} {...documentProps}>
          {children}
        </Document>
      )
    }
    return null
  }, [])

  return (
    <PdfPreloadContext.Provider value={{ isLoading, filesPages, totalPageCount, renderPdfBySrc, setIsLoading }}>
      {children}
    </PdfPreloadContext.Provider>
  )
}

export const usePdfPreload = () => {
  const context = useContext(PdfPreloadContext)

  if (!context) {
    throw new Error('PdfPreloadContext is missing in the module!')
  }

  return context
}
