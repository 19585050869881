import { Color, FontWeight } from '@design-system'

import styled from '@emotion/styled'

import styles from '../defaultStyles'
import { ColumnType } from '../types'

interface TableCellTextWrapperProps {
  truncate?: boolean
}

export const TableCellTextWrapper = styled.div<TableCellTextWrapperProps>`
  ${({ truncate }) =>
    truncate &&
    `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`}
`

interface TableCellProps {
  isTotalLabel?: boolean
  type: ColumnType
}

export const TableCell = styled.td<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isTotalLabel, type }) => (isTotalLabel ? 'flex-end' : styles[type].justifyContent)};
  color: ${Color.DeepSeaGreen};
  font-weight: ${FontWeight.Regular};
`
