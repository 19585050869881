import { Color, DefaultValue, Spacing } from '@design-system'

import { css } from '@emotion/core'
import { transparentize } from 'polished'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useInView } from 'react-intersection-observer'
import { Box } from 'rebass'

import { SelectItem } from '../Select'
import { SelectMenuItem } from './SelectMenuItem'
import { MenuItemText, MenuItemTextVariant } from './SelectMenuItemText'

export type SelectMenuProps<T> = {
  items?: T[]
  total?: number | null
  activeItem?: T
  render?: Function
  onFetch?: (offset: number) => void
  onSelect: (item: T) => void
  minWidth?: number
}

const Loader = () => {
  const { t } = useTranslation()

  return (
    <Box p="5px">
      <MenuItemText variant={MenuItemTextVariant.Secondary}>{t('components.select.loading')}</MenuItemText>
    </Box>
  )
}

const Trigger = React.forwardRef(({ ...rest }, ref) => {
  return <Box ref={ref} {...rest} />
})

export const SelectMenu = <T extends SelectItem>({
  items,
  total,
  activeItem,
  onFetch = () => ({}),
  onSelect,
  render,
  minWidth,
  ...rest
}: SelectMenuProps<T>) => {
  const activeRef = useRef<HTMLDivElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [ref, inView] = useInView()

  useEffect(() => {
    if (!activeRef?.current) {
      return
    }
    activeRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }, [activeItem])

  const handleOnSelect = useCallback(
    (id: string) => {
      if (!items) {
        return
      }

      const selectedItem = items.find((item) => item.id === id)
      if (!selectedItem) {
        return
      }

      onSelect(selectedItem)
    },
    [items, onSelect],
  )

  const fetch = () => {
    if (isLoading || isDone || items === null) {
      return
    }
    setIsLoading(true)
    onFetch(items?.length || 0)
  }

  const receive = () => {
    if (!items) {
      setIsDone(false)
      return
    }
    setIsLoading(false)
    setIsDone(items.length === total || typeof total === 'undefined')
  }

  useEffect(receive, [items, total])
  useEffect(
    fetch,
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inView],
  )

  if (!items?.length) {
    return null
  }

  return (
    <Box
      css={css`
        min-width: ${minWidth || 238}px;
        max-height: 220px;
        padding: ${Spacing.S};
        background-color: ${Color.White};
        box-shadow: 0 10px 70px ${transparentize(0.65, Color.Gray200)};
        border-radius: ${DefaultValue.BorderRadius};
        overflow: auto;
      `}
      {...rest}
    >
      <Box>
        {(items || []).map((item: SelectItem) => (
          <SelectMenuItem
            ref={item.id === activeItem?.id ? activeRef : null}
            id={item.id}
            key={item.id}
            active={item.id === activeItem?.id}
            onSelect={handleOnSelect}
          >
            {render ? render(item, item.id === activeItem?.id) : item.text}
          </SelectMenuItem>
        ))}
        {!isDone && <Loader />}
      </Box>
      {!isLoading && <Trigger ref={ref} />}
    </Box>
  )
}
