import { ModalStates } from '@components'

import { BankLinesModalBody } from '../elements/BankLinesModalBody'
import { BankLinesModalFooter } from '../elements/BankLinesModalFooter'
import { ManualPaymentModalBody } from '../elements/ManualPaymentModalBody'
import { ManualPaymentModalFooter } from '../elements/ManualPaymentModalFooter'
import { MatchModalBody } from '../elements/MatchModalBody'
import { MatchModalFooter } from '../elements/MatchModalFooter'
import { MultipleBillsModalBody } from '../elements/MultipleBillsModalBody'
import { MultipleBillsModalFooter } from '../elements/MultipleBillsModalFooter'
import { PrepaymentModalBody } from '../elements/PrepaymentModalBody'
import { PrepaymentModalFooter } from '../elements/PrepaymentModalFooter'
import { BillReconciliationModalStateId } from '../enums/billReconciliationModalStateId'

export const billReconciliationModalStates: ModalStates<BillReconciliationModalStateId> = [
  {
    id: BillReconciliationModalStateId.Match,
    headerTKey: 'bill.payment_reconcile.header.enter_payment_and_reconcile',
    modalBody: <MatchModalBody />,
    modalFooter: <MatchModalFooter />,
  },
  {
    id: BillReconciliationModalStateId.BankLines,
    headerTKey: 'bill.payment_reconcile.header.enter_payment_and_reconcile',
    modalBody: <BankLinesModalBody />,
    modalFooter: <BankLinesModalFooter />,
    withBackwardsNavigation: true,
  },
  {
    backwardsNavigationStateId: BillReconciliationModalStateId.BankLines,
    id: BillReconciliationModalStateId.ManualPayment,
    headerTKey: 'bill.payment_reconcile.header.enter_payment',
    modalBody: <ManualPaymentModalBody />,
    modalFooter: <ManualPaymentModalFooter />,
    withBackwardsNavigation: true,
  },
  {
    id: BillReconciliationModalStateId.Prepayment,
    headerTKey: 'bill.payment_reconcile.header.use_prepayment',
    modalBody: <PrepaymentModalBody />,
    modalFooter: <PrepaymentModalFooter />,
    withBackwardsNavigation: true,
  },
  {
    id: BillReconciliationModalStateId.MultipleBills,
    headerTKey: 'bill.payment_reconcile.header.enter_payment_and_reconcile',
    modalBody: <MultipleBillsModalBody />,
    modalFooter: <MultipleBillsModalFooter />,
    withBackwardsNavigation: true,
  },
]
