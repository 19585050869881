import { Button, ButtonsGroup, SkeletonBox, Space, useModal } from '@design-system'

import { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { prefixAmount, prefixGrossAmount } from '@modules-deprecated/bankReconciliation/utils'

import { queryClient } from '../../../../config/queryClient'
import { ModalId } from '../../../../enums/modalId'
import { QueryKeys } from '../../../../enums/queryKeys'
import { useSegment } from '../../../../hooks'
import { useBillReconciliationSuggestions } from '../../hooks/useBillReconciliationSuggestions'
import { useDeleteBillReconciliationSuggestion } from '../../hooks/useDeleteBillReconciliationSuggestion'
import { useReconcileBill } from '../../hooks/useReconcileBill'
import { getBillReconciliationDifference } from '../../utils/getBillReconciliationDifference'
import { useBillView } from '../BillReadableForm'
import { notifyPaymentRegistrationError } from '../BillReconciliationModal/utils/notifyPaymentRegistrationError'
import { BillReconciliationSuggestion } from '../BillReconciliationSuggestion'
import { ReconciliationSuggestionLine } from '../ReconciliationSuggestionLine'
import * as Styled from './styles'

export const BillOverviewReconciliationSuggestionContent = (): ReactElement | null => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { billId, billData } = useBillView()
  const { suggestions } = useBillReconciliationSuggestions(billData?.bill)
  const reconciliationTransaction = suggestions?.[0]?.transactions?.[0]
  const reconciliationAmount = prefixAmount(reconciliationTransaction)
  const billAmount = prefixGrossAmount(billData?.bill)
  const difference = getBillReconciliationDifference({ billsAmount: billAmount, reconciliationAmount })
  const hasDifference = difference?.differenceAmount !== 0
  const { track } = useSegment()

  const { deleteSuggestion, isProcessing: isProcessingDelete } = useDeleteBillReconciliationSuggestion({
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.Bill, billId])
      queryClient.invalidateQueries([QueryKeys.BillRecociliationMatches, billId])
      track('xxx - expenses - Bill overview - match rejected')
    },
    onError: () => {
      notifyPaymentRegistrationError(t)
    },
  })
  const { open: openBillReconciliationModal, close: closeBillReconciliationModal } = useModal(
    ModalId.BillReconciliationModal,
    {
      onOpen: () => {
        track('xxx - expenses - Pay and reconcile modal opened')
      },
    },
  )
  const { reconcileBill, isProcessing: isProcessingReconcile } = useReconcileBill({
    billId,
    matchId: reconciliationTransaction?.match_id,
    onSuccess: () => {
      track('xxx - expenses - Bill overview - match accepted')
      closeBillReconciliationModal()
    },
  })
  const isProcessing = isProcessingDelete || isProcessingReconcile

  const handleReject = useCallback(() => {
    if (!organization?.id || !suggestions?.length) {
      return
    }

    deleteSuggestion({
      organizationId: organization?.id,
      suggestion: suggestions?.[0],
    })
  }, [organization?.id, suggestions, deleteSuggestion])

  const handleAccept = useCallback(() => {
    if (hasDifference) {
      openBillReconciliationModal()
      return
    }

    reconcileBill()
  }, [hasDifference, reconcileBill, openBillReconciliationModal])

  useEffect(() => {
    if (suggestions?.length) {
      track('xxx - expenses - Bill overview - match shown')
    }
  }, [suggestions, track])

  if (!suggestions?.length) {
    return null
  }

  return (
    <>
      <BillReconciliationSuggestion withIcon>
        <Styled.ReconciliationSuggestionContent>
          {reconciliationTransaction ? (
            <ReconciliationSuggestionLine
              title={reconciliationTransaction.description}
              amount={reconciliationTransaction.amount}
              date={reconciliationTransaction.bank_date}
              currency={reconciliationTransaction.currency}
            />
          ) : (
            <SkeletonBox fullWidth />
          )}
          <ButtonsGroup>
            <Button variant="primary" onClick={handleAccept} loading={isProcessingReconcile} disabled={isProcessing}>
              {t('bill.payment_reconcile.button.enter_payment_and_reconcile')}
            </Button>
            <Button variant="secondary" onClick={handleReject} loading={isProcessingDelete} disabled={isProcessing}>
              {t('bill.payment_reconcile.reject')}
            </Button>
          </ButtonsGroup>
        </Styled.ReconciliationSuggestionContent>
      </BillReconciliationSuggestion>
      <Space size="xl" />
    </>
  )
}
