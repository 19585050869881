import { css } from '@emotion/core'
import styled from '@emotion/styled'

import { BorderRadius } from '../../enums/borderRadius'
import { Color } from '../../enums/color'
import { Spacing } from '../../enums/spacing'
import { getDefaultTransition } from '../../utils/getDefaultTransition'

interface DropzoneWrapperProps {
  active?: boolean
  blocked?: boolean
  clickDisabled?: boolean
  disabled?: boolean
  raw?: boolean
}

export const DropzoneWrapper = styled.div<DropzoneWrapperProps>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}

  ${({ blocked }) =>
    !blocked &&
    css`
      cursor: pointer;
    `}

  ${({ raw, active, clickDisabled }) =>
    !raw &&
    css`
      display: flex;
      width: 100%;
      height: 100%;
      border: 2px dashed ${Color.Gray50};
      border-radius: ${BorderRadius.XL};
      padding: ${Spacing.L};
      position: relative;
      z-index: 0;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      ${getDefaultTransition('border', 'background')};

      ${!clickDisabled &&
      css`
        &:hover {
          border-color: ${Color.DeepSeaGreen};
        }
      `}

      ${active &&
      css`
        border-color: ${Color.DeepSeaGreen};
        background-color: ${Color.Gray30};
      `}
    `};
`
