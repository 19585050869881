import styled from '@emotion/styled'

import { Color } from '../../../../enums/color'
import { rwd } from '../../../../utils/rwd'
import { AspectRatio } from '../../types'
import { LIMIT_CANVAS_WIDTH_CLASS_NAME } from './constants/limitCanvasWidthClassName'

interface PdfReactWrapperProps {
  aspectRatio?: AspectRatio
  isError?: boolean
  height?: string | number
}

export const PdfReactWrapper = styled.div<PdfReactWrapperProps>`
  display: flex;
  justify-content: center;
  width: 100%;
  color: ${Color.Gray90};
  background-color: ${Color.White};
  aspect-ratio: ${({ aspectRatio }) => aspectRatio || 'auto'};
  height: ${({ height }) => (height ? height + 'px' : 'auto')};

  .${LIMIT_CANVAS_WIDTH_CLASS_NAME} {
    canvas {
      max-width: 200%;
    }
  }

  ${({ isError }) =>
    isError &&
    `
      padding-bottom: 100%;
      position: relative;
      color: ${Color.Red};

      ${rwd.equalOrLessThan('large')} {
        padding-bottom: 45%;
      }
    `};
`

export const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

/* PDFs are rendered with scalex2 to ensure good quality on zoom */
export const ScaleWrapper = styled.div`
  transform: scale(0.5);
  transform-origin: left top;
`
