import { ProductType } from '../../../enums/productType'
import { InvoiceUnit } from '../enums/invoiceUnit'
import { InvoiceFormLine } from '../types/invoiceForm/invoiceFormLine'
import { PredefinedInvoiceValues } from '../types/predefinedInvoiceValues'

export const getDefaultLine = (predefinedInvoiceValues?: PredefinedInvoiceValues): InvoiceFormLine => ({
  date: new Date(),
  productFromSearch: undefined,
  productName: '',
  productType: predefinedInvoiceValues?.productType || ProductType.Goods,
  quantity: 1,
  totalExcludingVat: 0,
  totalIncludingVat: 0,
  unit: InvoiceUnit.Unit,
  unitPrice: 0,
  vatRateId: predefinedInvoiceValues?.vatRateId,
  vatRatePercent: predefinedInvoiceValues?.vatRatePercent,
})
