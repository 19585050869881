import { useEffect } from 'react'

export const useMousePress = (
  callback: (event: MouseEvent) => void,
  dependencies: any[] = [],
  el: HTMLElement = document.body,
  enabled = true,
) => {
  useEffect(
    () => {
      if (enabled) {
        el.addEventListener('mousedown', callback)
      } else {
        el.removeEventListener('mousedown', callback)
      }

      return () => {
        el.removeEventListener('mousedown', callback)
      }
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...dependencies, callback, el, enabled],
  )
}
