import { DEFAULT_CURRENCY } from '../constants/defaultCurrency'
import { DEFAULT_INVOICE_NUMBER_PREFIX_FOR_BETA } from '../constants/defaultInvoiceNumberPrefixForBeta'
import { DEFAULT_INVOICE_PAYMENT_TERM } from '../constants/defaultInvoicePaymentTerm'
import { MIN_INVOICE_NUMBER } from '../constants/minInvoiceNumber'
import { PredefinedInvoiceValues } from '../types/predefinedInvoiceValues'
import { getDefaultLine } from './getDefaultLine'

export const getDefaultValues = (predefinedInvoiceValues?: PredefinedInvoiceValues) => {
  const now = new Date()

  return {
    currency: predefinedInvoiceValues?.currency || DEFAULT_CURRENCY,
    footerNote: predefinedInvoiceValues?.footerNote || '',
    invoiceLines: [getDefaultLine(predefinedInvoiceValues)],
    invoiceNumber: predefinedInvoiceValues?.invoiceNumber || MIN_INVOICE_NUMBER,
    invoiceNumberPrefix: DEFAULT_INVOICE_NUMBER_PREFIX_FOR_BETA,
    issueDate: now,
    message: predefinedInvoiceValues?.message || '',
    paymentTerm: predefinedInvoiceValues?.paymentTerm || DEFAULT_INVOICE_PAYMENT_TERM,
    receiver: undefined,
  }
}
