import { Language } from '../types/language'
import { LocaleIdentifier } from '../types/localeIdentifier'

/* eslint-disable @typescript-eslint/naming-convention */
export const localeToLanguage: Record<LocaleIdentifier, Language> = {
  da_DK: 'da',
  de_DE: 'de',
  en_US: 'en',
  fi_FI: 'fi',
  fr_FR: 'fr',
  nl_NL: 'nl',
  sv_SE: 'sv',
}
/* eslint-enable @typescript-eslint/naming-convention */
