import { DEFAULT_FORMATTER_LOCALE } from '../../constants/defaultFormatterLocale'
import { getFormattedNumber } from './getFormattedNumber'

export const getPercentNumber = (value?: number, locale?: string, inputOptions?: Intl.NumberFormatOptions) => {
  const percentage = value || 0
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...inputOptions,
  }

  return getFormattedNumber(percentage, locale || DEFAULT_FORMATTER_LOCALE, options)
}
