import { Icon, SkeletonBox, Space, Text } from '@design-system'

import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { ReconciliationTransaction } from '@modules-deprecated/bankReconciliation/types'

import { ReconciliationSuggestionLine } from '../../../../../ReconciliationSuggestionLine'
import { BillReconciliationSuggestion } from '../BillReconciliationSuggestion'
import * as Styled from '../styles'

interface ReconciliationSuggestionProps {
  matchSuggestion?: ReconciliationTransaction
}

export const ReconciliationSuggestion = ({ matchSuggestion }: ReconciliationSuggestionProps): ReactElement => {
  const { t } = useTranslation()
  return (
    <>
      <BillReconciliationSuggestion>
        <Styled.ReconciliationSuggestionContent linePosition="right">
          <Styled.MatchIndicator>
            <Icon icon="aiStars" size="large" color="currentColor" />
            <Space size="xs" horizontal />
            <Text variant="micro" weight="bold">
              {t('bill.payment_reconcile.suggestion')}
            </Text>
          </Styled.MatchIndicator>
          {matchSuggestion ? (
            <ReconciliationSuggestionLine
              title={matchSuggestion?.description}
              amount={matchSuggestion?.amount}
              date={matchSuggestion?.bank_date}
              currency={matchSuggestion?.currency}
            />
          ) : (
            <SkeletonBox fullWidth />
          )}
        </Styled.ReconciliationSuggestionContent>
      </BillReconciliationSuggestion>
      <Space size="l" />
    </>
  )
}
