import { useCallback } from 'react'

import { useUserOrganization } from '@modules-deprecated/app/organization'

import { useFormContext } from '../../../../../hooks'
import { useZervantSettings } from '../../../hooks/useZervantSettings'
import { InvoiceForm } from '../types/invoiceForm'
import { InvoiceFormSchema } from '../types/invoiceFormSchema'
import { getInvoicePayload } from '../utils/invoicePayload/getInvoicePayload'
import { useConvertedInvoiceValues } from './useConvertedInvoiceValues'

export const useInvoiceDraftPayload = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { data: zervantSettings, isLoading: isZervantSettingsLoading } = useZervantSettings()
  const { getConvertedInvoiceValues, isLoading: isLoadingConvertedInvoiceValues } = useConvertedInvoiceValues()
  const { getValues } = useFormContext<InvoiceFormSchema>()

  const isLoading = isOrganizationLoading || isZervantSettingsLoading || isLoadingConvertedInvoiceValues

  const getInvoiceDraftPayload = useCallback(
    (formData?: InvoiceForm) => {
      const invoiceValues = formData || getValues?.()
      const convertedInvoiceValues = getConvertedInvoiceValues(invoiceValues)

      return getInvoicePayload({ invoiceValues: convertedInvoiceValues, organization, zervantSettings })
    },
    [getConvertedInvoiceValues, getValues, organization, zervantSettings],
  )

  return {
    getInvoiceDraftPayload,
    isLoading,
  }
}
