import { ApiContentType } from '../../../enums/apiContentType'
import { SignupMeta } from '../../../query-api/signup-query'
import { LocaleIdentifier } from '../../../types/localeIdentifier'
import { Metable } from '../../../types/metable'
import { ProviderId } from '../../../types/providerId'
import { ThemeLayoutMode } from '../../../types/themeLayoutMode'
import { getRequest, patchRequest, postRequest, putRequest } from '../../../utils'
import { NotificationSettings, OrganizationNotificationSettings, User } from './types'

export interface GetUserResponseData {
  user: User
}

interface GetUserNotificationSettingsResponseData {
  data: OrganizationNotificationSettings[]
}

export const getBootstrapUser = (): Promise<GetUserResponseData> => {
  return getRequest('/v2/user/bootstrap')
}

export const getUser = (): Promise<GetUserResponseData> => {
  return getRequest('/v2/user')
}

export const getUserNotificationSettings = (): Promise<GetUserNotificationSettingsResponseData> => {
  return getRequest('/user/notificationSettings')
}

interface PostDoesUserExistResponseData {
  isUser: boolean
}

export const postDoesUserExist = async (email: string): Promise<PostDoesUserExistResponseData> => {
  try {
    await postRequest('/meneto/validation/unique-user-email', { email }, undefined, false)
    return { isUser: false }
  } catch (error: any) {
    if (error?.statusCode === 400) {
      return { isUser: true }
    }

    throw new Error(error)
  }
}

export interface ChangeUserEmailPayload {
  providerId: ProviderId
  email: string
}

export interface ChangeUserPasswordPayload {
  oldPassword: string
  password: string
}

export interface UpdateUserPayload {
  avatarUrl?: string
  canCreateDaybooks?: boolean
  id: string
  ip?: string
  isAdmin?: boolean
  isStaff?: boolean
  isSupportAccessAllowed?: boolean
  isSupporter?: boolean
  locale?: LocaleIdentifier
  name?: string
  password?: string
  phone?: string
  themeMode?: ThemeLayoutMode
}

export type ChangeUserEmailResponseData = Metable

export interface ChangeUserPasswordResponseData {
  meta: SignupMeta
}

export interface UpdateUserResponseData {
  users: User[]
}

export interface UpdateUserNotificationSettingsPayload {
  organizationId?: string
  payload: NotificationSettings
}

export const updateUser = (payload: UpdateUserPayload): Promise<UpdateUserResponseData> =>
  putRequest(`/v2/users/${payload.id}`, { user: payload })

export const changeUserEmailRequest = (payload: ChangeUserEmailPayload): Promise<ChangeUserEmailResponseData> =>
  postRequest(`/v2/user/emailChangeRequest`, { emailChangeRequest: payload })

export const changeUserPassword = (payload: ChangeUserPasswordPayload): Promise<ChangeUserPasswordResponseData> =>
  postRequest(`/v2/user/passwordChange`, { passwordChange: payload })

export const updateUserNotificationsSettings = ({ organizationId, payload }: UpdateUserNotificationSettingsPayload) => {
  const endpoint = organizationId
    ? `/user/notificationSettings/organizations/${organizationId}`
    : `/user/notificationSettings/defaults`
  return patchRequest(endpoint, { data: payload }, {}, true, ApiContentType.ApplicationBillyJSON)
}
