import {
  DateInput,
  ErrorMessage,
  FinancialSummary,
  FormFilesLayout,
  FormFilesLayoutBox,
  Header,
  Input,
  NavigationButtons,
} from '@components-deprecated'
import { Button, ButtonsGroup, notify } from '@design-system'

import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import BigNumber from 'bignumber.js'
import { isFuture, isSameDay } from 'date-fns'
import { useTheme } from 'emotion-theming'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import { Box, Flex } from 'rebass'

import { UmbrellaRoute } from '@views/umbrella/enums/UmbrellaRoute'

import { useCommonFormData } from '../../../contexts/commonFormDataContext'
import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { Side } from '../../../enums/side'
import { useKeyDown } from '../../../hooks'
import { useQueryParamsStorage } from '../../../hooks/useQueryParamsStorage'
import { State } from '../../../types/reduxSaga-deprecated'
import { Themable } from '../../../types/themable'
import { Theme } from '../../../types/theme'
import { formatDate } from '../../../utils'
import { trackError } from '../../../utils/trackError'
import {
  AccountSelector,
  accountsSelector,
  CurrencyInput,
  CurrencyInputControlledResult,
  TaxRateSelector,
} from '../../app'
import { Account } from '../../app/accounts/types'
import { useUserOrganization } from '../../app/organization'
import { taxRateSelector } from '../../app/taxrates/selectors/taxRateSelector'
import { getTaxRateById } from '../../app/taxrates/utils/getTaxRateById'
import { useCurrentUser } from '../../app/user'
import { ReconciliationSuggestionsBox } from '../../bankReconciliation/elements/ReconciliationSuggestionsBox'
import { fetchBankLines } from '../../bankReconciliation/query-api'
import { BankLine } from '../../bankReconciliation/types'
import { voucherActionRequest } from '../action-creators'
import { AccountSuggestions as AccountSuggestionsComponent, ValidationModal, ValidationModalType } from '../elements'
import { LineActions, LineWrapper, RemoveLineButton } from '../elements/common/LineElements'
import { useBohrScanResult } from '../hooks/useBohrScanResult'
import { useVoucherActionState } from '../hooks/useVoucherActionState'
import { useVoucherNavigation } from '../hooks/useVoucherNavigation'
import { JournalCreateSchema } from '../schemas/journalCreate'
import { getBankAccountSelector } from '../selectors/getBankAccount'
import { getDaybooks } from '../services/api'
import { fetchVoucher } from '../services/query-api'
import {
  DaybookTransactionState,
  InboxRoutes,
  JournalCreatePayloadData,
  JournalLinePayload,
  NavDirection,
  TaxRate,
  VoucherAction,
  VoucherCommonData,
  VoucherPreviewFile,
} from '../types'
import {
  getVATSummaryFromLines,
  getVoucherBankLine,
  getVoucherFiles,
  getVoucherInboxPath,
  getVoucherPrefilledValues,
} from '../utils'
import * as Styled from './styles'
import { GetInitialDataProps } from './types'

type LinesListProps = Themable & {
  error?: boolean
}

const LinesList = styled(Box)<LinesListProps>`
  ${({ error, theme }) => {
    if (error) {
      return `
        padding-bottom: 10px;
        border-style: solid;
        border-color: ${theme.colors.warning};
        border-width: 2px 0 2px 0;
      `
    }
  }}
`

LinesList.defaultProps = {
  as: 'ul',
}

const CustomErrorMessage = styled(ErrorMessage)<Themable>`
  margin-top: 15px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.warning};
`

const AccountSuggestions = styled(AccountSuggestionsComponent)`
  margin-bottom: 30px;
`

enum Action {
  Close = 'Close',
  NextVoucher = 'NextVoucher',
}

type JournalLine = {
  amount: CurrencyInputControlledResult
  contraAccount?: Account
  account?: Account
  taxRate?: TaxRate
}

interface ReconciliationData {
  side: Side
  amount: number
  accountId: string
}

export type FormInputs = {
  description: string
  entryDate?: Date
  lines: JournalLine[]
  balanceCheck?: any // virtual for form balance validation
  bankAccountsCheck?: any // virtual for form balance validation
}

const getLineDefaultValues = (): JournalLine => {
  return {
    amount: { value: 0, currency: 'DKK' },
    account: { id: '', isPaymentEnabled: false } as Account,
  }
}

const formDefaultValues: FormInputs = {
  description: '',
  entryDate: new Date(),
  lines: [getLineDefaultValues()],
}

/*
 * Use `bignumber.js` library to prevent issues with decimal calculations
 * You can read more about it here: https://www.avioconsulting.com/blog/overcoming-javascript-numeric-precision-issues
 * */
const isBalanceValidFn = (lines: JournalLine[]) =>
  new BigNumber(
    lines.reduce((acc, curr) => {
      if (curr.contraAccount?.id) {
        return acc
      }

      return acc.plus(curr.amount.value)
    }, new BigNumber(0)),
  ).isEqualTo(0)

const areAccountsValidFn = (lines: JournalLine[]) => {
  const bankAccounts = lines.reduce((result: Account[], line) => {
    if (line.account?.isBankAccount) {
      result.push(line.account)
    }

    if (line.contraAccount?.isBankAccount) {
      result.push(line.contraAccount)
    }

    return result
  }, [])

  return bankAccounts.length <= 1
}

const Form = styled.form``

type QueryParams = {
  sort: string
}

export const JournalCreate = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const dispatch = useDispatch()
  const theme = useTheme<Theme>()
  const { commonFormData, dispatch: dispatchCommonFormData } = useCommonFormData<VoucherCommonData>()
  const form = useForm<FormInputs>({
    defaultValues: getInitialData({ commonData: commonFormData.value }),
    resolver: yupResolver(JournalCreateSchema(t)),
  })
  const queryClient = useQueryClient()
  const { voucherId } = useParams() as { voucherId: string }
  const [files, setFiles] = useState<VoucherPreviewFile[]>(commonFormData.value?.files || [])
  const [selectedAccountIdSuggestion, setSelectedAccountIdSuggestion] = useState<string>()
  const [validationModal, setValidationModal] = useState<ValidationModalType>()
  const [bankLineMatch, setBankLineMatch] = useState<BankLine>()
  const isVoucherFetchedRef = useRef(false)
  const isBohrDataFetchedRef = useRef(false)
  const isBankLineDataFetchedRef = useRef(false)
  const daybookId = useRef<string>()
  const { queryParams, setQueryParams } = useQueryParamsStorage<QueryParams>(QueryParamKeys.VoucherList)
  const bankAccount = useSelector(getBankAccountSelector)
  const organizationId = organization?.id || ''
  const firstFileId = files[0]?.id

  const {
    fields: lines,
    append: addLine,
    remove: removeLine,
  } = useFieldArray({
    control: form.control,
    name: 'lines',
  })

  const journalLines: JournalLine[] = form.watch('lines') // @todo let's get rid of this as it decreases performance A LOT
  const entryDate: Date | undefined = form.watch('entryDate')
  const { balanceCheck: balanceCheckError, bankAccountsCheck: bankAccountsCheckError } = form.formState.errors
  const vatSummary = getVATSummaryFromLines<JournalLine>(journalLines, t)

  // Queries

  const {
    data: voucher,
    isLoading,
    isError: isVoucherFetchError,
    error: voucherFetchError,
  } = useQuery([QueryKeys.Voucher, organizationId, voucherId], () =>
    fetchVoucher({
      queryKey: [QueryKeys.Voucher, { organizationId, voucherId }],
    }),
  )

  const accountId = voucher?.bankAccountId || bankAccount?.id
  const { data: bankLinesData } = useQuery(
    [QueryKeys.BankLines, accountId],
    () => fetchBankLines({ accountId: accountId as string }),
    {
      enabled: !!accountId,
      refetchOnMount: false,
    },
  )
  const { data: bohrData, isLoading: isBohrDataLoading } = useBohrScanResult(firstFileId)

  // Selectors

  const taxRates = useSelector((state: State) => taxRateSelector(state, {}))
  const accounts = useSelector((state: State) => accountsSelector(state, {}))
  const {
    prevVoucherId,
    nextVoucherId,
    isLoading: isNavigationLoading,
  } = useVoucherNavigation(organizationId, voucherId)
  const voucherBankLine = useMemo(
    () => getVoucherBankLine(voucher, bankLinesData?.bankLines),
    [voucher, bankLinesData?.bankLines],
  )

  const getReconciliationLine = useCallback((lines: JournalLine[]): JournalLine | undefined => {
    return lines.find((line) => line.account?.isBankAccount || line.contraAccount?.isBankAccount)
  }, [])

  const getReconciliationLineIndex = useCallback((lines: JournalLine[]): number => {
    return lines.findIndex((line) => line.account?.isBankAccount || line.contraAccount?.isBankAccount)
  }, [])

  const getReconciliationData = (lineWithBankAccount: JournalLine): ReconciliationData => {
    const { account, contraAccount, amount: { value: amount = 0 } = {} } = lineWithBankAccount
    const accountId = account?.isBankAccount ? account?.id : undefined
    const contraAccountId = contraAccount?.isBankAccount ? contraAccount?.id : undefined
    let side: Side

    if (accountId) {
      side = amount < 0 ? Side.Credit : Side.Debit
    } else {
      side = amount < 0 ? Side.Debit : Side.Credit
    }

    return {
      accountId: (accountId || contraAccountId) as string,
      amount,
      side,
    }
  }

  const reconciliationDate = entryDate
  const reconciliationLine = getReconciliationLine(journalLines)
  const reconciliationData: ReconciliationData | undefined = reconciliationLine
    ? getReconciliationData(reconciliationLine)
    : undefined

  // Shortcuts

  useKeyDown(
    '+',
    () => {
      addLine(getLineDefaultValues())
    },
    { withShift: true },
  )

  useKeyDown(
    'Enter',
    () => {
      onCreateAndGoNextClick()
    },
    { withShift: true },
  )

  // Action state

  const actionAfterSaveRef = useRef<Action>()
  const { processingActions } = useVoucherActionState({
    voucherId,
    actions: [VoucherAction.fetch, VoucherAction.createDaybookTransaction],
    onSuccess: (action) => {
      if (action === VoucherAction.createDaybookTransaction) {
        queryClient.invalidateQueries(QueryKeys.Voucher)
        notify({
          id: NotificationKeys.VoucherInboxJounalCreate,
          message: t('voucher.inbox.toast.create_journal_success'),
          variant: 'success',
        })

        if (actionAfterSaveRef.current === Action.NextVoucher) {
          goToVoucher(NavDirection.Next)
        } else if (actionAfterSaveRef.current === Action.Close) {
          close()
        }
      }
    },
    onError: (action, error) => {
      trackError(error)

      if (action === VoucherAction.createDaybookTransaction) {
        notify({
          id: NotificationKeys.VoucherInboxJounalCreate,
          message: t('voucher.inbox.toast.create_journal_failed'),
          variant: 'error',
        })
      }
    },
  })
  const isProcessing = processingActions[VoucherAction.createDaybookTransaction]
  const isDisabled = isProcessing || isLoading
  const areFieldsDisabled = isDisabled || !isBohrDataFetchedRef.current

  // Update form state when voucher is received

  useEffect(() => {
    if (!voucher || isVoucherFetchedRef.current || !accounts?.length) {
      return
    }

    isVoucherFetchedRef.current = true
    isBankLineDataFetchedRef.current = !!voucherBankLine
    isBohrDataFetchedRef.current = !isBohrDataLoading

    const formData = getInitialData({
      accounts,
      bankLine: voucherBankLine,
      bohrData,
      commonData: commonFormData.value,
      voucher,
    })
    const voucherFiles = getVoucherFiles(voucher)

    form.reset(formData)

    if (voucherFiles !== files) {
      setFiles(voucherFiles)
    }

    if (commonFormData) {
      dispatchCommonFormData({ type: 'CLEAR' })
    }

    // Disable eslint rule because it should be only triggered on voucher data update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts, voucher])

  const updateVouchersData = useCallback(() => {
    if (!voucher) {
      return
    }

    const { amount, date, description } = getVoucherPrefilledValues(bohrData, voucherBankLine) || {}

    if (date) {
      form.setValue('entryDate', date)
    }

    if (description) {
      form.setValue('description', description)
    }

    for (let i = 0; i < lines.length; i++) {
      if (amount) {
        form.setValue(`lines.${i}.amount`, amount)
      }
    }
  }, [bohrData, form, lines.length, voucher, voucherBankLine])

  useEffect(() => {
    if ((!bohrData && isBohrDataLoading) || isBohrDataFetchedRef.current) {
      return
    }

    isBohrDataFetchedRef.current = true
    updateVouchersData()
    // Trigger effect only when `bohrData` changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bohrData])

  useEffect(
    () => {
      if (isBankLineDataFetchedRef.current || !voucher || !voucherBankLine) {
        return
      }

      isBankLineDataFetchedRef.current = true
      updateVouchersData()
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [voucherBankLine, voucher],
  )

  useAsync(async () => {
    if (organizationId) {
      const { daybooks } = await getDaybooks(organizationId)

      daybookId.current = daybooks?.[0].id
    }
  }, [organizationId])

  useEffect(
    () => {
      resetView()
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [voucherId],
  )

  // takes form lines and construct lines payload data

  const getPayloadLines = (lines: JournalLine[], data: FormInputs): JournalLinePayload[] =>
    lines.map(
      (line): JournalLinePayload => ({
        accountId: line.account?.id,
        amount: Math.abs(line.amount.value),
        text: data.description,
        contraAccountId: line.contraAccount?.id || undefined,
        side: line.amount.value >= 0 ? Side.Debit : Side.Credit,
        priority: 0,
        taxRateId: line.taxRate?.id,
      }),
    )

  const isValidBankLineMatch = (data: FormInputs) => {
    if (!bankLineMatch || !reconciliationData) {
      return true
    }

    if (data.entryDate && !isSameDay(new Date(bankLineMatch.entryDate), data.entryDate)) {
      form.setError('entryDate', { type: 'date', message: t('voucher.inbox.form.bank_line_date_error') })
      return false
    }

    if (Math.abs(reconciliationData?.amount) - Math.abs(bankLineMatch.amount) !== 0) {
      const reconciliationLineIndex = getReconciliationLineIndex(data.lines)

      form.setError(`lines.${reconciliationLineIndex}.amount.value`, {
        type: 'amount',
        message: t('voucher.inbox.form.bank_line_amount_error'),
      })

      return false
    }

    return true
  }

  const isValidBalance = (data: FormInputs) => {
    const isBalanceValid = isBalanceValidFn(data.lines)
    const areAccountsValid = areAccountsValidFn(data.lines)

    if (!isBalanceValid) {
      form.setError('balanceCheck', { message: t('voucher.inbox.form.balance_error') })
      return false
    }

    if (!areAccountsValid) {
      form.setError('bankAccountsCheck', { message: t('voucher.inbox.form.too_many_bank_accounts') })
      return false
    }

    return true
  }

  const customValidate = (data: FormInputs, validatorToSkip?: ValidationModalType) => {
    const entryDate = data.entryDate
    const isFutureEntryDate = entryDate && isFuture(entryDate)

    if (validatorToSkip !== ValidationModalType.FutureDate && isFutureEntryDate) {
      setValidationModal(ValidationModalType.FutureDate)
      return false
    }

    if (!isValidBankLineMatch(data)) {
      return false
    }

    if (!isValidBalance(data)) {
      return false
    }

    return true
  }

  const handleOnSubmit = (data: FormInputs, validatorToSkip?: ValidationModalType) => {
    const hasPassedValidation = customValidate(data, validatorToSkip)

    if (!hasPassedValidation) {
      return
    }

    const payload: JournalCreatePayloadData = {
      daybookId: daybookId.current,
      state: DaybookTransactionState.Approved,
      entryDate: formatDate(data.entryDate),
      organizationId,
      lines: getPayloadLines(data.lines, data),
      userId: user?.id,
    }

    dispatch(
      voucherActionRequest({
        organizationId,
        voucherId,
        action: VoucherAction.createDaybookTransaction,
        data: payload,
        ...(bankLineMatch?.matchId ? { bankLinesIds: [bankLineMatch.matchId] } : {}),
      }),
    )
  }

  const selectAccountsTaxRate = (lineIndex: number, account?: Account) => {
    if (!account?.taxRateId) {
      return
    }

    const accountTaxRate = getTaxRateById(taxRates, account.taxRateId)
    form.setValue(`lines.${lineIndex}.taxRate`, accountTaxRate)
  }

  const resetView = () => {
    isBankLineDataFetchedRef.current = false
    isVoucherFetchedRef.current = false
    isBohrDataFetchedRef.current = false
    form.clearErrors()
    form.reset()
  }

  const goToVoucher = (direction: NavDirection) => {
    if (!voucher || isNavigationLoading) {
      return
    }

    resetView()

    const goToVoucherId = direction === NavDirection.Prev ? prevVoucherId : nextVoucherId

    if (goToVoucherId && goToVoucherId !== voucher.id) {
      const urlPath = getVoucherInboxPath(goToVoucherId, InboxRoutes.CreateJournal)
      setQueryParams(queryParams, urlPath)
      return
    }

    close()
  }

  const getCommonFormData = (): VoucherCommonData => {
    const formValues = form.getValues()

    return {
      paidFrom: formValues.lines[0].account,
      billDate: formValues.entryDate,
      lines: formValues.lines.map((line) => ({ ...line, description: formValues.description })),
      files,
    }
  }

  const close = () => {
    setQueryParams(queryParams, UmbrellaRoute.VoucherInbox)
  }

  const submit = (validatorToSkip?: ValidationModalType) => {
    form.handleSubmit((data: FormInputs) => handleOnSubmit(data, validatorToSkip))()
  }

  const onCreateClick = () => {
    actionAfterSaveRef.current = Action.Close
    submit()
  }

  const onCreateAndGoNextClick = () => {
    actionAfterSaveRef.current = Action.NextVoucher
    submit()
  }

  const handleVoucherAsBill = () => {
    dispatchCommonFormData({ type: 'SET', payload: getCommonFormData() })
    setQueryParams(queryParams, getVoucherInboxPath(voucherId, InboxRoutes.CreateBill))
  }

  const getHeaderTitle = () => {
    let title = t('create_journal')

    if (organization?.name) {
      title += ` (${organization?.name})`
    }

    return title
  }

  const handleAccountSuggestionClick = (accountId: string) => {
    const account = accounts.find((account) => account.id === accountId)

    if (!account) {
      return
    }

    const taxRateId = account.taxRateId
    const taxRate = taxRateId ? getTaxRateById(taxRates, taxRateId) : undefined

    for (let i = 0; i < lines.length; i++) {
      form.setValue(`lines.${i}.account`, account)

      if (taxRate) {
        form.setValue(`lines.${i}.taxRate`, taxRate)
      }
    }

    setSelectedAccountIdSuggestion(accountId)
  }

  const handleAccountSelect = (index: number, account: Account) => {
    selectAccountsTaxRate(index, account)
    setSelectedAccountIdSuggestion('')
  }

  const handleValidationModalConfirm = () => {
    submit(validationModal)
    setValidationModal(undefined)
  }

  const handleValidationModalCancel = () => {
    if (validationModal === ValidationModalType.FutureDate) {
      form.setError('entryDate', { type: 'date', message: t('voucher.inbox.form.date_future_error') })
    }

    setValidationModal(undefined)
  }

  const handleSuggestionSelected = (bankLine: BankLine | undefined) => {
    setBankLineMatch(bankLine)

    if (bankLine?.entryDate) {
      form.setValue('entryDate', new Date(bankLine.entryDate))
    }
  }

  if (isVoucherFetchError) {
    trackError(voucherFetchError)
    notify({
      id: NotificationKeys.VoucherInboxFetch,
      message: t('voucher.inbox.toast.voucher_fetched_failed', { voucherId }),
      variant: 'error',
    })
    close()
  }

  return (
    <>
      <Header title={getHeaderTitle()}>
        <ButtonsGroup>
          <Button onClick={onCreateAndGoNextClick} disabled={isDisabled}>
            {t('voucher.inbox.form.action.publish_and_go_next')}
          </Button>
          <Button onClick={onCreateClick} variant="secondary" disabled={isDisabled}>
            {t('voucher.inbox.form.action.publish')}
          </Button>
          <Button variant="secondary" onClick={handleVoucherAsBill} disabled={isDisabled}>
            {t('voucher.inbox.form.handleVouchersAsBill')}
          </Button>
        </ButtonsGroup>
      </Header>
      <Flex mb="15px" px="30px" pt="10px" justifyContent="space-between">
        <NavigationButtons
          idLeft={prevVoucherId}
          idRight={nextVoucherId}
          disableLeftAction={isNavigationLoading}
          disableRightAction={isNavigationLoading}
          onLeftClick={() => goToVoucher(NavDirection.Prev)}
          onRightClick={() => goToVoucher(NavDirection.Next)}
        />
      </Flex>

      <FormFilesLayout files={files}>
        <FormProvider {...form}>
          <Styled.FormWrapper>
            <Form>
              <FormFilesLayoutBox mb="20px">
                <DateInput
                  formControl={form.control}
                  name="entryDate"
                  label={t('voucher.inbox.form.entry_date')}
                  popperPlacement="bottom-end"
                  disabled={areFieldsDisabled}
                />
                <Input
                  formControl={form.control}
                  type="text"
                  name="description"
                  label={t('voucher.inbox.form.description')}
                  disabled={areFieldsDisabled}
                />
              </FormFilesLayoutBox>

              {!!bohrData && (
                <AccountSuggestions
                  bohrData={bohrData}
                  onClick={handleAccountSuggestionClick}
                  selectedId={selectedAccountIdSuggestion}
                />
              )}

              <LinesList error={!!(balanceCheckError?.message || bankAccountsCheckError?.message)}>
                {lines.map((item, index) => (
                  <LineWrapper key={item.id}>
                    <FormFilesLayoutBox key={item.id} as="li" fourInRow>
                      <AccountSelector
                        formControl={form.control}
                        name={`lines.${index}.account`}
                        placeholder={t('voucher.inbox.form.account')}
                        defaultValueId={item.account?.id}
                        onItemSelect={(account) => handleAccountSelect(index, account)}
                        withSelectEvent
                        disabled={areFieldsDisabled}
                        mb={0}
                      />
                      <TaxRateSelector
                        formControl={form.control}
                        name={`lines.${index}.taxRate`}
                        organizationId={organizationId}
                        placeholder={t('voucher.inbox.form.vat_rate_placeholder')}
                        disabled={areFieldsDisabled}
                        mb={0}
                      />
                      <CurrencyInput
                        formControl={form.control}
                        name={`lines.${index}.amount`}
                        placeholder={t('amount')}
                        defaultValue={item.amount}
                        withoutCurrencySelector
                        disabled={areFieldsDisabled}
                        mb={0}
                      />
                      <AccountSelector
                        formControl={form.control}
                        placeholder={t('voucher.inbox.form.contra_account')}
                        name={`lines.${index}.contraAccount`}
                        mb={0}
                        disabled={areFieldsDisabled}
                      />
                    </FormFilesLayoutBox>
                    <LineActions>
                      {lines.length > 1 && !areFieldsDisabled && (
                        <RemoveLineButton onClick={() => removeLine(index)} color={theme.colors.black} />
                      )}
                    </LineActions>
                  </LineWrapper>
                ))}
                {balanceCheckError && <CustomErrorMessage error={balanceCheckError} />}
                {bankAccountsCheckError && <CustomErrorMessage error={bankAccountsCheckError} />}
                {!areFieldsDisabled && (
                  <div>
                    <Button icon="plusCircle" variant="text" onClick={() => addLine(getLineDefaultValues())}>
                      {t('voucher.inbox.form.add_line')}
                    </Button>
                  </div>
                )}
              </LinesList>
              <Box mt="20px">
                <FinancialSummary items={vatSummary} placement="right" />
              </Box>
              <ReconciliationSuggestionsBox
                suggestionsSide={reconciliationData?.side}
                date={reconciliationDate}
                accountId={reconciliationData?.accountId}
                disabled={!reconciliationData?.accountId}
                amount={reconciliationData?.amount}
                onSuggestionsSelected={handleSuggestionSelected}
              />
            </Form>
          </Styled.FormWrapper>
        </FormProvider>
      </FormFilesLayout>

      <ValidationModal
        isOpen={!!validationModal}
        type={validationModal}
        onCancel={handleValidationModalCancel}
        onConfirm={handleValidationModalConfirm}
      />
    </>
  )
}

function getInitialData({
  accounts,
  bankLine,
  bohrData,
  commonData = {},
  voucher,
}: GetInitialDataProps<FormInputs>): FormInputs {
  const initialData: FormInputs = { ...formDefaultValues }

  if (voucher) {
    const {
      amount: prefilledAmount,
      date: prefilledDate,
      description: prefilledDescription,
    } = getVoucherPrefilledValues(bohrData, bankLine) || {}
    const description = prefilledDescription || voucher.description || ''
    const paidFromId = voucher.bankAccountId || ''
    const account = accounts?.find((account) => account.id === paidFromId) || ({ id: paidFromId } as Account)
    const entryDate =
      prefilledDate || (voucher.createdTimestamp ? new Date(voucher.createdTimestamp) : formDefaultValues.entryDate)

    initialData.description = description
    initialData.entryDate = entryDate
    initialData.lines = [
      {
        ...getLineDefaultValues(),
        ...(prefilledAmount ? { amount: prefilledAmount } : {}),
        account,
      },
    ]
  }

  // Common data is data from Handle As Journal screen
  if (commonData.lines?.[0].description) {
    initialData.description = commonData.lines?.[0].description
  }

  if (commonData.billDate) {
    initialData.entryDate = commonData.billDate
  }

  if (commonData.lines) {
    initialData.lines = commonData.lines
  }

  return initialData
}
