import { Link } from '@design-system'

import { EmberRoute } from '../../../../../../../../enums/emberRoute'
import { useEmberRouteUrl } from '../../../../../../../../hooks/routing/useEmberRouteUrl'
import { useInvoice } from '../../../../contexts/invoiceContext'

export const InvoiceCustomerLink = () => {
  const { invoice } = useInvoice()

  const { customer } = invoice || {}
  const { displayName: customerName, externalContactId } = customer || {}

  const { url: customerUrl } = useEmberRouteUrl(EmberRoute.ContactsCustomer, externalContactId)

  return (
    <Link to={customerUrl} withAnimation>
      {customerName}
    </Link>
  )
}
