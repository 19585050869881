import { amountToDisplayValue, Badge, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { UmbrellaOrganizationTable } from '@views/umbrella/routes/umbrellaOrganizations/types/umbrellaOrganizationTable'

import { getPlanName } from '../../utils/getPlanName'

interface SubscriptionCellProps {
  umbrellaOrganization: UmbrellaOrganizationTable
}

export const SubscriptionCell = ({ umbrellaOrganization }: SubscriptionCellProps): ReactElement => {
  const { t } = useTranslation()
  const { consolidatedBilling, isTerminated, subscriptionPrice, subscriptionProductPlan, subscriptionPeriod } =
    umbrellaOrganization

  const subscriptionPlanName = getPlanName(subscriptionProductPlan, t) || null

  let badge
  if (isTerminated) {
    badge = <Badge variant="error">{t('umbrella_organizations.organizations_table.terminated')}</Badge>
  } else if (consolidatedBilling) {
    badge = <Badge variant="primary">{t('umbrella_organizations.organizations_table.consolidated_billing')}</Badge>
  } else {
    badge = <Badge variant="success">{t('umbrella_organizations.organizations_table.self_paying')}</Badge>
  }

  return (
    <>
      <Text>{subscriptionPlanName}</Text>
      {subscriptionPrice && (
        <Text variant="small" colorVariant="secondary">
          {amountToDisplayValue(subscriptionPrice, false, 'DKK')}/
          {subscriptionPeriod === 'yearly' ? t('year') : t('month')}
        </Text>
      )}
      {badge}
    </>
  )
}
