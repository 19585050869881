import { Metable } from '../../types/metable'
import { getCurrentLocale, getRequest } from '../../utils'
import { ReportsApiUrl } from './enums/reportsApiUrl'
import { Report } from './types/report'
import {
  BalanceSheetReportOptions,
  IncomeStatementsReportOptions,
  TrialBalanceReportOptions,
} from './types/reportOptions'
import { getStringFromReportQueryParams } from './utils/getStringFromReportQueryParams'

interface IncomeStatementsPayload {
  organizationId: string
  options?: IncomeStatementsReportOptions
}

interface IncomeStatementsResponseData extends Metable {
  report: Report
}

export const fetchIncomeStatements = ({
  organizationId,
  options,
}: IncomeStatementsPayload): Promise<IncomeStatementsResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = getStringFromReportQueryParams(ReportsApiUrl.ProfitAndLoss, options)
  return getRequest(`/v2/organizations/${organizationId}/${ReportsApiUrl.ProfitAndLoss}?${queryParams}`, {
    'accept-language': locale,
  })
}

interface TrialBalancePayload {
  organizationId: string
  options?: TrialBalanceReportOptions
}

interface TrialBalanceResponseData extends Metable {
  report: Report
}

export const fetchTrialBalance = ({
  organizationId,
  options,
}: TrialBalancePayload): Promise<TrialBalanceResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = getStringFromReportQueryParams(ReportsApiUrl.TrialBalance, options)
  return getRequest(`/v2/organizations/${organizationId}/${ReportsApiUrl.TrialBalance}?${queryParams}`, {
    'accept-language': locale,
  })
}

interface BalanceSheetPayload {
  organizationId: string
  options: BalanceSheetReportOptions
}

interface BalanceSheetResponseData extends Metable {
  report: Report
}

export const fetchBalanceSheet = ({
  organizationId,
  options,
}: BalanceSheetPayload): Promise<BalanceSheetResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = getStringFromReportQueryParams(ReportsApiUrl.Balance, options)
  return getRequest(`/v2/organizations/${organizationId}/${ReportsApiUrl.Balance}?${queryParams}`, {
    'accept-language': locale,
  })
}
