import { useMemo } from 'react'

import { AspectRatio } from '../types'
import { getRatioAdjustedDimension } from '../utils/getRatioAdjustedDimension'

interface UseAdjustedDimensionsProps {
  aspectRatio?: AspectRatio
  componentHeight: number
  componentWidth: number
  fitToHeight?: boolean
  fitToWidth?: boolean
}

export const useAdjustedDimensions = ({
  aspectRatio,
  componentHeight,
  componentWidth,
  fitToHeight,
  fitToWidth,
}: UseAdjustedDimensionsProps) => {
  const isHeightZero = componentHeight === 0
  const isWidthZero = componentWidth === 0

  const adjustedWidth = useMemo(() => {
    if (!aspectRatio || fitToWidth || isHeightZero) {
      return componentWidth
    }

    return getRatioAdjustedDimension({ aspectRatio, dimension: componentHeight })
  }, [aspectRatio, fitToWidth, isHeightZero, componentHeight, componentWidth])

  const adjustedHeight = useMemo(() => {
    if (!aspectRatio || fitToHeight || isWidthZero) {
      return componentHeight
    }

    return getRatioAdjustedDimension({ aspectRatio, dimension: componentWidth })
  }, [aspectRatio, fitToHeight, isWidthZero, componentHeight, componentWidth])

  return { height: adjustedHeight, width: adjustedWidth }
}
