export enum ModalId {
  AccountingBasisConfirmationModal = 'accounting-basis-confirmation-modal',
  AccountingPackageModal = 'accounting-package-modal',
  AddCurrencyDefaultPaymentMethodModal = 'add-currency-default-payment-method-modal',
  ApproveFinancingModal = 'approve-financing-modal',
  ApproveInvoiceModal = 'approve-invoice-modal',
  AttachmentPreviewModal = 'attachment-preview-modal',
  AttachmentsModal = 'attachments-modal',
  BankAccountOrphanedModal = 'bank-account-orphaned-modal',
  BankConnectionAlreadyInUseModal = 'bank-connection-already-in-use-modal',
  BankConnectionErrorModal = 'bank-connection-error-modal',
  BankConnectionEstablishedModal = 'bank-connection-established-modal',
  BankConnectionExpiredModal = 'bank-connection-expired-modal',
  BankConnectionFailedModal = 'bank-connection-failed-modal',
  BankConnectionRemoveConfirmationModal = 'bank-connection-remove-confirmation-modal',
  BankConnectionRemovedModal = 'bank-connection-removed-modal',
  BankConnectionSuccessModal = 'bank-connection-success-modal',
  BankSettingsModal = 'bank-settings-modal',
  BankToAccountConnectionModal = 'bank-to-account-connection-modal',
  BillDeleteConfirmationModal = 'bill-delete-bill-confirmation',
  BillReconciliationModal = 'bill-reconciliation-modal',
  BillRegisterPaymentModal = 'bill-register-payment-modal',
  BillyWelcomeModal = 'billy-welcome-modal',
  CreateContactModal = 'create-contact-modal',
  CreateContactPersonModal = 'create-contact-person-modal',
  CreateOrEditAccessTokenModal = 'create-or-edit-access-token-modal',
  CreateOrEditAdditionalFieldModal = 'create-or-edit-additional-field-modal',
  CreateOrEditVatAccountModal = 'create-or-edit-vat-account-modal',
  CreateRulesetModal = 'create-ruleset-modal',
  DaybookConsequencesModal = 'daybook-consequences-modal',
  DebtCollectionNoteModal = 'debt-collection-note-modal',
  DefineSalesTaxReturnPeriodPayoutModal = 'define-sales-tax-return-period-payout-modal',
  DeleteActorConfirmationModal = 'delete-actor-confirmation-modal',
  DeleteOrganizationIconModal = 'delete-organization-icon-modal',
  DeleteOrganizationLogoModal = 'delete-organization-logo-modal',
  DeleteTrialDataModal = 'delete-trial-data-modal',
  DirtyRouteModal = 'dirty-route-modal',
  DisableDebtCollectionModal = 'disable-debt-collection-modal',
  DisableMobilePayModal = 'disable-mobilepay-modal',
  DisableStripeModal = 'disable-stripe-modal',
  DuplicatedBillActionModal = 'duplicated-bill-action-modal',
  EInvoiceRejectionModal = 'e-invoice-rejection-modal',
  EmailRateExceeded = 'email-rate-exceeded-modal',
  EnableDebtCollectionModal = 'enable-debt-collection-modal',
  EnableEInvoicingModal = 'enable-einvoicing-modal',
  EnableMobilePayModal = 'enable-mobilepay-modal',
  EnableStripeModal = 'enable-stripe-modal',
  ExportAsSaftModal = 'export-as-saft-modal',
  ExternalInvoicesSyncModal = 'external-invoices-sync-modal',
  FinancingApplicationModal = 'financing-application-modal',
  FinancingApplicationSentModal = 'financing-application-sent-modal',
  FinancingApproveSuccessModal = 'financing-approve-success-modal',
  FirstInvoiceModal = 'first-invoice-modal',
  FrodaFailedModal = 'froda-failed-modal',
  FrodaLendingEligibilityModal = 'froda-lending-eligibilty-modal',
  FrodaProcessingModal = 'froda-processing-modal',
  HelpModal = 'help-modal',
  InvoiceDateModal = 'invoice-date-modal',
  InvoiceEditorApprovalConfirmationModal = 'invoice-editor-approval-confirmation-modal',
  InvoiceEditorInvoiceNumberModal = 'invoice-editor-invoice-number-modal',
  InvoiceEditorSendAsEmailModal = 'invoice-editor-send-as-email-modal',
  MobileSignupWelcomeModal = 'mobile-signup-welcome-modal',
  OrganizationSettingsInviteBookkeeper = 'organization-settings-invite-bookkeeper-modal',
  OrganizationSettingsInviteUsersModal = 'organization-settings-invite-users-modal',
  OrganizationSettingsRemoveBookkeeperModal = 'organization-settings-remove-bookkeeper-modal',
  OrganizationSettingsRemoveUserModal = 'organization-settings-remove-user-modal',
  OrganizationSettingsTransferOwnershipModal = 'organization-settings-transfer-ownership-modal',
  PeriodicLockReminderModal = 'periodic-lock-reminder-modal',
  PlanConfirmationModal = 'plan-confirmation-modal',
  RegisterInternationallyFailedModal = 'registration-internationally-failed-modal',
  RegisterInternationallyFormModal = 'register-internationally-form-modal',
  RegisterInternationallyPendingModal = 'registration-internationally-pending-modal',
  RegisterInternationallySuccessModal = 'registration-internationally-success-modal',
  RegisterSalesTaxReturnPeriodPaymentModal = 'register-sales-tax-return-period-payment-modal',
  ScoutConnectionConnectedModal = 'scout-connection-connected-modal',
  ScoutConnectionDeletionModal = 'scout-connection-deletion-modal',
  SendInvoiceModal = 'send-invoice-modal',
  SettleSalesTaxReturnPeriodModal = 'settle-sales-tax-return-period-modal',
  SkipAndCompleteSyncModal = 'skip-and-complete-sync-modal',
  SubscriptionPeriodChangeModal = 'subscription-period-change-modal',
  TrialEndedModal = 'trial-ended-modal',
  UmbrellaCreateRoleModal = 'umbrella-create-role-modal',
  UmbrellaInviteOrUpdateUserModal = 'umbrella-invite-or-update-user-modal',
  UmbrellaTransferOwnershipModal = 'umbrella-transfer-ownership',
  UnrecognizedVatRatesModal = 'unrecognized-vat-rates-modal',
  UpgradeSubscriptionModal = 'upgrade-subscription-modal',
  VATConfirmationModal = 'vat-confirmation-modal',
  ViewAccessTokenModal = 'view-access-token-modal',
  VoidInvoiceModal = 'void-invoice-modal',
  VoidSalesTaxReturnPeriodPaymentModal = 'void-sales-tax-return-period-payment-modal',
  VoidSalesTaxReturnPeriodSettlementModal = 'void-sales-tax-return-period-settlement-modal',
}
