import { Flex, Text, Textarea } from '@design-system'

import debounce from 'lodash/debounce'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useSegment } from '../../../../../../../../../../hooks'
import { INVOICE_FOOTER_CHARACTER_LIMIT } from '../../../../../../constants/invoiceFooterCharacterLimit'
import { InvoiceFormSchema } from '../../../../../../types/invoiceFormSchema'
import { TRACKING_DELAY } from '../../constants/trackingDelay'

export const InvoiceFooterNote = () => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { FormItem, watch } = useFormContext<InvoiceFormSchema>()

  const footerNote = watch('footerNote')

  const handleDebouncedTextareaChange = useMemo(
    () =>
      debounce((value: string) => {
        track('XXX - Invoicing Core - Invoice Editor - Form - Footer Note typed', {
          messageLength: value.length,
        })
      }, TRACKING_DELAY),
    [track],
  )

  return (
    <FormItem
      name="footerNote"
      label={
        <Flex justifyContent="space-between">
          <Text colorVariant="secondary">{t('external_invoices.editor.form.field.invoice_footer_note.label')}</Text>
          <Text colorVariant="secondary">{`${footerNote.length}/${INVOICE_FOOTER_CHARACTER_LIMIT}`}</Text>
        </Flex>
      }
      render={({ field: { value, onChange, ...props } }) => {
        return (
          <Textarea
            {...props}
            expandOnType
            maxHeight={160}
            maxLength={INVOICE_FOOTER_CHARACTER_LIMIT}
            onChange={(event) => {
              onChange(event)
              handleDebouncedTextareaChange(event.target.value)
            }}
            rows={1}
            placeholder={t('external_invoices.editor.form.field.invoice_footer_note.placeholder')}
            resizable={false}
            value={value}
          />
        )
      }}
    />
  )
}
