import { Flex, Link, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ReactComponent as DocumentsIcon } from '../../../../../assets/images/lending/documents.svg'
import { ReactComponent as StarPaperIcon } from '../../../../../assets/images/lending/star.svg'
import { useEmberRouter } from '../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../enums/emberRoute'
import { HighlightedIcon } from '../HighlightedIcon'

export const FrodaLendingEligibilityModal = (props: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { close: closeModal } = useModal(props.id)

  const handleUpgradeAccountClick = useCallback(() => {
    closeModal()
    navigate(EmberRoute.SettingsSubscriptionsPlansSelection)
  }, [navigate, closeModal])

  const handleConnectBankLinkClick = useCallback(() => {
    closeModal()
    navigate(EmberRoute.BankAccounts)
  }, [navigate, closeModal])

  return (
    <Modal closable {...props}>
      <Modal.Header title={t('financing.froda_lending.eligibility_modal.title')} />
      <Modal.Body>
        <Text>{t('financing.froda_lending.eligibility_modal.description')}</Text>
        <Space size="xl" />
        <Flex alignItems="center">
          <HighlightedIcon>
            <DocumentsIcon />
          </HighlightedIcon>
          <Text colorVariant="secondary" variant="small">
            <Trans i18nKey="financing.froda_lending.eligibility_modal.criteria_1">
              Connect your bank to your account
              <Link onClick={handleConnectBankLinkClick}>Connect bank</Link>
            </Trans>
          </Text>
        </Flex>
        <Space size="m" />
        <Flex alignItems="center">
          <HighlightedIcon>
            <StarPaperIcon />
          </HighlightedIcon>
          <Text colorVariant="secondary" variant="small">
            <Trans i18nKey="financing.froda_lending.eligibility_modal.criteria_2">
              Select a paid plan to get started
              <Link onClick={handleUpgradeAccountClick}>Upgrade plan</Link>
            </Trans>
          </Text>
        </Flex>
      </Modal.Body>
    </Modal>
  )
}
