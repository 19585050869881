import { NavItem } from '@design-system'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization, useUserOrganizationSettings } from '@modules-deprecated/app/organization'
import { useUmbrella } from '@modules-deprecated/app/umbrellas'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { useOrganizationSource } from '../../../../../hooks'
import { useEmberRouteUrl } from '../../../../../hooks/routing/useEmberRouteUrl'
import { getOrganizationViewUrl } from '../../../../../utils/routing/getOrganizationViewUrl'
import { reactRoute } from '../../../../../utils/routing/reactRoute'
import { BetaBadge } from '../elements/BetaBadge'
import { FastItems } from '../types/fastItems'
import { getFastMenuFilteredItems } from '../utils/getFastMenuFilteredItems'

interface FastMenuItems {
  items: NavItem<string>[]
  isLoading: boolean
}

export const useFastMenuItems = (): FastMenuItems => {
  const { t } = useTranslation()
  const { organizationSettings, isLoading: isLoadingUserOrganizationSettings } = useUserOrganizationSettings()
  const { umbrella } = useUmbrella()
  const { isSourceZervant } = useOrganizationSource()

  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'

  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()
  const { url: invoicesNewUrl } = useEmberRouteUrl(EmberRoute.InvoicesNew)
  const { url: quotesNewUrl } = useEmberRouteUrl(EmberRoute.QuotesNew)

  const isLoadingLinks = !invoicesNewUrl || !quotesNewUrl || isLoadingOrganization

  const fastItems = useMemo((): FastItems => {
    if (isLoadingLinks || !organization?.url) {
      return {}
    }

    return {
      newInvoice: {
        id: 'new-invoice',
        children: t('invoice'),
        value: EmberRoute.InvoicesNew,
        iconLeft: 'invoice',
        to: invoicesNewUrl,
        iconRight: isSourceZervant ? React.createElement(BetaBadge) : undefined,
      },
      newBill: {
        id: 'new-bill',
        children: t('bill'),
        value: reactRoute.bills.getNewBillRoute(),
        iconLeft: 'bill',
        to: getOrganizationViewUrl(organization.url, reactRoute.bills.getNewBillRoute()),
      },
      newQuote: {
        id: 'new-quote',
        children: t('quote'),
        value: EmberRoute.QuotesNew,
        iconLeft: 'invoiceWithPencil',
        to: quotesNewUrl,
      },
    }
  }, [isLoadingLinks, organization?.url, isSourceZervant, t, invoicesNewUrl, quotesNewUrl])

  // ⛔️ WARNING getFastMenuFilteredItems not memoized on purpose as it uses the global object inside
  const items = getFastMenuFilteredItems({
    fastItems,
    isNewBillItemDisabled: isInboxEnabled,
    isSourceZervant,
    isUmbrellaContext: !!umbrella,
  })

  return { items, isLoading: isLoadingUserOrganizationSettings || isLoadingLinks }
}
