import { ToggleGroup, ToggleGroupItem } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useSegment } from '../../../../../../../../../../../../../../../../hooks'
import { ProductType } from '../../../../../../../../../../../../../../enums/productType'
import { InvoiceFormSchema } from '../../../../../../../../../../../../types/invoiceFormSchema'
import { CommonInvoiceLineFieldProps } from '../../../../types/commonInvoiceLineFieldProps'

type InvoiceLineProductTypeProps = CommonInvoiceLineFieldProps

export const InvoiceLineProductType = ({ lineIndex }: InvoiceLineProductTypeProps) => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { FormItem } = useFormContext<InvoiceFormSchema>()

  const productTypeItems: ToggleGroupItem[] = useMemo(
    () => [
      {
        icon: 'cube',
        tooltipLabel: t('external_invoices.editor.form.field.line.field.type.goods'),
        value: ProductType.Goods,
      },
      {
        icon: 'wrench',
        tooltipLabel: t('external_invoices.editor.form.field.line.field.type.services'),
        value: ProductType.Services,
      },
    ],
    [t],
  )

  const handleToggleGroupChange = useCallback(
    (value: ProductType) => {
      track('XXX - Invoicing Core - Invoice Editor - Form - Invoice lines - Product type selected', { option: value })
    },
    [track],
  )

  return (
    <FormItem
      name={`invoiceLines.${lineIndex}.productType`}
      render={({ field: { onChange, value } }) => {
        return (
          <ToggleGroup
            items={productTypeItems}
            onChange={(event) => {
              onChange(event)
              handleToggleGroupChange(value)
            }}
            value={value}
          />
        )
      }}
    />
  )
}
