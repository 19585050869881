import { InputNumberLocalized, TaxRateSelect } from '@components'
import { Color, FormItemsGroup, IconButton, Space, Text, Tooltip } from '@design-system'

import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TaxRate } from '@modules-deprecated/app/taxrates/types'

import { BillFormFieldsChangeSource } from '../../../../../../enums/billFormFieldsChangeSource'
import { useSegment } from '../../../../../../hooks'
import { useFormContext, useWatch } from '../../../../../../hooks/useForm'
import { useBillForm } from '../../contexts/billFormContext'
import { BillFormSchema } from '../../utils/formData'
import { BillLineDescription } from '../BillLineDescription'
import { BillLineExpenseCategory } from '../BillLineExpenseCategory'
import * as Styled from './styles'
import { calculateVat } from './utils/calculateVat'

export interface BillLineProps {
  id: string
  index: number
  linesTotal: number
  onLineDelete?: () => void
  sessionId: string
}

export const BillLine = ({ index, linesTotal, onLineDelete, sessionId }: BillLineProps): ReactElement => {
  const { t } = useTranslation()
  const { control, setValue, FormItem } = useFormContext<BillFormSchema>()
  const inclVatAmount = useWatch({ control, name: `billLines.${index}.inclVatAmount` })
  const taxRate = useWatch({ control, name: `billLines.${index}.taxRate` })
  const { track } = useSegment()
  const { billFormView, formFieldsChangeSource, setFormFieldsChangeSource } = useBillForm()

  const handleTaxRateSelect = useCallback(
    (value: TaxRate | undefined) => {
      if (value?.id) {
        track('xxx - expense - create expense - tax rate selected', {
          billPage: billFormView,
          context: BillFormFieldsChangeSource.Manually,
        })
        setFormFieldsChangeSource((previousSourceMap) => {
          return { ...previousSourceMap, taxRate: BillFormFieldsChangeSource.Manually }
        })
      } else {
        track('xxx - expense - create expense - tax rate removed', {
          billPage: billFormView,
          context: formFieldsChangeSource.taxRate || formFieldsChangeSource.main,
        })
        setFormFieldsChangeSource((previousSourceMap) => {
          return { ...previousSourceMap, taxRate: undefined }
        })
      }
    },
    [billFormView, formFieldsChangeSource.main, formFieldsChangeSource.taxRate, setFormFieldsChangeSource, track],
  )

  useEffect(() => {
    const vatAmount = calculateVat(inclVatAmount || 0, taxRate?.rate || 0)
    setValue(`billLines.${index}.vatAmount`, vatAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inclVatAmount, taxRate])

  const handleDeleteLineClick = useCallback(() => {
    onLineDelete?.()
  }, [onLineDelete])

  return (
    <Styled.BillLine>
      <Styled.BillLineTitleWrapper>
        <Text variant="large" weight="medium">
          {t('bill.edit.form.line')} #{index + 1}
        </Text>

        {linesTotal > 1 && (
          <Tooltip label={t('bill.edit.form.delete_line')} placement="top">
            <IconButton icon="trash" onClick={handleDeleteLineClick} />
          </Tooltip>
        )}
      </Styled.BillLineTitleWrapper>
      <Space size="xxs" />
      <FormItemsGroup>
        <BillLineDescription lineIndex={index} sessionId={sessionId} />
        <BillLineExpenseCategory lineIndex={index} />
      </FormItemsGroup>
      <FormItemsGroup itemsInRow={4}>
        <FormItem
          itemColumnStart={1}
          itemColumnEnd={3}
          name={`billLines.${index}.taxRate`}
          label={t('bill.edit.form.tax_rate')}
          render={({ field: { value, onChange, ...props } }) => (
            <TaxRateSelect
              {...props}
              allowClear
              dropdownSize="xxl"
              onSelect={(id, value) => {
                onChange(value)
                handleTaxRateSelect(value)
              }}
              purchase
              selectedId={value?.id}
            />
          )}
        />
        <FormItem
          name={`billLines.${index}.vatAmount`}
          label={t('bill.edit.form.vat_amount')}
          labelAlignment="right"
          render={({ field }) => <InputNumberLocalized readOnly bordered={false} {...field} />}
        />
        <FormItem
          name={`billLines.${index}.inclVatAmount`}
          label={t('bill.edit.form.incl_vat_amount')}
          labelAlignment="right"
          render={({ field }) => <InputNumberLocalized weight="medium" {...field} />}
        />
      </FormItemsGroup>

      <Styled.Divider dividerColor={Color.Gray30} />
    </Styled.BillLine>
  )
}
