export enum ZervantLocale {
  DE_AT = 'de_AT',
  DE_CH = 'de_CH',
  DE_DE = 'de_DE',
  EN_AU = 'en_AU',
  EN_GB = 'en_GB',
  FI_FI = 'fi_FI',
  FR_BE = 'fr_BE',
  FR_CH = 'fr_CH',
  FR_FR = 'fr_FR',
  NL_BE = 'nl_BE',
  NL_NL = 'nl_NL',
  SV_FI = 'sv_FI',
  SV_SE = 'sv_SE',
}
