import { useQuery } from 'react-query'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { fetchTaxRates } from '@modules-deprecated/app/taxrates/query-api'

import { QueryKeys } from '../../../../../enums/queryKeys'

export const useTaxRates = () => {
  const { organization } = useUserOrganization()

  const { data, isLoading } = useQuery(
    [QueryKeys.TaxRates, organization?.id],
    () => fetchTaxRates({ organizationId: organization?.id as string, isActive: true, sale: true, purchase: false }),
    {
      enabled: !!organization?.id,
    },
  )

  return { data: data?.taxRates || [], isLoading }
}
