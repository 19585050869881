import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import { Link, Router } from 'react-router-dom'

import { getGlobalBilly } from '../../utils/globalBilly'
import { history } from '../../utils/history'
import { getOrganizationViewUrl } from '../../utils/routing/getOrganizationViewUrl'
import { getReactRouteFromDeletedEmberRoute } from '../../utils/routing/getReactRouteFromDeletedEmberRoute'
import { trackError } from '../../utils/trackError'

const MODULE_NAME = 'link-module'

// This module was not split into more files on purpose
// We don't want those parts to be used somewhere else

// We want to simplify the use of link-module web-component, so we're not loading organization
// because we would need to pass organizationId to it
// instead we get organization url from Ember via global billy
// This works because we're using link-module on pages that can't be displayed before organization is loaded
export const getOrganizationUrlGlobalBilly = () => {
  try {
    const userOrganizationsEmberService = getGlobalBilly()?.__container__?.lookup('service:user-organizations')
    const organizationUrl = userOrganizationsEmberService?.get('activeOrganization.url')

    if (organizationUrl) {
      return organizationUrl
    }
  } catch (error) {
    trackError(error)
  }

  trackError('Organization not found. Link probably will not work')
  return ''
}

interface EmberViewLinkProps {
  text: string
  routeParam?: string
  routeParam2?: string
  route: string
  className: string
  to: string
  href: string
  target: string
  rel: string
}

const EmberViewLink = ({
  text,
  routeParam,
  routeParam2,
  route,
  className = 'ember-view',
  href,
  rel,
  to,
  target,
}: EmberViewLinkProps) => {
  const organizationUrl = getOrganizationUrlGlobalBilly()

  if (href) {
    return (
      <a href={href} className={className} target={target} rel={rel}>
        {text}
      </a>
    )
  }

  let url = to

  if (!url && route) {
    url = getOrganizationViewUrl(organizationUrl, getReactRouteFromDeletedEmberRoute(route, routeParam, routeParam2))
  }

  if (!url) {
    return null
  }

  return (
    <Link to={url} className={className} target={target} rel={rel}>
      {text}
    </Link>
  )
}

class LinkWebComponent extends DOMModel {
  @byAttrVal text: string
  @byAttrVal('route-param') routeParam: string
  @byAttrVal('route-param2') routeParam2: string
  @byAttrVal route: string
  @byAttrVal('class-name') className: string
  @byAttrVal to: string
  @byAttrVal href: string
  @byAttrVal target: string
  @byAttrVal rel: string
}

const LinkModule = (props: EmberViewLinkProps) => {
  return (
    <Router history={history}>
      <EmberViewLink {...props} />
    </Router>
  )
}

const MenuElement = createCustomElement(LinkModule, LinkWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, MenuElement)

export default MenuElement
