export enum QueryKeys {
  AccessTokens = 'access-tokens',
  Account = 'account',
  AccountBalance = 'account-balance',
  AccountGroups = 'account-groups',
  Accounts = 'accounts',
  AccountStatements = 'account-statements',
  Actors = 'actors',
  AdditionalFields = 'additional-fields',
  AgerasFinanceCustomer = 'ageras-finance-customer',
  AnnualReports = 'annual-reports',
  AnnualReportsCredits = 'annual-reports-credits',
  Attachment = 'attachment',
  AttachmentsCount = 'attachments-count',
  AttachmentsFiles = 'attachments-files',
  AttachmentsList = 'attachments-list',
  AttachmentsModal = 'attachments-modal',
  BalanceSheet = 'balance-sheet',
  BankBalance = 'bank-balance',
  BankConnections = 'bank-conections',
  BankAccountConnections = 'bank-account-connections',
  BankConnectionsOld = 'bank-conections-old',
  BankConnectionSessions = 'bank-connection-sessions',
  BankLineMatches = 'bank-line-matches',
  BankLines = 'bank-lines',
  BankLoan = 'bank-loan',
  Banks = 'banks',
  Bill = 'bill',
  BillRecociliationMatches = 'bill-reconciliation-matches',
  BillSubjectAssociations = 'bill-subject-associations',
  Bills = 'bills',
  BillsSummary = 'bills-summary',
  BillTimeline = 'bill-timeline',
  BohrScanResult = 'bohr-scan-result',
  Bookkeepers = 'bookkeepers',
  BookkeepingCategorySuggestions = 'bookkeeping-category-suggestions',
  BookkeepingCategories = 'bookkeeping-categories',
  BookkeepingTag = 'bookkeeping-tag',
  Cards = 'cards',
  Companies = 'companies',
  Company = 'company',
  Contact = 'contact',
  ContactBalance = 'contact-balance',
  ContactPersons = 'contact-persons',
  Contacts = 'contacts',
  ContactsAndContactPersons = 'contacts-and-contact-persons',
  Countries = 'countries',
  Creditors = 'creditors',
  Currencies = 'currencies',
  CurrentUser = 'current-user',
  DaybookConsequences = 'daybook-consequences',
  DebtCollectionTerms = 'debt-collection-terms',
  Debtors = 'debtors',
  EasyNumbers = 'easy-numbers',
  ExternalInvoice = 'external-invoice',
  ExternalInvoicesNumbering = 'external-invoices-numbering',
  ExternalInvoicesSync = 'external-invoices-sync',
  ExternalUnrecognizedVatRates = 'external-unrecognized-vat-rates',
  GlobalSearch = 'global-search',
  IncomeStatements = 'income-statements',
  IncomeTimeline = 'income-timeline',
  Invoice = 'invoice',
  InvoiceLogs = 'invoiceLogs',
  Invoices = 'invoices',
  InvoiceSnapshot = 'invoiceSnapshot',
  InvoicesSummary = 'invoices-summary',
  InvoicesZervant = 'invoicesZervant',
  InvoiceTemplates = 'invoice-templates',
  InvoiceZervant = 'invoice-zervant',
  InvoiceZervantHtml = 'invoice-zervant-html',
  IsTrustedPartner = 'is-trusted-partner',
  LogSearchCategory = 'log-search-category',
  MeasurementUnits = 'measurement-units',
  NextVoucherNo = 'next-voucher-no',
  Organization = 'organization',
  OrganizationBookkeepers = 'organization-bookkeepers',
  OrganizationImage = 'organization-image',
  OrganizationInvitation = 'organization-invitation',
  OrganizationOwners = 'organization-owners',
  OrganizationRoles = 'organization-roles',
  OrganizationSettingsUsersMembers = 'organization-settings-users-members',
  OrganizationSubscription = 'organization-subscription',
  OrganizationSubscriptionInfo = 'organization-subscription-info',
  OrganizationUsers = 'organization-users',
  PaymentMethod = 'payment-method',
  PaymentMethods = 'payment-methods',
  PeppolVerification = 'peppol-verification',
  Products = 'products',
  ScoutConnections = 'scout-connections',
  ProductPlans = 'product-plans',
  PublicAccounts = 'public-accounts',
  Quotes = 'quotes',
  RecurringInvoices = 'recurring-invoices',
  RecurringInvoicesSpawnSettings = 'recurring-invoices-spawn-settings',
  RegisteredForEInvoicing = 'registered-for-einvoicing',
  RequestAccountant = 'request-accountant',
  SalaryConnectionStatus = 'salary-connection-status',
  SalaryPayrolls = 'salary-payrolls',
  SalaryStatistics = 'salary-statistics',
  SalesTaxPeriods = 'sales-tax-periods',
  SalesTaxReturn = 'sales-tax-return',
  SalesTaxReturnPeriod = 'sales-tax-return-period',
  SalesTaxReturnPeriodPayments = 'sales-tax-return-period-payments',
  SalesTaxRulesets = 'sales-tax-rulesets',
  SalesWithoutVat = 'sales-without-vat',
  SkatConnection = 'skat-connection',
  StandardInvoiceTemplate = 'standard-invoice-template',
  SubscriptionInvoicePdf = 'subscription-invoice-pdf',
  SubscriptionInvoices = 'subscription-invoices',
  SubscriptionMetrics = 'subscription-metrics',
  SubscriptionPlans = 'subscription-plans',
  Tasks = 'tasks',
  TaxRates = 'tax-rates',
  TaxReturnPeriods = 'tax-return-periods',
  TokenInfo = 'token-info',
  TopCustomers = 'top-customers',
  TopSuppliers = 'top-suppliers',
  Transactions = 'transactions',
  TrialBalance = 'trial-balance',
  Umbrella = 'umbrella',
  UmbrellaInvitation = 'umbrella-invitation',
  UmbrellaInvitedUsers = 'umbrella-invited-users',
  UmbrellaInvoices = 'umbrella-invoices',
  UmbrellaLicensePacks = 'umbrella-license-packs',
  UmbrellaLogo = 'umbrella-logo',
  UmbrellaMembers = 'umbrella-members',
  UmbrellaOrganizations = 'umbrella-organizations',
  UmbrellaOrganizationsInvitations = 'umbrella-organizations-invitations',
  UmbrellaOrganizationsProductPlans = 'umbrella-organizations-product-plans',
  UmbrellaOrganizationsSubscriptions = 'umbrella-organizations-subscriptions',
  UmbrellaOrganizationsVoucherStats = 'umbrella-organizations-voucher-stats',
  UmbrellaPaymentMethod = 'umbrella-payment-method',
  UmbrellaRoles = 'umbrella-roles',
  UmbrellaSubscriptions = 'umbrella-subscriptions',
  User = 'user',
  UserNotificationSettings = 'user-notification-settings',
  UserOrganization = 'user-organization',
  UserOrganizations = 'user-organizations',
  UserOrganizationSetting = 'user-organization-setting',
  UserOrganizationSettings = 'user-organization-settings',
  UserProfilePic = 'user-profile-pic',
  VatAccounts = 'vat-accounts',
  VatRatesRulesets = 'vat-rates-rulesets',
  VatRatesTemplates = 'vat-rates-templates',
  VatRateTemplate = 'vat-rate-template',
  Voucher = 'voucher',
  VouchersList = 'vouchers-list',
  ZervantCompany = 'zervant-company',
  ZervantSettings = 'zervant-settings',
}
