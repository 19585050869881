import { TFunction } from 'i18next'
import { z, ZodType } from 'zod'

import { ContactType } from '../../../../contacts/types/contactType'
import { ProductCategory } from '../../../enums/productCategory'
import { ProductPricingModel } from '../../../enums/productPricingModel'
import { ProductType } from '../../../enums/productType'
import { INVOICE_FOOTER_CHARACTER_LIMIT } from '../constants/invoiceFooterCharacterLimit'
import { INVOICE_MESSAGE_CHARACTER_LIMIT } from '../constants/invoiceMessageCharacterLimit'
import { InvoiceUnit } from '../enums/invoiceUnit'
import { InvoiceFormLine } from '../types/invoiceForm/invoiceFormLine'
import { InvoiceFormSchema } from '../types/invoiceFormSchema'

const getInvoiceLineSchema = (t: TFunction): ZodType<InvoiceFormLine> => {
  return z
    .object({
      date: z.date(),
      productName: z.string().trim(),
      productFromSearch: z
        .object({
          category: z.nativeEnum(ProductCategory),
          id: z.string(),
          name: z.string(),
          price: z.number(),
          pricingModel: z.nativeEnum(ProductPricingModel),
          productType: z.nativeEnum(ProductType),
          total: z.number(),
          unit: z.string(),
          vatPct: z.number(),
          vatRateId: z.string(),
        })
        .optional()
        .nullable(),
      productType: z.nativeEnum(ProductType),
      quantity: z.number(),
      totalExcludingVat: z.number(),
      totalIncludingVat: z.number(),
      unit: z.nativeEnum(InvoiceUnit),
      unitPrice: z.number(),
      vatRateId: z.string(),
      vatRatePercent: z.number(),
    })
    .refine(
      (line) => {
        if (!line.productFromSearch && !line.productName) {
          return false
        }

        return true
      },
      {
        message: t('external_invoices.editor.form.field.line.field.product_name.error.required'),
        path: ['productFromSearch'],
      },
    )
}

export const getInvoiceFormSchema = (t: TFunction): InvoiceFormSchema => {
  return z.object({
    currency: z.string(), // field not in form
    footerNote: z.string().max(INVOICE_FOOTER_CHARACTER_LIMIT),
    invoiceLines: z.array(getInvoiceLineSchema(t)),
    invoiceNumber: z.number(),
    invoiceNumberPrefix: z.string().optional(),
    issueDate: z.date(),
    message: z.string().max(INVOICE_MESSAGE_CHARACTER_LIMIT),
    paymentTerm: z.number(),
    receiver: z.object(
      {
        cityText: z.string(),
        contactNo: z.string(),
        countryId: z.string(),
        externalId: z.string(),
        id: z.string(),
        name: z.string(),
        paymentTermsDays: z.number(),
        registrationNo: z.string(),
        street: z.string(),
        type: z.nativeEnum(ContactType),
        vatNo: z.string(),
        zipcodeText: z.string(),
      },
      {
        message: t('external_invoices.editor.form.field.send_to.error.required'),
      },
    ),
  })
}
