import { InvoiceLogErrorKey } from '@views/invoices'

export const errorKeyToErrorMessageTKey: Record<InvoiceLogErrorKey, string | null> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  account_exists: 'einvoce.status.description.account_exists',
  account_exists_nemhandel: 'einvoce.status.description.account_exists_nemhandel',
  attachments_unprocessable: 'einvoce.status.description.attachments_unprocessable',
  invalid_cvr: 'einvoce.status.description.invalid_cvr',
  recipient_not_found: 'einvoce.status.description.recipient_not_found',
  recipient_not_recognized: 'einvoce.status.description.recipient_not_recognized',
  request_error: 'einvoce.status.description.request_error',
  request_too_large: 'einvoce.status.description.request_too_large',
  schema_incomplete: 'einvoce.status.description.schema_incomplete',
  schema_invalid: 'einvoce.status.description.schema_invalid',
  schema_invalid_bank_digits: 'einvoce.status.description.schema_invalid_bank_digits',
  schema_invalid_zero_quantity: 'einvoce.status.description.schema_invalid_zero_quantity',
  sender_cvr_mismatch: 'einvoce.status.description.sender_cvr_mismatch',
  timeout: 'einvoce.status.description.timeout',
  undeliverable: 'einvoce.status.description.undeliverable',
  rejected: 'einvoce.status.description.rejected',
  unknown: 'einvoce.status.description.unknown',
  /* eslint-enable @typescript-eslint/naming-convention */
}
