// Bills --------------------------------------------------------------------------------------------------------------

const getBillsMainRoute = () => 'bills'
const getBillsCreateCreditNoteRoute = (billIdToCreateCreditNote = ':billIdToCreateCreditNote') =>
  `bills/create-credit-note/${billIdToCreateCreditNote}`
const getBillsDuplicateRoute = (billIdToDuplicate = ':billIdToDuplicate') => `bills/duplicate/${billIdToDuplicate}`
const getBillsEditBillRoute = (billId = ':billId') => `bills/${billId}/edit`
const getBillsEditBulkRoute = (bulkBillId = ':bulkBillId') => `bills/${bulkBillId}/bulk-edit`
const getBillsEditBulkAttachmentsRoute = (attachmentIdBulk = ':attachmentIdBulk') =>
  `bills/${attachmentIdBulk}/bulk-edit-attachments`
const getBillsListEmptyRoute = () => 'bills/empty'
const getBillsNewBillRoute = () => 'bills/new'
const getBillsNewCreditNoteRoute = () => 'bills/new-credit-note'
const getBillsNewBillFromAttachmentRoute = (attachmentId = ':attachmentId') =>
  `bills/new-from-attachment/${attachmentId}`
const getBillsNewBillFromContactRoute = (contactId = ':contactId') => `bills/new-from-contact/${contactId}`
const getBillsViewBillRoute = (billId = ':billId') => `bills/${billId}`

// Creditors ----------------------------------------------------------------------------------------------------------

const getCreditorsMainRoute = () => 'creditorbalance'

// Dashboard ------------------------------------------------------------------------------------------------------------

const getDashboardMainRoute = () => 'dashboard'

// Debtors ------------------------------------------------------------------------------------------------------------

const getDebtorsMainRoute = () => 'debtorbalance'

// Find Accountant ------------------------------------------------------------------------------------------------------------

const getFindAccountantMainRoute = () => 'find-accountant'

// Invoices -----------------------------------------------------------------------------------------------------------

const getInvoicesMainRoute = () => 'invoices'
const getInvoicesListEmpty = () => 'invoices/empty'
const getInvoicesNewInvoiceRoute = () => 'invoices/new'
const getInvoicesViewInvoiceRoute = (invoiceId = ':invoiceId', documentType = ':documentType') =>
  `invoices/${invoiceId}/${documentType}`

// Uploads -----------------------------------------------------------------------------------------------------------

const getUploadsMainRoute = () => 'uploads'

// Things to do ------------------------------------------------------------------------------------------------------

const getThingsToDoMainRoute = () => 'things-to-do'

// Salary ------------------------------------------------------------------------------------------------------------

const getSalaryMainRoute = () => 'salary'

export const reactRoute = {
  bills: {
    getMainRoute: getBillsMainRoute,
    getCreateCreditNoteRoute: getBillsCreateCreditNoteRoute,
    getDuplicateRoute: getBillsDuplicateRoute,
    getEditBillRoute: getBillsEditBillRoute,
    getEditBulkRoute: getBillsEditBulkRoute,
    getEditBulkAttachmentsRoute: getBillsEditBulkAttachmentsRoute,
    getListEmptyRoute: getBillsListEmptyRoute,
    getNewBillRoute: getBillsNewBillRoute,
    getNewCreditNoteRoute: getBillsNewCreditNoteRoute,
    getNewBillFromAttachmentRoute: getBillsNewBillFromAttachmentRoute,
    getNewBillFromContactRoute: getBillsNewBillFromContactRoute,
    getViewBillRoute: getBillsViewBillRoute,
  },
  creditors: {
    getMainRoute: getCreditorsMainRoute,
  },
  dashboard: {
    getMainRoute: getDashboardMainRoute,
  },
  debtors: {
    getMainRoute: getDebtorsMainRoute,
  },
  findAccountant: {
    getMainRoute: getFindAccountantMainRoute,
  },
  invoices: {
    getMainRoute: getInvoicesMainRoute,
    getListEmptyRoute: getInvoicesListEmpty,
    getNewInvoiceRoute: getInvoicesNewInvoiceRoute,
    getViewInvoiceRoute: getInvoicesViewInvoiceRoute,
  },
  thingsToDo: {
    getMainRoute: getThingsToDoMainRoute,
  },
  uploads: {
    getMainRoute: getUploadsMainRoute,
  },
  salary: {
    getMainRoute: getSalaryMainRoute,
  },
}
