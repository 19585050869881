import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

export const AttachmentPreviewWrapper = styled.div`
  height: 77vh;
  flex-grow: 1;
  border-radius: ${BorderRadius.L};
  margin-right: ${Spacing.M};
  background-color: ${Color.DayGrey};
  border: 1px solid ${Color.Gray30};
  display: flex;
  justify-content: center;
`
