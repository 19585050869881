import { LocaleIdentifier } from '../types/localeIdentifier'

export const localeCodeToLocaleIdentifier = (code: string | undefined): LocaleIdentifier => {
  if (code?.includes('da')) {
    return 'da_DK'
  }
  if (code?.includes('fr')) {
    return 'fr_FR'
  }

  return 'en_US'
}
