import { DEFAULT_CURRENCY } from '../../constants/defaultCurrency'
import { InvoiceForm } from '../../types/invoiceForm'
import { InvoicePayloadLine } from '../../types/invoicePayload/invoicePayloadLine'
import { getTaxAmount } from '../getTaxAmount'
import { NumberValue } from '../NumberValue'

export const getLinesPayload = (formValues: InvoiceForm): InvoicePayloadLine[] => {
  return formValues.invoiceLines.map((line) => {
    const {
      date,
      productName,
      productType,
      quantity,
      totalExcludingVat,
      totalIncludingVat,
      unit,
      unitPrice,
      vatRatePercent,
      vatRateId,
    } = line

    const grossTotal = new NumberValue(totalIncludingVat).getRounded()
    const netTotal = new NumberValue(totalExcludingVat).getRounded()
    const taxTotal = getTaxAmount(totalExcludingVat, vatRatePercent)

    return {
      currency: formValues?.currency || DEFAULT_CURRENCY,
      date: date.toISOString(),
      description: productName,
      discount: 0,
      grossTotal,
      netTotal,
      productType,
      quantity,
      taxTotal,
      type: 'row',
      unit,
      unitPrice,
      vatPct: vatRatePercent,
      vatRateId,
    }
  })
}
