import { NavItem, NavItemAccessor } from '@design-system'

import { TFunction } from 'i18next'
import reduce from 'lodash/reduce'

import { TaxRate, TaxRateGroup } from '@modules-deprecated/app/taxrates/types'

import { LocaleIdentifier } from '../../../../types/localeIdentifier'
import { TaxRateValue } from '../types/taxRateValue'
import { getDisplayTitle } from './getDisplayTitle'
import { groupTaxRates } from './groupTaxRates'

const groupHeadings: Record<TaxRateGroup, string> = {
  local: 'app.taxrates.taxrate_selector.groups.local',
  eu: 'app.taxrates.taxrate_selector.groups.eu',
  world: 'app.taxrates.taxrate_selector.groups.world',
  null: 'app.taxrates.taxrate_selector.groups.other',
}

interface GetItemOptions {
  accessorGroupId?: string
  hasShortItemTitle: boolean
  locale: LocaleIdentifier
  taxRate: TaxRate
}

const getItem = ({ accessorGroupId, hasShortItemTitle, locale, taxRate }: GetItemOptions): NavItem<TaxRateValue> => {
  const displayTitle = getDisplayTitle({ taxRate, isShortTitle: hasShortItemTitle, locale })
  return {
    id: taxRate.id,
    accessorGroupId,
    children: taxRate.name,
    searchKeywords: [displayTitle],
    value: {
      ...taxRate,
      displayData: {
        title: displayTitle,
        description: taxRate.description,
      },
    },
  }
}

interface GetItemsOptions {
  hasShortItemTitle: boolean
  locale: LocaleIdentifier
  t: TFunction
  taxRates: TaxRate[]
}

export const getItems = ({ hasShortItemTitle, locale, t, taxRates }: GetItemsOptions): NavItem<TaxRateValue>[] => {
  const taxRatesGroupped = groupTaxRates(taxRates)

  return reduce(
    taxRatesGroupped,
    (result, taxRates, groupKey) => {
      const accessorId = `header-${groupHeadings[groupKey]}`
      const items = taxRates?.map((taxRate) =>
        getItem({ taxRate, accessorGroupId: accessorId, hasShortItemTitle, locale }),
      )
      const resultUpdated: NavItem<TaxRateValue>[] = [...result]

      resultUpdated.push({
        id: accessorId,
        accessor: NavItemAccessor.Header,
        children: t(groupHeadings[groupKey] || groupHeadings.null),
        value: {} as TaxRateValue,
      })

      items && resultUpdated.push(...items)
      return resultUpdated
    },
    [] as NavItem<TaxRateValue>[],
  )
}
