import { Route } from '@components'

import React, { ReactElement, useMemo } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { useSignupGuide } from './context/signupGuideContext'
import { CreateOrganization } from './elements/CreateOrganization'
import { CreateUser } from './elements/CreateUser'
import { GuidePath } from './enums/guide-path'

export const SignupGuide = (): ReactElement => {
  const { user, routeUmbrellaId } = useSignupGuide()

  const initialPath = useMemo(() => {
    if (routeUmbrellaId) {
      return `${routeUmbrellaId}/${GuidePath.CreateOrganization}`
    }

    return user ? GuidePath.CreateOrganization : GuidePath.CreateUser
  }, [routeUmbrellaId, user])

  return (
    <Switch>
      <Route isHashRouter exact path={`/${GuidePath.CreateUser}`}>
        <CreateUser />
      </Route>
      <Route
        isHashRouter
        exact
        path={[`/${GuidePath.CreateOrganization}`, `/:umbrellaId/${GuidePath.CreateOrganization}`]}
      >
        <CreateOrganization />
      </Route>
      <Route isHashRouter>
        <Redirect to={`/${initialPath}`} />
      </Route>
    </Switch>
  )
}
