import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '@modules-deprecated/app/organization'
import { useRejectReceipt } from '@views/receipts/hooks/useRejectReceipt'

import { useForm } from '../../../hooks'
import { getDefaultValues, getValidationSchema, RejectReceiptForm } from '../utils/formData'

export interface UseRejectReceiptFormProps {
  attachmentId?: string
  onSuccess: () => void
}

export const useRejectReceiptForm = ({ attachmentId, onSuccess }: UseRejectReceiptFormProps) => {
  const { t } = useTranslation()
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { reject, isProcessing } = useRejectReceipt({
    onSuccess,
  })
  const { Form, FormItem, handleSubmit, reset } = useForm({
    defaultValues: useMemo(() => getDefaultValues(), []),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (!isOrganizationLoading) {
      reset(getDefaultValues())
    }
  }, [isOrganizationLoading, reset])

  const handleSubmitForm = useCallback(
    (values: RejectReceiptForm) => {
      const { reason } = values
      reject({
        organizationId: organization?.id,
        attachmentId,
        reason,
      })
    },
    // Warning disabled during the eslint warning cleanup. When refactoring this code fix this properly if possible.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return {
    Form,
    FormItem,
    submitForm,
    isProcessing,
    isOrganizationLoading,
  }
}
