import { createContext, ReactElement, ReactNode, useContext } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchOrganization } from './query-api'
import { Organization } from './types/organization'

interface ContextState {
  isLoading: boolean
  organization: Organization | undefined
}

const defaultValue: ContextState = {
  isLoading: true,
  organization: undefined,
}

const UserOrganizationContext = createContext<ContextState | undefined>(undefined)

interface UserOrganizationContextProps {
  children?: ReactNode
  organizationId?: string
  enabled?: boolean
}

export const UserOrganizationContextProvider = ({
  children,
  enabled = true,
  organizationId = '',
}: UserOrganizationContextProps): ReactElement | null => {
  const { data, isLoading } = useQuery(
    [QueryKeys.UserOrganization, organizationId],
    () => fetchOrganization(organizationId),
    {
      enabled: !!organizationId && enabled,
    },
  )

  return (
    <UserOrganizationContext.Provider value={{ isLoading, organization: data?.organization }}>
      {children}
    </UserOrganizationContext.Provider>
  )
}

// no context protection here on purpose - we don't know if we are in organization or umbrella view (TBD)
export const useUserOrganization = () => {
  const context = useContext(UserOrganizationContext)

  if (!context) {
    // we would usually throw an error here, but we had some hard to fix issues with that
    // so we are just logging a warning
    console.warn('UserOrganizationContextProvider is missing in the module!')
    return defaultValue
  }

  return context
}
