import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { InvoiceForm } from '../types/invoiceForm'
import { useApproveInvoiceDraft } from './useApproveInvoiceDraft'
import { useCreateInvoiceDraft } from './useCreateInvoiceDraft'
import { useInvoiceDraftPayload } from './useInvoiceDraftPayload'

interface CreateAndApproveInvoiceOptions {
  formData: InvoiceForm
  onError?: () => void
  onSuccess?: (approvedInvoiceId: number) => void
}

export const useCreateAndApproveInvoice = () => {
  const { t } = useTranslation()

  const { getInvoiceDraftPayload, isLoading: isLoadingDraftPayload } = useInvoiceDraftPayload()
  const { createInvoiceDraft, isProcessing: isProcessingCreateInvoiceDraft } = useCreateInvoiceDraft()
  const { approveInvoiceDraft, isProcessing: isProcessingApproveInvoiceDraft } = useApproveInvoiceDraft()

  const isProcessing = isLoadingDraftPayload || isProcessingCreateInvoiceDraft || isProcessingApproveInvoiceDraft

  const createAndApproveInvoice = useCallback(
    ({ formData, onError, onSuccess }: CreateAndApproveInvoiceOptions) => {
      const invoiceDraftPayload = getInvoiceDraftPayload(formData)

      createInvoiceDraft(invoiceDraftPayload, {
        onError,
        onSuccess: (data) => {
          const draftId = data?.data?.id

          approveInvoiceDraft(draftId, {
            onError: () => {
              notify({
                id: NotificationKeys.ExternalInvoiceApproved,
                message: t('error_saving'),
                variant: 'error',
              })
              onError?.()
            },
            onSuccess: (approvedInvoiceResponse) => {
              const approvedInvoiceId = approvedInvoiceResponse?.data[0]?.id

              onSuccess?.(approvedInvoiceId)
            },
          })
        },
      })
    },
    [approveInvoiceDraft, createInvoiceDraft, getInvoiceDraftPayload, t],
  )

  return { createAndApproveInvoice, isProcessing }
}
