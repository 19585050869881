import { Route } from '@components'

import React, { ReactElement } from 'react'
import { Switch } from 'react-router-dom'

import { withRouteLinkClickedListener } from '../../hocs/withRouteLinkClickedListener'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { TransactionRoute } from './enums/transactionRoute'
import { TransactionsList } from './routes/TransactionsList'
import { defaultTransactionsQuery } from './routes/TransactionsList/hooks/useTransactionsListFilters'
import { TransactionsListEmpty } from './routes/TransactionsListEmpty'
import { emberQueryParamsParser } from './utils/emberQueryParamsParser'

export const Transactions = withRouteLinkClickedListener((): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultTransactionsQuery),
    queryParamsParser: emberQueryParamsParser,
  })

  return (
    <Switch>
      <Route isHashRouter component={TransactionsList} exact path={TransactionRoute.List} />
      <Route isHashRouter component={TransactionsListEmpty} exact path={TransactionRoute.ListEmpty} />
    </Switch>
  )
})
