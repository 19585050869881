import { Button, ButtonsGroup, Checkbox, Modal, Space, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { useLocale } from '../../../../hooks/useLocale'
import { formatDate } from '../../../../utils'
import * as Styled from './styles'

interface InvoiceDateModalProps {
  entryDate: string
  onModalClose?: () => void
  onApproveInvoice?: (shouldApproveWithTodaysDate: boolean, shouldNeverShowAgain: boolean) => void
}

export const InvoiceDateModal = ({
  entryDate,
  onModalClose,
  onApproveInvoice,
}: InvoiceDateModalProps): ReactElement => {
  const [shouldNeverShowAgain, setShouldNeverShowAgain] = useState(false)
  const { t } = useTranslation()
  const { code } = useLocale()

  const handleModalClose = useCallback(() => {
    onModalClose?.()
  }, [onModalClose])

  const { close } = useModal(ModalId.InvoiceDateModal, { onClose: handleModalClose })

  const dateFormat = code === 'da' ? 'dd-MM-yyyy' : 'MM/dd/yyyy'
  const formattedEntryDate = formatDate(new Date(entryDate), dateFormat)
  const formattedTodaysDate = formatDate(new Date(), dateFormat)

  const handleCheckboxChange = useCallback(() => {
    setShouldNeverShowAgain((prevState) => !prevState)
  }, [])

  const handleApproveInvoiceButtonClick = useCallback(
    (shouldApproveWithTodaysDate: boolean) => {
      if (onApproveInvoice) {
        onApproveInvoice(shouldApproveWithTodaysDate, shouldNeverShowAgain)
      }
      close()
    },
    [close, onApproveInvoice, shouldNeverShowAgain],
  )

  const handleApproveWithOldDateClick = useCallback(
    () => handleApproveInvoiceButtonClick(false),
    [handleApproveInvoiceButtonClick],
  )
  const handleApproveWithTodaysDateClick = useCallback(
    () => handleApproveInvoiceButtonClick(true),
    [handleApproveInvoiceButtonClick],
  )

  return (
    <Modal id={ModalId.InvoiceDateModal} size="s" forceRender closable>
      <Modal.Header title={t('invoice_date_modal.title')} />
      <Modal.Body>
        <Text>{t('invoice_date_modal.paragraph.first', { todaysDate: formattedTodaysDate })}</Text>
        <Space size="l" />
        <Text>{t('invoice_date_modal.paragraph.second', { entryDate: formattedEntryDate })}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Styled.FooterContentWrapper>
          <Checkbox checked={shouldNeverShowAgain} onChange={handleCheckboxChange}>
            {t('invoice_date_modal.dismiss_checkbox')}
          </Checkbox>
          <ButtonsGroup>
            <Button variant="text" onClick={handleApproveWithOldDateClick}>
              {t('invoice_date_modal.approve_with_old_date', { entryDate: formattedEntryDate })}
            </Button>
            <Button onClick={handleApproveWithTodaysDateClick}>
              {t('invoice_date_modal.approve_with_todays_date', { todaysDate: formattedTodaysDate })}
            </Button>
          </ButtonsGroup>
        </Styled.FooterContentWrapper>
      </Modal.Footer>
    </Modal>
  )
}
