import { amountToDisplayValue, Flex } from '@design-system'

import cc from 'classcat'
import React, { useMemo } from 'react'

import { reactClass } from '../../utils'
import * as Styled from './styles'
import { Placement, SummaryItem } from './types'

export type FinancialSummaryProps = {
  borderColor?: string
  className?: string
  items: SummaryItem[]
  placement?: Placement
}

export const FinancialSummary = ({ className, items, placement = 'left' }: FinancialSummaryProps) => {
  const flexPlacement = useMemo(() => (placement === 'left' ? 'flex-start' : 'flex-end'), [placement])

  return (
    <Flex className={cc([className, reactClass('financial-summary')])} justifyContent={flexPlacement}>
      <Styled.SummaryContainer>
        <ul>
          {items.map((item, index) => {
            const { text, value } = item
            const isLastItem = index === items.length - 1

            return (
              <Styled.SummaryLine key={text} withBorder={isLastItem}>
                <Styled.LineHeader>{text}</Styled.LineHeader>
                <Styled.LineValue>{amountToDisplayValue(value)}</Styled.LineValue>
              </Styled.SummaryLine>
            )
          })}
        </ul>
      </Styled.SummaryContainer>
    </Flex>
  )
}
