import { isBefore } from 'date-fns'

import { BankLine } from '@modules-deprecated/bankReconciliation/types'

import { Side } from '../../../../../enums/side'
import { BankLinesSortingProperty } from '../elements/BankLinesModalBody/elements/BankLinesActions/types/bankLinesSortingProperty'

interface FilterAndSortBankLinesOptions {
  bankLines?: BankLine[]
  billsAmount?: number
  period?: string
  searchQuery?: string
  sortingProperty?: BankLinesSortingProperty
  side?: Side
}

export const filterAndSortBankLines = ({
  bankLines,
  billsAmount,
  searchQuery,
  sortingProperty,
  side,
}: FilterAndSortBankLinesOptions) => {
  if (!bankLines) {
    return []
  }

  const creditBankLines = bankLines.filter((bankLine) => bankLine?.side === side)

  const bankLinesFilteredBySearchQuery = creditBankLines?.filter((bankLine) => {
    if (!searchQuery) {
      return true
    }

    const bankLineAmountIncludesSearchQuery = bankLine?.amount.toString()?.includes(searchQuery)
    const bankLineDescriptionIncludesSearchQuery = bankLine?.description
      ?.toLowerCase()
      .includes(searchQuery.toLowerCase())

    return bankLineAmountIncludesSearchQuery || bankLineDescriptionIncludesSearchQuery
  })

  const bankLinesFilteredAndSorted = [...bankLinesFilteredBySearchQuery].sort((bankLineA, bankLineB) => {
    const bankLineAEntryDate = new Date(bankLineA?.entryDate)
    const bankLineBEntryDate = new Date(bankLineB?.entryDate)

    switch (sortingProperty) {
      case BankLinesSortingProperty.AmountDifference:
        return billsAmount ? Math.abs(bankLineA?.amount - billsAmount) - Math.abs(bankLineB?.amount - billsAmount) : 0
      case BankLinesSortingProperty.AmountAsc:
        return bankLineA?.amount - bankLineB?.amount
      case BankLinesSortingProperty.AmountDesc:
        return bankLineB?.amount - bankLineA?.amount
      case BankLinesSortingProperty.DateAsc:
        return isBefore(bankLineAEntryDate, bankLineBEntryDate) ? -1 : 1
      case BankLinesSortingProperty.DateDesc:
        return isBefore(bankLineAEntryDate, bankLineBEntryDate) ? 1 : -1
      default:
        return 0
    }
  })

  return bankLinesFilteredAndSorted
}
