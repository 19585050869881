import { FormItem, Text } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { InvoiceLineColumn } from '../../../../../../../../../../../../types/invoiceLineColumn'

type HeaderLabels = {
  [key in InvoiceLineColumn]: string
}

interface InvoiceLineColumnHeaderProps {
  column: InvoiceLineColumn
}

export const InvoiceLinesHeaderColumn = ({ column }: InvoiceLineColumnHeaderProps) => {
  const { t } = useTranslation()

  const headerLabels: Partial<HeaderLabels> = useMemo(
    () => ({
      description: t('external_invoices.editor.form.field.line.header.name'),
      productType: t('external_invoices.editor.form.field.line.header.type'),
      date: t('external_invoices.editor.form.field.line.header.date'),
      quantity: t('external_invoices.editor.form.field.line.header.quantity'),
      unit: t('external_invoices.editor.form.field.line.header.unit'),
      unitPrice: t('external_invoices.editor.form.field.line.header.unit_price'),
      vatPct: t('external_invoices.editor.form.field.line.header.vat'),
      total: t('external_invoices.editor.form.field.line.header.total_excluding_vat'),
      taxTotal: t('external_invoices.editor.form.field.line.header.total_including_vat'),
    }),
    [t],
  )

  return (
    <FormItem>
      <Text variant="small" weight="bold">
        {headerLabels[column] ? headerLabels[column] : null}
      </Text>
    </FormItem>
  )
}
