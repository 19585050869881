import download from 'downloadjs'
import omit from 'lodash/omit'
import React, { memo, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { trackError } from '../../../../../utils/trackError'
import { BorderRadius } from '../../../../enums/borderRadius'
import { Spacing } from '../../../../enums/spacing'
import { Direction, usePagination } from '../../../../hooks/usePagination'
import { NavigationDirection } from '../../../../types/navigationDirection'
import { SCROLLABLE_CONTAINER_CLASS } from '../../constants/scrollableContainerClass'
import { usePdfPreload } from '../../contexts/pdfPreloadContext'
import { useFloatingActionsVisibility } from '../../hooks/useFloatingActionsVisibility'
import { AspectRatio, FileType, FitType, FloatingActionsMenuProps, PreviewFile, RotatedFiles } from '../../types'
import { arePropsEqual } from '../../utils/arePropsEqual'
import { getFileType } from '../../utils/getFileType'
import { FileActions } from '../FileActions'
import { FileContent } from '../FileContent'
import { FileName } from '../FileName'
import { LazyPreviewOptions } from '../LazyPreview'
import { ZoomableWrapper } from '../withZoomableWrapper'
import * as Styled from './styles'

export type FilesPreviewContentProps = {
  actionsMenuItems?: (props: FloatingActionsMenuProps) => React.ReactNode
  aspectRatio?: AspectRatio
  asType?: FileType
  bordered?: boolean
  borderRadius?: BorderRadius
  circle?: boolean
  className: string
  files: PreviewFile[]
  fitToHeight?: boolean
  fitToWidth?: boolean
  fitType?: FitType
  isFloatingActionsView?: boolean
  lazyLoadOptions?: LazyPreviewOptions
  loading?: ReactElement | string
  margin?: Spacing
  navigationDirection?: NavigationDirection
  onClick?: () => void
  onDelete?: (file: PreviewFile) => void
  onEdit?: (file: File) => void
  onError?: () => void
  onImageZoomClick?: () => void
  onLoad?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onNavigation?: (index: number) => void
  width?: string
  withDeleteButton?: boolean
  withDownloadButton?: boolean
  withEditButton?: boolean
  withFilename?: boolean
  withLazyLoad?: boolean
  withModalZoom?: boolean
  withNavigation?: boolean
  withRotate?: boolean
  withShadow?: boolean
  zoomable?: boolean
  zoomScale?: number
}

const ROTATION_DEFAULT = 0
const ROTATION_STEP = 90
const ROTATION_FULL = 360

export const FilesPreviewContent = memo((props: FilesPreviewContentProps): ReactElement | null => {
  const {
    actionsMenuItems,
    aspectRatio,
    asType,
    bordered,
    borderRadius,
    className,
    files,
    fitToHeight = false,
    fitToWidth = false,
    isFloatingActionsView = false,
    lazyLoadOptions,
    loading,
    margin,
    navigationDirection = 'horizontal',
    onDelete,
    onEdit,
    onError,
    onImageZoomClick,
    onLoad,
    onNavigation,
    withDeleteButton,
    withDownloadButton,
    withFilename,
    withEditButton = false,
    withModalZoom = false,
    withNavigation = true,
    withRotate = false,
    withShadow,
    zoomable = false,
    ...rest
  } = props

  const {
    activePage: activeFilePage,
    setPage: setActiveFilePage,
    canChangePage: canChangeFilePage,
    changePage: changeFilePage,
    setPagesCount: setFilePagesCount,
  } = usePagination()
  const {
    activePage: activePdfPage,
    canChangePage: canChangePdfPage,
    changePage: changePdfPage,
    isFirstPage: isFirstPdfPage,
    isLastPage: isLastPdfPage,
    pagesCount: pdfPagesCount,
    setPage: setPdfPage,
    setPagesCount: setPdfPagesCount,
  } = usePagination()
  const containerRef = useRef<HTMLDivElement>(null)
  const [isLoading, setLoading] = useState(true)
  const [rotatedFiles, setRotatedFiles] = useState<RotatedFiles>()
  const { isLoading: isPreloadingPdf, filesPages, totalPageCount } = usePdfPreload()

  // Floating actions panel position is determined based on the scrolling position within the scrollable container
  // If there's no scrollable container, the position is "absolute" based on the component
  const scrollableParentContainer = containerRef?.current?.closest(SCROLLABLE_CONTAINER_CLASS)

  const isContainerFullyVisible = useFloatingActionsVisibility({
    containerRef,
    scrollableParentContainer,
    isEnabled: isFloatingActionsView,
  })

  if (!fitToHeight && !fitToWidth) {
    trackError('You have to define fit mode!')
  }

  useEffect(() => {
    setFilePagesCount(files.length)

    if (files.length) {
      if (!files[activeFilePage]) {
        changeFilePage(activeFilePage > 0 ? Direction.Back : Direction.Next)
      } else {
        setActiveFilePage(files.length - 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const activeFile = files[activeFilePage] || files[0]
  const activeFileType = asType || getFileType(activeFile?.src)
  const isPdf = activeFileType === 'pdf'
  const showFileName = withFilename && !isLoading

  const shouldRenderNavigation = withNavigation && totalPageCount > 1 && !isLoading
  const shouldRenderActions = withNavigation || withRotate || withModalZoom

  const activePageNumber = useMemo(() => {
    if (!filesPages || filesPages.size === 0 || isPreloadingPdf || isLoading) {
      return 0
    }

    const currentPageInFile = isPdf ? activePdfPage : 0
    let pagesBeforeCurrentFile = 0

    for (const [fileId, numPages] of filesPages?.entries()) {
      if (fileId === activeFile.id || fileId === activeFile.src) {
        break
      }
      pagesBeforeCurrentFile += numPages
    }

    const currentPage = pagesBeforeCurrentFile + currentPageInFile + 1
    setLoading(false)
    return currentPage
  }, [activeFile.id, activeFile.src, activePdfPage, filesPages, isLoading, isPdf, isPreloadingPdf])

  const shouldHandleFilePage = useCallback(
    (direction: Direction) =>
      activeFileType === FileType.Image ||
      (isLastPdfPage() && direction === Direction.Next) ||
      (isFirstPdfPage() && direction === Direction.Back),
    [activeFileType, isFirstPdfPage, isLastPdfPage],
  )

  const handleNavButtonClick = useCallback(
    (direction: Direction) => {
      if (shouldHandleFilePage(direction)) {
        setLoading(true)
        const newPage = changeFilePage(direction)

        /* when we move between files, if the navigation direction is back, we should open the last (or only) page of the previous file */
        direction === Direction.Back ? setPdfPage(pdfPagesCount - 1) : setPdfPage(0)
        onNavigation?.(newPage)
        return
      }

      changePdfPage(direction)
    },
    [changeFilePage, changePdfPage, onNavigation, pdfPagesCount, setPdfPage, shouldHandleFilePage],
  )

  const canChangePage = useCallback(
    (direction: Direction) => {
      if (shouldHandleFilePage(direction)) {
        return canChangeFilePage(direction)
      }

      return canChangePdfPage(direction)
    },
    [canChangeFilePage, canChangePdfPage, shouldHandleFilePage],
  )

  const onPdfLoad = useCallback(
    (pagesCount: number) => {
      setPdfPagesCount(pagesCount)

      const lastPage = pagesCount - 1
      setPdfPage(activePdfPage > lastPage ? lastPage : activePdfPage)
    },
    [activePdfPage, setPdfPage, setPdfPagesCount],
  )

  const handleLoad = useCallback(() => {
    setLoading(false)
    onLoad?.()
  }, [onLoad])

  const handleDownloadClick = useCallback(() => {
    const src = activeFile.srcDownload || activeFile.srcZoom || activeFile.src
    const url = new URL(src)
    let href = url.href

    // force get the src with different url to omit cached one
    href += url.search ? '&' : '?'

    download(href)
  }, [activeFile])

  const changeRotation = useCallback(
    (src: string, rotation: number) => {
      const value = rotation + ROTATION_STEP

      if (value === ROTATION_FULL && rotatedFiles?.[src]) {
        setRotatedFiles(omit(rotatedFiles, src))
      } else {
        setRotatedFiles({
          ...rotatedFiles,
          [src]: value,
        })
      }
    },
    [rotatedFiles],
  )

  const handleRotateClick = useCallback(() => {
    const { src } = activeFile
    const rotation = rotatedFiles?.[src] || ROTATION_DEFAULT

    changeRotation(src, rotation)
  }, [activeFile, changeRotation, rotatedFiles])

  const handleDeleteClick = useCallback(() => {
    onDelete?.(activeFile)
  }, [activeFile, onDelete])

  const handleFileSelect = useCallback(
    (files: File[]) => {
      onEdit?.(files[0])
    },
    [onEdit],
  )

  if (!files.length || !activeFile) {
    return null
  }

  const Actions = shouldRenderActions && (
    <FileActions
      actionsMenuItems={actionsMenuItems}
      activePageNumber={activePageNumber || 0}
      canChangePage={canChangePage}
      handleChangePage={handleNavButtonClick}
      handleDeleteClick={handleDeleteClick}
      handleDownloadClick={handleDownloadClick}
      handleFileSelect={handleFileSelect}
      handleRotateClick={handleRotateClick}
      isInView={isContainerFullyVisible}
      isFloatingActionsView={isFloatingActionsView}
      isLoading={isLoading || isPreloadingPdf}
      navigationDirection={navigationDirection}
      onImageZoomClick={onImageZoomClick}
      pagesAmount={totalPageCount}
      shouldRenderNavigation={shouldRenderNavigation}
      withDeleteButton={withDeleteButton}
      withDownloadButton={withDownloadButton}
      withEditButton={withEditButton}
      withModalZoom={withModalZoom}
      withRotate={withRotate}
      zoomable={zoomable}
    />
  )

  return (
    <Styled.SectionWrapper
      as="section"
      bordered={bordered}
      borderRadius={borderRadius}
      key={activeFile.src}
      ref={containerRef as React.Ref<HTMLDivElement>}
      {...rest}
    >
      <ZoomableWrapper
        actionsComponent={Actions}
        zoomable={zoomable}
        fitToWidth={fitToWidth}
        isLoading={isLoading}
        aspectRatio={aspectRatio}
      >
        <FileContent
          actionsComponent={Actions}
          activeFileType={activeFileType}
          activePdfPage={activePdfPage}
          file={activeFile}
          handleLoad={handleLoad}
          onPdfLoad={onPdfLoad}
          rotatedFiles={rotatedFiles}
          {...props}
        />
      </ZoomableWrapper>
      {showFileName && <FileName file={activeFile.src} />}
    </Styled.SectionWrapper>
  )
}, arePropsEqual)
