import { Spacing } from '@design-system'

import styled from '@emotion/styled'

interface PrePaymentModalBodyColumnProps {
  flex: number
}

export const PrePaymentModalBodyContainer = styled.div`
  display: flex;
  gap: ${Spacing.XL};
  width: 100%;
`

export const PrePaymentModalBodyColumn = styled.div<PrePaymentModalBodyColumnProps>`
  flex: ${({ flex }) => flex};
`
