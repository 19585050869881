import { ReactNode } from 'react'

import { Currency } from '../../../../../../types/currency'
import { DEFAULT_FORMATTER_LOCALE } from '../../../../constants/defaultFormatterLocale'
import { NumberFormat } from '../../../../utils/NumberFormat'
import { FormattedNumberProps } from '../../FormattedNumber'
import { FormattedNumberElement } from '../FormattedNumberElement'

export interface FormattedCurrencyNumberProps extends FormattedNumberProps {
  currency: Currency
}

export const FormattedCurrencyNumber = ({
  as = 'span',
  asString = false,
  currency,
  locale = DEFAULT_FORMATTER_LOCALE,
  value,
}: FormattedCurrencyNumberProps): ReactNode => {
  return (
    <FormattedNumberElement as={as} asString={asString}>
      {NumberFormat.getCurrencyNumber(value, currency, locale)}
    </FormattedNumberElement>
  )
}
