import { ExportButtonDropdown, ExportType } from '@components'

import qs from 'qs'
import React, { ReactElement, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { PeriodMode } from '../../../../../../../enums/periodMode'
import { useDownloadExport } from '../../../../../../../hooks/useDownloadExport'
import { apiUrl, getAccessToken, getCurrentLocale } from '../../../../../../../utils'
import { useAccountStatementsFilters } from '../../../hooks/useAccountStatementsFilters'
import { ChartOfAccountsUrlParams } from '../../../types/chartOfAccountsUrlParams'

export const ExportAccountStatement = (): ReactElement => {
  const { accountId } = useParams<ChartOfAccountsUrlParams>()
  const [{ sortDirection, withVoided, period }] = useAccountStatementsFilters()
  const { download } = useDownloadExport()

  const exportStatement = useCallback(
    (type: ExportType) => {
      if (!accountId) {
        return
      }

      const queryParams = qs.stringify({
        accessToken: getAccessToken(),
        acceptLanguage: getCurrentLocale(),
        includeVoided: withVoided,
        sortDirection,
        ...(period && period !== PeriodMode.All ? { period } : {}),
      })

      const downloadUrl = `${apiUrl}/v2/accounts/${accountId}/statement.${type}?${queryParams}`
      download(downloadUrl)
    },
    [accountId, download, period, sortDirection, withVoided],
  )

  const handleSelect = useCallback(
    (_: string, value: ExportType) => {
      exportStatement(value)
    },
    [exportStatement],
  )

  return <ExportButtonDropdown exportTypesToSkip={[ExportType.RegnskabBasis]} onSelect={handleSelect} />
}
