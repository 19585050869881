import { LocaleIdentifier } from '../../../types/localeIdentifier'

export const getDateFormat = (inputValue: string, locale: LocaleIdentifier) => {
  const isUSLocale = locale === 'en_US'
  const dateInputFormatShort = isUSLocale ? 'MMdd' : 'ddMM'
  const dateInputFormatSemi = isUSLocale ? 'yyMMdd' : 'ddMMyy'
  const dateInputFormatFull = isUSLocale ? 'MMddyyyy' : 'ddMMyyyy'

  if (inputValue.length === dateInputFormatShort.length) {
    return dateInputFormatShort
  }

  if (inputValue.length === dateInputFormatSemi.length) {
    return dateInputFormatSemi
  }

  return dateInputFormatFull
}
