import { useAsync } from 'react-use'

import { useAnonymousId } from '../../../hooks/useAnonymousId'
import { useSegment } from '../../../hooks/useSegment'
import { trackError } from '../../../utils/trackError'

const TIMEOUT_MS = 1000

export const useTrackEventOnLoad = (eventName: string, eventMeta?: Record<any, any>, anonymousId?: string) => {
  const { trackRaw, waitForInitialization } = useSegment()
  const { setAnonymousId } = useAnonymousId()

  useAsync(async () => {
    try {
      await waitForInitialization()
      setAnonymousId(anonymousId)

      setTimeout(() => {
        trackRaw(eventName, eventMeta)
      }, TIMEOUT_MS)
    } catch (error) {
      trackError(error)
    }
  }, [])
}
