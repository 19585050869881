import { notifyProgress, NotifyProgressProps, Timeout } from '@design-system'

import download from 'downloadjs'
import { TFunction } from 'i18next'
import round from 'lodash/round'

import { getRequest } from '../../../utils'
import { trackError } from '../../../utils/trackError'
import { DownloadStatus } from '../types/downloadStatus'
import { downloadStatusToProgressVariant } from './downloadStatusToProgressVariant'
import { downloadStatusToTKeys } from './downloadStatusToTKeys'

const MAX_TIMEOUT = 45000
const PROGRESS_TIMER_INTERVAL = 1000
const DEFAULT_PROGRESS = 0
const DEFAULT_STATUS: DownloadStatus = 'pending'

const processingUrls = new Set<string>()

export class DownloadExport {
  private progress = DEFAULT_PROGRESS // [0 - 1]
  private status = DEFAULT_STATUS

  private t: TFunction
  private url: string
  private filename?: string
  private progressInterval: Timeout
  private xhrInstance?: XMLHttpRequest

  constructor(url: string, t: TFunction, filename?: string) {
    this.url = url
    this.t = t
    this.filename = filename
  }

  // State updates

  start = () => {
    this.progress = 0
    this.status = 'processing'
    this.updateNotification()
    processingUrls.add(this.url)
  }

  finish = (status: DownloadStatus) => {
    this.progress = 1
    this.status = status
    this.updateNotification()
    this.removeEventListeners()
    processingUrls.delete(this.url)
  }

  reset = () => {
    this.progress = DEFAULT_PROGRESS
    this.status = DEFAULT_STATUS
    this.updateNotification()
    this.removeEventListeners()
    processingUrls.delete(this.url)
  }

  setProgress = (progress: number) => {
    this.progress = progress
    this.updateNotification()
  }

  // Events listeners

  addEventListeners = () => {
    if (this.xhrInstance) {
      this.xhrInstance.addEventListener('load', this.handleLoaded)
      this.xhrInstance.addEventListener('timeout', this.handleTimeout)
      this.xhrInstance.addEventListener('error', this.handleError)
    }
  }

  removeEventListeners = () => {
    if (this.xhrInstance) {
      this.xhrInstance.removeEventListener('load', this.handleLoaded)
      this.xhrInstance.removeEventListener('timeout', this.handleTimeout)
      this.xhrInstance.removeEventListener('error', this.handleError)
    }
  }

  // Events handlers

  handleLoaded = () => {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }

    this.finish('success')
  }

  handleTimeout = async () => {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }

    try {
      await this.sendExportInEmail()
      this.finish('timeout')
    } catch (error) {
      trackError(error)
      this.finish('error')
    }
  }

  handleError = () => {
    if (this.progressInterval) {
      clearInterval(this.progressInterval)
    }

    this.finish('error')
  }

  // Notification handlers

  showNotification = () => {
    notifyProgress(this.getNotificationPayload())
  }

  updateNotification = () => {
    if (notifyProgress.isActive(this.url)) {
      notifyProgress.update(this.getNotificationPayload())
    }
  }

  handleNotificationClose = () => {
    setTimeout(() => {
      this.reset()
    }, 1000) // notification animation (@todo better handle that)
  }

  // Helpers

  getNotificationPayload = (): NotifyProgressProps => {
    const tKeys = downloadStatusToTKeys[this.status]

    return {
      closable: this.status !== 'processing',
      description: this.t(tKeys.description),
      id: this.url,
      onClose: this.handleNotificationClose,
      progress: this.progress * 100, // convert [0 - 1] to [0 - 100]
      title: this.t(tKeys.title),
      variant: downloadStatusToProgressVariant[this.status],
    }
  }

  sendExportInEmail = () => {
    return new Promise((resolve, reject) => {
      const requestEmailSend = async () => {
        if (!this.xhrInstance) {
          return
        }

        this.xhrInstance.abort()

        try {
          const url = new URL(this.url)
          url.searchParams.append('sendEmail', 'true')
          await getRequest(url.href)
          resolve(undefined)
        } catch (error) {
          reject(error)
        }
      }

      requestEmailSend()
    })
  }

  isProcessing = () => {
    return processingUrls.has(this.url)
  }

  // Main

  showProgressNotification = () => {
    if (!this.xhrInstance) {
      return
    }

    this.start()
    this.showNotification()
    this.addEventListeners()

    // Simulate download progress:
    this.progressInterval = setInterval(() => {
      // @todo think about better simulating progress so it starts in the middle
      const newProgress = round(this.progress + PROGRESS_TIMER_INTERVAL / MAX_TIMEOUT, 2)

      if (this.progressInterval && newProgress >= 1) {
        clearInterval(this.progressInterval)
        this.setProgress(1)
      } else {
        this.setProgress(newProgress)
      }
    }, PROGRESS_TIMER_INTERVAL)
  }

  init = () => {
    if (this.isProcessing()) {
      return
    }

    const xhr = download(this.url, this.filename)

    if (xhr instanceof XMLHttpRequest) {
      this.xhrInstance = xhr
      this.xhrInstance.timeout = MAX_TIMEOUT
      this.showProgressNotification()
      return
    }

    return xhr
  }
}
