import { Contact } from '@views/contacts/types/contact'

import { ContactForm } from './formData'
import { getCreateSupplierPayload } from './getCreateSupplierPayload'

export const getCreateContactPayload = (
  contactForm: ContactForm,
  isSupplier: boolean,
  organizationId: string,
): Partial<Contact> => (isSupplier ? getCreateSupplierPayload(contactForm, organizationId) : {})
