import isEqual from 'lodash/isEqual'
import { QueryClient, setLogger } from 'react-query'

import { trackError } from '../utils/trackError'

setLogger({
  // eslint-disable-next-line no-console
  log: console.log,
  warn: console.warn,
  error: (error) => {
    if (error.silent !== true) {
      trackError(error)
    }
  },
})

export const defaultStaleTime = 1000 * 60 // 60 seconds
export const defaultCacheTime = 5 * 60 * 1000 // 5 minutes

export const queryClientDefaultOptions = {
  queries: {
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    isDataEqual: (oldData: any, newData: any) => {
      if (oldData?.meta?.time !== undefined) {
        delete oldData.meta.time
      }

      if (newData?.meta?.time !== undefined) {
        delete newData.meta.time
      }

      return isEqual(oldData, newData)
    },
  },
}

export const queryClient = new QueryClient({
  defaultOptions: queryClientDefaultOptions,
})
