import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface BillAttachmentPreviewProps {
  children?: ReactNode
}

export const BillAttachmentPreview = ({ children }: BillAttachmentPreviewProps): ReactElement => (
  <Styled.BillAttachmentPreviewWrapper>
    <Styled.BillAttachmentWrapper>{children}</Styled.BillAttachmentWrapper>
  </Styled.BillAttachmentPreviewWrapper>
)
