import { NumberFormat } from '@design-system'

import { TaxRate } from '@modules-deprecated/app/taxrates/types'

import { LocaleIdentifier } from '../../../../types/localeIdentifier'

interface GetDisplayTitleOptions {
  isShortTitle?: boolean
  locale: LocaleIdentifier
  taxRate: TaxRate
}

export const getDisplayTitle = ({ isShortTitle, locale, taxRate }: GetDisplayTitleOptions): string => {
  const taxRateName = taxRate.name
  const taxRatePercent = NumberFormat.getPercentNumber(taxRate.rate, locale)

  if (isShortTitle) {
    return taxRatePercent
  }

  return `(${taxRatePercent}) ${taxRateName}`
}
