import { Button, ButtonsGroup, Flex, Modal, ModalProps, useModal } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSendAsEmailForm } from '../../../../contexts/SendAsEmailFormContext'

type SendAsEmailModalFooterProps = Pick<ModalProps, 'id'>

export const SendAsEmailModalFooter = ({ id }: SendAsEmailModalFooterProps) => {
  const { t } = useTranslation()
  const { close } = useModal(id)
  const { isSubmitting, submitForm } = useSendAsEmailForm()

  const handleCancelButtonClick = useCallback(() => {
    close()
  }, [close])

  const handleSendButtonClick = useCallback(() => {
    submitForm()
  }, [submitForm])

  // TODO: add translations
  return (
    <Modal.Footer>
      <Flex justifyContent="space-between" alignItems="center" flexGrow={1}>
        <Button disabled={isSubmitting} variant="text">
          Add files
        </Button>
        <ButtonsGroup>
          <Button disabled={isSubmitting} variant="text" onClick={handleCancelButtonClick}>
            {t('cancel')}
          </Button>
          <Button disabled={isSubmitting} loading={isSubmitting} onClick={handleSendButtonClick}>
            Send now
          </Button>
        </ButtonsGroup>
      </Flex>
    </Modal.Footer>
  )
}
