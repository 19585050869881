import { css, keyframes } from '@emotion/core'

const buttonFadeOutAnimation = keyframes`
  0%, 80% { opacity: 1; }
  100% { opacity: 0; }
`

export const navigationButtonAnimation = () => css`
  animation: ${buttonFadeOutAnimation} 1.1s ease-in-out 0.8s forwards;
`
