import { Flex } from '@design-system'

import { css } from '@emotion/core'
import cc from 'classcat'
import React, { useCallback, useState } from 'react'

import { reactClass } from '../../utils'
import { IconCaretDown } from '../Icons'
import * as Styled from './styles'

export type FlipperProps = {
  children?: React.ReactNode
  className?: string
  onFlipSide?: () => void
}

export const Flipper = ({ children, className, onFlipSide = () => null }: FlipperProps) => {
  const [side, setSide] = useState<number>(0)

  const handleFlipSide = useCallback(() => {
    setSide((prevSide) => (prevSide + 1) % 2)
    onFlipSide()
  }, [onFlipSide])

  return (
    <Styled.FlipperContainer className={cc([className, reactClass('flipper')])}>
      <Flex>
        <Flex width="100%">
          {React.Children.map(
            children as React.ReactNode[],
            (child: React.ReactNode, index: number): JSX.Element | null =>
              React.isValidElement(child) && index === side ? React.cloneElement(child, {}) : null,
          )}
        </Flex>
        <IconCaretDown
          onClick={handleFlipSide}
          rotate={!!side}
          width={13}
          ml="15px"
          css={css`
            cursor: pointer;
          `}
        />
      </Flex>
    </Styled.FlipperContainer>
  )
}
